import { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { Tabs } from '@mui/base/Tabs';
import { TabsList as BaseTabsList } from '@mui/base/TabsList';
import { buttonClasses } from '@mui/base/Button';
import { Tab as BaseTab, tabClasses } from '@mui/base/Tab';
import GridViewIcon from '@mui/icons-material/GridView';
import CloudOutlinedIcon from '@mui/icons-material/CloudOutlined';
import * as pallete from '@geowellex/shared-ui/src/theme/default';

interface TabsDataSelectProps {
    onChange: () => void;
    cloudWells: () => void;
    reset?: boolean;
};  

export default function TabsDataSelect({ onChange, cloudWells, reset }: TabsDataSelectProps) {
    const [value, setValue] = useState('available-wellsites');
    const handleChange = (
        // @ts-ignore
        event: React.SyntheticEvent<Element, Event> | null,
         newValue: any
    ) => {
        setValue(newValue);
        if (newValue == 'available-wellsites') {
            onChange();
        }
        if (newValue == 'cloud-connection') {
            cloudWells();
        }
    };

    useEffect(() => {
        if(reset === true){
            handleChange(null, 'available-wellsites');
        }
    },[reset]);

    return (
    <div style={{ display: 'flex' }}>
        <Tabs value={value} onChange={handleChange}>
            <TabsList>
                <Tab value={'available-wellsites'}>
                    <div style={{display: 'flex', width: '100%', alignItems: 'center', gap: '16px'}}>
                        <GridViewIcon />
                        <span>Available Wellsites</span>
                    </div>
                </Tab>
                <Tab value={'cloud-connection'}>
                    <div style={{display: 'flex', width: '100%', alignItems: 'center', gap: '16px'}}>
                        <CloudOutlinedIcon />
                        <span>Cloud Connection</span>
                    </div>
                </Tab>
            </TabsList>
        </Tabs>
    </div>
    );
};

const Tab = styled(BaseTab)`
  color: ${pallete.customTheme.gray1};
  flex-grow: 1;
  white-space: nowrap;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  background-color: transparent;
  width: 100%;
  line-height: 1.5;
  padding: 8px 12px;
  margin: 6px;
  border: none;
  border-radius: 8px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${pallete.customTheme.purple3};
  }

  &:focus {
    color: #fff;
    outline: 1px solid ${pallete.customTheme.purple3};
  }

  &.${tabClasses.selected} {
    background-color: ${pallete.customTheme.purple3};
    color: #fff;
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;


const TabsList = styled(BaseTabsList)(() => `
  min-width: 400px;
  background-color: ${pallete.customTheme.purple4};
  border: 1px solid ${pallete.customTheme.purple3};
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  `,
);
