import React from 'react'
import { Container, TextFieldStyled } from './styles'
import { Controller, useFormContext } from 'react-hook-form';
import { Label } from '../Label'

export const FieldRange = ({ control, name, label, inputProps, handleChange, dataCy, ...rest }:any) => {
  const { setValue } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState}) => {
        const formatValue = field?.value ? parseFloat(field?.value): field.value;
        return (
          <Container>
            <Label text={label} />
            <TextFieldStyled
              {...field}
              inputProps={inputProps}
              error={fieldState.error?.message}
              helperText={fieldState.error?.message}
              {...rest}
              onChange={(e) => {
                field.onChange(e);
                setValue(name, e.target.value, { shouldTouch: true });
                rest?.clearError?.(name);
                handleChange(name, e.target.value)
              }}
              value={formatValue}
              data-cy={dataCy}
            />
          </Container>
        )
      }}
    />
  )
}