import React, { useState } from 'react';
import UI from '@geowellex/shared-ui';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ZoneItemType, ZoneListType } from '../../types/zone';
import { sortZones } from '../../utils/sort.ts';
import ConfirmDeleteZone from '../ConfirmDeleteZone'
import { ReactComponent as EditZone } from '../../assets/edit_icon.svg';
import { ReactComponent as DeleteZone } from '../../assets/delete_icon.svg';

import { ActionsZoneContainer, InfoZoneContainer, ItemOptions, ListContainer, OptionsZone, TitleZone, WrapperTopBottom } from './styles.tsx';
export type sortZoneType = {
  zoneA: { top: string };
  zoneB: { top: string };
};

export const List = ({
  zones = [],
  afterDelete,
  afterUpdate,
  customCallbackActions,
  getCurrentZone
}: ZoneListType) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenDeleteZone, setIsOpenDeleteZone] = useState(false);
  const [selectedZone, setSelectedZone] = useState<null | ZoneItemType>(null);
  const sortedZonesByTop = sortZones(zones);

  const handleClickMoreActions = (event: any, zone: ZoneItemType) => {
    setAnchorEl(event.currentTarget);
    setSelectedZone(zone);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClickAction = (openAction: any, getCurrentZone: any, zone: any) => {
    openAction(zone);
    getCurrentZone(zone);
  }

  const handleChangeDeleteZone = () => {
    setIsOpenDeleteZone(!isOpenDeleteZone);
  }

  const handleConfirmDeleteZone = () => {
    handleChangeDeleteZone();
    afterDelete(selectedZone as any);
  }

  return (
    <>
      {sortedZonesByTop.map((zone) => (
        <ListContainer key={zone.uuid}>
          <InfoZoneContainer>
            <TitleZone>
              <span>{zone.label}</span>
              <OptionsZone onClick={(event) => handleClickMoreActions(event, zone)}>
                <MoreVertIcon />
              </OptionsZone>
              <UI.Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                onClose={handleMenuClose}
              >
                <ItemOptions onClick={() => afterUpdate(selectedZone?.uuid as string)}>
                  <EditZone /> Edit
                </ItemOptions>
                <ItemOptions isDelete onClick={handleChangeDeleteZone}>
                  <DeleteZone /> Delete
                </ItemOptions>
              </UI.Menu>
            </TitleZone>
            <WrapperTopBottom>
              <span>Top: {Number(zone.top || 0)?.toFixed(2)} m</span>
              <span>Bottom: {Number(zone.bottom || 0)?.toFixed(2)} m</span>
            </WrapperTopBottom>
            {/* <Status>Enabled</Status> */}
          </InfoZoneContainer>

          <ActionsZoneContainer>
            {customCallbackActions.map(([cb, title, actionConfig]: [cb: any, title: any, actionConfig: any]) => (
              <UI.Button
                theme='tertiary'
                type='button'
                startIcon={actionConfig?.icon}
                contentAlign='start'
                onClick={() => handleClickAction(cb, getCurrentZone, zone)}
              >
                {title}
              </UI.Button>
            ))}
          </ActionsZoneContainer>
        </ListContainer>
      ))}
      <ConfirmDeleteZone
        isOpen={isOpenDeleteZone}
        onClose={handleChangeDeleteZone}
        onConfirm={handleConfirmDeleteZone}
      />
    </>
  );
};
