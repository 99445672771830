import { Container, CircularProgress } from '@material-ui/core';

import './style.scss';

const Loading = () => {
  return (
    <Container className='container-loading'>
      <CircularProgress className='circular-progress-molar' />
      <span>Loading</span>
    </Container>
  )
}

export default Loading;
