import React from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import './style.scss';

const FullScreenMode = (({ children }) => {
  const handle = useFullScreenHandle();

  const onClickHandler = handle.active ? handle.exit : handle.enter;

  return(
    <FullScreen handle={handle}>
      <div className="wrapper-full-screen">
        <button
          type="button"
          onClick={onClickHandler}
          className="wrapper-full-screen__btn"
        >
          {handle.active ? <FullscreenExitIcon className='full-screen-icon' /> : <FullscreenIcon className='full-screen-icon' />}
        </button>
      </div>
      {children}
    </FullScreen>
  );
});

export default FullScreenMode;
