import styled from "styled-components";
import TuneIcon from '@mui/icons-material/Tune';
import { Grid } from "antd";
import * as colors from "../../theme/colors";

export const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

export const WrapperInputs = styled.div`
  gap: 8px;
`;

export const WrapperLogSetSelect = styled.div`
  display: flex;
  gap: 8px;
`;

export const TuneIconStyled = styled(TuneIcon)`
  && {
    transform: rotate(90deg);
  }
`;

export const WrapperInputRow = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 8px;
  padding-bottom: 0;
`;

export const WrapperLoadedInterval = styled.div`
  display: flex;
  min-height: 287px;
  background-color: #111827;
  padding: 16px;
  border-radius: 8px;
  gap: 44px;

  > div:nth-child(1) {
    margin-left: 40px;
  }
`;

export const WrapperLoadedIntervalInputs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const WrapperButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 24px 16px 24px;
  margin-top: 40px;
`;

export const WrapperAddButton = styled.div`
  display: flex;
  width: 200px;
  padding: 10px;
`;

export const bitSizeItemStyles = {
  backgroundColor: `${colors.backgroundWrappers}`,
  border: `1px solid ${colors.wrapBorders}`,
  borderRadius: '10px',
  paddingLeft: '10px',
  paddingBottom: '15px',
  marginTop: '10px'
};  
