import React, { Component } from 'react';
import Highcharts from 'highcharts';
import { Button } from 'antd';
import classNames from 'classnames';
import { getPointsFromC1C3, filterToAvoidZeroXAxis } from '../../../utils';
import {
  calculateCoordinatesOfExponential,
  calculateTransitionExponential
} from '../../../utils/CalculatingCoordinatesArea';
import { drawPath } from '../../../utils/Svg';
import { customTheme } from '@geowellex/shared-ui/src/theme/default';
import { gasColors, oilColors, transitionColors } from 'constants/crossplots';
/*

X – C1 (N)   Y – C1/C3 (Log)

Oil Zone (1-2-3-4)
1-2: y = 177418e-0,365x
2-3: y = 0,824e0,0248x
3-4: y = 2E+15e-0,414x
4-1: y = 2,2768e0,0247x

Gas Zone (5-6-7-8)
5-6: y = 10385e-0,071x
6-7: y = 8E-05e0,1342x
7-8: y = 3E+35e-0,78x
8-5: y = 0,0002e0,1516x


Transition Superior:
y = 7E-05e0,1646x
Min: x = 74,5
Max: x = 80

Transition Inferior:
y = 0,0086e0,0846x
Min: x = 80,2
Max: x = 90

*/

// 1-2: y = 177418e-0,365x
// 4-1: y = 2,2768e0,0247x
const P1 = calculateCoordinatesOfExponential(
  177418,
  -0.365,
  2.2768,
  0.0247
);

// 1-2: y = 177418e-0,365x
// 2-3: y = 0,824e0,0248x
const P2 = calculateCoordinatesOfExponential(
  177418,
  -0.365,
  0.824,
  0.0248
);

// 2-3: y = 0,824e0,0248x
// 3-4: y = 2E+15e-0,414x
const P3 = calculateCoordinatesOfExponential(
  0.824,
  0.0248,
  2 * Math.pow(10, 15),
  -0.414
);

// 3-4: y = 2E+15e-0,414x
// 4-1: y = 2,2768e0,0247x
const P4 = calculateCoordinatesOfExponential(
  2.2768,
  0.0247,
  2 * Math.pow(10, 15),
  -0.414
);

// 5-6: y = 10385e-0,071x
// 8-5: y = 0,0002e0,1516x
const P5 = calculateCoordinatesOfExponential(
  10385,
  -0.071,
  0.0002,
  0.1516
);

// 5-6: y = 10385e-0,071x
// 6-7: y = 8E-05e0,1342x
const P6 = calculateCoordinatesOfExponential(
  10385,
  -0.071,
  8 * (1 / Math.pow(10, 5)),
  0.1342
);

// 6-7: y = 8E-05e0,1342x
// 7-8: y = 3E+35e-0,78x
const P7 = calculateCoordinatesOfExponential(
  8 * (1 / Math.pow(10, 5)),
  0.1342,
  3 * Math.pow(10, 35),
  -0.78
);

// 8-5: y = 0,0002e0,1516x
// 7-8: y = 3E+35e-0,78x
const P8 = calculateCoordinatesOfExponential(
  0.0002,
  0.1516,
  3 * Math.pow(10, 35),
  -0.78
);

/*
Transition bottom:
y = 0,0086e0,0846x
Min: x = 80,2
Max: x = 90
*/
const bottomMin = calculateTransitionExponential(
  80.2, 0.0086, 0.0846
);

const bottomMax = calculateTransitionExponential(
  90, 0.0086, 0.0846
);

/*
Transition upper:
y = 7E-05e0,1646x
Min: x = 74,5
Max: x = 80
*/
const upperMin = calculateTransitionExponential(
  74,
  7 * (1 / Math.pow(10, 5)),
  0.1646
);
const upperMax = calculateTransitionExponential(
  80,
  7 * (1 / Math.pow(10, 5)),
  0.1646
);

const drawOil = chart => drawPath(
  P1.x, P1.y,
  P2.x, P2.y,
  P3.x, P3.y,
  P4.x, P4.y,
  chart,
  oilColors,
  true
);

// Gas area
const drawGas = (chart) => drawPath(
  P5.x, P5.y,
  P6.x, P6.y,
  P7.x, P7.y,
  P8.x, P8.y,
  chart,
  gasColors
);

// Transition area
const drawTransition = (chart) => drawPath(
  upperMin.x, upperMin.y,
  bottomMin.x, bottomMin.y,
  bottomMax.x, bottomMax.y,
  upperMax.x, upperMax.y,
  chart,
  transitionColors
);

class ScatterPlotC1C3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: true
    };
    this.chart = null;
  }

  componentDidMount() {
    this.createScatterPlot();

    this.setupChart()
  }

  shouldComponentUpdate(nextProps) {
    const { data } = this.props;
    if ((nextProps.data && nextProps.data.length) !== (data && data.length)) {
      this.createScatterPlot(nextProps);
      this.setupChart();
      return true;
    }
    return false;
  }

  setupChart = () => {
    this.drawOil = () => drawOil(this.chart);
    this.drawGas = () => drawGas(this.chart);
    this.drawTransition = () => drawTransition(this.chart);

    // Oil
    this.chart.oil = this.drawOil();
    // Gas area
    this.chart.gas = this.drawGas();
    // Transition area
    this.chart.transition = this.drawTransition();

    const { idListener } = this.props;

    document.addEventListener(`${idListener}-chart4`, ({ detail }) => {
      const { config } = detail;
      const {yAxis, xAxis} = this.chart;
      if (yAxis && yAxis[0]) {
        yAxis[0].update({
          min: config.y.min,
          max: config.y.max,
          title: {
            text: config.y.title
          }
        });
      }
      if (xAxis && xAxis[0]) {
        xAxis[0].update({
          min: config.x.min,
          max: config.x.max,
          title: {
            text: config.x.title
          }
        });
      }

      this.chart.oil.destroy();
      this.chart.oil = this.drawOil();

      this.chart.gas.destroy();
      this.chart.gas = this.drawGas();

      this.chart.transition.destroy();
      this.chart.transition= this.drawTransition();

      this.setState({
        isVisible: config.isVisible
      });
    });
  }

  createScatterPlot = () => {
    const {
      id, data, receivePointsIsideZone, dataWithDepth
    } = this.props;

    const filteredToAvoidZero = filterToAvoidZeroXAxis(data);

    const eulerConstant = 2.718281828;
    const oilP1 = P2;
    const oilP2 = P3;
    const oilP3 = P4;
    const oilP4 = P1;
    // 3 - 4: 2E+15e-0,414x
    const equation3To4 = (nX) => 2 * Math.pow(10, 15) * Math.pow(eulerConstant, - 0.414 * nX);
    // 4 - 1: y = 2,2768e0,0247x
    const equation4To1 = (nX) => 2.2768 * Math.pow(eulerConstant, (0.0247 * nX));
    // 1 - 2: y = 177418e-0,365x
    const equation1To2 = (nX) => 177418 * Math.pow(eulerConstant, (-0.365 * nX));
    // 2 - 3: y = 0,824e0,0248x
    const equation2To3 = (nX) => 0.824 * Math.pow(eulerConstant, (0.0248 * nX));

    const supMin = { x: 74.5, y: 14.338 };
    const supMax = { x: 80, y: 35.44 };
    const transitionP3 = { x: 78.4, y: 15.8 };
    const infMin = { x: 80.2, y: 7.6 };
    const infMax = { x: 90, y: 17.42 };
    const upperEquation = (nX) => 7 * Math.pow(10, -5) * Math.pow(eulerConstant, 0.1646 * nX);
    const bottomEquation = (nX) => 0.0086 * Math.pow(eulerConstant, 0.0846 * nX);

    const gasP5 = P5;
    const gasP6 = P6;
    const gasP7 = P7;
    const gasP8 = P8;

    const resultInterestedArea = getPointsFromC1C3(
      data,
      oilP1, oilP2, oilP3, oilP4,
      supMin, supMax, transitionP3, infMin, infMax,
      gasP5, gasP6, gasP7, gasP8,
      equation3To4, equation4To1, equation1To2, equation2To3,
      upperEquation, bottomEquation
    );

    if (resultInterestedArea) {
      const filteredPoints = this.shouldFilterPointsWithinZoneByDepth(
        dataWithDepth,
        resultInterestedArea
      );
      receivePointsIsideZone(filteredPoints);
    }

    const getTooltipWithDepth = (dataWithDepth = []) => ({
      headerFormat: '<b>{series.name}</b><br>',
      // pointFormat: `x: {point.x}, y: {point.y}`,
      formatter: function() {
        const itemWithDepth = dataWithDepth?.find(
          value => value?.item?.[0] === this?.x && value?.item?.[1] === this?.y
        );
        return `
          <b>Depth</b>: ${itemWithDepth?.depth}<br/>
          <b>C1</b>: ${itemWithDepth?.item?.[0]?.toFixed(2)}  <br/>
          <b>C1/C3</b>: ${itemWithDepth?.item?.[1]?.toFixed(2)}
        `;
      },
      enabled: true
    });

    this.chart = Highcharts.chart(id, {
      chart: {
        type: 'scatter',
        backgroundColor: customTheme.purple4,
      },
      title: {
        text: ' '
      },
      subtitle: {
        text: ' '
      },
      credits: {
        enabled: false
      },
      fillOpacity: 1,
      opacity: 1,
      exporting: { enabled: false },
      xAxis: {
        title: {
          enabled: true,
          text: 'C1',
          style: {
            color: '#fff'
          }
        },
        labels: {
          style: {
            color: customTheme.gray7 // Color for the tick labels
          }
        },
        startOnTick: true,
        endOnTick: true,
        type: '',
        showLastLabel: true,
        tickInterval: 20,
        minorTickInterval: 1,
        pointStart: 1.0,
        max: 90,
        min: 0,
        gridLineWidth: 0
      },
      yAxis: {
        title: {
          text: 'C1/C3',
          style: {
            color: '#fff'
          }
        },
        lineWidth: 1,
        lineColor: '#ccd6eb',
        type: 'logarithmic',
        minorTickInterval: 0.1,
        max: 1000,
        min: 1,
        gridLineWidth: 0,
        labels: {
          style: {
            color: customTheme.gray7 // Color for the tick labels
          }
        },
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: 0,
        y: -14,
        floating: true,
        backgroundColor: '#FFFFFF',
        borderWidth: 0
      },
      plotOptions: {
        scatter: {
          marker: {
            radius: 3,
            states: {
              hover: {
                enabled: true,
                lineColor: 'rgb(100,100,100)'
              }
            }
          },
          states: {
            hover: {
              marker: {
                enabled: false
              }
            }
          },
          // tooltip: {
          //   headerFormat: '<b>{series.name}</b><br>',
          //   pointFormat: 'x -> {point.x} <br> y -> {point.y}',
          //   enabled: true
          // }
        }
      },
      tooltip: getTooltipWithDepth(this.props.dataWithDepth),
      series: [
        {
          name: ' ',
          color: 'rgba(231, 76, 60, 0.95)',
          data: filteredToAvoidZero,
          marker: {
            fillColor: 'rgba(231, 76, 60, 0.95)',
            symbol: 'circle'
          }
        }
      ]
    });
  }

  // AS5 = C1    CC5 = C1/C3
  calculateFluidTypeFromC1C3Chart = (AS5, CC5) => {
    /*
    =IF(
      AND(AS5<=87,1;AS5>=77,2;CC5<=26;CC5>=12)=TRUE;20;
        IF(AS5<=80,5;10;30)
    ) */
    if (AS5 <= 87.1 && AS5 >= 77.2 && CC5 <= 26 && CC5 >=12) {
      return 20;
    }
    if (AS5 <= 80.5) {
      return 10;
    }
    return 30;

  }

  shouldFilterPointsWithinZoneByDepth = (dataWithDepth, resultInterestedArea) => {
    let filteredPoints = [];
    filteredPoints = dataWithDepth.reduce((acc, currentValue) => {
      // Check if this oil point is equal to the point associated with a depth
      resultInterestedArea.oil.reduce((acc2, oilPoint) => {
        if (
          JSON.stringify(oilPoint) === JSON.stringify(currentValue.item)
        ) {
          const { c1c3, c1 } = currentValue;
          const normalizedX = this.calculateFluidTypeFromC1C3Chart(c1, c1c3);
          acc.push([normalizedX, currentValue.depth]);
        }
        return acc2;
      }, []);
      resultInterestedArea.gas.reduce((acc3, gasPoint) => {
        if (
          JSON.stringify(gasPoint) === JSON.stringify(currentValue.item)
        ) {
          const { c1c3, c1 } = currentValue;
          const normalizedX = this.calculateFluidTypeFromC1C3Chart(c1, c1c3);
          acc.push([normalizedX, currentValue.depth]);
        }
        return acc3;
      }, []);
      resultInterestedArea.transition.reduce((acc4, transitionPoint) => {
        if (
          JSON.stringify(transitionPoint) === JSON.stringify(currentValue.item)
        ) {
          const { c1c3, c1 } = currentValue;
          const normalizedX = this.calculateFluidTypeFromC1C3Chart(c1, c1c3);
          acc.push([normalizedX, currentValue.depth]);
        }
        return acc4;
      }, []);
      return  acc;
    }, []);
    return filteredPoints;
  }

  render() {
    const {
      id, withMinorGridLines, className,
      openConfigureScale
    } = this.props;
    const { isVisible } = this.state;
    return (
      <div
        className="scatter-general scatter-container-c1"
        style={{
          width: '400px',
          border: `1px solid ${customTheme.purple3}`,
          display: isVisible ? 'flex' : 'none',
          backgroundColor: 'transparent'
        }}
      >
        {
          openConfigureScale && (
            <Button
              type="primary"
              shape="circle"
              icon="setting"
              size="large"
              className="toggle-crossplot-config"
              onClick={openConfigureScale}
            />
          )
        }
        <div
          className={classNames(
            className,
            'deactivate-legend',
            { 'reset-minor-grids': !withMinorGridLines }
          )}
          id={id}
          style={{
            width: '400px',
            height: '350px',
            flex: 1
          }}
        />
      </div>
    );
  }
}

ScatterPlotC1C3.defaultProps = {
  withMinorGridLines: false,
  className: 'scatter-plot'
};

export default ScatterPlotC1C3;
