import React from 'react';
import Ui from '@geowellex/shared-ui'

const ChartHeader = ({
  title, unit, className
}) => (
  <div className={className}>
    <Ui.Header
      textCenter={`${title} (${unit ?? ''})`}
      background='#515775'
    />
  </div>
);

ChartHeader.defaultProps = {
  title: 'Total Depth',
  unit: null,
  className: 'charts-header__total-depth'
};

export default ChartHeader;
