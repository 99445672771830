import { Box, IconButton, MenuItem } from "@material-ui/core";
import styled from "styled-components";
import { customTheme } from '@geowellex/shared-ui/src/theme/default';

export const ListContainer = styled(Box)`
  width: 100%;
`

export const InfoZoneContainer = styled(Box)`
  border: 1px solid ${customTheme.purple7};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 16px;
`

export const TitleZone = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${customTheme.white};
  font-weight: 700;
  font-size: 21px;
`

export const OptionsZone = styled(IconButton)`
  color: ${customTheme.blue5};
  border-radius: 6px;
  padding: 8px;
  &:hover {
    background-color: ${customTheme.blue6};
  }
`

interface ItemOptionsProps {
  isDelete?: boolean
}

export const ItemOptions = styled(MenuItem)<ItemOptionsProps>`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  color: ${(props) => props.isDelete ? customTheme.red4 : customTheme.gray4};
  gap: 8px;
  padding: 6px 16px;
`

export const WrapperTopBottom = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: 16px;
  color: ${customTheme.gray5};
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: 8px;
`

export const Status = styled(Box)`
  color: ${customTheme.green2};
  font-size: 16px;
  font-weight: 500;
`

export const ActionsZoneContainer = styled(Box)`
  border: 1px solid ${customTheme.purple7};
  border-top: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
`