import { 
    GridToolbarContainer, 
    GridToolbarQuickFilter
} from "@mui/x-data-grid";
import TabsNav from "../TabsNav";
import { StyledBadge } from "../TabsNav/styles";

interface CustomToolbarProps {
    setFilter: (filter: boolean | null | undefined) => void;
}

export function CustomToolbar({ setFilter }: CustomToolbarProps) {

    const TabsItems = [
        {
            label: 'All',
            value: 'all-wells',
            content: (
                <StyledBadge 
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    badgeContent='' 
                    badgeTextColor="#0C0C14"
                    badgeColor="#fff">
                    All
                </StyledBadge>),
            filter: undefined,
        },
        {
            label: 'Active',
            value: 'active-wells',
            content: (<StyledBadge 
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                badgeContent='' 
                badgeTextColor="#45B56E"
                badgeColor="#116531">
                Active
            </StyledBadge>), 
            filter: true,
        },
        {
            label: 'Inactive',
            value: 'inactive-wells',
            content: (
                <StyledBadge 
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    badgeContent='' 
                    badgeTextColor="#E58E8E"
                    badgeColor="#881A1B">
                    Inactive
                </StyledBadge>),
            filter: false,
        },
    ];

    return (
        <GridToolbarContainer>
            <TabsNav tabsItems={TabsItems} setFilter={setFilter}/>
            <GridToolbarQuickFilter/>
        </GridToolbarContainer>
    );
}
