import styled from "styled-components";
import { Box } from "@mui/material";
import { customTheme } from "../../../src/theme/default";

export const ContainerInputColor = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 4px;

  span {
    font-size: 16px;
    color: ${customTheme.white};
  }
`

export const WrapperBoxColor = styled(Box)`
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid ${customTheme.purple7};
  border-radius: 4px;

  span {
    font-size: 16px;
    color: ${customTheme.white};
  }
`

export const BoxColor = styled(Box)`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  cursor: pointer;
`

export const ColorPickerContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: ${customTheme.white};
  width: 250px;
  height: 120px;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  position: relative;

  .alpha-picker {
    width: 230px !important;
  }
`

export const ColorPickerCompact = styled(Box)`
  > div > div {
    box-shadow: none !important;
    margin-bottom: 2px;
  }
  > div > div> div {
    padding-top: 0 !important;
    padding-left: 3px !important;
  }
`