import React from 'react';
import { Controller } from "react-hook-form";
import { SliderStyled } from './styles';
import Stack from '@mui/material/Stack';
import { useFormContext } from 'react-hook-form';

function valuetext(value: number) {
  return `${value}`;
}

export function RangeSlider({
  control, setValue, getTopAndBottom,
  minRange, maxRange, values
}) {
  const { getValues } = useFormContext();
  const formatMaxRange = parseInt(maxRange?.toFixed(2));
  const formatMinRange = parseInt(minRange?.toFixed(2));
  const marks = [
    {
      value: -formatMaxRange,
      label: formatMaxRange,
    },
    {
      value: -formatMinRange,
      label: formatMinRange
    }
  ];

  const handleChange = (event: Event, newValue: number | number[]) => {
    const positiveValue = [Math.abs(newValue[1]), Math.abs(newValue[0])]
    setValue('range', { start_depth: Math.abs(newValue[1]), stop_depth: Math.abs(newValue[0]) });
    getTopAndBottom(positiveValue);
  };

  const startValue = getValues().start_depth;
  const stopValue = getValues().stop_depth;

  return (
    <Controller
      name="range"
      control={control}
      render={() => {
        return (
          <Stack sx={{ height: 300, marginLeft: '30px', padding: '10px' }} spacing={1} direction="row">
            <SliderStyled
              orientation='vertical'
              getAriaValueText={valuetext}
              defaultValue={[-formatMinRange, -formatMaxRange]}
              valueLabelDisplay="auto"
              value={[-startValue, -stopValue]}
              onChange={handleChange}
              marks={marks}
              min={-formatMaxRange}
              max={-formatMinRange}
              scale={x => -x}
              data-cy="slider"
            />
          </Stack>
        )
      }}
    />
  );
}
