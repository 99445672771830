import { useCallback, useEffect, useState } from 'react';
import * as wellActions from 'actions/wells';
import { applyZoneToCharts } from '@geowellex/shared-zone/src/utils/applyZoneToCharts';
import Highcharts from 'highcharts';
import FullScreen from 'components/FullScreen';
import ZoomButton from 'components/ZoomButton';
import PlotBoxEthene from 'components/RelativeEthene/PlotBox';
import PlotBoxMolarAnalysis from 'components/MolarAnalysis/PlotBox';
import get from 'lodash.get';
import { useSelector, useDispatch } from 'react-redux';
import HoleDepthCard from '../HoleDepthCard';
import CarbonCard from '../CarbonCard';
import GasesCards from '../GasesCards';
import ApiGorCards from '../ApiCards';
import { getForm, syncronizeDepthZoom } from '../../utils';
import TotalDepth from '../Charts/TotalDepth';
import SelectedDepth from '../Charts/SelectedDepth';
import RopChart from '../Charts/RopChart';
import TotalGas from '../Charts/TotalGas';
import Chromatography from '../Charts/Chromatography';
import GasComposition from '../Charts/GasComposition';
import BalanceRatio from '../Charts/BalanceRatio';
import ReasonChart from '../Charts/ReasonChart';
import Isotope from '../Charts/Isotope';
import PlotBox from '../PlotBox';
import { getCurveData } from '../../utils/getCurveDataBySet';
import { useWizards } from '../../screens/Statistics/components/ModalStatistics/useStatisticsWizard';
import ZoneDrawer from '../ZoneDrawer';
import UI from '@geowellex/shared-ui';
import { scales as DEFAULT_SCALES } from '../../utils/constants';
import ConfigModal from 'components/ConfigModal';

export const Dashboard = () => {
  const wells = useSelector(state => state.wells);
  const zones = useSelector(state => state.zone);
  const dispatch = useDispatch();
  const { selectedWell, currentWell } = wells;
  const [logSet, setLogSet] = useState({ default_curves: 'RAW', calculated_curves: 'calculated' });
  const [updateChart, setUpdateChart] = useState(false);

  const defaultWell = { depth: [] };

  const depth = getCurveData('depth', currentWell, 'RAW');

  const selectedMin = depth?.data ? (depth.data.length - 1) - ((depth.data.length - 1) / 3) : 0;
  const selectedMax = depth?.data ? depth.data.length - 1 : 0;
  const criticalAreasC1 = get(wells, 'currentWell.statistics.criticalAreasC1', get(wells, 'currentWell.criticalAreasC1', []));
  const criticalAreasC2 = get(wells, 'currentWell.statistics.criticalAreasC2', get(wells, 'currentWell.criticalAreasC2', []));
  const criticalAreasC3 = get(wells, 'currentWell.statistics.criticalAreasC3', get(wells, 'currentWell.criticalAreasC3', []));
  const criticalAreasC4 = get(wells, 'currentWell.statistics.criticalAreasC4', get(wells, 'currentWell.criticalAreasC4', []));
  const criticalAreasC5 = get(wells, 'currentWell.statistics.criticalAreasC5', get(wells, 'currentWell.criticalAreasC5', []));
  const criticalAreasIC4 = get(wells, 'currentWell.statistics.criticalAreasIC4', get(wells, 'currentWell.criticalAreasIC4', []));
  const criticalAreasIC5 = get(wells, 'currentWell.statistics.criticalAreasIC5', get(wells, 'currentWell.criticalAreasIC5', []));
  const criticalAreasC1Composition = get(wells, 'currentWell.statistics') ?
    getForm(wells.currentWell.statistics, 'formCollapseC1Composition') : [];
  const criticalAreasC2Composition = get(wells, 'currentWell.statistics') ?
    getForm(wells.currentWell.statistics, 'formCollapseC2Composition') : [];
  const criticalAreasC3Composition = get(wells, 'currentWell.statistics') ?
    getForm(wells.currentWell.statistics, 'formCollapseC3Composition') : [];
  const criticalAreasC4Composition = get(wells, 'currentWell.statistics') ?
    getForm(wells.currentWell.statistics, 'formCollapseC4Composition') : [];
  const criticalAreasC5Composition = get(wells, 'currentWell.statistics') ?
    getForm(wells.currentWell.statistics, 'formCollapseC5Composition') : [];

  useEffect(() => {
    if (wells.currentWell?.gqc) {
      setLogSet({ default_curves: 'gqcData', calculated_curves: 'gqcData' });
    } else {
      setLogSet({ default_curves: 'RAW', calculated_curves: 'calculated' });
    }
  }, [wells.currentWell])

  const gammaRay = get(getCurveData('gr', currentWell, logSet.default_curves), 'data', []);
  const rop = get(getCurveData('rop', currentWell, logSet.default_curves), 'data', []);


  const c1 = getCurveData('c1', currentWell, logSet.default_curves);
  const c2 = getCurveData('c2', currentWell, logSet.default_curves);
  const c3 = getCurveData('c3', currentWell, logSet.default_curves);
  const ic4Normal = getCurveData('ic4', currentWell, logSet.default_curves);
  const ic5Normal = getCurveData('ic5', currentWell, logSet.default_curves);
  const nc4 = getCurveData('nc4', currentWell, logSet.default_curves);
  const nc5 = getCurveData('nc5', currentWell, logSet.default_curves);

  const index = wells.selectedDepthIndex || 0;
  const totalGas = getCurveData('total_gas', currentWell, logSet.default_curves);

  const totalCarbon = getCurveData('total_carbon', currentWell, logSet.calculated_curves);
  const c1Normalized = getCurveData('c1_norm', currentWell, logSet.calculated_curves);
  const gqr = getCurveData('gqr', currentWell, logSet.calculated_curves);

  const c1Composition = getCurveData('c1_perc', currentWell, logSet.calculated_curves);
  const c2Composition = getCurveData('c2_perc', currentWell, logSet.calculated_curves);
  const c3Composition = getCurveData('c3_perc', currentWell, logSet.calculated_curves);
  const nc4Composition = getCurveData('c4_perc', currentWell, logSet.calculated_curves);
  const nc5Composition = getCurveData('c5_perc', currentWell, logSet.calculated_curves);


  const wetnessRatio = getCurveData('wh_ratio', currentWell, logSet.calculated_curves);
  const balanceRatioData = getCurveData('bh_ratio', currentWell, logSet.calculated_curves);
  const characterRatioData = getCurveData('ch_ratio', currentWell, logSet.calculated_curves);

  const ic4BynC4 = getCurveData('ic4nc4', currentWell, logSet.calculated_curves);
  const ic5BynC5 = getCurveData('ic5nc5', currentWell, logSet.calculated_curves);
  const c1Byc2 = getCurveData('c1c2', currentWell, logSet.calculated_curves);
  const slopeFactor = getCurveData('slope_factor', currentWell, logSet.calculated_curves);

  const cutOffs = selectedWell !== -1 ? wells.currentWell?.cutOffs : {};
  const thereIsASelectedWell = selectedWell && selectedWell !== -1;
  const maxDepthHoleDepthCard = depth?.data && depth?.data?.length > 0 ? depth.data.slice(-1) : 'N/A';
  const unit = currentWell ? currentWell.unit : '';

  const isotopeData = getCurveData('isotope', currentWell, logSet.default_curves)?.data;

  const propagateTotalDetphZoom = (min, max) => {
    const minMaxData = { min, max }
    dispatch(wellActions.receiveSelectedMinMax(minMaxData))
    syncronizeDepthZoom(Highcharts, min, max);
  };

  const handleUpdateChart = () => setUpdateChart(!updateChart);

  useEffect(() => {
    if (thereIsASelectedWell) {
      const minMaxData = { min: selectedMin, max: selectedMax }
      dispatch(wellActions.receiveSelectedMinMax(minMaxData))

      if (zones?.items?.length > 0) {
        zones.items.map((zone) => applyZoneToCharts('total-gas')(zone, Highcharts.charts))
        // map(zoneData, zone => applyZoneToCharts('total-gas')(zone, Highcharts.charts));
      }
      handleUpdateChart();
    }
  }, [selectedWell])
  const memoizedPropagateTotalDetphZoom = useCallback(propagateTotalDetphZoom, []);
  const normalizedDataSelectedDepth = depth?.data?.map(item => parseInt(item?.toFixed(3)));
  const normalizedDepthData = currentWell?.RAW?.depth?.data?.map(item => parseInt(item?.toFixed(3)));

  return (
    <>
      <FullScreen>
        <div className="home__main-content">
          {/* <div className="home__cards">
            <HoleDepthCard
              selectedWell={thereIsASelectedWell}
              maxDepth={maxDepthHoleDepthCard}
              unit={depth.unit}
            />
            <CarbonCard
              selectedWell={thereIsASelectedWell}
              totalCarbon={currentWell?.totalCarbon}
            />
            <ApiGorCards
              selectedWell={thereIsASelectedWell}
              depth={depth?.data}
              api={currentWell?.api}
              gor={currentWell?.gor}
            />
            <GasesCards
              selectedWell={thereIsASelectedWell}
              c2c3Ratios={currentWell?.c2c3Ratios}
              c3c2Ratios={currentWell?.c3c2Ratios}
            />
          </div> */}
          <div className="home__charts">
            <ZoomButton
              min={currentWell?.selectedMin}
              max={currentWell?.selectedMax}
            />
            <UI.CustomScrollBar
              style={{ paddingRight: 20, }}
              className="charts-wrapper"
            >
                <div className="charts-container">
                  <UI.Drawer
                    triggerEvent="openConfigModal"
                    getTitleByEventType={(eventType) => DEFAULT_SCALES?.[eventType]?.titleModal}
                  >
                    <ConfigModal />
                  </UI.Drawer>
                  <TotalDepth
                    data={depth?.data}
                    afterSelect={memoizedPropagateTotalDetphZoom}
                    unit={depth.unit}
                    min={selectedMin}
                    max={selectedMax}
                    selectedWell={selectedWell}
                    previousSelectedWell={wells.beforeWell}
                    onAfter={wellActions.registerDepthData}
                  />
                  <SelectedDepth
                    criticalAreasC1={criticalAreasC1}
                    criticalAreasC2={criticalAreasC2}
                    criticalAreasC3={criticalAreasC3}
                    criticalAreasC4={criticalAreasC4}
                    criticalAreasC5={criticalAreasC5}
                    criticalAreasIC4={criticalAreasIC4}
                    criticalAreasIC5={criticalAreasIC5}
                    criticalAreasC1Composition={criticalAreasC1Composition}
                    criticalAreasC2Composition={criticalAreasC2Composition}
                    criticalAreasC3Composition={criticalAreasC3Composition}
                    criticalAreasC4Composition={criticalAreasC4Composition}
                    criticalAreasC5Composition={criticalAreasC5Composition}
                    min={selectedMin}
                    max={selectedMax}
                    data={normalizedDepthData}
                    unit={depth.unit}
                    wells={wells}
                    selectedWell={selectedWell}
                    beforeWell={wells.beforeWell || defaultWell}
                    currentWell={wells.currentWell || defaultWell}
                    selectedDepthIndex={wells.selectedDepthIndex}
                    updateChart={updateChart}
                    finishUpdate={handleUpdateChart}
                  />
                  <RopChart
                    min={selectedMin}
                    max={selectedMax}
                    wobData={gammaRay}
                    ropData={rop}
                    selectedWell={selectedWell}
                    cutOffs={cutOffs}
                    beforeWell={wells.beforeWell || defaultWell}
                    currentWell={wells.currentWell || defaultWell}
                    updateChart={updateChart}
                  />
                  <TotalGas
                    min={selectedMin}
                    max={selectedMax}
                    depth={depth.data}
                    totalGasData={totalGas.data}
                    totalCarbon={totalCarbon.data}
                    c1Normalized={c1Normalized.data}
                    gqr={gqr.data}
                    selectedWell={selectedWell}
                    cutOffs={cutOffs}
                    beforeWell={wells.beforeWell || defaultWell}
                    currentWell={wells.currentWell || defaultWell}
                    updateChart={updateChart}
                  />
                  <Chromatography
                    min={selectedMin}
                    max={selectedMax}
                    c1={c1.data}
                    c2={c2.data}
                    c3={c3.data}
                    ic4={ic4Normal.data}
                    nc4={nc4.data}
                    ic5={ic5Normal.data}
                    nc5={nc5.data}
                    selectedWell={selectedWell}
                    cutOffs={cutOffs}
                    beforeWell={wells.beforeWell || defaultWell}
                    currentWell={wells.currentWell || defaultWell}
                    updateChart={updateChart}
                  />
                  <GasComposition
                    min={selectedMin}
                    max={selectedMax}
                    gasCompositionC1={c1Composition.data}
                    gasCompositionC2={c2Composition.data}
                    gasCompositionC3={c3Composition.data}
                    gasCompositionC4={nc4Composition.data}
                    gasCompositionC5={nc5Composition.data}
                    selectedWell={selectedWell}
                    cutOffs={cutOffs}
                    beforeWell={wells.beforeWell || defaultWell}
                    currentWell={wells.currentWell || defaultWell}
                    updateChart={updateChart}
                  />

                  <BalanceRatio
                    min={selectedMin}
                    max={selectedMax}
                    selectedWell={selectedWell}
                    wetnessData={wetnessRatio.data}
                    balanceRatioData={balanceRatioData.data}
                    characterRatioData={characterRatioData.data}
                    beforeWell={wells.beforeWell || defaultWell}
                    currentWell={wells.currentWell || defaultWell}
                    cutOffs={cutOffs}
                    updateChart={updateChart}
                  />

                  <ReasonChart
                    min={selectedMin}
                    max={selectedMax}
                    C1C2={c1Byc2.data}
                    nc4={ic4BynC4.data}
                    nc5={ic5BynC5.data}
                    SF={slopeFactor.data}
                    selectedWell={selectedWell}
                    beforeWell={wells.beforeWell || defaultWell}
                    currentWell={wells.currentWell || defaultWell}
                    cutOffs={cutOffs}
                    updateChart={updateChart}
                  />
                  <Isotope
                    min={selectedMin}
                    max={selectedMax}
                    isotopeData={isotopeData}
                    selectedWell={selectedWell}
                    cutOffs={cutOffs}
                    beforeWell={wells.beforeWell || defaultWell}
                    currentWell={wells.currentWell || defaultWell}
                    updateChart={updateChart}
                  />
              </div>
            </UI.CustomScrollBar>
          </div>
        </div>
        <PlotBox
          selectedWell={currentWell || null}
          well={selectedWell}
          curvesKeys={currentWell?.curvesKeys}
          data={currentWell || null}
          sensorType={wells?.currentWell?.ethene_sensor?.has_sensor || null}
          zones={zones.items}
        />
        <PlotBoxEthene
          selectedWell={currentWell || null}
          well={selectedWell}
          curvesKeys={currentWell?.curvesKeys}
          data={currentWell || null}
        />
        <PlotBoxMolarAnalysis
          selectedWell={currentWell || null}
          well={selectedWell}
          curvesKeys={currentWell?.curvesKeys}
          data={currentWell}
        />
      </FullScreen>
      <ZoneDrawer wellId={currentWell?.wellId} />
    </>
  )
}
