import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import { customTheme } from '../../../../theme/default'; 

export const CustomText = styled(ListItemText)(() => (() => `
    display: flex;
    color: ${customTheme.gray2}
    & > span {
      margin-left: 20px;
    }
  }
`));
