import React, {
  memo,
  useEffect,
  useState
} from 'react';

import { useSelector } from 'react-redux';
import Ui from '@geowellex/shared-ui'
import store from 'store';
import {
  nFormatter,
  toggleC1NormalizedChart,
  toggleTotalCarbonChart,
  toggleTotalGasChart,
  toggleGQRChart,
} from 'utils';
import { getCalculatedCurveData } from '../../../../utils/getCalculatedCurveData';
import { getRawCurveData } from '../../../../utils/getRawCurveData';
import { useScales } from '../../../../hooks/useScales';
import { totalGasCurveTypes } from '../constants';
import { DEFAULT_SCALES } from '../../../../utils/constants';

const ChartHeader = ({
  rangeValues,
  positionsLeft
}) => {
  const wells = useSelector(state => state.wells);

  const initialHeaderState = {
    gasTotal: 0,
    totalCarbon: 0,
    c1Normalized: 0,
    gqr: 0,
  };

  const {
    currentScale, setHeaderState, headerState
  } = useScales(
    totalGasCurveTypes,
    wells.currentWell,
    initialHeaderState
  );

  // --- Toggle Tracks
  const [tracks, setToggle] = useState({
    isOpen: false,
    totalGas: true,
    totalCarbon: true,
    c1Normalized: true,
    gqr: true
  });

  const tooggleCallbacks = {
    totalGas: toggleTotalGasChart,
    totalCarbon: toggleTotalCarbonChart,
    c1Normalized: toggleC1NormalizedChart,
    gqr: toggleGQRChart
  };

  const toggleTrack = (event, name, index) => {
    const trackName = tracks[name];
    const callback = tooggleCallbacks[name];
    callback(parseInt(index))(event);
    setToggle({
      ...tracks,
      isOpen: !tracks.isOpen,
      [name]: !trackName
    });
  };

  const toggleTrackCurrentTrack = (event) => {
    const { dataset: { name, index } } = event.target;
    const trackName = tracks[name];
    const callback = tooggleCallbacks[name];

    if (callback) {
      callback(parseInt(index))(event);
      setToggle({
        ...tracks,
        isOpen: !tracks.isOpen,
        [name]: !trackName
      });
    }
  };

  const dispatchModal = (typeOfTitle) => (event) => {
    event.persist();
    event.stopPropagation();
    if (event.target && event.target.dataset.cmd === 'toggle') {
      toggleTrackCurrentTrack(event);
      return;
    } else if (event.target && event.target.dataset.cmd === 'openConfig') {
      const openModal = new CustomEvent('openConfigModal', {
        detail: {
          type: typeOfTitle
        }
      });
      document.dispatchEvent(openModal);
      return;
    }
  };

  // TODO create a custom hook to treat it
  useEffect(() => {
    if (wells.currentWell && wells.selectedDepthIndex >= 0) {
      const { currentWell } = wells;
      const totalGas = getRawCurveData('total_gas', currentWell);
      const totalCarbon = getCalculatedCurveData('total_carbon', currentWell);
      const c1Normalized = getCalculatedCurveData('c1_norm', currentWell);
      const gqr = getCalculatedCurveData('gqr', currentWell);

      const index = wells.selectedDepthIndex || 0;
      const gasTotalValue = totalGas.data && totalGas.data[index] ? totalGas.data[index] : 0;
      const totalCarbonValue = totalCarbon.data && totalCarbon.data[index] ? totalCarbon.data[index] : 0;
      const c1NormalizedValue = c1Normalized.data && c1Normalized.data[index] ? c1Normalized.data[index] : 0;
      const gqrNormalizedValue = gqr.data && gqr.data[index] ? gqr.data[index] : 0;
      setHeaderState({
        ...headerState,
        gasTotal: gasTotalValue < 0 ? '' : gasTotalValue?.toFixed(2),
        totalCarbon: totalCarbonValue < 0 ? '' : totalCarbonValue?.toFixed(2),
        c1Normalized: c1NormalizedValue < 0 ? '' : c1NormalizedValue?.toFixed(2),
        gqrDepth: gqrNormalizedValue < 0 ? '' : gqrNormalizedValue?.toFixed(2),
      });
    }

    if (!wells.currentWell) {
      setHeaderState({
        ...headerState,
        gasTotal: 0,
        totalCarbon: 0,
        c1Normalized: 0,
        gqrDepth: 0
      });
    }
  }, [wells]);
  const { gasTotal, totalCarbon, gqrDepth, c1Normalized} = headerState;
  const { total_gas, total_carbon, gqr, c1_norm } = currentScale;

  return (
    <div
      style={{
        display: 'flex',
        height: '180px',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        position:'relative',
        gap: '4px',
        width: 280,
        alignItems: 'center'
      }}
    >
      <Ui.Header
        textCenter={`Total Gas ${gasTotal > 0 ? gasTotal : '(units)'}`}
        min={nFormatter(total_gas?.min)}
        max={nFormatter(total_gas?.max)}
        background={total_gas?.color}
        checkboxIsVisible
        checked={tracks.totalGas}
        onChangeCheckbox={(event) => toggleTrack(event, 'totalGas', 1)}
        onClick={dispatchModal('total_gas')}
        activeCurveConfig='total_gas'
        dataCy="open-config-total-gas"
      />
      <Ui.Header
        textCenter={totalCarbon ? `Total Carbon ${totalCarbon}` : 'Total Carbon'}
        min={nFormatter(total_carbon?.min)}
        max={nFormatter(total_carbon?.max)}
        background={total_carbon?.color}
        checkboxIsVisible
        checked={tracks.totalCarbon}
        onChangeCheckbox={(event) => toggleTrack(event, 'totalCarbon', 2)}
        onClick={dispatchModal('total_carbon')}
        activeCurveConfig='total_carbon'
        dataCy="open-config-total-carbon"
      />
      <Ui.Header
        textCenter={`C1 Normalized ${c1Normalized > 0 ? c1Normalized : ''}`}
        min={nFormatter(c1_norm?.min)}
        max={nFormatter(c1_norm?.max)}
        background={c1_norm?.color}
        checkboxIsVisible
        checked={tracks.c1Normalized}
        onChangeCheckbox={(event) => toggleTrack(event, 'c1Normalized', 3)}
        onClick={dispatchModal('c1Normalized')}
        activeCurveConfig='c1Normalized'
        dataCy="open-config-c1-normalized"
      />
      <Ui.Header
        textCenter={`Gas Quality Ratio ${gqrDepth > 0 ? gqrDepth : ''}`}
        min={nFormatter(gqr?.min)}
        max={nFormatter(gqr?.max)}
        background={gqr?.color}
        checkboxIsVisible
        checked={tracks.gqr}
        activeCurveConfig='gqr'
        onChangeCheckbox={(event) => toggleTrack(event, 'gqr', 4)}
        onClick={dispatchModal('gqr')}
      />
      <Ui.Header
        rangeValue={rangeValues}
        background='#323752'
      />
    </div>
  );
};

export default memo(ChartHeader);
