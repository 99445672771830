import { useId, useState } from 'react';

import { MenuItemType } from '../../../types';
import { LogSetType, LogCurve } from '../../../../SelectLogSets/types';
import { Logo } from '../../Logo';
import { ReactComponent as FileIcon} from '../../../../../icons/file_icon.svg';
import { ReactComponent as RightArrowIcon } from '../../../../../icons/right_arrow.svg';
import { ReactComponent as DefaultLogLine } from '../../../../../icons/log_line.svg';
import { CustomLogLine } from '../../../../SelectLogSets/components/CustomLogLine';
import { CustomText } from '../../../../SelectLogSets/components/CustomText';

import {
  ListItemButtonStyle, DrawerHeader, ListStyle, CustomListItemIcon, MenuItemsWrapper, MenuItem,
  OptionLogSet, WrapperArrowIcon, MenuStyle, ScrollBar, ListItemStyle, WrapperListBtn
} from './styles';

type Props = {
  menuConfig: MenuItemType[];
  menuSelectedIndex: number;
  logSets?: LogSetType[];
  afterSelectLogSet: (logSet: string) => void;
  logSetCurves: LogCurve[];
  logo: string;
};

export const ClosedDrawer = ({
  menuConfig,
  menuSelectedIndex,
  logSets,
  afterSelectLogSet,
  logSetCurves,
  logo
}: Props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const uuid = useId();

  const handleClickLogSet = (event: any, logSetName: string) => {
    setAnchorEl(event.currentTarget);
    afterSelectLogSet(logSetName)
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
  <>
    <DrawerHeader>
      <Logo>
        {logo}
      </Logo>
    </DrawerHeader>
    <MenuItemsWrapper>
      <ListStyle>
        {menuConfig.map((menuItem: any, index) => (
          <WrapperListBtn key={menuItem.text} disablePadding disableGutters>
            <ListItemButtonStyle
              onClick={() => menuItem?.onClick(index)}
              disabled={menuItem?.isDisabled}
              selected={menuSelectedIndex === index}
            >
              <CustomListItemIcon>
                {menuItem.icon}
              </CustomListItemIcon>
              <MenuItem
                primary={menuItem.text}
                selected={menuSelectedIndex === index}  
              />
            </ListItemButtonStyle>
          </WrapperListBtn>
        ))}
      </ListStyle>
    </MenuItemsWrapper>
      <div>

      {logSets?.map((logSet: LogSetType, index) => (
        <OptionLogSet
          key={index}
          onClick={(event) => handleClickLogSet(event, logSet.logSetName)}
        >
          <div>
            <FileIcon />
            <WrapperArrowIcon>
              <RightArrowIcon />
            </WrapperArrowIcon>
          </div>
          {logSet.logSetName}
        </OptionLogSet>
      ))}

      <MenuStyle
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ScrollBar>
          {(logSetCurves || [])?.map((item: LogCurve) => (
            <ListItemStyle
              key={`${uuid}-${item?.mnemonic}`}
              disableGutters
              secondaryAction={
                <CustomLogLine color={item.color}>
                  <DefaultLogLine />
                </CustomLogLine>
              }
            >
              <CustomText primary={item?.curveName} />
            </ListItemStyle>
          ))}
        </ScrollBar>
      </MenuStyle>
    </div>
  </>
)}