import * as React from 'react';
import { IndicatorCard } from "./IndicatorCard";
import { IndicatorCardType } from "./types";
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const CardWrapper = styled(Box)(() => (() => `
  margin-left: 14px;
`));

const MainWrapper = styled(Box)(() => (() => `
  width: 100%;
  display: flex;
`));

type Props = { data: IndicatorCardType[] };

export const CardList = ({ data }: Props) => {
  const uuid = React.useId();
  return (
    <MainWrapper>
      {data.map((item: IndicatorCardType, index: number) => {
        if (index === 0) {
          return (
            <IndicatorCard
                key={`${uuid}-${item?.title}`}
                color={item?.color}
                title={item?.title}
                value={item?.value}
                unit={item?.unit}
            />
          )
        } else {
          return (
            <CardWrapper key={`${uuid}-${item?.title}`}>
              <IndicatorCard
                  color={item?.color}
                  title={item?.title}
                  value={item?.value}
                  unit={item?.unit}
              />
            </CardWrapper>
          )
        }
      })}
    </MainWrapper>
  );
};
