import { PopoverOrigin } from "@mui/material";
import { MenuStyle } from "./styles";
import { ReactNode } from "react";

interface MenuProps {
  anchorEl: null | HTMLElement;
  width?: string;
  onClose: () => void;
  children: ReactNode;
  anchorOrigin: PopoverOrigin;
  transformOrigin: PopoverOrigin;
}

export const Menu = ({ anchorEl, width, anchorOrigin, transformOrigin, onClose, children }: MenuProps) => {
  return (
    <MenuStyle
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      width={width}
    >
      {children}
    </MenuStyle>
  )
}