import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Ui from '@geowellex/shared-ui'
import { useScales } from '../../../../hooks/useScales';
import {
  DEFAULT_SCALES,
  GAS_COMP_C1,
  GAS_COMP_C2,
  GAS_COMP_C3,
  GAS_COMP_C4,
  GAS_COMP_C5
} from '../../../../utils/constants';
import {
  toggleGasComposition
} from '../../../../utils';
import { getCalculatedCurveData } from '../../../../utils/getCalculatedCurveData';

export const curveTypes = [
  'c1_perc',
  'c2_perc',
  'c3_perc',
  'c4_perc',
  'c5_perc'
];

export const ChartHeader = ({ rangeValues, positionsLeft }) => {
  const wells = useSelector(state => state.wells);

  const initialHeader = {
    c1Comp: 0,
    c2Comp: 0,
    c3Comp: 0,
    c4Comp: 0,
    c5Comp: 0
  };

  const {
    currentScale, setHeaderState, headerState
  } = useScales(
    curveTypes,
    wells.currentWell,
    initialHeader
  );

  // --- Toggle Tracks
  // ---- Activate
  const [tracks, setToggle] = useState({
    [GAS_COMP_C1]: true,
    [GAS_COMP_C2]: true,
    [GAS_COMP_C3]: true,
    [GAS_COMP_C4]: true,
    [GAS_COMP_C5]: true
  });

  const tooggleCallbacks = {
    [GAS_COMP_C1]: toggleGasComposition,
    [GAS_COMP_C2]: toggleGasComposition,
    [GAS_COMP_C3]: toggleGasComposition,
    [GAS_COMP_C4]: toggleGasComposition,
    [GAS_COMP_C5]: toggleGasComposition
  };

  const toggleTrack = (event, name, index) => {
    const trackName = tracks[name];
    const callback = tooggleCallbacks[name];
    callback(parseInt(index))(event);
    setToggle({
      ...tracks,
      isOpen: !tracks.isOpen,
      [name]: !trackName
    });
  };

  const toggleTrackCurrentTrack = (event) => {
    const { dataset: { name, index } } = event.target;
    const trackName = tracks[name];
    const callback = tooggleCallbacks[name];
    if (callback) {
      callback(parseInt(index))(event);
      setToggle({
        ...tracks,
        isOpen: !tracks.isOpen,
        [name]: !trackName
      });
    }
  };

  // --- Open modal with config
  const dispatchModal = (typeOfTitle) => (event) => {
    event.persist();
    event.stopPropagation();
    if (event.target && event.target.dataset.cmd === 'toggle') {
      toggleTrackCurrentTrack(event);
      return;
    } else if (event.target && event.target.dataset.cmd === 'openConfig') {
      const openModal = new CustomEvent('openConfigModal', {
        detail: {
          type: typeOfTitle
        }
      });
      document.dispatchEvent(openModal);
    }
  };

  useEffect(() => {
    if (wells.currentWell && wells.selectedDepthIndex >= 0) {

      // TODO - when connect with API get values from useAPI customHook charts
      const { currentWell } = wells;
      const c1Composition = getCalculatedCurveData('c1_perc', currentWell);
      const c2Composition = getCalculatedCurveData('c2_perc', currentWell);
      const c3Composition = getCalculatedCurveData('c3_perc', currentWell);
      const nc4Composition = getCalculatedCurveData('c4_perc', currentWell);
      const nc5Composition = getCalculatedCurveData('c5_perc', currentWell);
      const index = wells.selectedDepthIndex;
      const c1Value = c1Composition.data && c1Composition.data[index] ? c1Composition.data[index] : 0;
      const c2Value = c2Composition.data && c2Composition.data[index] ? c2Composition.data[index]  : 0;
      const c3Value = c3Composition.data && c3Composition.data[index] ? c3Composition.data[index] : 0;
      const c4Value = nc4Composition.data && nc4Composition.data[index] ? nc4Composition.data[index] : 0;
      const c5Value = nc5Composition.data && nc5Composition.data[index] ? nc5Composition.data[index] : 0;

      setHeaderState({
        ...headerState,
        c1Comp: c1Value?.toFixed(2),
        c2Comp: c2Value?.toFixed(2),
        c3Comp: c3Value?.toFixed(2),
        c4Comp: c4Value?.toFixed(2),
        c5Comp: c5Value?.toFixed(2)
      });
    }

    if (!wells.currentWell) {
      setHeaderState({
        ...headerState,
        c1Comp: 0,
        c2Comp: 0,
        c3Comp: 0,
        c4Comp: 0,
        c5Comp: 0
      });
    }

  }, [wells]);

  useEffect(() => {
    const gastCompositionListener = (currentTrack) => ({ detail }) => {
      headerState.scales[currentTrack] = detail;
      setHeaderState(headerState);
    };

    // Gas Composition
    const gasTracks = [
      GAS_COMP_C1,
      GAS_COMP_C2,
      GAS_COMP_C3,
      GAS_COMP_C4,
      GAS_COMP_C5
    ];

    const gasCompositionC1 = gastCompositionListener(GAS_COMP_C1);
    const gasCompositionC2 = gastCompositionListener(GAS_COMP_C2);
    const gasCompositionC3 = gastCompositionListener(GAS_COMP_C3);
    const gasCompositionC4 = gastCompositionListener(GAS_COMP_C4);
    const gasCompositionC5 = gastCompositionListener(GAS_COMP_C5);

    const callbackListeners = {
      gasCompositionC1,
      gasCompositionC2,
      gasCompositionC3,
      gasCompositionC4,
      gasCompositionC5
    };

    // Add event listeners
    for (let i = 0; i < gasTracks.length; i++) {
      const currentTrack = gasTracks[i];
      document.addEventListener(currentTrack, callbackListeners[currentTrack]);
    }

    return () => {
      // Remove all event listeners
      for (let i = 0; i < gasTracks.length; i++) {
        const currentTrack = gasTracks[i];
        document.removeEventListener(currentTrack, callbackListeners[currentTrack]);
      }
    };
  }, [rangeValues]);

  const {
    c1Comp, c2Comp, c3Comp, c4Comp, c5Comp, scales
  } = headerState;

  return(
    <div className="gas-composition-header" style={{ width: 210 }}>
      <Ui.Header
        textCenter={`C1% ${c1Comp ? `(${c1Comp})` : ''}`}
        background={currentScale[GAS_COMP_C1]?.color}
        checkboxIsVisible
        checked={tracks[GAS_COMP_C1]}
        onChangeCheckbox={(event) => toggleTrack(event, GAS_COMP_C1, 1)}
        onClick={dispatchModal(GAS_COMP_C1)}
        activeCurveConfig={GAS_COMP_C1}
        dataCy="open-config-gas-comp-c1"
      />
      <Ui.Header
        textCenter={`C2% ${c2Comp ? `(${c2Comp})` : ''}`}
        background={currentScale[GAS_COMP_C2]?.color}
        checkboxIsVisible
        checked={tracks[GAS_COMP_C2]}
        onChangeCheckbox={(event) => toggleTrack(event, GAS_COMP_C2, 2)}
        onClick={dispatchModal(GAS_COMP_C2)}
        activeCurveConfig={GAS_COMP_C2}
        dataCy="open-config-gas-comp-c2"
      />
      <Ui.Header
        textCenter={`C3% ${c3Comp ? `(${c3Comp})` : ''}`}
        background={currentScale[GAS_COMP_C3]?.color}
        checkboxIsVisible
        checked={tracks[GAS_COMP_C3]}
        onChangeCheckbox={(event) => toggleTrack(event, GAS_COMP_C3, 3)}
        onClick={dispatchModal(GAS_COMP_C3)}
        activeCurveConfig={GAS_COMP_C3}
        dataCy="open-config-gas-comp-c3"
      />
      <Ui.Header
        textCenter={`C4% ${c4Comp ? `(${c4Comp})` : ''}`}
        background={currentScale[GAS_COMP_C4]?.color}
        checkboxIsVisible
        checked={tracks[GAS_COMP_C4]}
        onChangeCheckbox={(event) => toggleTrack(event, GAS_COMP_C4, 4)}
        onClick={dispatchModal(GAS_COMP_C4)}
        activeCurveConfig={GAS_COMP_C4}
        dataCy="open-config-gas-comp-c4"
      />
      <Ui.Header
        textCenter={`C5% ${c5Comp ? `(${c5Comp})` : ''}`}
        background={currentScale[GAS_COMP_C5]?.color}
        checkboxIsVisible
        checked={tracks[GAS_COMP_C5]}
        onChangeCheckbox={(event) => toggleTrack(event, GAS_COMP_C5, 5)}
        onClick={dispatchModal(GAS_COMP_C5)}
        activeCurveConfig={GAS_COMP_C5}
        dataCy="open-config-gas-comp-c5"
      />
      <Ui.Header
        rangeValue={rangeValues}
        background='#323752'
      />
    </div>
  );
};
