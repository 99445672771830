import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import { ReactComponent as ChevronIcon } from '../../icons/chevron_icon.svg';
import { ReactComponent as FilesIcon } from '../../icons/mini_folder.svg';
import { customTheme } from '../../theme/default';
import { LogSetType } from './types';

const FolderIcon = styled(FilesIcon)(() => `
  position: absolute;
  left: 18px;
  top: 18px;
  z-index: 10;
 `,
);

export const FormControlWrapper = styled(FormControl)(() => ({
  '&': {
    width: '100%',
    position: 'relative',
    margin: 0,
    padding: 0,
  },
  '&  .MuiOutlinedInput-input': {
    background: customTheme.blue1,
    borderRadius: 6,
    color: customTheme.gray2,
    width: 198,
    fontSize: 16,
    fontWeight: 700,
    paddingRight: 10,
    paddingLeft: 48,
  }
}));

type Props = {
  logSets?: LogSetType[];
  afterSelectLogSet: (logSet: string) => void;
  disabled: boolean;
};

const SelectComponent = ({ logSets, afterSelectLogSet, disabled }: Props) => {
  const [logSet, setLogSet] = React.useState<any>('');

  React.useEffect(() => {
    if (!logSet) {
      const defaultLogSet = logSets?.find((logSet: LogSetType) => logSet?.logSetName.includes('RAW'))?.logSetName;
      if (defaultLogSet) {
        setLogSet(defaultLogSet);
        afterSelectLogSet(defaultLogSet);
      }
    }
  }, [logSets, logSet, afterSelectLogSet]);

  const handleChange = (event: SelectChangeEvent) => {
    setLogSet(event.target.value);
    afterSelectLogSet(event.target.value);
  };

  const uuid = React.useId();

  return (
    <FormControlWrapper sx={{ m: 1 }}>
      <FolderIcon />
      <Select
        sx={{
          width: 248,
        }}
        value={logSet ?? logSets?.[0]?.logSetName}
        onChange={handleChange}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        IconComponent={ChevronIcon}
        disabled={disabled}
        MenuProps={{ MenuListProps: { style: { backgroundColor: customTheme.blue1, color: customTheme.gray4 } } }}
      >
        {logSets?.map((logSet: LogSetType) => {
          return (
            <MenuItem 
              key={`${uuid}-${logSet.logSetName}`}
              value={logSet.logSetName}
            >
                {logSet.logSetName}
            </MenuItem>
          );
        })}
      </Select>
    </FormControlWrapper>
  )  
};

export const SelectLogSets = styled(SelectComponent)(() => ({
  '& .MuiOutlinedInput-input': {
  },
}));

