import './style.scss';

import React, {
  useCallback,
  useEffect,
  useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReactSVG from 'react-svg';
import Highcharts from 'highcharts';
import { ReactComponent as AddBaseline } from 'assets/new-baseline.svg';
import { ReactComponent as OpenBaseLineIcon } from 'assets/baseline-zone.svg';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { listWell, listWells } from 'actions/wells';
import HighcartsMore from 'highcharts/highcharts-more';
import {
  mean,
  std
} from 'mathjs';
import store from 'store';
import uuid from 'uuid/v1';

import { useZones } from '@geowellex/shared-zone/src/hooks/useApi';
import { successNotification } from '../../utils';
import ZonesIcon from './images/zones-icon.svg';
import BaselinePanel from './components/BaselinePanel';
import MolarChart from './components/MolarChart';
import SelectedZones from './components/SelectedZones';
import SettingsTab from './SettingsTab';
import { getCalculatedCurveData } from '../../utils/getCalculatedCurveData';
import { getRawCurveData } from '../../utils/getRawCurveData';
import { Button as ButtonMui } from './components/Button/index.tsx';
import { CardInfo } from './components/CardInfo/index.tsx';
import Loading from './components/Loading/index.tsx';
import { customTheme } from '@geowellex/shared-ui/src/theme/default/index.tsx';


HighcartsMore(Highcharts);

const MolarAnalysis = () => {
  const dispatch = useDispatch();
  const [isVisilbleZonesPanel, setZonesPanel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isVisilbleBaselinePanel, setToggleBaseline] = useState(false);
  const [baseline, setBaseline] = useState();
  const [dirty, setDirty] = useState(false);
  const [compare, setCompare] = useState(false);
  const [visible, setVisible] = useState(false);
  const [yAxisMin, setYaxisMin] = useState(0.1);
  const [yAxisMax, setYaxisMax] = useState(100);
  const [chartType, setChartType] = useState('logarithmic');
  const [zones, setZones] = useState([]);
  const [seriesList, setSeriesList] = useState([]);
  const [well, setWell] = useState('');

  const params = useParams();

  const wells = useSelector((state) => state.wells);

  const {
    insertNewZone,
    updateAndMutateZone,
    isLoading,
    data,
    mutate
  } = useZones(params.wellId, store.get('token'));

  useEffect(() => {
    dispatch(listWells());
  }, []);

  useEffect(() => {
    const newSeriesList = getSeriesList();
    setSeriesList(newSeriesList);
  }, [zones?.length, well]);

  useEffect(() => {
    if (wells?.items && !zones.length && params.wellId) {
      const [queryId] = params.id.split('--');
      const queryData = store.get('querys')[queryId]
      const well = wells?.items?.find(w => w?.wellId === params.wellId);
      const zonesList = well?.zone_sets;
      const selectedZone = queryData.zone;
      if (zonesList?.[0]?.zones) {
        const unselectedZones = zonesList?.[0]?.zones.filter(z => (z.label !== selectedZone.label && z.top !== selectedZone.top && z.bottom !== selectedZone.bottom));
        const newZones = [{...selectedZone, checked: true}, ...unselectedZones.map((z) => ({...z, checked: false, id: uuid()}))];
        setZones(newZones);
      }
    }
  }, [wells?.items]);

  const zonesStore = useSelector((state) => state.wells?.currentWell?.zone_sets?.gold_zones?.zones);

  // retrieve baseline stored and use it
  useEffect(() => {
    const [queryId] = params.id.split('--');
    const queryData = store.get('querys')[queryId]
    const baslelineValue = store.get(`baseline-${queryData.wellId}`);
    if (baslelineValue) {
      setBaseline(JSON.parse(baslelineValue));
    }
  }, [params]);

  const showDrawer = () => setVisible(true);
  const onClose = () => setVisible(false);

  const onSubmitYaxisForm = (values) => {
    const { min, max, scaleType } = values;
    setYaxisMin(Number(min));
    setYaxisMax(max);
    setChartType(scaleType)
    onClose();
  };

  const onSubmitXaxisForm = () => {
    onClose();
  };

  const afterSuccess = () => {
    setLoading(false);
  };

  // retrieve the current well data
  useEffect(() => {
    const [queryId] = params.id.split('--');
    const token = store.get('token');
    const queryData = store.get('querys')[queryId]
    setLoading(true);
    dispatch(listWell(queryData.wellId, token, afterSuccess));
  }, [params]);

  useEffect(() => {
    if (zonesStore && zonesStore.length && !zones) {
      setZones(zonesStore);
    }
  }, [zonesStore]);

  useEffect(() => {
    if (wells && wells.currentWell && !well) {
      setWell(wells.currentWell);
    }
  }, [wells && wells.currentWell]);

  const onClosePanel = useCallback(() => {
    setZonesPanel(false);
  }, [isVisilbleZonesPanel]);

  useEffect(() => {
    if (isVisilbleBaselinePanel && isVisilbleZonesPanel) {
      setZonesPanel(false);
    }
  }, [isVisilbleBaselinePanel, isVisilbleZonesPanel]);

  // make sure if there is many zones only check the first one by default
  const defaultCheckedZones = (zones || []).map((z) => ({
    checked: z.checked,
    id: z.id
  }));

  const openAddBaseline = () => {
    setToggleBaseline(true);
  };

  const onCloseBaselinePanel = () => {
    setToggleBaseline(false);
  };

  const onSubmitZonesConfig = (currentCheckedZones) => {
    // setCheckedZones(currentCheckedZones.checkedZones);
    setCompare(currentCheckedZones.compare)
    const newSeriesList = getSeriesList(currentCheckedZones.checkedZones);
    setSeriesList(newSeriesList);
  };

  // should store the baseline on localStorage to be retrived
  const onSubmitBaseLine = useCallback((values) => {
    store.set(`baseline-${params.wellId}`, JSON.stringify(values));
    setToggleBaseline(false);
    setBaseline(values);

    const isThereBaseline = store.get(`baseline-${params.wellId}`);
    const action = isThereBaseline ? 'updated' : 'added';
    successNotification(`Baseline ${action} successfully`);
  }, [wells?.currentwell]);

  const calculateStandardDeviation = (valuesCompositionInDepth) => {
    if (!valuesCompositionInDepth) {
      return 0;
    }
    return std(valuesCompositionInDepth.map(v => !v || v === null ? 0 : v));
  };

  const calculateAverageOfComposition = (valuesCompositionInDepth) => {
    return mean(valuesCompositionInDepth.map(v => !v || v === null ? 0 : v));
  };

  const getDataByZone = (zone) => {
    const depth = getRawCurveData('depth', wells?.currentWell)?.data;
    const c1Composition = getCalculatedCurveData('c1_perc', wells?.currentWell)?.data;
    const c2Composition = getCalculatedCurveData('c2_perc', wells?.currentWell)?.data;
    const c3Composition = getCalculatedCurveData('c3_perc', wells?.currentWell)?.data;
    const nc4Composition = getCalculatedCurveData('c4_perc', wells?.currentWell)?.data;
    const nc5Composition = getCalculatedCurveData('c5_perc', wells?.currentWell)?.data;
    const indexOfDepthBottom = parseInt(zone.bottom_depth_index);
    const indexOfDepthTop = parseInt(zone.top_depth_index);

    const cuttedDepth = depth.slice(indexOfDepthTop, indexOfDepthBottom);

    const cuttedC1 = c1Composition.slice(indexOfDepthTop, indexOfDepthBottom);
    const cuttedC2 = c2Composition.slice(indexOfDepthTop, indexOfDepthBottom);
    const cuttedC3 = c3Composition.slice(indexOfDepthTop, indexOfDepthBottom);
    const cuttedC4 = nc4Composition.slice(indexOfDepthTop, indexOfDepthBottom);
    const cuttedC5 = nc5Composition.slice(indexOfDepthTop, indexOfDepthBottom);

    const c1Mean = calculateAverageOfComposition(cuttedC1);
    const c2Mean = calculateAverageOfComposition(cuttedC2);
    const c3Mean = calculateAverageOfComposition(cuttedC3);
    const c4Mean = calculateAverageOfComposition(cuttedC4);
    const c5Mean = calculateAverageOfComposition(cuttedC5);

    const c1Stdv = calculateStandardDeviation(cuttedC1);
    const c2Stdv = calculateStandardDeviation(cuttedC2);
    const c3Stdv = calculateStandardDeviation(cuttedC3);
    const c4Stdv = calculateStandardDeviation(cuttedC4);
    const c5Stdv = calculateStandardDeviation(cuttedC5);

    return {
      ...zone,

      detph: cuttedDepth,
      // mol%
      c1Percentage: c1Mean > 0 ? c1Mean : 0.01,
      c2Percentage: c2Mean > 0 ? c2Mean : 0.01,
      c3Percentage: c3Mean > 0 ? c3Mean : 0.01,
      c4Percentage: c4Mean > 0 ? c4Mean : 0.01,
      c5Percentage: c5Mean > 0 ? c5Mean : 0.01,

      // stdv
      c1StardardDeviation: c1Stdv > 0 ? c1Stdv : 0.01,
      c2StardardDeviation: c2Stdv > 0 ? c2Stdv : 0.01,
      c3StardardDeviation: c3Stdv > 0 ? c3Stdv : 0.01,
      c4StardardDeviation: c4Stdv > 0 ? c4Stdv : 0.01,
      c5StardardDeviation: c5Stdv > 0 ? c5Stdv : 0.01,

      // box plot down
      c1PercentMinusStdv: (c1Mean - c1Stdv) > 0 ? (c1Mean - c1Stdv) : 0.01,
      c2PercentMinusStdv: (c2Mean - c2Stdv) > 0 ? (c2Mean - c2Stdv) : 0.01,
      c3PercentMinusStdv: (c3Mean - c3Stdv) > 0 ? (c3Mean - c3Stdv) : 0.01,
      c4PercentMinusStdv: (c4Mean - c4Stdv) > 0 ? (c4Mean - c4Stdv) : 0.01,
      c5PercentMinusStdv: (c5Mean - c5Stdv) > 0 ? (c5Mean - c5Stdv) : 0.01,

      // box plot up
      c1PercentPlusStdv: (c1Mean + c1Stdv)  > 0 ? (c1Mean + c1Stdv) : 0.01,
      c2PercentPlusStdv: (c2Mean + c2Stdv)  > 0 ? (c2Mean + c2Stdv) : 0.01,
      c3PercentPlusStdv: (c3Mean + c3Stdv)  > 0 ? (c3Mean + c3Stdv) : 0.01,
      c4PercentPlusStdv: (c4Mean + c4Stdv)  > 0 ? (c4Mean + c4Stdv) : 0.01,
      c5PercentPlusStdv: (c5Mean + c5Stdv)  > 0 ?  (c5Mean + c5Stdv) : 0.01
    };
  };

  const getDefaultBaseLine = () => {
    let defaultBaseline = {
      data: [
        ['C1', 0, -1, -1],
        ['C2', 0, -1, -1],
        ['C3', 0, -1, -1],
        ['C4', 0, -1, -1],
        ['C5', 0, -1, -1]
      ],
      type: 'spline',
      name: 'Baseline',
      lineColor: '#ccc',
      color: '#ccc'
    };

    const [queryId] = params.id.split('--');
    const queryData = store.get('querys')[queryId];

    const baslineFromStorage = store.get(`baseline-${queryData.wellId}`);
    const parsedBaseline = baslineFromStorage ? JSON.parse(baslineFromStorage) : null;
    if (baslineFromStorage) {
      const {
        c1MolPercent, c2MolPercent,
        c3MolPercent, c4MolPercent, c5MolPercent,
        c1TStdMolPercent, c2TStdMolPercent, c3TStdMolPercent,
        c4TStdMolPercent, c5TStdMolPercent
      } = parsedBaseline;
      defaultBaseline = {
        data: [
          ['C1', 0, c1MolPercent || 0, c1TStdMolPercent || 0],
          ['C2', 1, c2MolPercent || 0, c2TStdMolPercent || 0],
          ['C3', 2, c3MolPercent || 0, c3TStdMolPercent || 0],
          ['C4', 3, c4MolPercent || 0, c4TStdMolPercent || 0],
          ['C5', 4, c5MolPercent || 0, c5TStdMolPercent|| 0 ]
        ],
        type: 'spline',
        name: 'Baseline',
        lineColor: 'orange',
        color: 'orange',
        keys: ['name', 'custom.x', 'y', 'custom.stdv']
      };
    }
    return defaultBaseline;
  };

  const formatToDecimals = (value) => value <= 0 ? 0.01 : parseFloat(value?.toFixed(2));

  // Logic of getSeriesList
  // Should access the data in depth of each zone
  // NOTE: the c1, c2, c3, c4, c5 should be in percentage
  // Should create a array of values by each curve
  // Should extract mean and stdv
  // Should calculate plotboxes
  // Should create a object with the series
  // Should generate the series to apply for each chart
  // if compare is marked as true, the all zones calculated and transformed in series should be displayed in only one chart
  // otherwise we should make all series by each zone be displayed in different charts
  const getSeriesList = (checkedZones = []) => {
    const defaultBaseline = getDefaultBaseLine();
    const zonesToGetData = zones || [];
    const checkedList = checkedZones.filter(z => z && z.checked);
    const zonesDataChecked = checkedList.length > 0 ? checkedList.map((zone) => zonesToGetData.find((z) => z.id === zone.id)) : zonesToGetData;
    let seriesWithData = [];
    if (well) {
      const depth = getRawCurveData('depth', wells?.currentWell)?.data || [];
      // calculate data of each zone
      const calculatedZones = zonesDataChecked.map(z => getDataByZone(z));
      // series from zones
      seriesWithData = calculatedZones.reduce((acc, calculatedZone) => {
        if (calculatedZone) {
          const { r, g, b, a } = calculatedZone.zone_plot_settings.color;
          const color = `rgba(${r}, ${g}, ${b}, ${a})`;
          acc.push({
            data: [
              [
                '<b>C1</b>', 0,
                formatToDecimals(calculatedZone.c1Percentage), formatToDecimals(calculatedZone.c1StardardDeviation),
                formatToDecimals(calculatedZone.c1PercentMinusStdv), formatToDecimals(calculatedZone.c1PercentPlusStdv)
              ],
              [
                '<b>C2</b>', 1,
                formatToDecimals(calculatedZone.c2Percentage), formatToDecimals(calculatedZone.c2StardardDeviation),
                formatToDecimals(calculatedZone.c2PercentMinusStdv), formatToDecimals(calculatedZone.c2PercentPlusStdv)
              ],
              [
                '<b>C3</b>', 2,
                formatToDecimals(calculatedZone.c3Percentage), formatToDecimals(calculatedZone.c3StardardDeviation),
                formatToDecimals(calculatedZone.c3PercentMinusStdv), formatToDecimals(calculatedZone.c3PercentPlusStdv)
              ],
              [
                '<b>C4</b>', 3,
                formatToDecimals(calculatedZone.c4Percentage), formatToDecimals(calculatedZone.c4StardardDeviation),
                formatToDecimals(calculatedZone.c4PercentMinusStdv), formatToDecimals(calculatedZone.c4PercentPlusStdv)
              ],
              [
                '<b>C5</b>', 4,
                formatToDecimals(calculatedZone.c5Percentage), formatToDecimals(calculatedZone.c5StardardDeviation),
                formatToDecimals(calculatedZone.c5PercentMinusStdv), formatToDecimals(calculatedZone.c5PercentPlusStdv)
              ]
            ],
            type: 'spline',
            name: calculatedZone.label,
            lineColor: color,
            color,
            yAxis: 0,
            top: depth[calculatedZone.top_depth_index],
            bottom: depth[calculatedZone.bottom_depth_index],
            keys: ['name', 'custom.x', 'y', 'custom.stdv', 'custom.min', 'custom.max'],
            tooltip: {
              pointFormat: (
                '<b>{point.series.name}</b>: <br/><b>%mol:</b> {point.options.y} - <b>stdv:</b> {point.options.custom.stdv}<br/> <b>min:</b> {point.options.custom.min} - <b>max:</b> {point.options.custom.max}'
              )
            },
            boxPlotSerie: {
              yAxis: 0,
              name: '',
              type: 'errorbar',
              data: [
                [parseFloat(calculatedZone.c1PercentMinusStdv?.toFixed(2)), parseFloat(calculatedZone.c1PercentPlusStdv?.toFixed(2))],
                [parseFloat(calculatedZone.c2PercentMinusStdv?.toFixed(2)), parseFloat(calculatedZone.c2PercentPlusStdv?.toFixed(2))],
                [parseFloat(calculatedZone.c3PercentMinusStdv?.toFixed(2)), parseFloat(calculatedZone.c3PercentPlusStdv?.toFixed(2))],
                [parseFloat(calculatedZone.c4PercentMinusStdv?.toFixed(2)), parseFloat(calculatedZone.c4PercentPlusStdv?.toFixed(2))],
                [parseFloat(calculatedZone.c5PercentMinusStdv?.toFixed(2)), parseFloat(calculatedZone.c5PercentPlusStdv?.toFixed(2))]
              ],
              color: '#D5D5D6', // Change this to your desired color for the box
              medianColor: '#D5D5D6', // Change this to your desired color for the median line
              whiskerColor: '#D5D5D6',
              enableMouseTracking: false,
              lineColor: '#D5D5D6',
              color: '#D5D5D6',
              lineWidth: 1,
              tooltip: {
                enabled: false,
                headerFormat: '<em>{point.key}</em><br/>'
              }
            }
          });
          acc.push()
        }
        return acc;
      }, []);
    }

    // make sure that when all series are listed at least one is checked
    if (!checkedZones.length || checkedList.length === 1) {
      // access the current calculated zone to plot them
      const [queryId] = params.id.split('--');
      const queryData = store.get('querys')[queryId];
      const { minLabel, maxLabel } = queryData;
      const currentIndex = (zones || []).findIndex((z) => z.top === minLabel && z.bottom === maxLabel);

      const result = seriesWithData.length ? [[
        defaultBaseline,
        seriesWithData[currentIndex],
        seriesWithData[currentIndex].boxPlotSerie
      ]] : [[defaultBaseline]];
      return result;
    }

    // when compare is not checked should generate a structure to show each
    // zone data in a specific chart with a baseline
    if (!compare && (checkedZones || []).filter(z => z && z.checked).length > 0) {
      const seriesToDisplay = seriesWithData.map((serie) => [defaultBaseline, serie, serie.boxPlotSerie]);
      return seriesToDisplay;
    } else {
      // should diplay all series data with baseline in same chart
      return [
        defaultBaseline,
        ...seriesWithData
      ];
    }
  };

  return (
    <div className="molar-analysis"
      style={{
        background: customTheme.bg
      }}
    >
      <div>
        <div className="header-molar-analysis">
          <h1 className="scatters-title">
            Molar Analysis
          </h1>
          <SelectedZones
            visible={isVisilbleZonesPanel}
            style={{
              background: 'orange'
            }}
            zones={zones}
            onClose={onClosePanel}
            defaultCheckedZones={defaultCheckedZones}
            openAddBaseline={openAddBaseline}
            onSubmit={onSubmitZonesConfig}
            dirty={dirty}
            setDirty={setDirty}
            compare={compare}
            setCompare={setCompare}
          />
          <div className='actions-molar-analysis'>
            <ButtonMui
              theme='secondary'
              startIcon={<AddBaseline style={{ marginRight: '5px' }} />}
              onClick={openAddBaseline}
            >
              Baseline
            </ButtonMui>
            <ButtonMui
              theme='secondary'
              startIcon={
                <OpenBaseLineIcon />
              }
              onClick={() => setZonesPanel(true)}
            >
              Zones
            </ButtonMui>
            {/* Functionality will be added later */}
            {/* <ButtonMui
              theme='secondary'
            >
              Add PVT Data
            </ButtonMui>
            <ButtonMui
              theme='secondary'
            >
              Generate Fluid Composition
            </ButtonMui> */}
            <ButtonMui
              theme='secondary'
              width='37px'
              onClick={showDrawer}
            >
              <SettingsOutlinedIcon className='settings-outline-icon' />
            </ButtonMui>
            <SettingsTab
              closable={false}
              onClose={onClose}
              visible={visible}
              onSubmitYaxisForm={onSubmitYaxisForm}
              onSubmitXaxisForm={onSubmitXaxisForm}
              yAxisMin={yAxisMin}
              yAxisMax={yAxisMax}
              chartType={chartType}
            />
            <BaselinePanel
              visible={isVisilbleBaselinePanel}
              zones={zones}
              onClose={onCloseBaselinePanel}
              defaultCheckedZones={defaultCheckedZones}
              openAddBaseline={openAddBaseline}
              onSubmit={onSubmitBaseLine}
              defaultBaselineValue={baseline}
            />
          </div>
        </div>
        <br/>
        {loading ? <Loading /> : null}
        {!compare && !loading ? seriesList.map((customSeries, index) => (
          <div className='chart-content'>
            <div className='wrapper-card-info-zones'>
              {customSeries?.length > 0 && customSeries.map((serieInfo) => serieInfo.type !== 'errorbar' ? (
                <CardInfo
                  title={serieInfo.name ?? ''}
                  top={serieInfo.top ?? ''}
                  bottom={serieInfo.bottom ?? ''}
                  color={serieInfo.color ?? ''}
                />
              ) : null)}
            </div>
            <MolarChart
              defaultBaseline={baseline}
              customSeries={customSeries}
              chartId={`molar-${index}`}
              minY={yAxisMin}
              maxY={yAxisMax}
              chartType={chartType}
            />
            <br />
          </div>
        )) : null}
        {compare && !loading ? (
          <div className='chart-content'>
            <div className='wrapper-card-info-zones'>
              {seriesList.map((serieInfo) => serieInfo.type !== 'errorbar' && serieInfo.name !== 'Baseline' ? (
                <CardInfo
                  title={serieInfo.name ?? ''}
                  top={serieInfo.top ?? ''}
                  bottom={serieInfo.bottom ?? ''}
                  color={serieInfo.color ?? ''}
                />
              ) : null)}
            </div>
            <MolarChart
              defaultBaseline={baseline}
              customSeries={seriesList}
              chartId="molar-chart"
              minY={yAxisMin}
              maxY={yAxisMax}
              chartType={chartType}
            />
            <br />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default MolarAnalysis;
