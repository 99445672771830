import {
  RECEIVE_CUT_OFF,
  RECEIVE_CUT_OFFS
} from '../constants/cutoff';

export const receiveCutOffs = (cutoffs) => ({
  type: RECEIVE_CUT_OFFS,
  cutoffs
});

export const receiveCutOff = (cutOff) => ({
  type: RECEIVE_CUT_OFF,
  cutOff
});

export const createCutOff = (cutOff, token, well_uid) => {
  return async (dispatch, getState) => {
    const oldCutoffs = getState().cutoff;
    try {
      // if (oldCutoffs && oldCutoffs.previous && oldCutoffs.previous.length) {
      // await createCutOffFilter({
      //   well_well_uid: well_uid,
      //   ...cutOff
      // });
      // delete all
      // if (oldCutoffs && oldCutoffs.previous && oldCutoffs.previous.length) {
      //   await dispatch(deleteCutOff(token, oldCutoffs.previous, wellId));
      // }
      // const arrayOfPromises = cutOffs.map(c => createCutOffFilter(c, token));
      // await Promise.all(arrayOfPromises);
      dispatch(fetchCutOffs(token, well_uid));
    } catch (e) {
      console.log('error', e)
    }
  };
};

export const fetchCutOffs = (token, wellId, afterSuccess) => {
  return async (dispatch, getState) => {
    const mockedCutoffs = getState().cutoff.items;
    const currentWellId = getState().wells.selectedWell;
    try {
      // TODO remove this comment
      // const response = await getAllCutOffs(token, currentWellId || wellId);
      // console.log('response ==>', response);
      dispatch(receiveCutOffs(mockedCutoffs));
      afterSuccess && afterSuccess();
    } catch (e) {
      console.log('error', e)
    }
  };
};

