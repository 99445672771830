/* eslint-disable react/no-unused-state */
import React, { Component, useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import { useSelector } from 'react-redux';
import Exporting from 'highcharts/modules/exporting';
import store from 'store';
import { getRawCurveData } from 'utils/getRawCurveData';
import get from 'lodash.get';
import {
  propagateZoom, syncronizeTooltip,
  getToolTipValues, displayZoomBtn, defaultCrossHair,
  disableDots, nFormatter, defaultValuesToCrosshair
} from '../../../utils';
import { DEFAULT_SCALES } from '../../../utils/constants';
import { applyDefaultZoomToChart } from '../../../lib/charts';
import { ChartHeader } from './ChartHeader/ChartHeader';
import zIndex from '@material-ui/core/styles/zIndex';


Exporting(Highcharts);

const getDefaultSeries = (scales, isotopeData) => {
  const placeholderData = defaultValuesToCrosshair(isotopeData, 0);

  return [
    {
      ...disableDots,
      name: 'isotopePlaceholder',
      data: placeholderData,
      color: 'rgba(0, 0, 0, 0)',
      lineWidth: 0,
      zIndex: -1,
      index: 0
    },
    {
      ...disableDots,
      name: 'isotopes',
      data: isotopeData,
      color: scales?.isotope?.color,
      lineWidth: 0.8,
      index: 1
    }
  ]
};

class Isotope extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isotopeData: props.isotopeData,
      min: 0,
      max: 0,
      scales: store.get('configScales') || DEFAULT_SCALES,
      isotope: 0
    };
    this.chart = null;
  }

  componentDidMount() {
    const { parentContainer, id } = this.props;
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.vpHeight = document.querySelector(parentContainer).clientHeight || 0;

    this.createChart();
    syncronizeTooltip(`#${id}`);

    // TODO refactor this syncronization
    document.addEventListener('sincronize-selected', event => {
      const { detail: { min, max } } = event;
      this.chart.xAxis[0].setExtremes(min, max, true, true);
      this.chart.redraw();
    });

    // Update isotope track
    document.addEventListener('isotope', ({ detail }) => {
      this.chart.yAxis[1].update({
        min: detail.min,
        max: detail.max
      });
      this.chart.series[1].color = detail.color;
      this.chart.series[1].redraw();
    });

    if (this.chart) {
      this.zoomId = applyDefaultZoomToChart(this.chart);
    }
  }

  shouldComponentUpdate(nextProps) {
    const { isotopeData, selectedWell, currentWell, min, max } = nextProps;
    const { props } = this;
    const beforeDepthData = get(getRawCurveData('depth', props.beforeWell), 'data', []);
    const currentDepthData = get(getRawCurveData('depth', currentWell), 'data', []);
    if (
      props.updateChart || selectedWell === -1 ||
      selectedWell !== props.selectedWell ||
      beforeDepthData !== currentDepthData ||
      isotopeData !== props.isotopeData ||
      selectedWell !== -1 && props.updateChart
    ) {
      this.chart.update({
        series: getDefaultSeries(currentWell.scales, isotopeData)
      });

      this.chart.redraw();
      this.zoomId = setTimeout(() => {
        const defaultMin = min < 0 ? 0 : min;
        this.chart.xAxis[0].setExtremes(defaultMin, max);
      }, 200);

      return true;
    }

    return false;
  }

  componentWillUnmount() {
    // eslint-disable-next-line no-unused-expressions
    this.zoomId && clearTimeout(this.zoomId);
  }

  createChart = () => {
    const { id, currentWell } = this.props;
    const { isotopeData } = this.state;

    this.chart = Highcharts.chart(id, {
      chart: {
        type: 'line',
        inverted: true,
        width: 184,
        padding: 0,
        marginTop: 0,
        marginBottom: 0,
        zoomType: 'x',
        backgroundColor: '#151724',
        events: {
          selection: (event) => {
            displayZoomBtn();
            propagateZoom(event);
          }
        },
        animation: false,
        shadow: false
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
      xAxis: {
        allowDecimals: false,
        labels: {
          enabled: false
        },
        tickPosition: 'inside',
        tickColor: '#333',
        gridLineColor: '#333',
        minorGridLineColor: '#333',
        gridLineWidth: 0.5,
        lineColor: '#333',
      },
      yAxis: [
        {
          title: {
            text: ''
          },
          tickInterval: 25,
          labels: {
            enabled: false
          },
          lineWidth: 0.9,
          index: 0,
          minorGridLineWidth: 0.4,
          minorGridLineColor: '#333',
          gridLineColor: '#333',
        },
        {
          title: {
            text: ''
          },
          tickInterval: 25,
          labels: {
            enabled: false
          },
          lineWidth: 0.9,
          index: 1,
          minorGridLineWidth: 0.4,
          minorGridLineColor: '#333',
          gridLineColor: '#333',
        }
      ],
      plotOptions: {
        series: {
          marker: {
            enabled: false
          },
          enableMouseTracking: false,
          showInLegend: false
        }
      },
      exporting: { enabled: false },
      tooltip: {
        shared: true,
        ...defaultCrossHair,
        formatter: getToolTipValues('isotope-chart')
      },
      series: getDefaultSeries(currentWell?.scales || DEFAULT_SCALES, isotopeData)
    });
  }

  render() {
    const { id } = this.props;
    return (
      <div
        className="chart__box-container"
        style={{ marginLeft: 10 }}
      >
        <ChartHeader />
        <div
          id={id}
          style={{
            height: 'calc(100% - 180px)'
          }}
        />
      </div>
    );
  }
}

Isotope.defaultProps = {
  isotopeData: [],
  isotopeColor: '#338a61',
  id: 'isotope-s13',
  parentContainer: '.charts-container'
};

export default React.memo(Isotope);
