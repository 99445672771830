import React, { 
    createContext, 
    useContext, 
    useState, 
} from 'react';

type ServiceContextType = {
    children: React.ReactNode;
};

const ServiceContext = createContext<any>(undefined);

export const ServiceProvider = ({ children }:ServiceContextType) => {
    const [service, setService] = useState<string>('');

    const checkAccess = (userInfo:any) => {

        let allowedServices = userInfo?.data?.services_allowed || [];

        const canAccessGold = allowedServices?.includes('GOLD');
        const canAccessAutomud = allowedServices?.includes('AUTOMUD');

        return { canAccessGold, canAccessAutomud };
    };

    return (
        <ServiceContext.Provider value={{ service, setService, checkAccess }}>
            {children}
        </ServiceContext.Provider>
    );
};

export const useService = () => {
    const context = useContext(ServiceContext);
    if (context === undefined) {
        throw new Error('useService must be used within a ServiceProvider');
    }
    return context;
};