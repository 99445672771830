/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/no-unused-state */
import React, { Component, useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import { useSelector } from 'react-redux';
import store from 'store';
import Exporting from 'highcharts/modules/exporting';
import Ui from '@geowellex/shared-ui'
import { applyDefaultZoomToChart } from '../../../lib/charts';
import {
  syncronizeTooltip, getToolTipValues,
  disableDefaultZoom, displayZoomBtn, propagateZoom,
  disableDots, disableLabels, toggleTrack, toggleBalanceRatio,
  nFormatter, defaultCrossHair, defaultValuesToCrosshair,
  formattingMin
} from '../../../utils';
import { DEFAULT_SCALES } from '../../../utils/constants';
import GlobalCutOff from '../../../utils/GlobalCutOff';
import { getCalculatedCurveData } from '../../../utils/getCalculatedCurveData';
import get from 'lodash.get';
import { getRawCurveData } from 'utils/getRawCurveData';
import { useScales } from '../../../hooks/useScales';
import { shouldUpdateScale } from '../../../hooks/useScales';

const curveTypes = [
  'ch_ratio',
  'bh_ratio',
  'wh_ratio'
];

const initialHeader = {
  balanceRatio: 0,
  wetnessRatio: 0,
  characterRatio: 0
};

Exporting(Highcharts);

const HeaderChart = () => {
  const wells = useSelector(state => state.wells);
  // --- Intital State
  const {
    currentScale, setHeaderState, headerState
  } = useScales(
    curveTypes,
    wells.currentWell,
    initialHeader
  );

  // --- Toggle Tracks
  const [tracks, setToggle] = useState({
    isOpen: false,
    character: true,
    balance: true,
    wetness: true
  });

  const tooggleCallbacks = {
    character: toggleBalanceRatio,
    balance: toggleBalanceRatio,
    wetness: toggleBalanceRatio
  };

  const toggleOneTrack = (event, name, index) => {
    const trackName = tracks[name];
    const callback = tooggleCallbacks[name];
    callback(parseInt(index))(event);
    setToggle({
      ...tracks,
      isOpen: !tracks.isOpen,
      [name]: !trackName
    });
  };

  // eslint-disable-next-line no-unused-vars
  const toggleTrackCurrentTrack = (event) => {
    const { dataset: { name, index } } = event.target;
    const trackName = tracks[name];
    const callback = tooggleCallbacks[name];
    if (callback) {
      callback(parseInt(index))(event);
      setToggle({
        ...tracks,
        isOpen: !tracks.isOpen,
        [name]: !trackName
      });
    }
  };

  // --- Open modal with config or toggle track
  const dispatchModal = (typeOfTitle) => (event) => {
    event.persist();
    event.stopPropagation();
    if (event.target && event.target.dataset.cmd === 'toggle') {
      toggleTrackCurrentTrack(event);
      return;
    } else if (event.target && event.target.dataset.cmd === 'openConfig') {
      const openModal = new CustomEvent('openConfigModal', {
        detail: {
          type: typeOfTitle
        }
      });
      document.dispatchEvent(openModal);
      return;
    }
  };

  useEffect(() => {
    if (wells.currentWell && wells.selectedDepthIndex >= 0) {

      // TODO - when connect with API get values from useAPI customHook charts
      const wetnessRatio = getCalculatedCurveData('wh_ratio', wells.currentWell);
      const balanceRatioData = getCalculatedCurveData('bh_ratio', wells.currentWell);
      const characterRatioData = getCalculatedCurveData('ch_ratio', wells.currentWell);

      const index = wells.selectedDepthIndex;
      const wetnessValue = wetnessRatio && wetnessRatio.data && wetnessRatio.data[index] ? wetnessRatio.data[index] : 0;
      const balanceValue = balanceRatioData && balanceRatioData.data && balanceRatioData.data[index] ? balanceRatioData.data[index] : 0;
      const characterValue = characterRatioData && characterRatioData.data && characterRatioData.data[index] ? characterRatioData.data[index] : 0;

      setHeaderState({
        ...headerState,
        balanceRatio: balanceValue?.toFixed(2),
        wetnessRatio: wetnessValue?.toFixed(2),
        characterRatio: characterValue?.toFixed(2)
      });
    }

    if (!wells.currentWell) {
      setHeaderState({
        ...headerState,
        balanceRatio: 0,
        wetnessRatio: 0,
        characterRatio: 0
      });
    }

  }, [wells]);

  useEffect(() => {
    const characterListener = ({ detail }) => {
      // headerState?.scales?.ch_ratio = detail;
      setHeaderState({
        ...headerState,
        ...updatedHeader
      });
    };
    document.addEventListener('characterRatio', characterListener);

    const balanceRatioListener = ({ detail }) => {
      // headerState?.scales?.bh_ratio = detail;
      setHeaderState({
        ...headerState,
        ...updatedHeader
      });
    };
    document.addEventListener('balanceRatio', balanceRatioListener);

    const wetnessListener = ({ detail }) => {
      // headerState?.scales?.wh_ratio = detail;
      setHeaderState({
        ...headerState,
        ...updatedHeader
      });
    };
    document.addEventListener('wetnessRatio', wetnessListener);

    return () => {
      document.removeEventListener('characterRatio', characterListener);
      document.removeEventListener('balanceRatio', balanceRatioListener);
      document.removeEventListener('wetnessRatio', balanceRatioListener);
    };
  }, []);

  const {
    characterRatio, balanceRatio, wetnessRatio
  } = headerState;

  return (
    <div className="balance-ratio">
      <Ui.Header
        min={nFormatter(currentScale?.ch_ratio?.min)}
        max={nFormatter(currentScale?.ch_ratio?.max)}
        textCenter={`Cha. Ratio (${characterRatio && characterRatio})`}
        background={currentScale?.ch_ratio?.color}
        checkboxIsVisible
        checked={tracks.character}
        onChangeCheckbox={(event) => toggleOneTrack(event, "character", 1)}
        onClick={dispatchModal('ch_ratio')}
        activeCurveConfig="ch_ratio"
        dataCy="open-config-cha-ratio"
      />
      <Ui.Header
        min={nFormatter(currentScale?.bh_ratio?.min)}
        max={nFormatter(currentScale?.bh_ratio?.max)}
        textCenter={`Bal. Ratio (${balanceRatio && balanceRatio})`}
        background={currentScale?.bh_ratio?.color}
        checkboxIsVisible
        checked={tracks.balance}
        onChangeCheckbox={(event) => toggleOneTrack(event, "balance", 2)}
        onClick={dispatchModal('bh_ratio')}
        activeCurveConfig="bh_ratio"
        dataCy="open-config-balance-ratio"
      />
      <Ui.Header
        min={nFormatter(currentScale?.wh_ratio?.min)}
        max={nFormatter(currentScale?.wh_ratio?.max)}
        textCenter={`Wet. Ratio (${wetnessRatio})`}
        background={currentScale?.wh_ratio?.color}
        checkboxIsVisible
        checked={tracks.wetness}
        onChangeCheckbox={(event) => toggleOneTrack(event, "wetness", 3)}
        onClick={dispatchModal('wh_ratio')}
        activeCurveConfig="wh_ratio"
        dataCy="open-config-wet-ratio"
      />
    </div>
  );
};

const getDefaultSeries = (scales = DEFAULT_SCALES, wetnessData, balanceRatioData, characterRatioData) => {
  const {
    wetnessRatio,
    characterRatio,
    balanceRatio
  } = scales;

  const placeholderData = defaultValuesToCrosshair(wetnessData, 0.1);
  return [
    {
      ...disableDots,
      fillOpacity: 0.5,
      name: 'character-ratio',
      type: 'spline',
      tooltip: {
        valueSuffix: ' ',
        enabled: false
      },
      fill: {
        color: 'rgba(0, 0, 0, 0)'
      },
      data: placeholderData,
      lineColor: 'rgba(0, 0, 0, 0)',
      min: scales?.ch_ratio?.min,
      max: scales?.ch_ratio?.max,
      yAxis: 0,
      zIndex: 3,
      lineWidth: 1.2,
      shadow: false,
      labels: {
        format: ' ',
        enabled: false
      },
      selected: true,
      index: 0
    },
    {
      ...disableDots,
      fillOpacity: 0.5,
      name: 'character-ratio',
      type: 'spline',
      tooltip: {
        valueSuffix: ' ',
        enabled: false
      },
      fill: {
        color: '#3fcf3f'
      },
      data: characterRatioData,
      lineColor: scales?.ch_ratio?.color,
      min: scales?.ch_ratio?.min,
      max: scales?.ch_ratio?.max,
      yAxis: 1,
      zIndex: 3,
      lineWidth: 1.2,
      shadow: false,
      labels: {
        format: ' ',
        enabled: false
      },
      selected: true,
      index: 1
    },
    {
      fillOpacity: 0.1,
      name: 'balance-ratio',
      type: 'spline',
      opposite: true,
      tooltip: {
        valueSuffix: ' ',
        enabled: false
      },
      data: balanceRatioData,
      lineColor: scales?.bh_ratio?.color,
      min: scales?.bh_ratio?.min,
      max: scales?.bh_ratio?.max,
      yAxis: 2,
      zIndex: 1,
      lineWidth: 1.2,
      ...disableDots,
      labels: {
        format: ' ',
        enabled: false
      },
      selected: true,
      index: 2
    },
    {
      ...disableDots,
      fillOpacity: 0.5,
      name: 'wetness-ratio',
      type: 'spline',
      tooltip: {
        valueSuffix: ' ',
        enabled: false
      },
      min: scales?.wh_ratio?.min,
      max: scales?.wh_ratio?.max,
      fill: {
        color: '#3fcf3f'
      },
      data: wetnessData,
      lineColor: scales?.wh_ratio?.color,
      yAxis: 3,
      zIndex: 1,
      step: true,
      lineWidth: 1.2,
      shadow: false,
      labels: {
        format: ' ',
        enabled: false
      },
      selected: true,
      index: 3
    }
  ]
};

class BalanceRatio extends Component {
  constructor(props) {
    super(props);
    this.chart = null;
    this.state = {
      selectedId: 0,
      plotBands: [],
      scatters: [],
      wetnessData: props.wetnessData,
      balanceRatioData: props.balanceRatioData,
      characterRatioData: props.characterRatioData,
      series: {
        0: true,
        1: true,
        2: true,
        3: true
      }
    };
    this.balanceRef = React.createRef();
  }

  componentDidMount() {
    const { selectedId } = this.state;
    this.createChart();

    if (this.chart) {
      this.zoomId = applyDefaultZoomToChart(this.chart);
    }

    GlobalCutOff.registerInstances(
      'characterRatio',
      this.chart
    );

    // TODO refactor this workaround because with two windows we need to comunicate
    // theses tabs using some reactive way
    // this was made one day before alfa presentation
    this.timerId = window.setInterval(() => {
      const shoudlClose = !!store.get('opened-cross-plot-balanceRatioToggle');
      if (shoudlClose === true) {
        this.chart.xAxis[0].removePlotBand(`selection${selectedId}`);
      }
    }, 100);

    document.addEventListener('removePlotBands', () => {
      const count = store.get('openedCrossPlots')
        ? parseInt(store.get('openedCrossPlots'))
        : 0;

      if (count) {
        this.chart.xAxis[0].removePlotBand(`selection${selectedId}`);
      }
    });

    // TODO refactor this syncronization
    document.addEventListener('sincronize-selected', event => {
      const { detail: { min, max } } = event;
      this.chart.xAxis[0].setExtremes(min, max, true, true);
      this.chart.redraw();
    });

    syncronizeTooltip('#balance-ratio');

    document.addEventListener('toggle-balance-ratio', ({ detail: { index } }) => {
      toggleTrack(this)(index);
    });

    // Change scales
    document.addEventListener('characterRatio', ({ detail }) => {
      this.chart.yAxis[1].update({
        min: formattingMin(detail.min),
        max: detail.max
      });
      const serie = this.chart.series[1];
      serie.update({
        color: detail.color,
        lineColor: detail.color
      })
      serie.redraw();
    });
    document.addEventListener('balanceRatio', ({ detail }) => {
      this.chart.yAxis[2].update({
        min: formattingMin(detail.min),
        max: detail.max
      });
      const serie = this.chart.series[2];
      serie.update({
        color: detail.color,
        lineColor: detail.color
      })
      serie.redraw();
    });
    document.addEventListener('wetnessRatio', ({ detail }) => {
      this.chart.yAxis[3].update({
        min: formattingMin(detail.min),
        max: detail.max
      });
      const serie = this.chart.series[3];
      serie.update({
        color: detail.color,
        lineColor: detail.color
      });
      serie.redraw();
    });
  }

  shouldComponentUpdate(nextProps) {
    const {
      wetnessData, characterRatioData, balanceRatioData,
      selectedWell, currentWell, min, max, updateChart
    } = nextProps;

    const { props } = this;

    const beforeDepthData = get(getRawCurveData('depth', props.beforeWell), 'data', []);
    const currentDepthData = get(getRawCurveData('depth', nextProps.currentWell), 'data', []);
    if (
      selectedWell !== props.selectedWell ||
      beforeDepthData?.length !== currentDepthData?.length
      || wetnessData !== props.wetnessData || characterRatioData !== props.characterRatioData
      || balanceRatioData !== props.balanceRatioData
      || updateChart
    ) {
      this.chart.update({
        series: getDefaultSeries(
          currentWell?.scales || store.get('configScales'),
          wetnessData || [],
          balanceRatioData || [],
          characterRatioData || []
        )
      });

      if (this?.chart?.xAxis?.length !== 0 && min >= 0 && max >= 0) {
        this.zoomId = setTimeout(() => {
          this?.chart?.xAxis?.[0]?.setExtremes(min, max);
        }, 300);
      }
      return true;
    }

    const defaultScales = store.get('configScales');
    curveTypes.forEach((curveName) => {
      const checkToUpdateScales = shouldUpdateScale(
        curveName,
        currentWell?.scales,
        defaultScales
      );

      if (currentWell && checkToUpdateScales && this.chart && this.chart.update) {
        this.chart.update({
          series: getDefaultSeries(
            currentWell?.scales,
            wetnessData,
            balanceRatioData,
            characterRatioData
          )
        });
        return true;
      }
    });

    return false;
  }

  componentWillUnmount() {
    window.clearInterval(this.timerId);
    // eslint-disable-next-line no-unused-expressions
    this.zoomId && clearTimeout(this.zoomId);
  }

  // TODO create a util this code exists in reasons charts
  selectingAreas = event => {
    if (!this.props.enableZoomIn) {
      event.preventDefault();
      const { customEventType, currentWell } = this.props;
      const { selectedId } = this.state;
      if (store.get('crossPlots')) {
        event.preventDefault();
        const axis = event.xAxis[0];
        const xAxis = this.chart.xAxis[0];

        xAxis.removePlotBand(`selection${selectedId}`);
        const count = store.get('openedCrossPlots')
          ? parseInt(store.get('openedCrossPlots'))
          : 0;
        store.set('openedCrossPlots', count + 1);

        const id = `selection${selectedId}`;
        const plotSelection = {
          from: axis.min,
          to: axis.max,
          color: 'rgba(51, 92, 173, 0.25)',
          id,
          zIndex: 20
        };

        xAxis.addPlotBand(plotSelection);

        const minValue = Math.floor(axis?.min?.toFixed());
        const maxValue = Math.floor(axis?.max?.toFixed());
        let minLabel = minValue;
        let maxLabel = maxValue;
        if (currentWell) {
          const depthData = get(getRawCurveData('depth', currentWell), 'data', []);
          minLabel = depthData[minLabel];
          maxLabel = depthData[maxLabel];
        }
        const togglePlotBox = new CustomEvent('showPlotBox', {
          detail: {
            type: customEventType,
            min: minValue,
            max: maxValue,
            minLabel,
            maxLabel
          }
        });
        document.dispatchEvent(togglePlotBox);
        store.set('opencrossplotFluidTyping', 'balanceRatio');
      } else {
        displayZoomBtn();
        propagateZoom(event);
      }
    } else {
      displayZoomBtn();
      propagateZoom(event);
    }
  }

  createChart = () => {
    const {
      id, wetnessData, balanceRatioData, characterRatioData, currentWell
    } = this.props;

    const scales = store.get('configScales') || DEFAULT_SCALES;
    this.chart = Highcharts.chart(id, {
      chart: {
        zoomType: 'x',
        type: 'area',
        inverted: true,
        width: 162,
        marginTop: 2,
        marginBottom: 2,
        // backgroundColor: window.color || '#fff',
        backgroundColor: '#151724',
        ...disableDefaultZoom,
        events: {
          selection: this.selectingAreas
        },
        animation: false,
        shadow: false
      },
      boost: {
        useGPUTranslations: true,
        usePreAllocated: true
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false
          },
          enableMouseTracking: false,
          showInLegend: false
        }
      },
      exporting: { enabled: false },
      title: ' ',
      subtitle: {
        text: null
      },
      credits: {
        enabled: false
      },
      xAxis: [
        {
          minorGridLineWidth: 1,
          minorGridLineColor: '#666666',
          minorTickInterval: 1,
          showFirstLabel: true,
          showLastLabel: true,
          lineColor: '#666666',
          minPadding: 0.0,
          maxPadding: 0.0,
          lineWidth: 0,
          opposite: true,
          labels: {
            enabled: false
          },
          gridLineColor: '#666666',
          gridLineWidth: 0.5,
          tickLength: 0
        }
      ],
      yAxis: [
        {
          type: 'linear',
          minorTickInterval: 0.1,
          ...disableLabels,
          allowDecimals: true,
          index: 0,
          labels: {
            enabled: false
          },
          gridLineColor: '#333',
          minorGridLineWidth: 0.4,
          minorGridLineColor: '#333',
          min: 0,
          max: 10
        },
        {
          type: 'linear',
          minorTickInterval: 0.1,
          ...disableLabels,
          allowDecimals: true,
          gridLineColor: '#333',
          minorGridLineWidth: 0.4,
          minorGridLineColor: '#333',
          index: 1,
          labels: {
            enabled: false
          },
          min: scales?.ch_ratio?.min,
          max: scales?.ch_ratio?.max
        },
        {
          ...disableLabels,
          type: 'logarithmic',
          minorTickInterval: 0.1,
          allowDecimals: true,
          gridLineColor: '#333',
          minorGridLineWidth: 0.4,
          minorGridLineColor: '#333',
          index: 2,
          labels: {
            enabled: false
          },
          min: scales?.bh_ratio?.min,
          max: scales?.bh_ratio?.max
        },
        {
          type: 'logarithmic',
          minorTickInterval: 0.1,
          ...disableLabels,
          allowDecimals: true,
          gridLineColor: '#333',
          minorGridLineWidth: 0.4,
          minorGridLineColor: '#333',
          index: 3,
          labels: {
            enabled: false
          },
          min: scales?.wh_ratio?.min,
          max: scales?.wh_ratio?.max
        }
      ],
      tooltip: {
        ...defaultCrossHair,
        shared: true,
        formatter: getToolTipValues('balance-ratio')
      },
      series: getDefaultSeries(
        currentWell.scales || DEFAULT_SCALES,
        wetnessData,
        balanceRatioData,
        characterRatioData
      )
    });
  }

  // componentWillUnmount() {
  //   window.clearInterval(this.timerId);
  // }

  render() {
    const { id } = this.props;
    return (
      <div
        className="chart__box-container"
      >
        <HeaderChart />
        <div
          id={id}
          style={{
            height: 'calc(100% - 180px)'
          }}
          data-cy="chart-balance-ratio"
        />
      </div>
    );
  }
}

BalanceRatio.defaultProps = {
  wetnessColor: '#67b675',
  balanceColor: '#724296',
  characterColor: '#5bb9e6',
  id: 'balance-ratio',
  parentContainer: '.charts-container',
  customEventType: 'balanceRatioToggle'
};

export default React.memo(BalanceRatio);
