import { Checkbox } from "@mui/material";
import styled from "styled-components";

interface ContainerHeaderProps {
  background: string;
  align: string;
};

export const CheckBoxCurve = styled(Checkbox)`
  display: none !important;
  padding: 0 !important;
  position: absolute !important;
  right: -30px;
  color: #FDFDFD !important;
`;

export const ContainerHeader = styled.div<ContainerHeaderProps>`
  width: 100%;
  font-size: 12px;
  background-color: ${(props) => props.background};
  color: #FDFDFD;
  display: flex;
  justify-content: ${(props) => props.align};
  padding: 4px;
  border-radius: 2px;
  cursor: pointer;

  &:hover ${CheckBoxCurve} {
    display: flex !important;
  }
`;

export const TextCenterHeader = styled.span`
  font-weight: 600;
`;

export const TextCenterContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  position: relative;
`;

export const RangeValueContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;