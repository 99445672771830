import React from 'react'
import { TextField } from '@mui/material';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Controller } from 'react-hook-form';
import { Label } from '../Label';

import { AutocompleteStyled, PopperStyled } from './styles';
import { uppercaseOptions } from '../../utils/matchCurves';
import { AutocompleteProps } from './types';

const PopperAutocomplete = function (props) {
  return (<PopperStyled {...props} />)
}

export const Autocomplete = ({ control, name, options, label, dataCy }: AutocompleteProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        return (
          <>
            <Label text={label} />
            <AutocompleteStyled
              {...field}
              disablePortal
              options={options}
              value={field?.value ? uppercaseOptions?.[field?.value] : null}
              popupIcon={<ExpandMoreIcon />}
              PopperComponent={PopperAutocomplete}
              renderInput={(params) => <TextField {...params} />}
              onChange={(e, value) => {
                field.onChange(value?.id ?? null)
                console.log('value: ', value)
              }}
              data-cy={dataCy}
            />
          </>
        )
      }}
    />
  )
}