import React from 'react'
import { Tooltip } from "@mui/material"
import { BadgeStyled, ErrorIconStyled, TitleTooltip } from './styles';

const Badge = ({ open, text, children, ...rest }) => {
  return (
    <BadgeStyled
      invisible={!open}
      badgeContent={
        <Tooltip
          title={<TitleTooltip>{text}</TitleTooltip>}
          placement="top"
          arrow
        >
          <ErrorIconStyled />
        </Tooltip>
      }
      {...rest}
    >
      {children}
    </BadgeStyled>
  )
}

export default Badge;