import { Box, Snackbar } from "@material-ui/core";
import { Skeleton } from "@mui/material";
import styled from "styled-components";
import { customTheme } from '@geowellex/shared-ui/src/theme/default';

export const ZoneContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 16px 32px;
  gap: 16px;
`

export const ZoneHeader = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const SkeletonLoading = styled(Skeleton)`
  && {
    background-color: ${customTheme.purple6};
  }
  border-radius: 8px;
`

export const SnackbarMessage = styled(Snackbar)`
  && .MuiPaper-root {
    width: fit-content;
    background-color: ${customTheme.blue1};
  }
`