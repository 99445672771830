export const config = { auth: '' };

export const setupToken = (token: string) => config.auth = token;

export const allCharts: any = {
  instances: {
    gasComposition: null
  }
};

export const chartsToApplyZones = [
  'rop',
  'total-gas',
  'chromatography',
  'gas-composition',
  'balance-ratio',
  'reason-chart',
  'isotope-s13',
  'pixler-chart',
  'gqr-chart'
];
