import React from 'react';
import UI from '@geowellex/shared-ui';
import { ReactComponent as DeleteIcon } from '../../assets/delete2_icon.svg';

import { Body, Footer } from './styles'

interface ConfirmDeleteZoneProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const ConfirmDeleteZone = ({ isOpen, onClose, onConfirm }: ConfirmDeleteZoneProps) => {
  return (
    <UI.ConfirmDialog
      title='Delete Zone'
      isOpen={isOpen}
      onClose={onClose}
    >
      <>
        <Body>
          <span>This can't be undone</span>
        </Body>
        <Footer>
          <UI.Button
            type="button"
            theme="secondary"
            onClick={onClose}
          >
            Cancel
          </UI.Button>
          <UI.Button
            type="button"
            theme="danger"
            startIcon={<DeleteIcon />}
            onClick={onConfirm}
          >
            Delete
          </UI.Button>
        </Footer>
      </>
    </UI.ConfirmDialog>
  )
}