import { useState, useCallback } from 'react';
import { GridCellParams, GridColDef, GridEventListener } from '@mui/x-data-grid';
import store from 'store';
import { LinkButton, StyledDataGrid } from './styles';
import { Chip } from '@mui/material';
import { Actions } from './components/Actions';
import { CustomToolbar } from './components/CustomToolbar';
import Loading from '@geowellex/shared-ui/src/components/Loading';
import { ImportWellActionEnum } from './components/ImportWellAction';
import useDataTable from './hooks/useDataTable';

type DataTableProps = {
    data: any[] ;
    selectedKey?: any;  
    canDelete?: boolean;
};

export default function DataTable({data, selectedKey, canDelete }: DataTableProps) {
  const [loading, setLoading] = useState(false);
  const [filterProperty, setFilterProperty] = useState(undefined);
  const { handleSelectWell } = useDataTable({ setLoading, token: store.get('token'), selectedKey });

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    console.log(`Well "${params.row.uid}" clicked`);
  };

  const filteredWells = useCallback(() => {
    return data?.filter((well: any) => {
        if (filterProperty === undefined) return data;

        if(filterProperty === false){
          return (well?.is_active === filterProperty || well?.is_active === null);
        };
        return well?.is_active === filterProperty;
    });
  }, [filterProperty, data]);

  const columns: GridColDef[] = [
    { 
        field: 'wellName', 
        headerName: 'Name', 
        flex: 1, 
        minWidth: 160, 
        renderCell: (params: GridCellParams) => 
              {
                const importType = canDelete ? 
                    ImportWellActionEnum.AVAILABLE_WELLSITES : 
                    ImportWellActionEnum.CLOUD_CONNECTION;           
                return (<LinkButton 
                    onClick={
                        () => { 
                            handleSelectWell(params?.row, importType)}
                    }
                >
                    {params.row.wellName}
                </LinkButton>)}
    },
    {
        field: 'logName',
        flex: 1,
        headerName: 'Log Name',
        minWidth: 150,
    },
    {
        field: 'start_depth',
        flex: 1,
        headerName: 'Start',
        maxWidth: 150,
    },
    {
        field: 'stop_depth',
        flex: 1,
        headerName: 'Stop',
        type: 'number',
        maxWidth: 110,
    },
    {
        field: 'index_unit',
        flex: 1,
        headerName: 'Unit',
        type: 'number',
        maxWidth: 110,
    },
    {
        field: 'is_active',
        headerName: 'Status',
        flex: 1,
        type: 'string',
        maxWidth: 110,
        renderCell: (params: GridCellParams) => {
        let status = { 
            label: '', 
            color: '',
            text: ''
        };
        if(params.value == true) {
            status.label = 'Active';
            status.color = '#116531';
            status.text = '#73C892'
        }
        else {
            status.label = 'Inactive';
            status.color = '#881A1B';
            status.text = '#F5C9C9'
        }
        return (
            <div>
                <Chip 
                    label={status.label} 
                    sx={{background: status.color, borderRadius: '4px', color: status.text}}
                    size="small"
                />
            </div>
        )},
    },
    {
        field: 'actions',
        headerName: '',
        flex: 1,
        renderCell: (params: GridCellParams) => {    
            return(
                <Actions 
                    setLoading={setLoading}
                    token={store.get('token')}
                    selectedKey={selectedKey}
                    canDelete={canDelete}
                    params={params}
                />)

        },
    },
  ];

  return (
    <div style={{ height: 'calc(100% - 14rem)', width: '98%' }}>
        <Loading show={loading}/>
        <StyledDataGrid
            rows={filteredWells()}
            getRowId={(row) => row.uid}
            columns={columns}
            onRowClick={handleRowClick}
            density='comfortable'
            initialState={{
                pagination: {
                    paginationModel: {
                        pageSize: 5,
                    },
                },
            }}
            slots={{
                // @ts-ignore
                toolbar: CustomToolbar,
            }}
            slotProps={{ toolbar: { setFilter: setFilterProperty } }}
            pageSizeOptions={[5]}
            //checkboxSelection
            //disableRowSelectionOnClick
        />
    </div>
  );
}