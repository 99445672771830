import{ useState }from 'react';
import Box from '@mui/material/Box';
import { CustomTabs, CustomTab } from './styles'

interface TabItem {
    label: string;
    value: string;
    content: React.ReactNode;
    filter: boolean | null | undefined;
}

interface TabsNavProps {
    tabsItems: TabItem[];
    setFilter?: any;
}

export default function TabsNav({ tabsItems, setFilter }: TabsNavProps) {
    const [value, setValue] = useState(tabsItems[0].value);

    // @ts-ignore
    const handleChange = (event: any, newValue: string) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <CustomTabs onChange={handleChange} value={value} aria-label="filter-tabs">
                    {tabsItems.map(({ content, value, filter}) => (
                        <CustomTab label={content} value={value} onClick={()=> setFilter(filter)} />
                    ))}
                </CustomTabs>
            </Box>
        </Box>
    );
}