import React from 'react';

const RenderChart = ({
  id, styles, className
}) => (
  <div
    id={id}
    style={styles}
    className={className}
  />
);

RenderChart.defaultProps = {
  styles: {
    height: 'calc(100% - 180px)'
  },
  className: ''
};

export default RenderChart;
