import { Box, Dialog } from "@mui/material";
import styled from "styled-components";
import { customTheme } from "../../../src/theme/default";

export const DialogStyled = styled(Dialog)`
  .MuiPaper-root {
    background-color: ${customTheme.bg};
    width: 313px;
    border-radius: 16px;
  }
`

export const Header = styled(Box)`
  padding: 16px;
  color: ${customTheme.white};
  font-size: 21px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CloseContainer = styled(Box)`
  display: flex;
  align-items: center;
  cursor: pointer;
`