import './style.scss';

/* eslint-disable */
import React, {
  useEffect,
  useState
} from 'react';

import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import store from 'store';
import { useParams } from 'react-router-dom';

import { getEthenenAndDepth } from '../../utils';
import ChartContainer from '../Shared/ChartContainer';
import {
  defaultConfigAxis,
  relativeEtheneChart
} from './chart';
import RenderChart from './RenderChart';
import Settings from './Settings';
import { useDispatch, useSelector } from 'react-redux';
import { getRawCurveData } from '../../utils/getRawCurveData';
import { getCalculatedCurveData } from '../../utils/getCalculatedCurveData';
import { listWell } from '../../actions/wells';

Exporting(Highcharts);
const createChart = (
  config,
  id
) => {
  const chart = new Highcharts.chart(id, {
    ...config,
    chart: {
      ...config.chart
    }
  });
  return chart;
};

const updateAxisCofig = ( chart, yMin, yMax, xMin, xMax) => {
  chart.yAxis[0].update({
    min: yMin,
    max: yMax
  }, true);
  chart.xAxis[0].update({
    min: xMin,
    max: xMax
  }, true);
  chart.redraw();
};

const RelativeEthene = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [scattersConfig, setConfig] = useState();
  const [chart, setChart] = useState(null);
  const [etheneConfig, setEtheneConfig] = useState(defaultConfigAxis);
  const [currentWell, setCurrentWell] = useState();
  const params = useParams();
  const wells = useSelector((state) => state.wells);

  useEffect(() => {
    const [queryId] = params.id.split('--');
    const token = store.get('token');
    const queryData = store.get('querys')[queryId];

    dispatch(listWell(queryData.wellId, token));
    setConfig(queryData)
  }, [params]);

  useEffect(() => {
    if (wells && wells.currentWell && !currentWell) {
      setCurrentWell(wells.currentWell);
    }
  }, [wells && wells.currentWell]);

  useEffect(() => {
    if (currentWell) {
      const c2 = getRawCurveData('c2', currentWell)?.data;;
      const c2s = getRawCurveData('c2s', currentWell)?.data;
      const c3h6 = getRawCurveData('c3h6', currentWell)?.data;
      const c2h4 = getRawCurveData('c2h4', currentWell)?.data;
      const depth = getRawCurveData('depth', currentWell)?.data;
      const fidCurve = getRawCurveData('c2fid', currentWell)?.data;

      const dataEthene = getEthenenAndDepth(
        depth,
        c2,
        c2s,
        c3h6,
        c2h4,
        scattersConfig?.ethene_sensor,
        fidCurve
      );

      const config = relativeEtheneChart(dataEthene);
      const chartInstance = createChart(config, 'relative-ethene');
      setChart(chartInstance);
    }
  }, [currentWell]);

  const onSubmitEthene = (values) => {
    const { yMin, yMax, xMin, xMax } = values;
    setEtheneConfig({
      ...etheneConfig,
      yMin,
      yMax,
      xMin,
      xMax
    });
    updateAxisCofig(chart, yMin, yMax, xMin, xMax);
  };

  const showDrawer = () => setVisible(true);
  const onClose = () => setVisible(false);

  return (
    <div className="scatters-ethene">
      <div className="scatter-header-ethene">
        <div className="scatter-info-ethene">
          <h3 className="scatter-title-ethene">
            Relative Ethene
          </h3>
          {scattersConfig && (
            <h3 className="scatters-ethene-range">
              {`Min: ${scattersConfig.minLabel} - Max: ${scattersConfig.maxLabel}`}
            </h3>
          )}
        </div>
      </div>
      <Settings
        onClose={onClose}
        visible={visible}
        currentScaleConfig={etheneConfig}
        onSubmitEthene={onSubmitEthene}
      />
      <div className="ethene-container">
        <ChartContainer>
          {/* <div className="wrapper-button-ethene">
            <Button
              type="primary"
              shape="circle"
              icon="setting"
              size="large"
              className="toggle-crossplot-ethene"
              onClick={showDrawer}
            />
          </div> */}
          <RenderChart id="relative-ethene"/>
        </ChartContainer>
      </div>
    </div>
  );
}

export default RelativeEthene;
