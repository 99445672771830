import React, { useContext, useEffect, useState } from 'react';
import store from 'store';
import { LoadWellContainer, LoadWellHeader } from './styles';
import { Typography } from '@material-ui/core';
import DataTable from './components/DataTable';
import ConfigContext from '@geowellex/shared-ui/src/context/ConfigContext';
import TabsDataSelect from './components/TabsDataSelect';
import Loading from '@geowellex/shared-ui/src/components/Loading';
import useDataTable from './components/DataTable/hooks/useDataTable';
import { ImportFromFile } from './components/ImportFromFile';
import UI from '@geowellex/shared-ui';

const LoadWell = () => {
  const { wellsItems, resetLoadWell } = useContext(ConfigContext);
  const [flag, setFlag] = useState(false);
  const [canDelete, setCanDelete] = useState(true);
  const [loading, setLoading] = useState(false);

  const { 
    wellsData, setWellsData, uploadCloudWell
  } = useDataTable({ token: store.get('token'), setLoading, setCanDelete });

  const handleChangeTable = () =>{
      setCanDelete(true);
      setFlag(!flag);   
  };

  useEffect(()=>{
      if(wellsItems){
        setWellsData(wellsItems);
      }
  },[wellsItems, flag]); 

  return (
   <>
    <Loading show={loading}/>
    <UI.CustomScrollBar>
      <LoadWellContainer>
      <Typography variant="inherit" style={{ color: '#979798' }} >Import Data</Typography>    
      <LoadWellHeader>
        <ImportFromFile />
      </LoadWellHeader>
        <TabsDataSelect onChange={handleChangeTable} cloudWells={uploadCloudWell} reset={resetLoadWell}/>
        <DataTable data={wellsData ?? []} canDelete={canDelete} />
      </LoadWellContainer>
    </UI.CustomScrollBar>
  </>);
};

export default LoadWell;
