import React, {
  createContext,
  useContext,
  useState
} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import store from 'store';

import {
  doAuthenticate,
  getUserInfo
} from '../api';
import { useService } from './useService';
import { onSubmitLoginValues } from '../types/login';
import { ProvideAuthProps } from '../types/useAuth';

{/* @ts-ignore */}
export const AuthContext = createContext();

export const useAuth = () =>
  useContext(AuthContext);

const configureAxiosToken = (token: string) => {
  axios.defaults.headers.Authorization = `Bearer ${token}`;
  axios.defaults.headers['access-token'] = token;
};

export const useProvideAuth = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [error, setError] = useState();

  const { service, checkAccess } = useService();

  const [isValidToken, setValidToken] = useState(false);
  const [checkingToken, setCheckingToken] = useState(true);

  const checkValidSession = async () => {
    const token = store.get('token');
    configureAxiosToken(token);
    try {
      setValidToken(true);
    } catch(e) {
      setValidToken(false);
      console.log('error ', e)
    } finally {
      setCheckingToken(false);
      setLoading(false);
    }
  };

  const signIn = async (values: onSubmitLoginValues) => {
    setLoading(true);
    try {
      console.log('node env', process.env.NODE_ENV);
      if (process.env.NODE_ENV === 'test') {
        console.log('inside');
        navigate('/home');
        return;
      }
      const { data } = await doAuthenticate({
        username: values.username,
        password: values.password,
        grant_type: 'password',
        client_id: 'gold'
      });
      store.set('token', data.access_token);
      setUserInfo(data.access_token);
      configureAxiosToken(data.access_token);
      const userInfoData = await getUserInfo();

      const { canAccessGold, canAccessAutomud } = checkAccess(userInfoData);

      if (userInfoData?.data?.sub) {
        if (service === 'GOLD' && !canAccessGold) {
          throw new Error('User not allowed to access GOLD service');
        }
        if (service === 'AUTOMUD' && !canAccessAutomud) {
          throw new Error('User not allowed to access AUTOMUD service');
        }
        store.set('email', userInfoData.data.sub);
        store.set('services_allowed', userInfoData.data.services_allowed);
      }

      store.set('preferred_username', values.username);

      setLoading(false);

      navigate('/home');
    } catch(e: any) {
      if (e.response && e.response.data && e.response.data.error) {
        setError(e.response.data.error_description)
      }else{
        setError(e?.message);
      }
      console.log('login error', e);
    } finally {
      setLoading(false);
    }
  };

  const signOut = () => {
    configureAxiosToken('');
    store.set('token', '');
    store.set('email', '');
    navigate('/login');
  };

  const checkInWellIsAllowed = () => {
    const email = store.get('preferred_username');
    if (email.includes('repsol') || email.includes('geowellex') || email.includes('sinopec')) {
      return true;
    }
    return false;
  };

  return {
    isValidToken,
    checkValidSession,
    checkingToken,
    error,
    loading,
    userInfo,
    signIn,
    signOut,
    checkInWellIsAllowed
  };
};

export const ProvideAuth = ({children }: ProvideAuthProps) => {
  const auth = useProvideAuth();

  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  );
};
