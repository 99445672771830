interface ZoneIndicatorIconProps {
  size: string,
  color: string
}

const ZoneIndicatorIcon = ({ size, color }: ZoneIndicatorIconProps) => (
  <svg width={size} height={size} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.75 7.5H11.25L9.375 13.125L5.625 1.875L3.75 7.5H1.25" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
)

export default ZoneIndicatorIcon
