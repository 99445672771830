import { Box } from "@mui/material";
import styled from "styled-components";
import { customTheme } from '@geowellex/shared-ui/src/theme/default';

export const Body = styled(Box)`
  padding: 16px;
  border-top: 1px solid ${customTheme.purple3};
  border-bottom: 1px solid ${customTheme.purple3};
  color: ${customTheme.gray1};
`

export const Footer = styled(Box)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`