import { GridCellParams } from '@mui/x-data-grid';
import { DeleteActionButton } from '../DeleteActionButton';
import { ImportWellAction, ImportWellActionEnum } from '../ImportWellAction';

interface ActionsProps {
    setLoading: any;
    token: any;
    selectedKey: string;
    canDelete: boolean | undefined;
    params: GridCellParams;
};

export const Actions = ({ setLoading, token, selectedKey, canDelete, params}: ActionsProps) => {
  return(<div style={{display: 'flex'}}>
        <ImportWellAction
          setLoading={setLoading}
          token={token}
          selectedKey={selectedKey}
          well={params.row}
          btnText={canDelete ? 'Select Well' : 'Import Well'}
          importType={canDelete ? 
            ImportWellActionEnum.AVAILABLE_WELLSITES : 
            ImportWellActionEnum.CLOUD_CONNECTION
        }
        />
        { canDelete ? <DeleteActionButton
          setLoading={setLoading}
          token={token}
          selectedKey={selectedKey}
          wellUid={params.row.uid}
        /> : null}
      
  </div>
  )
}