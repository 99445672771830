import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import { IconButton } from '@mui/material';
import { customTheme } from '../../theme/default';
import ConfigContext from '../../context/ConfigContext';
import { ReactComponent as CloseIcon } from '../../icons/close_icon.svg';

import { Header } from './styles';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

type Props = {
  drawerDirection: Anchor;
  width: number;
  isOpenDrawer?: boolean,
  triggerEvent?: string;
  children?: JSX.Element;
  title?: string;
  getTitleByEventType?: (title: string) => string;
};

export default function DrawerBase({
  drawerDirection = 'right',
  width = 384,
  isOpenDrawer = false,
  triggerEvent,
  children,
  title,
  getTitleByEventType,
}: Props) {
  const { setNameDrawerOpen, setActiveCurve } = React.useContext(ConfigContext);
  const [isOpen, setIsOpen] = React.useState(false);
  const [drawerTitle, setDrawerTitle] = React.useState('');

  const closeDrawer = () => {
    setIsOpen(false)
    setNameDrawerOpen?.('');
    setActiveCurve?.('');
  }

  React.useEffect(() => {
    if (triggerEvent && getTitleByEventType) {
      const openConfigChart = (event: any) => {
        setIsOpen(true);
        const newTitle = getTitleByEventType?.(event?.detail?.type) || '';
        setDrawerTitle(newTitle);
      };
      document?.addEventListener(triggerEvent, openConfigChart);
      return () => {
        document?.removeEventListener(triggerEvent, openConfigChart);
      };
    }
  }, [triggerEvent, getTitleByEventType]);

  React.useEffect(() => {
    setIsOpen(isOpenDrawer);
  }, [isOpenDrawer]);

  return (
    <div>
      <Drawer
        sx={{
          "& .MuiPaper-root": {
            width,
            background: customTheme.bg,
          },
        }}
        anchor={drawerDirection}
        open={isOpen}
        onClose={closeDrawer}
      >
        <Header>
          <div>
            {title ? <span>{title}</span> : null}
            {drawerTitle ? <span style={{ color: '#fff', fontSize: 18, }}>{drawerTitle}</span> : null}
          </div>
          <IconButton aria-label="close" onClick={closeDrawer}>
            <CloseIcon />
          </IconButton>
        </Header>
        {children}
      </Drawer>
    </div>
  );
}

/*
*  How to use?

* -renderProps
```
  <UI.Drawer
    render={(openDrawer, closeDrawer) => {
      return (
        <MenuItem onClick={openDrawer} />
        <CloseButton onClick={closeDrawer} />
      );
    }}
  >
```

* -children
```
  <UI.Drawer
    drawerDirection='right'
    width={384}
    triggerEvent={() => {
      window.addEventLister('customEvent', (e) => {
        if (e.type === 'customEvent') {
          ...do something
        }
      })
    }}
  >
    <ConfigModal />
  </UI.Drawer>
```
*/