import { ReactNode } from 'react';
import { ReactComponent as CloseIcon } from '../../icons/close_icon.svg';

import { CloseContainer, DialogStyled, Header } from "./styles"

interface ConfirmDialogProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode
}

export const ConfirmDialog = ({ title, isOpen, onClose, children }: ConfirmDialogProps) => {
  return (
    <DialogStyled
      open={isOpen}
      onClose={onClose}
    >
      <Header>
        <span>{title}</span>
        <CloseContainer onClick={onClose}>
          <CloseIcon />
        </CloseContainer>
      </Header>
      {children}
    </DialogStyled>
  )
}