import { Box, FormHelperText, Select } from '@mui/material';
import styled from "styled-components";
import * as colors from "../../theme/colors";

export const Container = styled(Box)`
  width: 100%;
`

export const SelectStyled = styled(Select)`
  width: 100%;
  background-color: transparent;

  && .Mui-disabled {
    color: #fff;
    -webkit-text-fill-color: #ffffff61;
  }

  div {
    color: #E5E7EB;
    padding: 8px;
  }
  > svg {
    color: #FFFFFF;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${(props) => props.error ? '#EB3434' : `${colors.wrapBorders}` } !important;
  }

  .MuiPaper-root {
    background-color: #1F2937;
    font-size: 16px;
    color: #FFFFFF;
    padding: 0;
    border-radius: 6px;
    max-height: 200px;

    .MuiList-root {
      padding: 0;
    }
    .MuiButtonBase-root {
      padding: 8px;
    }
    .MuiButtonBase-root:hover {
      background-color: #374151;
    }

    .MuiButtonBase-root:hover, .Mui-selected {
      background-color: #374151;
    }
  }

  .MuiSvgIcon-root {
    color: #F5F5FF;
  }
`

export const ErrorTextStyled = styled(FormHelperText)`
  && {
    color: #EB3434;
    font-size: 16px;
  }
`
