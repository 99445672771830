interface ZoneAnalysisProps {
  color: string
}

export const ZoneAnalysisIcon = ({ color }: ZoneAnalysisProps) => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.25 14.75C11.5637 14.75 14.25 12.0637 14.25 8.75C14.25 5.43629 11.5637 2.75 8.25 2.75C4.93629 2.75 2.25 5.43629 2.25 8.75C2.25 12.0637 4.93629 14.75 8.25 14.75Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.75 16.25L12.4875 12.9875" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.25 6.5V11" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6 8.75H10.5" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
)
