import React from 'react';
import InstructionsMessage from '../InstructionsMessage';
import CollapsePanel from '../CollapsePanel';
import { Typography } from 'antd';

const { Paragraph } = Typography;

const SecondStep = ({ values, curvesConfiguration }) =>  {
  return (
    <>
      <div className="statistic-modal-curves__content">
        <div className="wrapper-instruction-message">
            <Paragraph className="statistic-modal__label">
              Curves Configuration
            </Paragraph>
          <InstructionsMessage />
        </div>
        <div className="wrapper-collapse-panel">
          <CollapsePanel values={values} curvesConfiguration={curvesConfiguration} />
        </div>
      </div>
    </>
  );
}

export default SecondStep;
