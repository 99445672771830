import { styled } from '@mui/material/styles';
import { customTheme } from '../../../../theme/default'; 
import Box from '@mui/material/Box';

type BtnProps = {
  isOpen: boolean;
  theme?: any;
};

export const BtnToggleDrawer = styled(Box)<BtnProps>(({ isOpen }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #323752;
  cursor: pointer;
  background: ${customTheme.bg};
  border-radius: 50%;
  position: absolute;
  top: 25px;
  right:  ${isOpen ? '-13px' : '-13px'};
  background: ;
  z-index: 9999;
  color: ${customTheme.white};
  padding: 2px;
  line-height: 0;
  width: 32px;
  height: 32px;
  & > svg {
    cursor: pointer;
    margin-left: ${isOpen ? '4px' : '0px' };
    width: 20px;
    font-size: 14px;
    text-align: center;
    & path {
      fill: ${customTheme.gray3};
    }
  }
`);
