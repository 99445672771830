import { styled } from '@mui/material/styles';
import { customTheme } from '../../theme/default';
import Box from '@mui/material/Box';

export const CardBase = styled(Box)(() => (({ customStyles }: { customStyles?: string }) => `
  background: ${customTheme.purple4};
  border: 1px solid ${customTheme.purple3};
  padding-top: 10px;
  padding-left: 16px;
  height: 82px;
  border-radius: 8px;
  ${customStyles}
`));
