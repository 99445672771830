import { CircularProgress } from "@mui/material"

import { ContainerLoading } from "./styles"

export const Loading = () => {
  return (
    <ContainerLoading>
      <CircularProgress />
    </ContainerLoading>
  )
}
