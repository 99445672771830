import {useState} from 'react';
import {IconButton, Button} from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import useDataTable, { UseDataTableProps } from '../../hooks/useDataTable';
import { ConfirmWrapper, StyledPopover } from './styles';

type DeleteActionButtonProps = {
    wellUid: any;
} & UseDataTableProps;

export const DeleteActionButton = ({ setLoading, token, selectedKey, wellUid }: DeleteActionButtonProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const { handleDeleteWell } = useDataTable({ setLoading, token, selectedKey });
    return (
        <div>
            <IconButton aria-label="delete" onClick={handleClick}>
                <DeleteOutlineOutlinedIcon color='error'/>
            </IconButton>
            <StyledPopover 
                id={id} 
                open={open} 
                anchorEl={anchorEl} 
                onClose={handleClose}>
                <ConfirmWrapper>
                    <span>Are you sure you want to delete this well?</span>
                    <div style={{display: 'flex', justifyContent: 'flex-end', width:'100%'}}>
                        <Button onClick={handleClose} >Cancel</Button>
                        <Button 
                            variant="text" 
                            color="error"
                            onClick={() => handleDeleteWell(wellUid)}
                        >
                            OK
                        </Button>
                    </div>
                </ConfirmWrapper>
            </StyledPopover>
        </div>
    );
};