// import React from 'react';
// @ts-ignore
import ToggleButton from 'react-toggle-button';
import store from 'store';
import { useRealtimeContext } from '@geowellex/shared-hooks';

// type Props = {
//     render?: any;//(toggleSwitch: () => void, switchValue: boolean) => React.ReactNode
//     notificationFunction?: (message: string) => void;
//     keyToUpdateStorage?: string; 
// };

export const RealtimeSwitch = ({ render, notificationFunction, keyToUpdateStorage }: any) => {
    const initialValue = store.get(keyToUpdateStorage || 'openEditLithology');
    const { update, setUpdate, isActive } = useRealtimeContext();

    const toggleSwitch = () => {
        if (isActive) {
            const currentValue = !update;
            setUpdate(currentValue);
        } else {
            return notificationFunction?.('Please select a active well to enable Realtime Update');
        }
    };

    const switchValue = update || initialValue;
    return (
        <div style={{ display: 'flex', color: '#fff' }}>
        {render ? render(toggleSwitch, switchValue) : (
            <>
                <span style={{ marginRight: 5 }}>Realtime Update</span>
                <ToggleButton
                    colors={{
                        activeThumb: {
                            base: 'rgb(255,255,255)'
                        },
                        inactiveThumb: {
                            base: 'rgb(255,255,255)'
                        },
                        active: {
                            base: '#65c970',
                            hover: '#65c970'
                        },
                        inactive: {
                            base: 'rgb(65,66,68)',
                            hover: 'rgb(95,96,98)'
                        }
                    }}
                    value={switchValue}
                    onToggle={(e: any) => {
                        console.log('e ==>',e);
                        toggleSwitch();
                    }}
                />
            </>
            )}
        </div>
    );
};
