export const theme =  {
  colors: {
    white: '#fff',
    colorRollingStone: '#737C80',
    colorMineShaft: '#262626',
    blue: '#007bff',
    colorShuttleGray: '#526066',
    red: '#dc3545',
    red2: '#ff0000',
    bgDark2: '#1d1c1c',
    gray3: '#808080',
    dark1: '#2F2E36',
    bgLogin: '#141317',
    white2: '#DADFE3',
    gray4: '#B0B3B8',
    gray5: '#B5B9BD',
    disabled: '#2F334D',
    disabled2: '#A3A8C7'
  }
};