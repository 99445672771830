import React, { HTMLInputTypeAttribute } from 'react'
import {UseFormRegister, FieldValues } from 'react-hook-form'
import { Label } from '../Label'

import { Container, TextFieldStyled } from './styles'

interface InputTextProps {
  type: HTMLInputTypeAttribute
  label: string,
  placeholder?: string;
  error?: boolean;
  errorMessage?: string;
  dataCyInput?: string;
  dataCyText?: string;
  register?: UseFormRegister<FieldValues>
}

export const InputText = ({
  type, label, placeholder, error = false,
  errorMessage, dataCyInput, dataCyText, register, ...rest
}: InputTextProps) => {
  return (
    <Container>
      <Label text={label} dataCy={dataCyText} />
      <TextFieldStyled
        type={type}
        placeholder={placeholder}
        error={error}
        helperText={errorMessage}
        data-cy={dataCyInput}
        {...register}
        {...rest}
      />
    </Container>
  )
}
