import { formatZones } from "./Zones";

export const getFormattedWell = (well) => {
  const calculatedData = well?.log_sets?.CALCULATED?.data ?? [];

  const calculatedObject = calculatedData.reduce((acc, row) => {
    acc[row.type] = row;
    return acc;
  }, {});

  const rawData = well?.log_sets?.RAW?.data ?? [];
  const rawObject = rawData.reduce((acc, row) => {
    if (row.mnemonic == well?.log_sets?.RAW?.index_curve?.mnemonic && row.type === 'depth') {
      acc[row.type] = row;
      return acc;
    } else if (row.type !== 'depth') {
      acc[row.type] = row;
    }
    return acc;
  }, {});

  const zonesFormated = formatZones(well?.zone_sets);

  const gqcData = well?.log_sets?.GQC?.data ?? well?.log_sets?.gqc?.data ?? [];
  const gqcObject = gqcData.reduce((acc, row) => {
    acc[row.type] = row;
    return acc;
  }, {});

  return {
    ...well,
    ethene_sensor: well?.ethene_sensor,
    calculated: calculatedObject,
    RAW: rawObject,
    log_sets: well?.log_sets,
    filtered: {},
    zone_sets: zonesFormated,
    gqcData: gqcObject,
    // gqc: gqcObject,
    index_curve: well?.log_sets?.RAW?.index_curve,
    cutoff: well?.modules_parameters?.cutoff?.settings?.cutoffs || []
  };
};
