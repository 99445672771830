import React from 'react';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import { styled } from '@mui/material/styles';
import { ThemeProps, customTheme } from '../../theme/default';
import { IndicatorCardType } from './types';
import { CardBase } from './CardBase';

const Title = styled(Box)(() => (() => `
  color: ${customTheme.white};
  font-size: 16px;
  font-weight: 700;
`));

const Value = styled(Box)<{ color?: string; cardValue?: string | React.ReactNode }>(() => (({ color, cardValue }: ThemeProps & { color?: string; cardValue?: string | React.ReactNode }) => `
  color: ${customTheme.white};
  font-size: ${typeof cardValue === 'string' && cardValue.length >= 15 ? '24px' : '28px'} ;
  font-weight: 700;
  color: ${color || customTheme.white};
`));

const CustomContent = styled(CardContent)(() => (() => `
  & :last-child {
    padding: 0px;
  }
  padding: 0;
`));

export const IndicatorCard = ({ title, value, unit, color, styles = {} }: IndicatorCardType) => {
  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: 250,
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
        gap: 2,
        ...(styles)
      }}
    >
      <CardBase>
        <CustomContent sx={{
          padding: 0,
        }}>
          <Title>
            {title}
          </Title>
          <Value cardValue={value} color={color}>{value} <span>{unit}</span></Value>
        </CustomContent>
      </CardBase>
    </Box>
  );
}
