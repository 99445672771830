import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import { ReactComponent as FilesIcon } from '../../icons/folder.svg';
// import { ReactComponent as TopBottomArrow } from '../../icons/top_bottom_arrow.svg';
import { customTheme } from '../../theme/default';
import { AfterSelectProjectType } from '../Sidebar/components/Drawer/types';
import ConfigContext from '../../context/ConfigContext';
import { useLocation, useParams, useNavigate } from 'react-router-dom';

export const FormControlWrapper = styled(FormControl)(() => ({
  '&': {
    width: '100%',
    position: 'relative',
    margin: 0,
    padding: 0,
  },
  "& .MuiInputBase-root.Mui-disabled": {
    color: "rgba(0, 0, 0, 1)"
  },
  '& .MuiOutlinedInput-input': {
    background: customTheme.blue1,
    color: customTheme.gray2,
    width: 168,
    fontSize: 16,
    fontWeight: 700,
    paddingRight: 0,
    paddingLeft: 46,
  },
  "& .MuiSelect-icon": {
    display: 'none'
  },
}));

const FolderIcon = styled(FilesIcon)(() => `
  position: absolute;
  left: 6px;
  top: 12px;
  z-index: 10;
 `,
);

// const TopBottomIcon = styled(TopBottomArrow)(() => `
//   position: absolute;
//   right: 11px;
//   top: 16px;
//   z-index: 10;
//  `,
// );

export type OptionsType = {
  label: string;
  value: string;
};

type Props = {
  afterSelect?: AfterSelectProjectType;
  defaultOptions?: OptionsType[];
};

const SelectComponent = ({ defaultOptions }: Props) => {
  const { afterSelect, options, unselect, } = React.useContext(ConfigContext);
  const [project, setProject] = React.useState('');
  const [touched, setTouched] = React.useState(false);
  const params = useParams();
  const location = useLocation();

  const navigate = useNavigate();
  const handleChange = React.useCallback((event: SelectChangeEvent) => {
    setProject(event.target.value);
    setTouched(true);
    if (!event.target.value) {
      unselect?.();
      setProject('');
    } else {
      afterSelect?.(event.target.value);
    }
  }, [setProject, afterSelect, setTouched, unselect]);

  React.useEffect(() => {
    if (!project && touched) {
      setProject('');
      navigate('/home', { replace: true });
    }
  }, [touched, project, navigate]);

  React.useEffect(() => {
    if (params?.id && ((params?.id && !project) || params?.id !== project)) {
      setProject(params.id);
    }
  }, [params.id, project, location.pathname, setProject]);

  // Every time visiting load well page should clear
  React.useEffect(() => {
    if (location?.pathname === '/home') {
      setProject('');
      unselect?.();
    } 
  }, [location?.pathname, setProject, unselect]);

  const formattedOptions = [
    {
      label: 'Select a well',
      value: '',
    },
    ...(defaultOptions ?? []),
    ...(options ?? []),
  ];

  return (
    <FormControlWrapper sx={{ m: 1 }}>
      <FolderIcon />
      <Select
         sx={{
          width: 248,
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#fff",
          },
        }}
        value={project}
        onChange={handleChange}
        displayEmpty
        disabled
        inputProps={{ 'aria-label': 'Without label' }}
        MenuProps={{ MenuListProps: { style: { backgroundColor: customTheme.blue1, color: customTheme.gray4 } } }}
      >
        {formattedOptions.map((option: OptionsType) => 
          <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
        )}
      </Select>
      {/* <TopBottomIcon /> */}
    </FormControlWrapper>
  )  
};

export const SelectWell = styled(SelectComponent)(() => ({
  '& .MuiOutlinedInput-input': {
  },
}));
