import Box from '@material-ui/core/Box';
import ZoneIndicatorIcon from '../../images/zone-indicator-icon'
import { customTheme } from '@geowellex/shared-ui/src/theme/default';
import './style.scss';

interface CardInfoProps {
  title: string,
  top: number,
  bottom: number,
  color: string
}

export const CardInfo = ({ title, top, bottom, color }: CardInfoProps) => {
  return (
    <Box className='container-card-info' style={{
      backgroundColor: customTheme.purple4,
    }}>
      <Box className='header-info'>
        <span>{title}</span>
        <ZoneIndicatorIcon size='18' color={color}/>
      </Box>
      {top && bottom && (
        <span>{top} - {bottom}</span>
      )}
    </Box>
  )
}
