import { IconButton } from "@material-ui/core";
import styled from "styled-components";

export const WrapperExtraction = styled.div`
  display: 'flex';
  flex-direction: 'column';
  height: '100%';
`;

export const ContainerTitle = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
  padding: 16px 21px;
  border-bottom: 1px dashed #323752;
`;

export const TitleExtraction = styled.h3`
  font-weight: 700;
  font-size: 21px;
  color: #FDFDFD;
  margin-bottom: 0;
`;

export const CloseButton = styled(IconButton)`
  color: #A7B1E2;
  padding: 0;
`;

export const ContainerBody = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  padding: 16px;
  align-content: start;
`;

export const ContainerFooter = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
  padding: 16px;
  border-top: 1px dashed #323752;
  margin-top: 50px;
`;

export const WrapperApplyReset = styled.div`
  display: flex;
  gap: 16px;
`;
