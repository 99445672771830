import styled from 'styled-components';
import ButtonBase from './ButtonBase';
import { ButtonStyleProps } from './types';
import { customTheme } from '@geowellex/shared-ui/src/theme/default/index.tsx';

const ButtonSecondary = styled(ButtonBase)<ButtonStyleProps>`
  && {
    width: ${(props) => props.width};
    border: 1px solid #FFFFFF;
    background-color: ${customTheme.blue1};
  }
  &&:hover {
    background-color: ${customTheme.purple3};
  }
`;

export default ButtonSecondary
