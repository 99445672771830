import React, { useEffect, useState } from 'react';

import SpinnerLogo from 'components/SpinnerLogo';
import { createBrowserHistory } from 'history';
import Modal from 'react-modal';
import { Provider } from 'react-redux';
import {
  useNavigate,
  createBrowserRouter
} from 'react-router-dom';
import { StylesProvider } from '@material-ui/core/styles';
import store, { set } from 'store';

import { WizardProvider } from 'screens/Statistics/components/ModalStatistics/useStatisticsWizard';
import CutOffChartPage from './components/CutOffs/Chart';
import MolarAnalysis from './components/MolarAnalysis';
import RelativeEthene from './components/RelativeEthene';
import ResetSystem from './components/ResetSystem';
import UpdatePassword from './components/UpdatePassword';
import Crossplots from './components/Crossplots';
import {
  ProvideAuth,
  useProvideAuth,
} from '@geowellex/shared-login/src/hooks/useAuth';
import App from './screens/App';
import {
  Login,
} from '@geowellex/shared-login';
import { configureStore } from './store';
import StatisticsModule from './screens/Statistics';
import Root from './screens/Root';
import { Dashboard } from './components/Dashboard';
import QualityControl from './screens/QualityControl';
import { QualityControlProvider } from './screens/QualityControl/hooks/useQualityControl';
import { RealtimeProvider } from '@geowellex/shared-hooks';

import { listWell } from './actions/wells';
import { parseWellDataIntegration, updateWellData } from './api/import-data';
import { WELLEX_DATA_TEMP_URL, fetchInWellWatcherSite } from './api/wellwatcher';
import { errorNotification, successNotification } from './utils';
import { ServiceProvider, useService } from '@geowellex/shared-login/src/hooks/useService';

import UI from '@geowellex/shared-ui';
import ConfigContext from '@geowellex/shared-ui/src/context/ConfigContext';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import theme from '@geowellex/shared-ui/src/theme/default';
import LoadWell from './screens/LoadWell';

export const history = createBrowserHistory();

const storeReducer = configureStore();

Modal.setAppElement('#root');
const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();
  const { service, setService } = useService();
  const { checkValidSession, checkingToken, isValidToken } = useProvideAuth();

  useEffect(() => {
    checkValidSession();
    setService('GOLD');
  }, []);

  if (checkingToken) {
    return <SpinnerLogo />;
  }

  if (!isValidToken && isValidToken !== undefined && !checkingToken) {
    navigate('/login');
  }

  if (!store.get('services_allowed').includes(service)) {
    navigate('/login');
  }

  return <div>{children}</div>;
};

const RootRouter = createBrowserRouter([
  {
    path: "/",
    element: <StylesProvider injectFirst>
      <ServiceProvider>
        <Provider store={storeReducer} history={history}>
          <ProvideAuth>
            <Root />
          </ProvideAuth>
        </Provider>
      </ServiceProvider>
    </StylesProvider>
  },
  {
    path: "/login",
    element:  
    <ServiceProvider>
      <StylesProvider injectFirst>
        <ProvideAuth>
          <Login />
        </ProvideAuth>
      </StylesProvider>
    </ServiceProvider>
  },
  {
    path: "/home",
    element:
    <ServiceProvider>
      <PrivateRoute>
        <StylesProvider injectFirst>
          <Provider store={storeReducer} history={history}>
            <ProvideAuth>
              <RealtimeProvider
                listWell={listWell}
                parseWellDataIntegration={parseWellDataIntegration}
                updateWellData={updateWellData}
                fetchInWellWatcherSite={fetchInWellWatcherSite}
                afterSuccess={successNotification}
                afterError={errorNotification}
                WELLEX_DATA_TEMP_URL={WELLEX_DATA_TEMP_URL}
              >
                <QualityControlProvider>
                  <App />
                </QualityControlProvider>
              </RealtimeProvider>
            </ProvideAuth>
          </Provider>
        </StylesProvider>
      </PrivateRoute>
    </ServiceProvider>,
    children: [
      {
        path: 'well/:id',
        element:
        <ServiceProvider >
          <RealtimeProvider
              listWell={listWell}
              parseWellDataIntegration={parseWellDataIntegration}
              updateWellData={updateWellData}
              fetchInWellWatcherSite={fetchInWellWatcherSite}
              afterSuccess={successNotification}
              afterError={errorNotification}
              WELLEX_DATA_TEMP_URL={WELLEX_DATA_TEMP_URL}
            >
            <Dashboard />
          </RealtimeProvider>
        </ServiceProvider>
      },
      {
        path: "statistics",
        element: <StylesProvider injectFirst>
          <ServiceProvider>
            <Provider store={storeReducer} history={history}>
              <ProvideAuth>
                <WizardProvider>
                  <StatisticsModule />
                </WizardProvider>
              </ProvideAuth>
            </Provider>
          </ServiceProvider>
        </StylesProvider>
      },
      {
        path: "quality-control",
        element: <StylesProvider injectFirst>
          <ServiceProvider>
            <Provider store={storeReducer} history={history}>
              <ProvideAuth>
                <WizardProvider>
                  <QualityControlProvider>
                    <QualityControl />
                  </QualityControlProvider>
                </WizardProvider>
              </ProvideAuth>
            </Provider>
          </ServiceProvider>
        </StylesProvider>
      },
      {
        path: "/home",
        element: <StylesProvider injectFirst>
          <ServiceProvider>
            <Provider store={storeReducer} history={history}>
              <ProvideAuth>
                <WizardProvider>
                  <QualityControlProvider>
                    <LoadWell/>
                  </QualityControlProvider>
                </WizardProvider>
              </ProvideAuth>
            </Provider>
          </ServiceProvider>
        </StylesProvider>
      },
    ]
  },
  {
    path: "/cutoff-plotbox/:id",
    element: <StylesProvider injectFirst>
      <ServiceProvider>
        <Provider store={storeReducer} history={history}>
          <ProvideAuth>
            <CutOffChartPage />
          </ProvideAuth>
        </Provider>
      </ServiceProvider>
    </StylesProvider>
  },
  {
    path: "/relative-ethene/:id",
    element: <StylesProvider injectFirst>
      <ServiceProvider>
        <Provider store={storeReducer} history={history}>
          <ProvideAuth>
            <RelativeEthene />
          </ProvideAuth>
        </Provider>
      </ServiceProvider>
    </StylesProvider>
  },
  {
    path: "/crossplots/:id",
    element: <StylesProvider injectFirst>
      <ServiceProvider>
        <Provider store={storeReducer} history={history}>
          <ProvideAuth>
            <Crossplots />
          </ProvideAuth>
        </Provider>
      </ServiceProvider >
    </StylesProvider>
  },
  {
    path: "/molar-analysis/:id/:wellId",
    element: <StylesProvider injectFirst>
      <ServiceProvider>
        <Provider store={storeReducer} history={history}>
          <ProvideAuth>
            <MolarAnalysis />
          </ProvideAuth>
        </Provider>
      </ServiceProvider>
    </StylesProvider>
  },
  {
    path: "/updatepassword/:id/",
    element: <StylesProvider injectFirst>
      <ServiceProvider>
        <Provider store={storeReducer} history={history}>
          <ProvideAuth>
            <UpdatePassword />
          </ProvideAuth>
        </Provider>
      </ServiceProvider>
    </StylesProvider>
  },
  {
    path: "/reset",
    element: <StylesProvider injectFirst>
      <ServiceProvider>
        <Provider store={storeReducer} history={history}>
          <ProvideAuth>
            <ResetSystem />
          </ProvideAuth>
        </Provider>
      </ServiceProvider>
    </StylesProvider>
  }
]);

export default RootRouter;
