import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as DefaultLogLine } from '../../../../icons/log_line.svg';

import { LogCurve } from '../../types';
import { CustomText } from '../CustomText';
import { CustomLogLine } from '../CustomLogLine';
import { CustomScrollBar } from '../CustomScrollBar'; 

export type Props = {
  logSets?: LogCurve[];
};

export const LogList = ({ logSets }: Props) => {
  const uuid = React.useId();
  return (
    <CustomScrollBar>
      {(logSets || [])?.map((item: LogCurve) => (
        <ListItem
          key={`${uuid}-${item?.mnemonic}`}
          disableGutters
          secondaryAction={
            <IconButton aria-label="comment">
              <CustomLogLine color={item.color}>
                <DefaultLogLine />
              </CustomLogLine>
            </IconButton>
          }
        >
          <CustomText primary={item?.curveName} />
        </ListItem>
      ))}
    </CustomScrollBar>
  )  
};
