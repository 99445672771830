import React, { useState, useEffect, useRef } from 'react';
import 'react-image-crop/src/ReactCrop.scss';
import Modal from 'react-modal';
import jsPDF from 'jspdf';
import { Button, Input, Form, Row, Col } from 'antd';
import { CloseOutlined, CheckOutlined, PlusOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Loading from '../../../Statistics/components/Loading';
import { defaultNotification, successNotification, image64toCanvasRef } from 'utils';

import ReactCrop, { makeAspectCrop, centerCrop, convertToPixelCrop } from 'react-image-crop';
import { warningNotification } from '../../../../utils';

import setCanvasPreview from './setCanvasPreview';

const MIN_DIMENSION = 100;

const CropImageModal = ({ isModalOpen, currentWell, setIsGeneratingPDF, imgSrc, close, ...props }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [crop, setCrop] = useState();
    const [currentImage, setCurrentImage] = useState(imgSrc);
    const imgRef = useRef(null);
    const imagePreviewCanvasRef = useRef(null);

    const closeModal = () => {
        close();
    };

    const onImageLoad = (e) => {
        const { width, height } = e.currentTarget;
        const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

        const crop = makeAspectCrop(
            {
                unit: "%",
                width: cropWidthInPercent,
            },
            undefined,
            width,
            height
        );
        const centeredCrop = centerCrop(crop, width, height);
        setCrop(centeredCrop);
    };

    useEffect(() => {
        console.log(imgSrc)
    }, [imgSrc])

    const handleOnCropChange = (pixelCrop, percentCrop) => {
        setCrop(percentCrop)
    }

    const handleFinishCropAndSavePdf = (canvas) => {
        setIsGeneratingPDF(true)
        try {
            const wellName = currentWell.wellName
            const scale = 2;
            const imgWidth = canvas.width * scale;
            const imgHeight = canvas.height * scale;

            const pdf = new jsPDF({
                orientation: imgWidth > imgHeight ? 'landscape' : 'portrait',
                unit: 'pt',
                format: [imgWidth, imgHeight],
            });
            const posX = (pdf.internal.pageSize.width - imgWidth) / 2;
            const posY = (pdf.internal.pageSize.height - imgHeight) / 2;

            const fontSizeMultiplier = 3.5;
            const fontSize = 36 * fontSizeMultiplier;
            const textWidth = pdf.getStringUnitWidth(wellName) * fontSize;
            const textX = (pdf.internal.pageSize.width - textWidth) / 2;
            const textY = 120;

            pdf.addImage(currentImage, 'PNG', posX, posY, imgWidth , imgHeight);

            pdf.setFontSize(fontSize);
            pdf.text(wellName, textX, textY);
            pdf.save('chart.pdf');
            setIsGeneratingPDF(false);
            successNotification('PDF generated succesfuly!')
        } catch (e) {
            console.error(e);
            warningNotification(e.message);
        }
        close();
    }

    const wells = useSelector(state => state.wells);

    return (
        <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 1000,
                },
                content: {
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    maxWidth: '1200px',
                    width: '100%',
                    height: '80vh',
                    padding: '20px',
                    backgroundColor: '#09090B',
                    border: 'none',
                    borderRadius: '8px',
                },
            }}
        >
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <ReactCrop
                        crop={crop}
                        onChange={handleOnCropChange}
                    >
                        <img
                            ref={imgRef}
                            src={imgSrc}
                            width={1000}
                            onLoad={onImageLoad}
                        />
                    </ReactCrop>
                    <div style={{ display: 'flex', width: '90%', justifyContent: 'space-between', margin: '10px' }}>
                        <Button onClick={closeModal} style={{ marginRight: '12px' }}>
                            <CloseOutlined />
                            Cancel
                        </Button>
                        <div>
                            <Button style={{ backgroundColor: '#233BB6', color: 'white', border: 'none', marginRight: 10 }}
                                onClick={() => {
                                    setCanvasPreview(
                                        imgRef.current, // HTMLImageElement
                                        imagePreviewCanvasRef.current, // HTMLCanvasElement
                                        convertToPixelCrop(
                                            crop,
                                            imgRef.current.width,
                                            imgRef.current.height
                                        )
                                    );
                                    const dataUrl = imagePreviewCanvasRef.current.toDataURL();
                                    setCurrentImage(dataUrl);
                                }}
                            >
                                Crop
                            </Button>
                            <Button style={{ backgroundColor: '#233BB6', color: 'white', border: 'none' }}
                                onClick={() => handleFinishCropAndSavePdf(imagePreviewCanvasRef.current)}
                            >
                                <CheckOutlined />
                                Apply
                            </Button>
                        </div>
                    </div>
                    <div maxWidth={MIN_DIMENSION} >
                        <canvas ref={imagePreviewCanvasRef}
                            style={{
                                objectFit: 'contain',
                                width:700,
                                height: 500
                            }}
                        ></canvas>
                    </div>
                </div>

            </div>
        </Modal >
    );
};

export default CropImageModal;
