import React, { useEffect } from 'react'
import { Grid, Box} from '@mui/material';
import { useFieldArray, useFormContext } from "react-hook-form";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Subtitle } from '../Subtitle'
import { Select } from '../Select'
import { Title } from '../Title'
import { InputText } from '../TextField'
import { Button } from '../Button';
import BitSizeClipboard from '../BitSizeClipboard';
import AddIcon from '@mui/icons-material/Add';

import {
  WrapperInputRow, WrapperInputs,
  WrapperLoadedIntervalInputs, WrapperAddButton, bitSizeItemStyles
} from './styles'

const optionsUnit = [
  { text: '6 7/8', value: '6 7/8' },
  { text: '6 3/4', value: '6 3/4' },
  { text: '6 1/8', value: '6 1/8' },
  { text: '7 7/8', value: '7 7/8' },
  { text: '8 3/4', value: '8 3/4' },
  { text: '8 1/2', value: '8 1/2' },
  { text: '8 3/8', value: '8 3/8' },
  { text: '9 1/2', value: '9 1/2' },
  { text: '10 5/8', value: '10 5/8' },
  { text: '12 1/4', value: '12 1/4' },
  { text: '13 1/2', value: '13 1/2' },
  { text: '16', value: '16' },
  { text: '17 1/2', value: '17 1/2' },
];

// type IRowProps = {
//   baseValue?: string;
//   topValue?: string;
//   bitSize?: string;
//   handleChangeBitSize: (event: SelectChangeEvent<unknown>) => void;
// }

export const WellPhases = ({ defaultData, customErrors, clearErrors }) => {
  const { control, getValues } = useFormContext();
  const formAllValues = getValues();
  const normalizeDiameter = defaultData?.well_phases?.map(item => ({
    text: item?.diameter,
    value: item?.diameter
  }));

  const {
    fields,
    append,
    remove,
    update,
  } = useFieldArray({
    control,
    name: 'bs'
  });

  useEffect(() => {
    if (!fields?.length) {
      defaultData.well_phases.map((item, index) => {
        append(
          index,
          {
            base: item.base,
            top: item.top,
            diameter: normalizeDiameter.value
          }
        );
      })
    }
  }, [fields]);
  
  return (
    <div>
      <WrapperInputs>
        <Title text='Well Phases' />
        <Subtitle text='Copy the values of bit size and then enter in the Top and Bottom inputs:' />
        <BitSizeClipboard
          rangeTop={formAllValues?.start_depth}
          rangeBottom={formAllValues?.stop_depth}
          bottom={100}
          top={200}
        />
      </WrapperInputs>
        <WrapperInputs>
          <WrapperLoadedIntervalInputs>
            {fields.length > 0 
            ? <WrapperInputRow>
                <Box sx={{ width: 652, flexGrow: 1, mb: 2, }}>
                  <Grid container spacing={2} justifyContent="space-between">
                  <Grid container item data-cy="bit-size-phases">
                      {
                        fields.map((item, index) => {
                          const customErrorTopField = `bs${index}top`;
                          const customErrorBaseField = `bs${index}base`;
                          const customErrorDiameterField = `bs${index}diameter`;
                          return (
                            <Grid container spacing={1} sx={{...bitSizeItemStyles}} key={index}>
                              <Grid item xs={4}>
                                <InputText
                                  control={control}
                                  name={`bs.${index}.top`}
                                  label="Top"
                                  afterChange={clearErrors}
                                  defaultValue={item.top}
                                  dataCy={`bs-${index}-top`}
                                />
                                <span style={{
                                  color: '#EB3434',
                                  fontSize: 12,
                                }}
                                data-cy='message-error-top'
                                >
                                  {customErrors?.[customErrorTopField]
                                    ? customErrors?.[customErrorTopField]?.message : ''}
                                </span>
                              </Grid>
                              <Grid item xs={4}>
                                <InputText
                                  control={control}
                                  name={`bs.${index}.base`}
                                  label="Base"
                                  defaultValue={item.base}
                                  afterChange={clearErrors}
                                  dataCy={`bs-${index}-base`}
                                />
                                <span
                                  style={{
                                    color: '#EB3434',
                                    fontSize: 12,
                                  }}
                                  data-cy='message-error-bottom'
                                >
                                  {customErrors?.[customErrorBaseField]
                                    ? customErrors?.[customErrorBaseField]?.message : ''}
                                </span>
                              </Grid>
                              <Grid item xs={3}>
                                <label
                                  style={{
                                    fontWeight: 700,
                                    fontSize: 16,
                                    color: '#FFFFFF'
                                  }}
                                >
                                  Bit Size (inches)
                                </label>
                                <Select
                                  control={control}
                                  name={`bs.${index}.diameter`}
                                  label=""
                                  options={optionsUnit}
                                  afterChange={clearErrors}
                                  // defaultValue={textByDiameter[String(item.diameter)]}
                                  defaultValue={item.diameter}
                                  dataCy='select-bit-size'
                                />
                                <span
                                  style={{
                                    color: '#EB3434',
                                    fontSize: 12,
                                  }}
                                  data-cy='message-error-diameter'
                                >
                                  {customErrors?.[customErrorDiameterField]
                                    ? customErrors?.[customErrorDiameterField]?.message : ''}
                                </span>
                              </Grid>
                              <Grid item xs={1}>
                                <button
                                  style={{ marginTop: 30, background: 'transparent', border: 'none', cursor: 'pointer' }}
                                  type="button"
                                  onClick={() => remove(index)}
                                  data-cy='button-remove-bit-size'
                                >
                                  <DeleteForeverIcon  style={{ color: '#fff' }} />
                                </button>
                              </Grid>
                            </Grid>
                          );
                        })
                      }
                  </Grid>
                </Grid>
              </Box>
            </WrapperInputRow>
            : ''}
            </WrapperLoadedIntervalInputs>
           </WrapperInputs>
        <WrapperInputs>
        <WrapperAddButton>
          <Button
            theme='secondary'
            startIcon={<AddIcon />}
            onClick={() => {
              append({ base: 0, top: 0, diameter: 0 });
            }}
            dataCy='button-add-bitSize'
          >
            Add Bit Size
          </Button>
        </WrapperAddButton>
      </WrapperInputs>
    </div>
  )
};
