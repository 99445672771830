import { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { customTheme } from '../../theme/default';

export default class CustomScroolBar extends Component {
    constructor(props, ...rest) {
        super(props, ...rest);
        this.state = { top: 0 };
        this.handleUpdate = this.handleUpdate.bind(this);
        this.renderThumb = this.renderThumb.bind(this);
    }

    handleUpdate(values) {
        const { top } = values;
        this.setState({ top });
    }

    renderThumb({ style, ...props }) {
        const thumbStyle = {
            opacity: 0.2,
            backgroundColor: customTheme.white,
            borderRadius: '100px'
        };
        return (
            <div
                style={{ ...style, ...thumbStyle }}
                {...props}/>
        );
    }

    render() {
        return (
            <Scrollbars
                className={this.props.className}
                renderThumbHorizontal={this.renderThumb}
                renderThumbVertical={this.renderThumb}
                onUpdate={this.handleUpdate}
                {...this.props}
            />
        );
    }
}
