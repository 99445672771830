/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable no-dupe-keys */
/* eslint-disable react/no-unused-state */
import './style.scss';

import React, {
  Component,
  useEffect,
  useState
} from 'react';

import classNames from 'classnames';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import { useSelector } from 'react-redux';
import store from 'store';
import get from 'lodash.get'
import { useScales, shouldUpdateScale } from '../../../hooks/useScales';

import { getRawCurveData } from '../../../utils/getRawCurveData';
import Ui from '@geowellex/shared-ui';

import { applyDefaultZoomToChart } from '../../../lib/charts';
import {
  defaultCrossHair,
  defaultValuesToCrosshair,
  disableDots,
  disableLabels,
  displayZoomBtn,
  getToolTipValues,
  getUnit,
  handleSelectArea,
  propagateZoom,
  syncronizeTooltip,
  togglePixlerChart,
  toggleTrack as toggleTrackUtils
} from '../../../utils';
import { DEFAULT_SCALES } from '../../../utils/constants';
import GlobalCutOff from '../../../utils/GlobalCutOff';

Exporting(Highcharts);

const curveTypes = [ 'c1c2', 'c1c3', 'c1c4', 'c1c5']

const HeaderChart = () => {
  const wells = useSelector(state => state.wells);

  const initialState = {
    c1c2: 0,
    c1c3: 0,
    c1c4: 0,
    c1c5: 0,
    scales: store.get('configScales') || { ...DEFAULT_SCALES }
  };

  const {
    currentScale, setHeaderState, headerState
  } = useScales(
    curveTypes,
    wells.currentWell,
    initialState
  );

  // --- Toggle Tracks
  const [tracks, setToggle] = useState({
    isOpen: false,
    c1c2Toggle: true,
    c1c3Toggle: true,
    c1c4Toggle: true,
    c1c5Toggle: true
  });

  const tooggleCallbacks = {
    c1c2Toggle: togglePixlerChart,
    c1c3Toggle: togglePixlerChart,
    c1c4Toggle: togglePixlerChart,
    c1c5Toggle: togglePixlerChart
  };

  const toggleTrack = (event, name, index) => {
    const trackName = tracks[name];
    const callback = tooggleCallbacks[name];
    callback(parseInt(index))(event);
    setToggle({
      ...tracks,
      isOpen: !tracks.isOpen,
      [name]: !trackName
    });
  };

  const toggleTrackCurrentTrack = (event) => {
    const { dataset: { name, index } } = event.target;
    const trackName = tracks[name];
    const callback = tooggleCallbacks[name];
    if (callback) {
      callback(parseInt(index))(event);
      setToggle({
        ...tracks,
        isOpen: !tracks.isOpen,
        [name]: !trackName
      });
    }
  };

  // --- Open modal with config
  const dispatchModal = (typeOfTitle) => (event) => {
    event.persist();
    event.stopPropagation();
    if (event.target && event.target.dataset.cmd === 'toggle') {
      toggleTrackCurrentTrack(event);
      return;
    } else if (event.target && event.target.dataset.cmd === 'openConfig') {
      const openModal = new CustomEvent('openConfigModal', {
        detail: {
          type: typeOfTitle
        }
      });
      document.dispatchEvent(openModal);
      return;
    }
  };

  useEffect(() => {
    if (wells.currentWell && wells.selectedDepthIndex >= 0) {
      const {
        c1ByC2, c1ByC3, c1ByC4, c1ByC5
      } = wells.currentWell.calculated?.pixlerRatios ?? {};

      const index = wells.selectedDepthIndex;

      const c1c2Value = c1ByC2 && c1ByC2[index] ? c1ByC2[index] : 0;
      const c1c3Value = c1ByC3 && c1ByC3[index] ? c1ByC3[index] : 0;
      const c1c4Value = c1ByC4 && c1ByC4[index] ? c1ByC4[index] : 0;
      const c1c5Value = c1ByC5 && c1ByC5[index] ? c1ByC5[index] : 0;

      setHeaderState({
        ...headerState,
        c1c2: c1c2Value?.toFixed(2),
        c1c3: c1c3Value?.toFixed(2),
        c1c4: c1c4Value.toFixed(2),
        c1c5: c1c5Value?.toFixed(2)
      });
    }

    if (!wells.currentWell) {
      setHeaderState({
        ...headerState,
        c1c2: 0,
        c1c3: 0,
        c1c4: 0,
        c1c5: 0
      });
    }

  }, [wells]);

  useEffect(() => {
    setHeaderState({
      scales: store.get('configScales') || DEFAULT_SCALES
    });

    const sfChartListener = (currentTrack) => ({ detail }) => {
      // headerState.scales[currentTrack] = detail;
      setHeaderState({
        ...headerState,
      });
    };

    const pixlerCharts = [
      'c1c2',
      'c1c3',
      'c1c4',
      'c1c5'
    ];

    const c1c2 = sfChartListener('c1c2');
    const c1c3 = sfChartListener('c1c3');
    const c1c4 = sfChartListener('c1c4');
    const c1c5 = sfChartListener('c1c5');

    const callbackListeners = {
      c1c2,
      c1c3,
      c1c4,
      c1c5
    };

    // Add event listeners
    for (let i = 1; i <= pixlerCharts.length; i++) {
      const currentTrack = pixlerCharts[i];
      document.addEventListener(currentTrack, callbackListeners[currentTrack]);
    }

    return () => {
      // Remove all event listeners
      for (let i = 0; i < pixlerCharts.length; i++) {
        const currentTrack = pixlerCharts[i];
        document.removeEventListener(currentTrack, callbackListeners[currentTrack]);
      }
    };
  }, []);

  const {
    c1c2, c1c3, c1c4, c1c5, scales
  } = headerState;
  const c1c4TrackConfig = {
    title: 'C1/C4', className: 'sfC1ByC2'
  };
  const c1c5TrackConfig = {
    title: 'C1/C5', className: 'sfTrackConfig'
  };
  const c1c3Config = {
    title: 'C1/C3', className: 'ic5Track'
  };
  const c1c2Config = {
    title: 'C1/C2', className: 'ic4Track'
  };

  return (
    <div className="sf-title-box">
      <Ui.Header
        background={scales.c1c2.color}
        textCenter={c1c2 > 0 ? `${c1c2Config.title} (${c1c2})` : `${c1c2Config.title}`}
        min={scales?.c1c2GQR?.min ?? 0}
        max={scales?.c1c2GQR?.max ?? 0}
        className={c1c2Config.className}
        name="c1c2Toggle"
        index={1}
        checked={tracks.c1c2Toggle}
        onClick={dispatchModal('c1c2GQR')}
        activeCurveConfig='c1c2GQR'
        onChangeCheckbox={(event) => toggleTrack(event, 'c1c2Toggle', 1)}
        dataCy="open-config-c1c2"
        checkboxIsVisible
      />
      <Ui.Header
        background={scales.c1c3 ? scales.c1c3.color : 'white'}
        textCenter={c1c3 > 0 ? `${c1c3Config.title} (${c1c3})` : `${c1c3Config.title}`}
        min={scales.c1c3 ? scales.c1c3.min : 0}
        max={scales.c1c3 ? scales.c1c3.max : 0}
        className={c1c3Config.className}
        name="c1c3Toggle"
        index={2}
        checked={tracks.c1c3Toggle}
        onClick={dispatchModal('c1c3')}
        activeCurveConfig='c1c3'
        onChangeCheckbox={(event) => toggleTrack(event, 'c1c3Toggle', 2)}
        dataCy="open-config-c1c3"
        checkboxIsVisible
      />
      <Ui.Header
        background={scales.c1c4 ? scales.c1c4.color : 'white'}
        textCenter={c1c4 > 0 ? `${c1c4TrackConfig.title} (${c1c4})` : `${c1c4TrackConfig.title}`}
        min={scales.c1c4 ? scales.c1c4.min : 0}
        max={scales.c1c4 ? scales.c1c4.max : 0}
        className={c1c4TrackConfig.className}
        name="c1c4Toggle"
        index={3}
        checked={tracks.c1c4Toggle}
        onClick={dispatchModal('c1c4')}
        activeCurveConfig='c1c4'
        onChangeCheckbox={(event) => toggleTrack(event, 'c1c4Toggle', 3)}
        dataCy="open-config-c1c4"
        checkboxIsVisible
      />
      <Ui.Header
        name="c1c5Toggle"
        index={4}
        checked={tracks.c1c5Toggle}
        background={scales.c1c5? scales.c1c5.color : 'white'}
        textCenter={c1c5 > 0 ? `${c1c5TrackConfig.title} (${c1c5})` : `${c1c5TrackConfig.title}`}
        min={scales.c1c5 ? scales.c1c5.min : 0}
        max={scales.c1c5 ? scales.c1c5.max : 0}
        className={c1c5TrackConfig.className}
        onClick={dispatchModal('c1c5')}
        activeCurveConfig='c1c5'
        onChangeCheckbox={(event) => toggleTrack(event, 'c1c5Toggle', 4)}
        dataCy="open-config-c1c5"
        checkboxIsVisible
      />
    </div>
  );
};

const getDefaultSeries = (
  c1c2Data = [],
  c1c3Data = [],
  c1c4Data = [],
  c1c5Data = []
) => {
  const {
    c1c3,c1c4,c1c5, c1c2GQR
  } = store.get('configScales');
  const placeholderData = defaultValuesToCrosshair(c1c2Data, 0.1);
  return [
    // Placeholder
    {
      ...disableDots,
      labels: {
        format: '-',
        enabled: false,
        style: {
          color: '#333',
        }
      },
      fill: {
        color: 'rgba(0, 0, 0, 0)',
      },
      lineColor: 'rgba(0, 0, 0, 0)',
      data: placeholderData,
      title: {
        y: 45,
        useHTML: true,
        text: ''
      },
      yAxis: 0,
      zIndex: 3,
      min: 0,
      max: 4,
      allowDecimals: true,
      minorGridLineWidth: 1,
      gridLineWidth: 1,
      tickInterval: 10,
      shadow: false,
      selected: true,
      index: 0
    },
    {
      ...disableDots,
      labels: {
        format: '-',
        enabled: false,
        style: {
          color: c1c2GQR.color,
        }
      },
      data: c1c2Data,
      title: {
        y: 45,
        useHTML: true,
        color: 'blue',
        text: ''
      },
      yAxis: 1,
      zIndex: 3,
      min: 0,
      max: 4,
      allowDecimals: true,
      minorGridLineWidth: 1,
      gridLineWidth: 1,
      minorGridLineColor: '#333',
      tickInterval: 10,
      shadow: false,
      selected: true,
      index: 1
    },
    // C1/C2
    {
      ...disableDots,
      min: 0,
      max: 10,
      labels: {
        format: ' ',
        enabled: false
      },
      yAxis: 2,
      zIndex: 1,
      title: {
        text: '',
        useHTML: true,
        color: c1c3 ? c1c3.color : 'red'
      },
      data: c1c3Data,
      allowDecimals: true,
      index: 2
    },
    {
      ...disableDots,
      min: 0,
      max: 2,
      labels: {
        format: ' ',
        enabled: false
      },
      style: {
        color: c1c4 ? c1c4.color : 'red'
      },
      yAxis: 3,
      zIndex: 1,
      title: {
        text: '',
        useHTML: true,
        color: '#741b14'
      },
      data: c1c4Data,
      index: 3
    },
    {
      ...disableDots,
      min: 0,
      max: 2,
      labels: {
        format: ' ',
        enabled: false
      },
      zIndex: 1,
      yAxis: 4,
      style: {
        color: c1c5 ? c1c5.color : 'red'
      },
      title: {
        text: '',
        useHTML: true,
        color: '#741b14'
      },
      data: c1c5Data,
      allowDecimals: true,
      index: 4
    }
  ];
};

class PixlerChart extends Component {
  constructor(props) {
    super(props);
    this.chart = null;

    this.state = {
      chartsHeight: 0,
      selectedId: 0,
      plotBands: [],
      c1c2: [],
      c1c3: [],
      c1c4: [],
      c1c5: [],
      series: {
        0: true,
        1: true,
        2: true,
        3: true,
        4: true
      },
      scales: store.get('configScales') || DEFAULT_SCALES,
      selectedDepth: 0,
      balanceRatio: 0,
      wetnessRatio: 0,
      chromatography: 0,
      characterRatio: 0,
      gasTotal: 0,
      isotope: 0,
      unit: getUnit(),
      rop: 0,
      gasRatio: 0,
      c1: 0,
      c2: 0,
      c3: 0,
      ic4: 0,
      nc4: 0,
      ic5: 0,
      nc5: 0,
      c1Comp: 0,
      c2Comp: 0,
      c3Comp: 0,
      c4Comp: 0,
      c5Comp: 0,
      slopeFactor: 0,
      c1Byc2: 0,
      ic4nc4: 0,
      ic5nc5: 0,
      tracks: {
        isOpen: false,
        rop: true,
        gasRatio: true,
        chromaC1: true,
        chromaC2: true,
        chromaC3: true,
        chromaIc4: true,
        chromaNc4: true,
        chromaIc5: true,
        chromaNc5: true,
        gasCompC1: true,
        gasCompC2: true,
        gasCompC3: true,
        gasCompC4: true,
        gasCompC5: true,
        character: true,
        balance: true,
        wetness: true,
        sfToggle: true,
        c1C2Toggle: true,
        ic5nC5Toggle: true,
        ic4nC4Toggle: true
      }
    };
    this.vpHeight = 0;
  }

  componentDidMount() {
    const { id, customEventType } = this.props;
    const { selectedId } = this.state;
    // This dispatch some event after to select the area and display the scatters plot
    // this.handleSelect = handleSelectArea(this, this.afterSelect);

    const { parentContainer } = this.props;
    this.vpHeight = document.querySelector(parentContainer).clientHeight - 10 || 0;

    this.createChart();

    if (this.chart) {
      this.zoomId = applyDefaultZoomToChart(this.chart);
    }

    GlobalCutOff.registerInstances(
      'pixlerChart',
      this.chart
    );

    // TODO apply it when mark to generate scatters
    document.addEventListener(customEventType, () => {
      this.chart.xAxis[0].removePlotBand(`selection${selectedId}`);
    });

    document.addEventListener('removePlotBands', () => {
      const count = store.get('openedCrossPlots')
        ? parseInt(store.get('openedCrossPlots'))
        : 0;

      if (count) {
        this.chart.xAxis[0].removePlotBand(`selection${selectedId}`);
      }
    });

    // TODO refactor this syncronization
    document.addEventListener('sincronize-selected', event => {
      const { detail: { min, max } } = event;
      this.chart.xAxis[0].setExtremes(min, max, true, true);
      this.chart.redraw();
    });
    syncronizeTooltip(`#${id}`);

    document.addEventListener('toggle-pixler-chart', ({ detail: { index } }) => {
      toggleTrackUtils(this)(index);
    });

    // TODO refactor this workaround because with two windows we need to comunicate
    // theses tabs using some reactive way
    // this was made one day before alfa presentation
    this.timerId = window.setInterval(() => {
      const shoudlClose = !!store.get('opened-cross-plot-C1C2SF');
      if (shoudlClose === true) {
        this.chart.xAxis[0].removePlotBand(`selection${selectedId}`);
      }
    }, 200);

    const pixlerCharts = [
      '',
      'c1c2',
      'c1c3',
      'c1c4',
      'c1c5'
    ];
    for (let i = 1; i < pixlerCharts.length; i++) {
      const currentTrack = pixlerCharts[i];
      document.addEventListener(currentTrack, ({ detail }) => {
        this.chart.yAxis[i].update({
          min: detail.min,
          max: detail.max
        });
        const serie = this.chart.series[i];
        serie.update({
          color: detail.color,
          lineColor: detail.color
        });
        serie.redraw();
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    const { c1c2, c1c3, c1c4, c1c5, selectedWell, currentWell, min, max, updateChart } = nextProps;
    const { chart, props } = this;

    const beforeDepthData = get(getRawCurveData('depth', props.beforeWell), 'data', []);
    const currentDepthData = get(getRawCurveData('depth', nextProps.currentWell), 'data', []);

    if (
      selectedWell !== props.selectedWell
      || nextProps.selectedWell !== props.selectedWell
      || beforeDepthData !== currentDepthData
      || props.beforeWell?.calculated?.pixlerRatios !== currentWell?.calculated?.pixlerRatios
      || c1c2 !== props.c1c2 || c1c3 !== props.c1c3 || c1c4 !== props.c1c4 || c1c5 !== props.c1c5
      || updateChart
    ) {
      chart.update({
        series: getDefaultSeries(c1c2 ?c1c2:[], c1c3 ? c1c3 : [], c1c4 ? c1c4 : [], c1c5 ? c1c5: [])
      });
      this.zoomId = setTimeout(() => {
        chart.xAxis[0].setExtremes(min, max);
      }, 300);
      return true;
    }
    const defaultScales = store.get('configScales');

    curveTypes.forEach((curveName) => {
      const checkToUpdateScales = shouldUpdateScale(
        curveName,
        currentWell?.scales,
        defaultScales
      );
      if (currentWell && checkToUpdateScales && this.chart && this.chart.update) {
        this.chart.update({
          series: getDefaultSeries(c1c2?c1c2: [], c1c3? c1c3: [], c1c4? c1c4: [], c1c5? c1c5: [])

        });
        return true;
      }
    })

    return false;
  }

  componentWillUnmount() {
    window.clearInterval(this.timerId);
    // eslint-disable-next-line no-unused-expressions
    this.zoomId && clearTimeout(this.zoomId);
  }

  // TODO refactor it this is repeated in Balance ratio
  selectAreas = event => {
    if (!this.props.enableZoomIn) {
      event.preventDefault();
      const axis = event.xAxis[0];
      const { customEventType, currentWell } = this.props;
      const { selectedId } = this.state;

      const afterSelect = () => {
        const minValue = Math.floor(axis?.min?.toFixed());
        const maxValue = Math.floor(axis?.max?.toFixed());
        let minLabel = minValue;
        let maxLabel = maxValue;
        if (currentWell) {
          const depthData = currentWell.depth;
          minLabel = depthData[minValue];
          maxLabel = depthData[maxValue];
        }
        const togglePlotBox = new CustomEvent('showPlotBox', {
          detail: {
            type: customEventType,
            min: minValue,
            max: maxValue,
            minLabel,
            maxLabel
          }
        });
        document.dispatchEvent(togglePlotBox);
      };

      // TODO Apply it when select plot band
      if (store.get('crossPlots')) {
        const count = store.get('openedCrossPlots')
          ? parseInt(store.get('openedCrossPlots'))
          : 0;
        store.set('openedCrossPlots', count + 1);

        handleSelectArea(this.chart, afterSelect, selectedId)(event);
      } else {
        displayZoomBtn();
        propagateZoom(event);
      }
    } else {
      displayZoomBtn();
      propagateZoom(event);
    }
  };

  createChart = () => {
    const { id } = this.props;
    const { C1C2, nc4, nc5 } = this.state;
    const { c1c2, c1c3, c1c4, c1c5, c1c2GQR } =
      store.get('configScales') || DEFAULT_SCALES;

    this.chart = Highcharts.chart(id, {
      chart: {
        zoomType: 'x',
        inverted: true,
        width: 160,
        padding: 0,
        margin: 0,
        backgroundColor: window.color || '#151724',
        events: {
          selection: this.selectAreas
        },
        animation: false,
        shadow: false
      },
      boost: {
        useGPUTranslations: true,
        usePreAllocated: true
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false
          },
          enableMouseTracking: false,
          showInLegend: false
        }
      },
      exporting: { enabled: false },
      title: ' ',
      subtitle: {
        text: null
      },
      credits: {
        enabled: false
      },
      xAxis: {
        allowDecimals: true,
        tickPosition: 'inside',
        tickColor: '#333',
        gridLineColor: '#333',
        gridLineWidth: 1,
        minorTickInterval: 1,
        zIndex: 800,
        opposite: true,
        tickLength: 0,
        labels: {
          enabled: true,
          y: -300
        }
      },
      yAxis: [
        {
          title: {
            text: ''
          },
          padding: 0,
          margin: 0,
          gridLineColor: '#333',
          minorGridLineWidth: 1,
          minorGridLineColor: '#333',
          tickStart: 0.1,
          type: 'logarithmic',
          minorTickInterval: 0.1,
          min: c1c2GQR?.min || 0.1,
          max: c1c2GQR?.max || 100000,
          color: 'rgba(0, 0, 0, 0)',
          index: 0,
          gridLineWidth: 1,
          tickInterval: 1,
          zIndex: 10,
          step: true,
          allowDecimals: true,
          labels: {
            enabled: true,
            y: -2000
          }
        },
        {
          type: 'logarithmic',
          boostThreshold: 1,
          min: c1c2GQR?.min ?? 0.1,
          max: c1c2GQR?.max ?? 100000,
          ...disableLabels,
          allowDecimals: true,
          index: 1,
          labels: {
            enabled: false
          },
          margin: 0,
          gridLineColor: '#333',
          minorGridLineWidth: 1,
          minorGridLineColor: '#333',
          tickStart: 0.1,
          minorTickInterval: 0.1,
          gridLineWidth: 1,
          tickInterval: 1,
          zIndex: 10,
          step: true
        },
        {
          boostThreshold: 1,
          min: c1c3 ? c1c3.min: 0.1,
          max: c1c3 ? c1c3.max: 100000,
          ...disableLabels,
          type: 'logarithmic',
          allowDecimals: true,
          index: 2,
          labels: {
            enabled: false
          },
          margin: 0,
          gridLineColor: '#333',
          minorGridLineWidth: 1,
          minorGridLineColor: '#333',
          tickStart: 0.1,
          minorTickInterval: 0.1,
          gridLineWidth: 1,
          tickInterval: 1,
          zIndex: 10,
          step: true
        },
        {
          boostThreshold: 1,
          min: c1c4 ? c1c4.min: 0.1,
          max: c1c4 ? c1c4.max: 100000,
          ...disableLabels,
          type: 'logarithmic',
          allowDecimals: true,
          index: 3,
          labels: {
            enabled: false
          },
          margin: 0,
          gridLineColor: '#333',
          minorGridLineWidth: 1,
          minorGridLineColor: '#333',
          tickStart: 0.1,
          minorTickInterval: 0.1,
          gridLineWidth: 1,
          tickInterval: 1,
          zIndex: 10,
          step: true
        },
        {
          boostThreshold: 1,
          min: c1c5 ? c1c5.min:0.1,
          max: c1c5 ? c1c5.max:100000,
          ...disableLabels,
          type: 'logarithmic',
          allowDecimals: true,
          index: 4,
          labels: {
            enabled: false
          },
          margin: 0,
          gridLineColor: '#333',
          minorGridLineWidth: 1,
          minorGridLineColor: '#333',
          tickStart: 0.1,
          minorTickInterval: 0.1,
          gridLineWidth: 1,
          tickInterval: 1,
          zIndex: 10,
          step: true
        }
      ],
      tooltip: {
        ...defaultCrossHair,
        formatter: getToolTipValues('pixler-chart')
      },
      series: [
        {
          fillOpacity: 0.1,
          name: ' ',
          tooltip: {
            valueSuffix: ' ',
            enabled: false
          },
          // eslint-disable-next-line react/destructuring-assignment
          data: this.props.c1c2,
          labels: {
            formatter: () => '<span class="deactivate-labels">-</span>'
          },
          lineColor: c1c2.color,
          color: c1c2.color,
          yAxis: 0,
          zIndex: 2,
          lineWidth: 1,
          index: 0,
          type: 'spline',
          ...disableDots,
          allowDecimals: true
        },
        {
          fillOpacity: 0.5,
          name: ' ',
          type: 'spline',
          tooltip: {
            valueSuffix: ' ',
            enabled: false
          },
          data: c1c2 ?? [],
          lineColor: c1c2 ? c1c2.color : 'red',
          color: c1c2 ? c1c2.color: 'red',
          yAxis: 1,
          zIndex: 2,
          lineWidth: 1,
          index: 1
        },
        {
          fillOpacity: 0.5,
          name: ' ',
          type: 'spline',
          tooltip: {
            valueSuffix: ' ',
            enabled: false
          },
          data: c1c3 ?? [],
          lineColor: c1c3 ? c1c3.color :'red',
          color: c1c3 ? c1c3.color : 'red',
          yAxis: 2,
          zIndex: 2,
          lineWidth: 1,
          index: 2
        },
        {
          fillOpacity: 0.5,
          name: ' ',
          type: 'spline',
          tooltip: {
            valueSuffix: ' ',
            enabled: false
          },
          data: c1c4 ?? [],
          lineColor: c1c4 ? c1c4.color : 'red',
          color: c1c4 ? c1c4.color: 'red',
          yAxis: 3,
          zIndex: 2,
          lineWidth: 1,
          index: 3
        },
        {
          fillOpacity: 0.1,
          name: ' ',
          type: 'spline',
          tooltip: {
            valueSuffix: ' ',
            enabled: false
          },
          // eslint-disable-next-line react/destructuring-assignment
          data: this.props.c1c5,
          labels: {
            formatter: () => '<span class="deactivate-labels">-</span>'
          },
          lineColor: c1c5.color,
          color: c1c5.color,
          yAxis: 4,
          zIndex: 2,
          lineWidth: 1,
          index: 4
        }
      ]
    });
  }

  render() {
    const { id } = this.props;
    return (
      <div className="chart__box-container" style={{ marginLeft: '10px' }}>
        <HeaderChart />
        <div
          id={id}
          style={{
            height: 'calc(100% - 180px)'
          }}
          data-cy="chart-reason"
        />
      </div>
    );
  }
}

PixlerChart.defaultProps = {
  id: 'pixler-chart',
  parentContainer: '.charts-container',
  c1c2: [],
  c1c3: [],
  c1c4: [],
  c1c5: [],
  customEventType: 'C1C2SF'
};

export default React.memo(PixlerChart);
