// import TextField from "@material-ui/core/TextField";
import { TextField } from '@mui/material'
import styled from "styled-components";
import * as colors from '../../theme/colors'

export const TextFieldStyled = styled(TextField)`
  width: 100%;
  border-radius: 4px;
  display: inline-flex;
  .MuiOutlinedInput-root {
    &.MuiInputBase-readOnly > fieldset{
      border-color: ${colors.backgroundWrappers};
    }
    fieldset {
      border-color: ${colors.wrapBorders};
    }
    &.Mui-focused fieldset {
      border-color: ${colors.wrapBorders};
    }
    &:hover fieldset {
      border-color: ${colors.wrapBorders};
    }
  }

  // Label error
  .MuiFormHelperText-contained {
    margin: 0;
    color: #EB3434;
    bottom: -28px;
    font-size: 16px;
  }

  div {
    color: #F5FAFF;
    font-size: 16px;
    background-color: transparent;

    input {
      padding: 8px;
    }

    input.Mui-disabled {
      color: #fff;
      border: 0px solid #fff;
      -webkit-text-fill-color: #fff;
      background-color: transparent;
    }

    // Remove arrows
    // Chrome, Safari, Edge, Opera
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    // Firefox
    input[type=number] {
      -moz-appearance: textfield;
    }
  }
`

export const InputTextField = styled(TextField)`
  width: 100%;
  border-radius: 4px;

  .MuiOutlinedInput-root {
    fieldset {
      border-color: ${colors.wrapBorders};
    }
    &.Mui-focused fieldset {
      border-color: ${colors.wrapBorders};
    }
    &:hover fieldset {
      border-color: ${colors.wrapBorders};
    }
  }

  // Label error
  .MuiFormHelperText-contained {
    margin: 0;
    color: #EB3434;
    bottom: -28px;
    font-size: 16px;
  }

  div {
    color: #F5FAFF;
    font-size: 16px;
    background-color: transparent;

    input {
      padding: 8px;
    }

    // Remove arrows
    // Chrome, Safari, Edge, Opera
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    // Firefox
    input[type=number] {
      -moz-appearance: textfield;
    }
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
