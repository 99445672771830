import React, { useState, useEffect, useCallback } from 'react';

import {
  storeStatistcsConfig,
  updateStatistcsConfigAction
} from 'actions/wells';
import {
  Button,
  Input
} from 'antd';
import { useFormik } from 'formik';
import find from 'lodash.find';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { useLocation } from 'react-router-dom';
import { successNotification, errorNotification } from 'utils';

import MiniSpinner from '../MiniSpinner';
import { useStatisticWizard } from '../ModalStatistics/useStatisticsWizard';
import MessageSignificanceLevel from '../MessageSignificanceLevel';
import FormSignificanceLevel from '../FormSignificanceLevel';
import { Chart } from 'highcharts';
import ChartSignificanceLevel from '../ChartSignificanceLevel';
import CriticalAreas from '../CriticalAreas';

// Example of Props
// curveName="C1"
// typeForm="significanceLevelC1"
// dataCurve="c1"

const ContainerSignificanceLevel = ({ curveName, typeForm, dataCurve, defaultConfig, movingAvarage,
  differenceType, chartId, handleApplySignificanceLevel }) => {
  const wells = useSelector(state => state.wells);
  const dispatch = useDispatch();
  const location = useLocation();

  const wizardState = useStatisticWizard();

  const {
    curves, validFormsCurves,
    rangeIntervalValue, currentWell, isLoadingStatistics,
    setIsLoadingStatistics, payloadCriticalPoints, setPayloadCriticalPoints,
    checkedTabs, setCheckedTabs
  } = wizardState;
  const setSignificanceLevel = wizardState[`setSignificanceLevel${curveName}`];
  const formCollapse = wizardState[`formCollapse${curveName}`];
  const chartDataValues = wizardState[`chartData${curveName}`];
  const chartData = chartDataValues && chartDataValues.data ? chartDataValues.data : [];

  const wellId = currentWell?.wellId || '-1';

  const [errorEmptyForm, setErrorEmpty] = useState();
  const [errorPercentForm, setErrorPercentForm] = useState();
  const [displayCutLine, setDisplayCutLine] = useState(false);

  const [chartInstance, setChart] = useState(null);
  const [showCutLine, enableCutLine] = useState(false);
  const [depthIndexToCut, setDepthIndexToCut] = useState();
  const [percenteOfOccurrencesSignificanceLevel, setPercenteOfOcurrences] = useState();

  const enableSignificanceLevel = (isEnable) => {
    setDisplayCutLine(isEnable)
  }

  const onSubmitForm = async (values, type, depthIndex, showCutLine, percenteOfOccurrencesSignificanceLevel) => {
    let rangeMin = formCollapse && formCollapse.rangMin ? formCollapse.rangMin : 0;
    if (!rangeMin) {
      rangeMin = rangeIntervalValue && rangeIntervalValue.length && rangeIntervalValue[0]
        ? rangeIntervalValue[0] : wells.currentWell.RAW.depth.data[0];
    }

    let rangeMax = formCollapse && formCollapse.rangMax ? formCollapse.rangMax : 0;
    if (!rangeMax) {
      rangeMax = rangeIntervalValue && rangeIntervalValue.length && rangeIntervalValue[1]
        ? rangeIntervalValue[1] : wells.currentWell.RAW.depth.data.slice(-1)[0];
    }

    let normalizedCurveName = dataCurve === 'ic4' || dataCurve === 'ic5'
      ? `${dataCurve}Normal` : dataCurve;

    if (normalizedCurveName === 'c4Composition') {
      normalizedCurveName = 'nc4Composition';
      if (!currentWell[normalizedCurveName]) {
        normalizedCurveName = 'c4Composition';
      }
    }

    if (normalizedCurveName === 'c5Composition') {
      normalizedCurveName = 'nc5Composition';
      if (!currentWell[normalizedCurveName]) {
        normalizedCurveName = 'c5Composition';
      }
    }

    const formValue = wizardState?.[typeForm];
    if (formValue === 0 || formValue === undefined && showCutLine === false) {
      setErrorEmpty('This field is required');
    } else if (type === 'cut') {

      if (percenteOfOccurrencesSignificanceLevel > 50 ||
          percenteOfOccurrencesSignificanceLevel === 0 ||
          percenteOfOccurrencesSignificanceLevel === undefined)
      {
        errorNotification('Significance Level need to be less than 50% and greater than 0')
        setCheckedTabs({
          ...checkedTabs,
          [curveName]: 'error'
        });
        return;
      }

      setDisplayCutLine(true);
      // try {
      setDisplayCutLine(true);
      if (wellId && currentWell) {
        const depthIndexToCutValue = chartData && chartData.slice(depthIndex, chartData.length);

        const occurrencesLength = chartData.filter(v => v > 0) || 0;
        const percentCriticalAreaCut = (depthIndex / occurrencesLength) * 100;

        currentWell.statistics = {
          ...(currentWell.statistics || {}),
          ...{ [typeForm]: percenteOfOccurrencesSignificanceLevel, typeForm }
        };

        const significanceData = currentWell && currentWell.statistics &&
          currentWell.statistics.significanceChartData
          ? currentWell.statistics.significanceChartData : [];

        let dataFromCurve = currentWell[normalizedCurveName];
        if (normalizedCurveName === 'gqr') {
          dataFromCurve = currentWell[normalizedCurveName].map(v => v * 1000);
        }

        const curveMnemonic = currentWell.RAW[dataCurve].mnemonic;
        setPayloadCriticalPoints({
          ...payloadCriticalPoints,
          significance_level_percent: {
            ...payloadCriticalPoints.significance_level_percent,
            [curveMnemonic]: percenteOfOccurrencesSignificanceLevel
          },
          well_uid: currentWell.well_uid,
          range_min: rangeMin,
          range_max: rangeMax
        })

          // const criticalAreas = chartData.reduce((acc, criticalArea, index) => {

          //   acc.push({
          //     depthIndex: index,
          //     criticalAreaValue: criticalArea,
          //     shouldShow: criticalArea >= criticalValue,
          //     color: '#FF0000',
          //     curveName
          //   });
          //   return acc;
          // }, []);

          // console.log('criticalAreas foi,', criticalAreas);
          // currentWell.statistics = {
          //   ...currentWell.statistics,
          //   significanceLevelValue: criticalValue,
          //   validFormsCurves,
          //   significanceData,
          //   depthIndexToCutValue: depthIndexToCutValue || [],
          //   percentCriticalAreaCut,
          //   [`criticalAreas${curveName}`]: criticalAreas,
          //   formCollapse,
          //   chartData,
          //   rangeMin,
          //   rangeMax,
          //   curves,
          //   ...{
          //     [typeForm]: percentCriticalAreaCut,
          //     typeForm
          //   }
          // };
          // const payload = {
          //   curves: [curveName],
          //   curveInfo: {
          //     [`criticalAreas${curveName}`]: criticalAreas,
          //     chartData,
          //     significanceData,
          //     moving_average_type: formCollapse.moving_average_type || '',
          //     difference_type: formCollapse.difference_type || '',
          //     window_length_value: formCollapse.window_length_value || 0,
          //     sensitivity_value: formCollapse.sensitivity_value,
          //     depthIndexToCutValue: depthIndexToCutValue || [],
          //     percentCriticalAreaCut: criticalValue,
          //     rangeMin,
          //     rangeMax,
          //     significanceLevelValue: criticalValue,
          //     significance_level_percent: percenteOfOccurrencesSignificanceLevel,
          //     typeForm,
          //     validFormsCurves,
          //     wellId: wellId || currentWell.wellInfo.wellId
          //   },
          //   chartData,
          //   depthIndexToCutValue: depthIndexToCutValue || [],
          //   percentCriticalAreaCut: criticalValue,
          //   rangeMin,
          //   rangeMax,
          //   significanceData,
          //   significanceLevelValue: criticalValue,
          //   typeForm,
          //   validFormsCurves,
          //   wellId:  wellId || currentWell.wellInfo.wellId
          // };
        // successNotification(`Critical Areas calculated successfully!`);
        successNotification(`Critical Areas saved successfully!`);

          // const config = find(
          //   (currentWell && currentWell.statistics || []),
          //   (c) => c.formName === `formCollapse${curveName}`
          // );

          // if (config && config.id) {
          //   dispatch(
          //     updateStatistcsConfigAction(payload, config.id, payload.wellId)
          //   );
          // } else {
          //   dispatch(
          //     storeStatistcsConfig(payload, payload.wellId)
          //   );
          // }
        setErrorEmpty('');
        setErrorPercentForm('');
        setCheckedTabs({
          ...checkedTabs,
          [curveName]: 'saved'
        });
      }
      // } catch(e) {
      //   console.log('error', e)
      // }
    } else {
      const parseNumber = Number(wizardState?.[typeForm] || values?.[typeForm] || 0);
      const floatValue = parseNumber || 0;

      if (floatValue > 50 || floatValue === 0 || floatValue === undefined)
      {
        errorNotification('Significance Level need to be less than 50% and greater than 0')
        setCheckedTabs({
          ...checkedTabs,
          [curveName]: 'error'
        });
        return;
      }

      setSignificanceLevel(parseNumber);
      setIsLoadingStatistics(true);

      // try {
      if (wellId && currentWell) {
        currentWell.statistics = {
          ...(currentWell.statistics || {}),
          ...{ [[typeForm]]: floatValue, typeForm }
        };

        let dataFromCurve = currentWell[normalizedCurveName];
        if (normalizedCurveName === 'gqr') {
          dataFromCurve = currentWell[normalizedCurveName].map(v => v * 1000);
        }

        const curveMnemonic = currentWell.RAW[dataCurve].mnemonic;
        const bodyCriticalPoints = {
          ...payloadCriticalPoints,
          significance_level_percent: {
            ...payloadCriticalPoints.significance_level_percent,
            [curveMnemonic]: Number(values[`significanceLevel${curveName}`])
          },
          well_uid: currentWell.well_uid,
          range_min: rangeMin,
          range_max: rangeMax
        }
        setPayloadCriticalPoints(bodyCriticalPoints)

          // const significanceData = currentWell && currentWell.statistics &&
          //   currentWell.statistics.significanceChartData
          //   ? currentWell.statistics.significanceChartData : [];

          // const criticalAreas = chartData.reduce((acc, criticalArea, index) => {
          //   acc.push({
          //     depthIndex: index,
          //     criticalAreaValue: criticalArea,
          //     shouldShow: criticalArea >= criticalValue,
          //     color: '#FF0000',
          //     curveName
          //   });
          //   return acc;
          // }, []);

          // currentWell.statistics = {
          //   ...currentWell.statistics,
          //   significanceLevelValue: criticalValue,
          //   validFormsCurves,
          //   significanceData,
          //   [`criticalAreas${curveName}`]: criticalAreas,
          //   formCollapse,
          //   chartData,
          //   rangeMin,
          //   rangeMax,
          //   curves,
          //   ...{
          //     [typeForm]: floatValue,
          //     typeForm
          //   }
          // };

          // const payload = {
          //   curves: [curveName],
          //   curveInfo: {
          //     [`criticalAreas${curveName}`]: criticalAreas,
          //     chartData,
          //     significanceData,
          //     moving_average_type: formCollapse.moving_average_type || '',
          //     difference_type: formCollapse.difference_type || '',
          //     window_length_value: formCollapse.window_length_value || 0,
          //     significance_level_percent: significanceLevel || floatValue,
          //     sensitivity_value: formCollapse.sensitivity_value
          //       ? formCollapse.sensitivity_value: undefined,
          //     [typeForm]: floatValue
          //   },
          //   chartData,
          //   depthIndexToCutValue: [],
          //   percentCriticalAreaCut: 0,
          //   rangeMin,
          //   rangeMax,
          //   significanceData,
          //   [`significanceLevel${curveName}`]: significanceLevel,
          //   significanceLevelValue: criticalValue,
          //   typeForm,
          //   validFormsCurves,
          //   wellId: wellId || currentWell.wellInfo.wellId
          // };
          successNotification(`Critical Areas saved successfully!`);

          // const config = find(
          //   (currentWell && currentWell.statistics || []),
          //   (c) => c.formName === `formCollapse${curveName}`
          // );

          // if (
          //   config && config.id
          // ) {
          //   dispatch(
          //     updateStatistcsConfigAction(payload, config.id, payload.wellId)
          //   );
          // } else {
          //   dispatch(
          //     storeStatistcsConfig(payload, payload.wellId)
          //   );
          // }
        setErrorEmpty('');
        setErrorPercentForm('');
        setCheckedTabs({
          ...checkedTabs,
          [curveName]: 'saved'
        });
        setIsLoadingStatistics(false);
      }
      // } catch (e) {
      //   setIsLoadingStatistics(false);
      //   console.log('error -->', e);
      // }
    }
  }

  const onChangeInput = (e) => {
    setFieldValue(typeForm, e.target.value);
  };

  return (
    <div className='container-boxes'>
      <div className='box-significance'>
        <MessageSignificanceLevel 
          curve={curveName} 
          differenceType={differenceType} 
          movingAvarage={movingAvarage} 
        />
        {isLoadingStatistics ? <MiniSpinner /> : (
          <ChartSignificanceLevel 
            defaultConfig={defaultConfig} 
            id={chartId} 
            curveName={curveName}
            onSubmit={onSubmitForm} 
            movingAvarage={movingAvarage} 
            enableSignificanceLevel={enableSignificanceLevel}
            chartInstance={chartInstance}
            setChart={setChart}
            showCutLine={showCutLine}
            enableCutLine={enableCutLine}
            depthIndexToCut={depthIndexToCut}
            setDepthIndexToCut={setDepthIndexToCut}
            setPercenteOfOcurrences={setPercenteOfOcurrences}
          />
        )}
      </div>
      <div>
        <div className='box-significance'>
          <FormSignificanceLevel 
            onSubmitForm={onSubmitForm} 
            displayCutLine={displayCutLine}
            errorEmptyForm={errorEmptyForm}
            errorPercentForm={errorPercentForm}
            curveName={curveName}
            typeForm={typeForm}
            depthIndexToCut={depthIndexToCut}
            showCutLine={showCutLine}
            percenteOfOccurrencesSignificanceLevel={percenteOfOccurrencesSignificanceLevel}
          />
        </div>
        <div className='box-significance' style={{ marginTop: '20px' }}>
          <CriticalAreas 
            showCutLine={showCutLine}
            onSubmit={onSubmitForm}
            depthIndexToCut={depthIndexToCut}
            setDepthIndexToCut={setDepthIndexToCut}
            enableCutLine={enableCutLine}
            chartInstance={chartInstance}
            percenteOfOccurrencesSignificanceLevel={percenteOfOccurrencesSignificanceLevel}
          />
        </div>
        <Button
          type="button"
          className="container-significance-form__button"
          style={{ width: '100%' }}
          data-cy="apply-significance-level"
          onClick={handleApplySignificanceLevel}
        >
          Apply all
        </Button>
      </div>
    </div>
  )}

export default ContainerSignificanceLevel;
