import styled from "styled-components";
import BaseButton from "./Base";
import { customTheme } from "../../../src/theme/default";

const Danger = styled(BaseButton)`
  && {
    background-color: ${customTheme.red4};
    transition: border 0.1s ease;

    &:hover {
      background-color: ${customTheme.red5};
      border: 3px solid ${customTheme.red3};
    }

    &:disabled {
      background-color: ${customTheme.blue1};
    }
  }
`

export default Danger;