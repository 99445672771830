import axios from 'axios';
import map from 'lodash.map';

/*
  These functions are responsible to store fetching one well or a list of wells and syncing
  to google cloud functions to prevent the user from loose the current local well sites using
  wellex-gold.
  https://console.cloud.google.com/functions/details/us-central1/well
*/

export const BASE_URL = 'https://prod.gateway.geowellex.com';

export function fetchInWell(token, uid, uidWell) {
  const resource = `${BASE_URL}/inwell-data/well/${uid}/${uidWell}/data?filter_empty=True`;
  return axios.get(
    resource,
    {
      headers: {
        'access-token': token,
        'Content-type': 'application/json'
      }
    }
  );
}

export function fetchInWells(token) {
  const resource = `${BASE_URL}/inwell-data/well`;
  return axios.get(
    resource,
    {
      headers: {
        'access-token': token,
        'Content-type': 'application/json'
      }
    }
  );
}

export function getURLToDownloadLas(wellId, token) {
  return axios.post(
    `https://wellex-wells-oofp55ubjq-uc.a.run.app/wellex-wells/v1/data-export/las`,
    {
      'well_uid': wellId
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
}

export function deleteWell(token, wellId) {
  return axios.delete(
    `https://wellex-wells-oofp55ubjq-uc.a.run.app/wellex-wells/v1/wells/${wellId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
}

export function normalizeWellBoreToStore(well) {
  let zones = well.zones || [];

  if (well.zones && !Array.isArray(well.zones)) {
    zones = map(well.zones, (z) => z);
  }

  const formattedWell = {
    api: well.api,
    balanceRatioData: well.balanceRatioData,
    wellInfo: {
      wellName: well.wellName,
      unit: well.unit,
      depthMin: well.selectedMax,
      depthMax: well.selectedMin,
      sensorType: well.sensorType,
      wellDescription: well.description || '',
      ...(well.wellInfo || {})
    },
    bitSize: well.bitSize,
    cutOffs: well.cutOffs,
    zones,
    checkedSensor: well.checkedSensor,
    curvesNames: well.curvesNames,
    statistics: well.statistics || null,
    curves: {
      checkedSensor: well.checkedSensor,
      sensorType: well.sensorType,
      c1: well.c1 || [],
      c1Byc2: well.c1Byc2 || [],
      c1Composition: well.c1Composition || [],
      c1Normalized: well.c1Normalized || [],
      c1Percentage: well.c1_percentage,
      c2: well.c2,
      c2Composition: well.c2Composition,
      c2Percentage: well.c3_percentage,
      c2c3Ratios: well.c2c3Ratios,
      c3: well.c3,
      c3Composition: well.c3Composition,
      c3Percentage: well.c3_percentage,
      c3c2Ratios: well.c3c2Ratios,
      characterRatioData: well.characterRatioData,
      depth: well.depth,
      flow: well.flow,
      gammaRay: well.gammaRay,
      gor: well.gor,
      ic4: well.ic4,
      ic4BynC4: well.ic4BynC4,
      ic4Normal: well.ic4Normal,
      ic5: well.ic5,
      ic5BynC5: well.ic5BynC5,
      ic5Normal: well.ic5Normal,
      isotope: well.isotope,
      nc4: well.nc4,
      nc4Composition: well.nc4Composition,
      nc4Percentage: well.nc4_percentage,
      nc5: well.nc5,
      nc5Composition: well.nc5Composition,
      nc5Percentage: well.nc5_percentage,
      rop: well.rop,
      slopeFactor: well.slowFactor || well.slopeFactor,
      totalCarbonWithDepth: well.totalCarbon,
      totalCarbon: well.totalCarbonOnly || well.totalCarbon,
      totalGas: well.totalGas,
      wetnessRatio: well.wetnessRatioData || well.wetnessRatio,
      wob: well.wob,
      c2h4: well.c2h4 || [],
      c2h6: well.c2h6 || [],
      c2s: well.c2s || []
    }
  };
  return formattedWell;
};

const decodeStreamsToJSON = async (response) => {
  const readableStream = response.body;
  const reader = readableStream.getReader();

  let responseText = '';

  while (true) {
    const {value, done} = await reader.read();

    if (done) break;

    const utf8decoder = new TextDecoder();
    responseText += utf8decoder.decode(value);
  }

  try {
    const contentToJSON = JSON.parse(responseText);
    return contentToJSON;
  } catch(e) {
    console.log('Invalid well');
    return null;
  }
};

const encodeJsonToStream = (jsonData) => {
  const encoder = new TextEncoder();
  const encodedData = encoder.encode(jsonData);
  return encodedData;
};

export const getFileToParse = async (signedURL) => {
  try {
    const myHeadersSecond = new Headers();
    myHeadersSecond.append('Content-Type', 'application/octet-stream');

    const options = {
      method: 'GET',
      headers: myHeadersSecond
    };

    const response = await fetch(signedURL, options);
    const data = await decodeStreamsToJSON(response);

    if (data) {
      return JSON.parse(data);
    } else {
      console.log('invalid well in getFileToParse', data);
    }
  } catch(e) {
    console.log(`error in getFileToParse ==>`, e);
  }

  return {};
}

export const updateBucketWellFile = async (signedURL, data) => {
  try {
    const myHeadersSecond = new Headers();
    myHeadersSecond.append('Content-Type', 'application/octet-stream');

    const options = {
      method: 'PUT',
      headers: myHeadersSecond,
      body: encodeJsonToStream(JSON.stringify(data))
    };

    const response = await fetch(signedURL, options);

    if (response.status === 200) {
      return data;
    } else {
      console.log('error in updateBucketWellFile');
    }
  } catch(e) {
    console.log(`error test ==>`,e.message);
  }
};


