import styled from "styled-components";
import {
  TextField, Button, Grid,
  IconButton as IconButtonMui, InputAdornment as InputAdornmentMui
} from '@mui/material';
import { Cancel as CancelIcon } from '@mui/icons-material';
// import { colors } from '../../../styles/variables';
import { theme } from './variables';
import ReactSVG from "react-svg";

// ------- Carousel -------

export const CarouselItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

export const WrapperDescription = styled.div`
  display: flex;
  justify-content: left;
  margin-top: 4.6875rem;
  margin-left: 6rem;
`;

export const CarouselDescription = styled.span`
  display: block;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-style: normal;
  width: 60%;
  font-size: 1rem;
  line-height: 1.75rem;
  letter-spacing: 0.0625rem;
  color: ${theme.colors.white};

  @media (min-width: 768px) and (max-width: 991.98px) {
    display: block;
    font-size: 1.6rem;
    line-height: 2.15rem;
    letter-spacing: 0.0625rem;
  }
`;

export const CarouselImage = styled.img`
  position: absolute;
  bottom: 5.6rem;
  left: 0;
  right: 0;
  width: 55%;
  margin: 0 auto;

  @media (min-width: 768px) and (max-width: 991.98px) {
    margin-right: -3rem;
    width: 120%;
  }
`;

export const StepContainer = styled.div`
  position: sticky;
  margin-top: 1rem;
  display: flex;
  
`;

export const TextContainer = styled.div`
  padding: 2rem;
  height: 35vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Step = styled.div<{ $active: boolean }>`
  > svg {
    font-size: 30px;
    color: ${theme.colors.white};
    opacity: ${props => props.$active ? '1' : '0.3'};

    &:hover {
      opacity: ${props => props.$active ? '1' : '0.5'};
      cursor: pointer;
    }
  }
`;

export const CarouselContainer = styled.div` 
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CarouselImageBg = styled.div<{ src?: string; }>`
  display: flex;
  align-items: center;
  height: 100vh;
  background-image: url(${(props) => props.src || '' });
  background-size: cover;
  background-position: center;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const ScreenImage = styled.img`
  height: 100%;

  @media (width: 1024px) and (min-height: 655px) {
    width: 90%;
  }

  @media (min-width: 769px) and (max-width: 1200px) {
    height: 75%;
`;

export const ScreenContainer = styled.div`
  height: 65vh;
  display: flex;
  justify-content: end;
  align-items: end;
  width: 100%;

  @media (min-width: 768px) and (min-height: 1180px) {
    display: none;
  }
  
`;

// ------- Copyright -------

export const CopyrightContainer = styled.div`
  text-align: center;
  margin-bottom: 5rem;
  svg {
    width: 9rem;
    height: auto;
  }
`;

export const CopyrightImage = ReactSVG;

export const CopyrightInfoText = styled.span`
  font-size: 0.9375rem;
  color: ${theme.colors.gray5};
  font-size: 0.875rem;
  line-height: 1.5rem;
  display: block;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-style: normal;
`;

export const LoadingForm = styled.div`
  position: absolute;
  z-index: 2;
  display: flex;
  width: 100%;
  height: 105%;
  align-items: center;
  justify-content: center;
  background: rgba(255,255,255, 0.5);
  bottom: -3px;

  .MuiCircularProgress-colorPrimary {
    color: ${theme.colors.blue};
  }
`;

// ------- Content -------
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  gap: 2rem;
  margin-bottom: 1.875rem;
  margin-top: 0.5rem;
`;

// ------- Form -------

export const FormLogin = styled.form`
  position: relative;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;

  @media (max-height: 652px) {
    margin-bottom: 0.875rem;
    gap: 1rem;
  }
`;

export const Input = styled(TextField)`
  input:-webkit-autofill,
  .MuiInputBase-root:has(> input:-webkit-autofill) {
    background-color: #e8f0fe;
  }

  label {
    position: relative;
    left: -0.875rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.3rem;
    line-height: 1.5rem;
    letter-spacing: 0.0625rem;
    color: ${theme.colors.white};
  }

  input {
    height: 1rem;
    padding: 0.6875rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 0.9375rem;
    color: ${theme.colors.gray4};
    border-color: ${theme.colors.colorRollingStone};
  }

  .MuiFormLabel-root.Mui-focused, .MuiFormLabel-root.Mui-error {
    color: ${theme.colors.colorShuttleGray};
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #40a9ff;
    transition: 0.5s;
  }

  .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: ${theme.colors.red};
  }

  .MuiOutlinedInput-root.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${theme.colors.red};
    box-shadow: 0px 0px 3px ${theme.colors.red};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #40a9ff;
    border-width: 1px;
    box-shadow: 0px 0px 3px ${theme.colors.blue};
  }

  fieldset {
    border-color: ${theme.colors.colorRollingStone};

    legend {
      width: unset;

      span {
        display: none;
      }
    }
  }

  .MuiFormHelperText-contained {
    margin-left: 0;
  }
`;

export const InputAdornmentPassword = styled(InputAdornmentMui)`
  gap: 10px;
`;

export const IconErrorContainer = styled.div`
  display: flex;
`;

export const ErrorMessage = styled.span`
  font-size: 0.875rem;
  color: ${theme.colors.red2};
`;

export const IconError = styled(CancelIcon)`
  color: ${theme.colors.red};
  font-size: 0.875rem;
  margin-left: 0.313rem;
`;

export const IconButton = styled(IconButtonMui)`
  background-color: transparent;
  padding: 0;

  .MuiSvgIcon-root {
    color: ${theme.colors.gray3};
    font-size: 1rem;

    &:hover {
      color: ${theme.colors.bgDark2};
      transition: 0.3s;
    }
  }
`;

export const ButtonSubmit = styled(Button)<{ buttonColor: string }>`
  &.MuiButton-root {
    background-color: ${(props) => props.buttonColor};
    border-color: initial;
    display: flex;
    align-items: center;
    width: 100%;
    height: 2.5rem;
    color: ${theme.colors.white};
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 1rem;
    letter-spacing: 0.0625rem;
    padding: 0 15px;
    margin-top: 0.75rem;

    &:hover, &:active, &:focus{
      background-color: #1D3195;
      border: 3px solid #7B809E;
      color: ${theme.colors.white};
    }

    &:disabled {
      background-color: ${theme.colors.disabled};
      border: 3px solid ${theme.colors.disabled};
      color: ${theme.colors.disabled2};
    }
  }
`;

// ------- Login -------

export const LoginContainer = styled.div<{ bgColor: string }>`
  background: ${(props) => props.bgColor};
  height: 100vh;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: grid;
  grid-template-columns: 1fr 2fr;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const LeftSideContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const WrapperInsideLeftSide = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
`;

export const HeaderLogin = styled.div<{ isError: boolean }>`
  width: 100%;
  text-align: center;
  margin-top: 3.125rem;
  margin-bottom: ${(props) => props.isError ? 0 : '1.875rem'};
`;

export const HeaderImageContainer = styled.div<{ isError: boolean }>`
  width: 100%;
  text-align: center;
  margin-bottom: ${(props) => props.isError ? 0 : '1.5625rem'};
`;

export const HeaderImage = ReactSVG;

// styled(ReactSVG)`
//   svg {
//     width: 16.375rem;
//   }

//   @media (max-width: 1041px) {
//     svg {
//       width: 14.375rem;
//     }
//   }
// `;

export const LoginTitle = styled.h1`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 2rem;
  line-height: 3rem;
  letter-spacing: 0.0625rem;
  color: ${theme.colors.white};
  margin-bottom: 0;
`;

export const LoginSubtitle = styled.p`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: 0.0625rem;
  color: ${theme.colors.white2};
  margin-bottom: 0;
`;

export const Row = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CarouselSection = styled.div`
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#223e78+0,076587+51,0c96c8+98 */
  // background: #223e78; /* Old browsers */
  // background: -moz-linear-gradient(left,  #223e78 0%, #076587 51%, #0c96c8 98%); /* FF3.6-15 */
  // background: -webkit-linear-gradient(left,  #223e78 0%,#076587 51%,#0c96c8 98%); /* Chrome10-25,Safari5.1-6 */
  // background: linear-gradient(left right,  #223e78 0%,#076587 51%,#0c96c8 98%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#223e78', endColorstr='#0c96c8',GradientType=0 ); /* IE6-9 */
  background: linear-gradient(35.68deg, #223E78 0%, #076587 51.55%, #0C96C8 98.23%);
  height: 100vh;

  @media (max-width: 767.98px) {
    display: none;
  }
`;
