import React from 'react';
import { Button, Input, Select } from 'antd';
import { useFormik, ErrorMessage } from 'formik';
import { errorNotification } from '../../utils';
import { customTheme } from '@geowellex/shared-ui/src/theme/default';

// YaxisForm
const YaxisForm = ({ onSubmitYaxisForm, onClose, yAxisMin, yAxisMax, chartType }) => {
  const { Option } = Select;

  const onSubmit = (values) => {
    if (values.min === '' || !values.max) {
      errorNotification('All fields are required.');
      return;
    }
    if (values.max > 100) {
      errorNotification('The maximum value should be less or equal than 100.');
      return;
    }
    if (values.scaleType === 'logarithmic' && values.min <= 0) {
      errorNotification('The minimum value should be greater than 0');
      return;
    }
    if (isNaN(values.min) || isNaN(values.max)) {
      errorNotification('The values should be valid numbers.');
      return;
    }
    onSubmitYaxisForm(values);
  };

  const formik = useFormik({
    initialValues: {
      min: yAxisMin,
      max: yAxisMax,
      scaleType: chartType
    },
    enableReinitialize: true,
    onSubmit: onSubmit
  });

  const { handleSubmit, handleChange, values, errors, setFieldValue } = formik;

  const handleChangeSelect = (value) => {
    if (value === 'linear') setFieldValue('min', 0);
    else setFieldValue('min', 0.1);
    setFieldValue('scaleType', value);
  };

  return (
    <form onSubmit={handleSubmit}
      style={{
        height: '100%',
        overflow: 'auto',
      }}
    >
      <div className="wrapper-form-molar"
        style={{
          height: '85%',
        }}
      >
        <div className="box-inputs wrapper-settings-input-molar">
          <label className="label-settings-molar-chart">Min</label>
          <div className="text-min box-input-molar">
            <Input
              onChange={handleChange}
              name="min"
              type="text"
              placeholder="Min"
              value={values.min}
              className="input-settings-molar-chart"
              style={{
                border: `1px solid ${customTheme.purple6}`,
                background: 'transparent'
              }}
            />
            {errors && errors.min && <ErrorMessage message={errors.min} />}
          </div>
          <label className="label-settings-molar-chart">Max</label>
          <div className="text-max">
            <Input
              onChange={handleChange}
              name="max"
              type="number"
              placeholder="max"
              value={values.max}
              className="input-settings-molar-chart"
              style={{
                border: `1px solid ${customTheme.purple6}`,
                background: 'transparent'
              }}
            />
            {errors && errors.max && <ErrorMessage message={errors.max} />}
          </div>
          <label className="label-settings-molar-chart">Scale Type</label>
          <div className="select-scale-type">
            <Select
              name="scaleType"
              defaultValue="logarithmic"
              onChange={handleChangeSelect}
              style={{
                border: `1px solid ${customTheme.purple6}`,
                background: 'transparent'
              }}
            >
              <Option value="logarithmic">Log</Option>
              <Option value="linear">Linear</Option>
            </Select>
          </div>
        </div>
      </div>
      <div
        className="container-actions"
        style={{
          borderTop: `1px solid ${customTheme.purple7}`,
          height: '10%',
          justifyItems: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          className="btn-cancel"
          onClick={onClose}
          style={{
            width: 85,
            height: 35,
          }}
        >
          CANCEL
        </Button>
        <Button
          type="primary"
          className="btn-apply"
          htmlType="submit"
          style={{
            width: 75,
            height: 35,
            background: customTheme.blue4,
            border: 'none',
          }}
        >
          APPLY
        </Button>
      </div>
    </form>
  )
};

export default YaxisForm;
