import React, { Component } from 'react';

import ChartContainer from 'components/Shared/ChartContainer';
import Highcharts from 'highcharts';
import get from 'lodash.get';
import {
  syncronizeTooltip,
  handleSelectArea,
  displayZoomBtn,
  propagateZoom,
  toggleTrack as toggleTrackUtils
} from 'utils';

import store from 'store';
import ChartHeader from './ChartHeader';
import {
  createDefaultConfig,
  defaultSeries,
  generateSerie,
  generateQualityData,
  generateUpdateData
} from './config';
import { getRawCurveData } from '../../../utils/getRawCurveData';
import GlobalCutOff from '../../../utils/GlobalCutOff';

class QualityControlChart extends Component {
  constructor(props) {
    super(props);
    this.chart = null;

    this.state = {
      data: [],
      qualityData: [],
      intervals: [],
      series: {
        0: true,
        1: true,
        2: true,
      },

    };
  }

  componentDidMount() {
    this.createQualityDataChart([], []);
  }

  shouldComponentUpdate(nextProps) {
    const { selectedWell, currentWell, removeDataInterval, min, max } = nextProps;

    const beforeDepthData = get(getRawCurveData('depth', this.props.beforeWell), 'data', []);
    const currentDepthData = get(getRawCurveData('depth', nextProps.currentWell), 'data', []);
    if (
      selectedWell !== this.props.selectedWell ||
      currentWell !== this.props.currentWell ||
      beforeDepthData !== currentDepthData ||
      removeDataInterval !== this.props.removeDataInterval ||
      nextProps.currentWell.calculated?.qualityData?.goodData && !this.state.qualityData?.goodData ||
      nextProps.currentWell.calculated?.qualityData?.badData && !this.state.qualityData?.badData ||
      nextProps.intervals !==  this.props.intervals ||
      (currentWell.calculated?.qualityData && this.state.qualityData !== currentWell.calculated?.qualityData)
    ) {

      this.setState({ qualityData: currentWell.calculated?.qualityData ?? generateUpdateData(this.props.qualityData?.data, this.props.currentWell?.RAW?.depth?.data?.length)});
      const currentDepth =
        nextProps.currentWell && nextProps.currentWell.curves && nextProps.currentWell.curves.depth
          ? nextProps.currentWell.curves.depth : [];
      const data = this.props.goodBadData ? this.props.goodBadData : generateUpdateData(this.props?.qualityData?.data, this.props.currentWell?.RAW?.depth?.data?.length); // Remove data will change the bad data
      const series = Object.keys(data).map((key, index) => (
        generateSerie(key, data[key], index + 1)));
      const { depth } = this.props;

      this.chart.destroy();
      this.createQualityDataChart(data, series);

      this.zoomId = setTimeout(() => {
        this.chart.xAxis[0].setExtremes(min, max);
      }, 300);
      return true;
    }

    return false;
  }

  componentWillUnmount() {
    this.zoomId && clearTimeout(this.zoomId);
  }

  createQualityDataChart = (data, series) => {
    const { id } = this.props;

    let defaultPlaceholderData = [];

    if (Object.keys(data).length) {
      defaultPlaceholderData = data;
    }

    // This map will generate each serie that we are passing data via props
    // NOTE: This will generate data just relyng from the serie that we are receiveing
    // const series = Object.keys(data).map(
    //   (key, index) => generateSerie(key, data[key], index + 1)
    // );
    series.push(defaultSeries(defaultPlaceholderData));
    const config = createDefaultConfig(this.props, series);
    const chart = Highcharts.chart(id, {
      ...config,
      chart: {
        ...config.chart,
      }
    });

    this.chart = chart;

    GlobalCutOff.registerInstances(
      'quality-control-chart',
      chart
    );

    document.addEventListener('sincronize-selected', event => {
      const {
        detail: { min, max }
      } = event;

      const { depth } = this.props;
      let minValue = depth[min];
      let maxValue = depth[max];

      this.chart.xAxis[0].setExtremes(depth - 200, depth - 200, true, true);
      this.chart.redraw();
    });

    syncronizeTooltip(`#${this.props.id}`);


    document.addEventListener('toggle-quality-control-chart', ({ detail: { index } }) => {
      console.log(this);
      toggleTrackUtils(this)(index);
    });
  }

  render() {
    const { id, currentWell, title, depth } = this.props;
    return (
      <ChartContainer style={{ marginLeft: '10px' }}>
        <ChartHeader qualityData={this.state.qualityData  || {}} title={title} depth={depth} />
        <div
          id={id}
          style={{
            height: 'calc(100% - 180px)'
          }}
          data-cy="chart-quality-control"
        />
      </ChartContainer>
    );
  }
}

QualityControlChart.defaultProps = {
  width: 140,
  type: 'area',
  bgColor: 'transparent',
  inverted: true,
  animation: false,
  customProps: {},
  id: 'quality-control-chart',
  title: <span>Quality Control</span>
};

export default QualityControlChart;
