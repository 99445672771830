import React, { useEffect } from 'react';
import { Collapse } from 'antd';
import {useStatisticWizard} from '../ModalStatistics/useStatisticsWizard';
import PanelCurveConfig from './PanelCurvesConfig/PanelCurveConfig';

const { Panel } = Collapse;

const configuredStatus = <span style={{ color: '#46a01b' }}> - Configuration OK</span>;
const pendingStatus = <span style={{ color: '#d7bb10' }}>- Pending configuration</span>;

const checkIfIsMissingConfiguration = (curveToCheck, selectedGasCurves, globalContext) => {
  const { setInvalidAllCurves, invalidAllCurves } = globalContext;

  /*
   * (Valid form config)
   * should check if the curve exists in selectedGasCurves array
   * should check if there is this form on global state
   * should check if there is the form name within validFormsCurves array
   **/
  if (
    selectedGasCurves.includes(curveToCheck)
    && globalContext?.validFormsCurves
    && globalContext.validFormsCurves.includes(curveToCheck)
  ) {
    if (invalidAllCurves.includes(curveToCheck)) {
      const newInvalidCurve = invalidAllCurves.filter((curve) => curve !== curveToCheck);
      setInvalidAllCurves(newInvalidCurve)
    }
    return configuredStatus;
  }
  if (!selectedGasCurves.includes(curveToCheck)) {
    return '';
  }
  if (!invalidAllCurves.includes(curveToCheck)) {
    setInvalidAllCurves([...invalidAllCurves, curveToCheck])
  }
  return pendingStatus;
};

export const curveMapTabs = {
  'C1': (c1Status, data) => (
    <Panel header={<div>C1 {c1Status}</div>} key="2">
      <PanelCurveConfig curveName="C1" data={data} />
    </Panel>
  ),
  'C2': (c2Status, data) => (
    <Panel header={<div>C2 {c2Status}</div>} key="3">
      <PanelCurveConfig curveName="C2" data={data} />
    </Panel>
  ),
  'C3': (c3Status, data) => (
    <Panel header={<div>C3 {c3Status}</div>} key="4">
      <PanelCurveConfig curveName="C3" data={data} />
    </Panel>
  ),
  'IC4': (ic4Status, data) => (
    <Panel header={<div>IC4 {ic4Status}</div>} key="5">
      <PanelCurveConfig curveName="IC4" data={data} />
    </Panel>
  ),
  'IC5': (ic5Status, data) => (
    <Panel header={<div>IC5 {ic5Status}</div>} key="6">
      <PanelCurveConfig curveName="IC5" data={data} />
    </Panel>
  ),
  'C4': (c4Status, data) => (
    <Panel header={<div>C4 {c4Status}</div>} key="7">
      <PanelCurveConfig curveName="C4" data={data} />
    </Panel>
  ),
  'C5': (c5Status, data) => (
    <Panel header={<div>C5 {c5Status}</div>} key="8">
      <PanelCurveConfig curveName="C5" data={data} />
    </Panel>
  ),
  'C1Composition': (c1CompositionStatus, data) => (
    <Panel header={<div>C1% {c1CompositionStatus}</div>} key="9">
      <PanelCurveConfig curveName="C1Composition" data={data} />
    </Panel>
  ),
  'C2Composition': (c2CompositionStatus, data) => (
    <Panel header={<div>C2% {c2CompositionStatus}</div>} key="10">
      <PanelCurveConfig curveName="C2Composition" data={data} />
    </Panel>
  ),
  'C3Composition': (c3CompositionStatus, data) => (
    <Panel header={<div>C3% {c3CompositionStatus}</div>} key="11">
      <PanelCurveConfig curveName="C3Composition" data={data} />
    </Panel>
  ),
  'C4Composition': (c4CompositionStatus, data) => (
    <Panel header={<div>C4% {c4CompositionStatus}</div>} key="12">
      <PanelCurveConfig curveName="C4Composition" data={data} />
    </Panel>
  ),
  'C5Composition': (c5CompositionStatus, data) => (
    <Panel header={<div>C5% {c5CompositionStatus}</div>} key="13">
      <PanelCurveConfig curveName="C5Composition" data={data} />
    </Panel>
  ),
  'GQR': (gqrStatus, data) => (
    <Panel header={<div>GQR {gqrStatus}</div>} key="13">
      <PanelCurveConfig curveName="GQR" data={data} />
    </Panel>
  )
};

// should find C1ECC and return only C1
export const findAndMatchCurve = (curves) => curves.map(c => {
  const keysToMap = Object.keys(curveMapTabs);
  const findMatchCurve = keysToMap.find(key => c.includes(key));
  if (findMatchCurve) {
    return findMatchCurve;
  }
  return curves?.[0];
});

const CollapsePanel = ({ onChange, type, label, windowlength, curvesConfiguration }) => {
  const globalState = useStatisticWizard();

  // TODO should get the curve values from global state
  // get curves
  const selectedCurves = findAndMatchCurve(globalState.curves) || [];

  useEffect(() => {
    if (curvesConfiguration) {
      selectedCurves.map((curveName) => {
        const setForm = globalState[`setFormCollapse${curveName}`];
        setForm({...curvesConfiguration[curveName], formType: curveName});
        return curveName;
      })
    }
  }, [])

  return (
    <div className="statistic-collapse">
      <Collapse defaultActiveKey={['2']} onChange={onChange}>
        {/* <Panel header="Apply to all" key="1">
          <p>Apply configfor All data</p>
        </Panel> */}
        {/* Get curves of selected cruves */}
        {selectedCurves.map((curveName) => {
          const tabStatus = checkIfIsMissingConfiguration(curveName, selectedCurves, globalState);
          // make sure when memnoic is C1EECC this will select C1
          if (!curveMapTabs?.[curveName]) {
            const curveMatTabsKeys = Object.keys(curveMapTabs);
            const curveNameWithoutAlias = curveMatTabsKeys.find(key => curveName.includes(key));
            if (curveNameWithoutAlias) {
              return curveMapTabs[curveNameWithoutAlias](tabStatus, curvesConfiguration);
            }
          } else {
            return curveMapTabs[curveName](tabStatus, curvesConfiguration);
          }
        })}
      </Collapse>
    </div>
  );
};

export default CollapsePanel;
