export const RECEIVE_FLUID_TYPE_POINTS = 'RECEIVE_FLUID_TYPE_POINTS';

export const gasColors = {
  fill: '#DC262633',
  stroke: '#B22021',
  'stroke-width': 2,
  opacity: '0.5'
};

export const oilColors = {
  fill: '#65A30D33',
  stroke: '#53840D',
  'stroke-width': 2,
  opacity: '1'
};

export const transitionColors =  {
  fill: '#D9770633',
  stroke: '#AF6107',
  'stroke-width': 2,
  opacity: '0.5'
};