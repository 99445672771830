import { ReactNode } from "react"
import BaseButton from "./Base"
import PrimaryButton from "./Primary"
import SecondaryButton from "./Secondary"
import TertiaryButton from "./Tertiary"
import DangerButton from "./Danger"

interface ButtonProps {
  type: 'submit' | 'button',
  theme: 'primary' | 'secondary' | 'tertiary' | 'danger',
  disabled?: boolean,
  children: ReactNode,
  startIcon?: ReactNode,
  endIcon?: ReactNode,
  dataCy?: string,
  contentAlign?: 'start' | 'center' | 'end',
  onClick?: () => void
}

const ThemeButton = {
  primary: PrimaryButton,
  secondary: SecondaryButton,
  tertiary: TertiaryButton,
  danger: DangerButton
}

export const Button = ({
  children, type, startIcon, endIcon, theme, disabled,
  dataCy, contentAlign, onClick, ...rest
}: ButtonProps) => {
  return (
    <BaseButton
      type={type}
      startIcon={startIcon}
      endIcon={endIcon}
      as={ThemeButton[theme]}
      disabled={disabled}
      onClick={onClick}
      data-cy={dataCy}
      contentAlign={contentAlign}
      {...rest}
    >
      {children}
    </BaseButton>
  )
}