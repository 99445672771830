import React from 'react'
import { ButtonProps } from './types'
import ButtonPrimary from "./ButtonPrimary"
import ButtonBase from './ButtonBase'
import ButtonSecondary from './ButtonSecondary'
import ButtonGray from './ButtonGray'

const ButtonTheme = {
  primary: ButtonPrimary,
  secondary: ButtonSecondary,
  gray: ButtonGray,
}

export const Button = ({
  theme, width = 'fit-content', startIcon,
  endIcon, children, type, onClick,
  disabled
}: ButtonProps) => {
  return (
    <ButtonBase 
      type={type}
      as={ButtonTheme[theme]}
      width={width}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </ButtonBase>
  )
}
