import React, { createContext, useState, useRef, useEffect, useMemo } from 'react'; //
import { OptionsType } from '../components/SelectWell/SelectWell';
import { LogSetType } from '../components/SelectLogSets/types';

type ConfigType = {
  navigate?: (path?: string) => void;
};

type PropsConfigContext = {
  options?: OptionsType[];
  state?: ConfigType;
  setState?: any; //React.Dispatch<React.SetStateAction<ConfigType>>;
  afterSelect?: any;
  api?: string;
  gor?: string;
  totalCarbon?: string;
  holeDepth?: string;
  ethene?: string;
  propene?: string;
  getUnitValue?: any;
  logSets?: LogSetType[];
  warningNotification?: (message: string) => void;
  nameDrawerOpen?: string;
  setNameDrawerOpen?: (value: string) => void;
  uploadFile?: any;
  wellsItems?: any;
  onChangeUpload?: any;
  handleOpenImportData?: () => void;
  activeCurveConfig?: string;
  setActiveCurve?: (value: string) => void;
  resetLoadWell?: any;
  wellUid?: string;
  setWellUid?: (value: string) => void;
  unselect?: any;
  currentWell: any;
};

const NOT_AVAILABLE = 'N/A';

const DEFAULT_VALUE = {
  state: {
    navigate: undefined,
  },
  options: undefined,
  setState: undefined,
  afterSelect: undefined,
  ethene: NOT_AVAILABLE,
  propene: NOT_AVAILABLE,
  logSets: undefined,
  nameDrawerOpen: undefined,
  setNameDrawerOpen: undefined,
  activeCurveConfig: '',
  currentWell: undefined,
};

const ConfigContext = createContext<PropsConfigContext>(DEFAULT_VALUE);

type ProviderProps = {
  children?: React.ReactNode;
  selectWell: any;
  options: OptionsType[];
  wellsItems?: any;
  selectedDepthIndex: number;
  currentWell?: any;
  getUnit?: any;
  defaultLogSets?: any;
  defaultScales?: any;
  warningNotification?: (messasge: string) => void;
  uploadFile?: any;
  onChangeUpload?: any;
  handleOpenImportData?: () => void;
  resetLoadWell?: any;
  unSelectWell?: any;
};

const getApiGorValue = (value23: number, value234: number): string => {
  // see which is lower
  let lowerLimit: number = Math.min(value23, value234);
  let upperLimit: number = Math.max(value23, value234);

  return lowerLimit <= 0 || !lowerLimit|| upperLimit <= 0 || !upperLimit
    ? NOT_AVAILABLE : `${String(lowerLimit.toFixed(2))} - ${String(upperLimit.toFixed(2))}`;
};

const ConfigContextProvider = ({ 
  children, selectWell, options, wellsItems,
  currentWell, selectedDepthIndex = -1,
  getUnit, defaultLogSets, defaultScales,
  warningNotification, uploadFile, onChangeUpload,
  handleOpenImportData, resetLoadWell, unSelectWell
}: ProviderProps) => {
  const [state, setState] = useState(DEFAULT_VALUE.state);
  const [api, setApi] = useState(NOT_AVAILABLE);
  const [gor, setGor] = useState(NOT_AVAILABLE);
  const [totalCarbon, setTotalCarbon] = useState(NOT_AVAILABLE);
  const [holeDepth, setHoleDepth] = useState(NOT_AVAILABLE);
  const [ethene, setEthene] = useState(NOT_AVAILABLE);
  const [propene, setPropene] = useState(NOT_AVAILABLE);
  const [nameDrawerOpen, setNameDrawerOpen] = useState('');
  const [activeCurveConfig, setActiveCurve] = useState('');
  const [wellUid, setWellUid] = useState('');
  const previousWellUidRef = useRef(wellUid);

  const well = useMemo(() => {
    if (currentWell) {
      return currentWell;
    }
    return null;
  }, [currentWell]);

  const unselect = React.useCallback(() => unSelectWell, [unSelectWell]);
  const afterSelect = React.useCallback(() => selectWell, [selectWell]);
  const getUnitValue = useRef(getUnit);

  const logSets = useMemo(() => {
    if (defaultLogSets) {
      return Object.keys(defaultLogSets)?.reduce((acc: any, logSetName: any) => {
        acc.push({
          logSetName,
          curves: Object.keys(defaultLogSets?.[logSetName]?.curves)?.reduce((acc2: any, curveName: any) => {
            acc2.push({
              mnemonic: defaultLogSets?.[logSetName]?.curves?.[curveName]?.mnemonic,
              curveName: defaultLogSets?.[logSetName]?.curves?.[curveName]?.name,
              color: defaultScales?.[defaultLogSets?.[logSetName]?.curves?.[curveName]?.type]?.color || '#fff',
            });
            return acc2;
          }, []) ?? []
        });
        return acc;
      }, [])
    } else {
      return [];
    }
  }, [
    defaultLogSets,
    defaultScales,
  ]);

  useEffect(() => {
    if (currentWell && selectedDepthIndex >= 0 && currentWell?.calculated?.api_23) {
      const minApi = currentWell?.calculated?.api_23?.data?.[selectedDepthIndex];
      const maxApi = currentWell?.calculated?.api_234?.data?.[selectedDepthIndex]
      const apiValue = getApiGorValue(minApi, maxApi);
      setApi(apiValue);
    }
    if (!currentWell && selectedDepthIndex === -1 && api) {
      setApi(NOT_AVAILABLE);
    }
  
    if (currentWell && selectedDepthIndex >= 0 && currentWell?.calculated?.gor_23) {
      const minGor = currentWell?.calculated?.gor_23?.data?.[selectedDepthIndex];
      const maxGor = currentWell?.calculated?.gor_234?.data?.[selectedDepthIndex]
      const gorValue = getApiGorValue(minGor, maxGor);
      setGor(gorValue);
    }
    if (!currentWell && selectedDepthIndex === -1 && gor) {
      setGor(NOT_AVAILABLE);
    }
  
    if (currentWell && selectedDepthIndex >= 0 && currentWell?.calculated?.total_carbon) {
      const totalCarbonValue = currentWell?.calculated?.total_carbon?.data?.[selectedDepthIndex];
      setTotalCarbon(String(totalCarbonValue?.toFixed(2)));
    }
    if (!currentWell && selectedDepthIndex === -1 && totalCarbon) {
      setTotalCarbon(NOT_AVAILABLE);
    }

    if (currentWell && selectedDepthIndex >= 0 && currentWell?.RAW?.c2) {
      const etheneValue = currentWell?.RAW?.c2?.data?.[selectedDepthIndex]?.toFixed(2) || NOT_AVAILABLE;
      setEthene(String(etheneValue));
    }
    if (!currentWell && selectedDepthIndex === -1 && ethene) {
      setEthene(NOT_AVAILABLE);
    }

    if (currentWell && selectedDepthIndex >= 0 && currentWell?.RAW?.c3) {
      const propeneValue = currentWell?.RAW?.c3?.data?.[selectedDepthIndex]?.toFixed(2) || NOT_AVAILABLE;
      setPropene(String(propeneValue));
    }
    if (!currentWell && selectedDepthIndex === -1 && propene) {
      setPropene(NOT_AVAILABLE);
    }

    if (currentWell && selectedDepthIndex >= 0) {
      const depth = currentWell?.RAW?.depth?.data;
      const depthLength = depth?.length || 0;
      const depthValue = depth?.length ? depth?.[depthLength - 1] : 0;
      setHoleDepth(depthValue?.toFixed(2));
    }
    if (!currentWell && selectedDepthIndex === -1 && holeDepth) {
      setHoleDepth(NOT_AVAILABLE);
    }
    
  }, [selectedDepthIndex, currentWell, api, gor, ethene, propene, holeDepth, totalCarbon]);

  useEffect(() => {
    if (currentWell?.well_uid) {
      const newWellUid = currentWell.well_uid;

      if (newWellUid !== previousWellUidRef.current) {
        setWellUid(newWellUid);
        previousWellUidRef.current = newWellUid;
      }
    }
  }, [currentWell]);

  return (
    <ConfigContext.Provider
      value={{
        currentWell: well,
        unselect,
        options,
        state,
        afterSelect,
        setState,
        api,
        gor,
        totalCarbon,
        holeDepth,
        getUnitValue: getUnitValue?.current,
        ethene,
        propene,
        logSets,
        warningNotification,
        nameDrawerOpen,
        setNameDrawerOpen,
        uploadFile,
        wellsItems,
        onChangeUpload,
        handleOpenImportData,
        activeCurveConfig,
        setActiveCurve,
        resetLoadWell,
        wellUid,
        setWellUid,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export { ConfigContextProvider };

export default ConfigContext;