// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';

import theme  from './theme';
import Dashboard from './components/Dashboard';
import { Header } from './components/Charts';
import customTheme from './theme/default';
import CustomScrollBar from './components/CustomScrollBar';
import Loading from './components/Loading';
import Drawer from './components/Drawer';
import Button from './components/Button';
import Menu from './components/Menu';
import InputText from './components/InputText';
import RangeSlider from './components/RangeSlider';
import InputColor from './components/InputColor';
import Autocomplete from './components/Autocomplete';
import ConfirmDialog from './components/ConfirmDialog';

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );

// root.render(
//   <React.StrictMode>
//     <EmotionThemeProvider theme={customTheme}>
//       <ThemeProvider theme={theme}>
//         <CssBaseline />
//         <Dashboard />
//       </ThemeProvider>
//     </EmotionThemeProvider>
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
const Components = {
  ThemeProvider,
  EmotionThemeProvider,
  CssBaseline,
  Dashboard,
  Header,
  theme,
  customTheme,
  CustomScrollBar,
  Loading,
  Drawer,
  Button,
  Menu,
  InputText,
  RangeSlider,
  InputColor,
  Autocomplete,
  ConfirmDialog
};

export default Components;
