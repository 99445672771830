import './style.scss';

import React, {
  useEffect,
  useState,
  useMemo
} from 'react';
import { useDispatch } from 'react-redux';

import styled from 'styled-components';
import { Table, Input, Popconfirm, Button } from 'antd';
import removeIcon from 'assets/trash-white.svg';
import closeIcon from '../../assets/close.svg';
import { fetchInWellWatcherSites } from 'api/wellwatcher'
import Spinner from 'components/Spinner';
import ToggleButton from 'react-toggle-button';
import store, { set } from 'store';
import { deleteWell } from '../../api/wells';
import { receiveWells, removeWell } from '../../actions/wells';

import { successNotification, errorNotification, warningNotification } from '../../utils';
import { listWellsMetadata } from '../../api/import-data';

const { Search } = Input;


const StyledSearch = styled(Search)`
    .ant-input {
        background-color: #333;
        border: 1px solid #333;
        color: #eee;
        &-suffix i{
            color: #eee;
        }
    }
`;

const TableSelectUserWells = ({
    onSelectWell, selectedWellKey, wellOptions,
    selectionType, setSelectionType, setAction
}) => {
    const [inWellList, setInWells] = useState([]);
    const [loading, setLoading] = useState(true);
    const [refreshWells, setRefreshWells] = useState({});
    const [wellsType, setWellsType] = useState(false);
    const [querySearch, setQuerySearch] = useState("");
    const [rowSelection, setRowSelection] = useState({});
    const [selectedWells, setSelectedWells] = useState([]);

    async function updateWellMetadataList(token){
        try{
            const response = await listWellsMetadata(token)
            const updatedWells = response.data.content;
            dispatch(receiveWells(updatedWells));
        } catch(err){
            console.log(err);
        }
    };

    const columns = [
        {
            title: 'Name',
            key: 'name',
            render: (data) => {
                if (data?.name?.length >= 25) {
                    return `${data.name.substr(0, 25)}...`;
                } else {
                    return data.name;
                }
            }
        },
        {
            title: 'Start',
            dataIndex: 'start_depth',
            key: 'start_depth'
        },
        {
            title: 'Stop',
            dataIndex: 'stop_depth',
            key: 'stop_depth'
        },
        {
            title: 'Unit',
            dataIndex: 'index_unit',
            key: 'index_unit'
        },
        {
            title: 'Status',
            key: 'is_active',
            render: (data) => {
                if (data?.is_active === false) {
                    return (
                        <div style={{ color: 'red', display: 'flex' }}>
                            <div style={{
                                width: 6, height: 6, background: 'red',
                                borderRadius: '50%', marginTop: 6, marginRight: 4
                            }} />
                            <span style={{ textTransform: 'capitalize', fontSize: 12, }}>Deactivated</span>
                        </div>
                    );
                }

                if (data?.is_active === true) {
                    return (
                        <div style={{ color: '#24ff24', display: 'flex' }}>
                            <div style={{
                                width: 6, height: 6, background: '#24ff24',
                                borderRadius: '50%', marginTop: 8, marginRight: 4
                            }} />
                            <span style={{ textTransform: 'capitalize' }}>Active</span>
                        </div>
                    );
                }
                return 'N/A';
            }
        },
        {
            title: 'Last Updated',
            key: 'created_at',
            render: (data) => {
                if (data?.created_at) {
                    return new Date(data.created_at).toLocaleString();
                }
            }
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (data) => {
                const token = store.get('token');
                const handleDelete = async (wellUid) => {
                    if (!wellUid) return;
                    setLoading(true);
                    try{
                        if(wellUid === selectedWellKey){
                            dispatch(removeWell(token, wellUid));
                        } else {
                            await deleteWell(token, wellUid);
                        }
                        setRefreshWells({ delete: true, wellUid });
                        await updateWellMetadataList(token);
                        successNotification('Success', 'Well deleted successfully');
                    }catch(err){
                        console.log(err);
                        errorNotification('Error', 'An error occurred while deleting the well');
                    }
                    setLoading(false);
                };

                if (data?.uid) {
                    return (<>
                        <Popconfirm
                            placement="bottom"
                            title="Are you sure you want to delete this well?"
                            onConfirm={() => { handleDelete(data.uid) }}
                            okText="Yes"
                            href="javascript:;"
                            cancelText="No"
                        >
                            <Button
                                style={{
                                    marginRigth: 0,
                                    marginLeft: 0,
                                    backgroundColor: 'transparent',
                                    border: 'none',
                                    paddingRight: 10,
                                    paddingLeft: 10
                                }}
                                title="Remove Well"
                                data-cy="remove-well"
                            >
                                <img src={removeIcon} alt="remove" style={{ width: '16px', height: '16px' }} />
                            </Button>
                        </Popconfirm>
                    </>);
                }
            }
        },
    ];

    const inWellFormatted = useMemo(() => {
      return inWellList.map((item, key) => ({ ...item, key }))
    }, [inWellList, loading]);

    const dispatch = useDispatch();


    const rowSelectionChange = () => {
        if (selectionType === 'radio') {
            setAction('-1');
            setRowSelection({
                type: 'radio',
                onSelect: (row) => {
                    onSelectWell(row);
                }
            });
        }
        if(selectionType === 'checkbox'){
            setRowSelection({
                type: 'checkbox',
                onChange: (selectedRowKeys, selectedRows) => {
                  setSelectedWells(selectedRows);
                },
            });
        }
    };

    const handleDeleteMultipleWells = async () => {
        const token = store.get('token');
        if (selectedWells.length === 0) {
            warningNotification('Warning', 'Please select wells to delete');
            return;
        }

        setLoading(true);
        setRefreshWells({ delete: true });
        try {
          const deletePromises = selectedWells.map(async (well) => {
            if (well.uid === selectedWellKey) {
              dispatch(removeWell(token, well.uid));
            } else {
              await deleteWell(token, well.uid);
            }
          });

          await Promise.all(deletePromises);

          await updateWellMetadataList(token);

          setRowSelection(undefined);
          setSelectionType('radio');
          setRefreshWells({ delete: false });
          successNotification('Success', 'Wells deleted successfully');
        } catch (err) {
          console.log(err);
          errorNotification('Error', 'An error occurred while deleting wells');
          setRefreshWells({ delete: false });
        } finally {
          setLoading(false);
        }
    };

    useEffect(() => {
        rowSelectionChange();
    },[selectionType]);

    useEffect(() => {
        if (refreshWells.delete) {
            const filterDeletedWells = inWellList.filter(well => well.uid !== refreshWells.wellUid);
            setInWells(filterDeletedWells);
        };
    }, [refreshWells]);

    useEffect(() => {
        let activeWells = [];
        if (wellsType) {
            activeWells = (wellOptions ?? []).filter(w => w?.is_active !== null && w?.is_active === true);
        } else {
            activeWells = wellOptions ?? [];
        }
        const filteredWells = activeWells.filter(well => {
            return well?.name?.toUpperCase().includes(querySearch.toUpperCase())
        })
        setInWells(filteredWells);
        setLoading(false);
    }, [wellsType, wellOptions, querySearch]);

    return (
        <>
            {selectionType === 'checkbox' &&
                (<div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}><Popconfirm
                    placement="bottom"
                    title="Are you sure you want to delete these wells?"
                    onConfirm={() => { handleDeleteMultipleWells() }}
                    okText="Yes"
                    href="javascript:;"
                    cancelText="No"
                >
                    <Button
                        style={{
                            marginRigth: 0,
                            marginTop: 0,
                            display: 'flex',
                            backgroundColor: 'red',
                            border: 'none',
                            paddingRight: 10,
                            paddingLeft: 10,
                            alignItems: 'center',
                            color: '#fff'

                        }}
                        title="Remove Well"
                        data-cy="remove-well"
                    >   <span>Delete Selected Wells</span>
                        <img src={removeIcon} alt="remove" style={{ width: '16px', height: '16px' }} />
                    </Button>
                </Popconfirm>
                <Button
                    style={{
                        marginRigth: 0,
                        marginLeft: 0,
                        backgroundColor: 'transparent',
                        border: 'none',
                        paddingRight: 10,
                        paddingLeft: 10
                    }}
                    title="Cancel Action"
                    data-cy="cancel-action"
                    onClick={() => { setAction('-1'); setRowSelection(undefined); }}
                >
                    <img src={closeIcon} alt="remove" style={{ width: '16px', height: '16px' }} />
                </Button></div>)}
            {loading && <div className="cuttoff-load-container">
                <Spinner />
                <br />
                <div className="cuttoff-load-text">
                    Loading...
                </div>
            </div>}
            {!loading && (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginTop: 20 }}>
                    <div style={{ display: 'flex', alignItems: 'center', width: '80%' }}>
                        <StyledSearch
                          placeholder="Search Well by Name"
                          onChange={e => setQuerySearch(e.target.value)}
                          style={{ width: '100%' }}
                          data-cy="search-well-input"
                        />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
                        <span style={{ color: '#fff', fontFamily: 'sans-serif', fontSize: 13, whiteSpace: 'nowrap' }}>Filter Only Actives:</span><div>&nbsp;</div>
                        <ToggleButton
                            inactiveLabel="All"
                            activeLabel="Active"
                            value={wellsType}
                            colors={{
                                activeThumb: {
                                    base: 'rgb(255,255,255)'
                                },
                                inactiveThumb: {
                                    base: 'rgb(255,255,255)',
                                    hover: 'rgb(255,255,255)'
                                },
                                active: {
                                    base: '#65c970',
                                    hover: '#65c970'
                                },
                                inactive: {
                                    base: '#ff000099',
                                    hover: '#ff000099'
                                }
                            }}
                            onToggle={(value) => {
                                setWellsType(!value);
                            }}
                            disabled={loading}
                        />
                    </div>
                </div>
            )}

            {!loading &&
                <Table
                  columns={columns}
                  dataSource={inWellFormatted}
                  // pagination={false}
                  className="table-select-inWell"
                  rowSelection={rowSelection}
                  pagination={{ defaultPageSize: 5, showSizeChanger: true, pageSizeOptions: ['5', '10'] }}
                  data-cy="list-of-wells"
                />
            }
        </>
    );
};

export default TableSelectUserWells;
