import styled from 'styled-components';
import Popover from '@mui/material/Popover';
import * as pallete from '@geowellex/shared-ui/src/theme/default';

export const StyledPopover = styled(Popover)`
    & .MuiPopover-paper{
        background-color: transparent;
        border-radius: 6px;
    }
`;

export const ConfirmWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: ${pallete.customTheme.gray1};
    background-color: ${pallete.customTheme.purple3};
    padding: 10px;
    border-radius: 6px;
`; 