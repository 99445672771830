import { Button } from "@mui/material";
import styled from "styled-components";


const BaseButton = styled(Button)`
  && {
    color: #FDFDFD;
    font-size: 16px;
    font-weight: 500;
    padding: 8px 16px;
    gap: 8px;
    border-radius: 6px;
    text-transform: none;
    height: 36px;

  }
  .MuiButton-startIcon, .MuiButton-endIcon {
    margin: 0;
  }
  &:disabled {
    color: #FDFDFD;
    opacity: 0.4;
  }
`

export default BaseButton;
