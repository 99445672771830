import { Box, ExtendButtonBase, List, ListItemButton, ListItemButtonTypeMap, ListItemText } from "@mui/material";
import styled from "styled-components";
import { customTheme } from "../../../../theme/default";

export const DrawerWrapper = styled(Box)<{ isCloseDrawer: boolean }>`
  padding: ${(props) => props.isCloseDrawer ? '25px 4px' : '25px 20px'};
  display: flex;
  flex-direction: column;
  color: ${customTheme.white};
  height: 100%;

  @media(max-height: 1000px) {
    height: 150%;
  }
`;

export const ListStyle = styled(List)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ListItemButtonStyle = styled(ListItemButton)<ExtendButtonBase<ListItemButtonTypeMap<{}, "div">>>`
  && {
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 12px 16px;

    &:hover, &::selection {
      background-color: ${customTheme.blue2};
    }
  }
`

export const MenuItem = styled(ListItemText)<{ selected: boolean }>`
  color: ${(props) => props.selected ? customTheme.gray2 : customTheme.gray1};
  
  && {
    margin-top: 0;
    margin-bottom: 0;
  }

  .MuiListItemText-primary {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
  }
  
`;