import React, { useState } from 'react';
import { Card, Radio, Slider, Input, Button } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

const NoiseCard = ({ curveName, setData, selectedOptions, data, sliderValues }) => {
  const wells = useSelector(state => state.wells);
  const [status, setStatus] = useState();
  const [formData, setFormData] = useState({
    moving_average_type: 'SMA',
    sensitivity_value: 50,
    window_length_value: 10,
    difference_type: "ABS",
    // data: wells.currentWell.RAW[curveName].data.slice(sliderValues[0], sliderValues[1] + 1),
    // curve: curveName,
    curve_mnemonic: wells.currentWell.RAW[curveName].mnemonic,
    log_set_name: "GQC"
  });

  const handleInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSave = (curveName) => {
    setData((prevData) => {

      const newCurve = {
        ...formData,
      };

      return [...prevData, newCurve];
    });
    setStatus("- Configuration OK")
  };

  return (
    <Card
      title={
        <div>
          <span
            style={{ color: 'white' }}
            data-cy='curve-name-config-noise'
          >
            {curveName.toUpperCase()}
          </span>
          {status && (
            <span
              style={{ color: 'green', marginLeft: '8px' }}
              data-cy='status-config-noise'
            >
              {status}
            </span>
          )}
        </div>
      }
      headStyle={{ color: 'white' }}
      style={{ border: '1px solid #3F3F46', marginBottom: '16px', color: 'white', backgroundColor: "#151724" }}
    >
      <div style={{ borderBottom: '1px solid #3F3F46', marginBottom: '16px' }}>
        <Radio.Group
          name="moving_average_type"
          onChange={(e) => handleInputChange('moving_average_type', e.target.value)}
          value={formData.moving_average_type}
        >
          <Radio style={{ color: 'white' }} value="SMA" data-cy='sma-config-noise'>Simple Moving Average</Radio>
          <Radio style={{ color: 'white' }} value="EMA" data-cy='ema-config-noise'>Exponential Moving Average</Radio>
        </Radio.Group>
      </div>
      {formData.moving_average_type === 'EMA' ? (
        <div style={{ marginBottom: '16px' }}>
          <label
            style={{ marginRight: '8px', color: 'white' }}
          >
            Sensitivity
          </label>
          <Slider
            value={formData.sensitivity_value}
            onChange={(value) => handleInputChange('sensitivity_value', value)}
          />
          <span
            style={{ marginLeft: '8px', color: 'white' }}
            data-cy='sensitivity-value-config-noise'
          >
            {formData.sensitivity_value}
          </span>
        </div>
      ) : null}
      <div style={{ marginBottom: '16px' }}>
        <label style={{ marginRight: '8px', color: 'white' }}>Window Length</label>
        <Input
          value={formData.window_length_value}
          onChange={(e) => handleInputChange('window_length_value', e.target.value)}
          style={{ backgroundColor: '#151724', border: '1px solid #525775', color: 'white' }}
          data-cy='window-length-config-noise'
        />
      </div>
      <Button
        type="primary"
        onClick={(e) => handleSave(curveName)}
        icon={<SaveOutlined />}
        style={{ backgroundColor: '#233BB6', borderColor: '#233BB6' }}
        data-cy='save-config-noise'
      >
        Save
      </Button>
    </Card>
  );
};

export default NoiseCard;
