import AutocompleteMui from '@material-ui/lab/Autocomplete';
import InputText from "../InputText"

import { PopperStyled } from "./styles"

interface AutocompleteProps {
  options: unknown[],
  label: string,
  value: any,
  error?: boolean,
  errorMessage?: string,
  onChange: (value: any) => void
}

const PopperAutocomplete = function (props: any) {
  return (<PopperStyled {...props} />)
}

export const Autocomplete = ({ options, label, value, error, errorMessage, onChange, ...rest }: AutocompleteProps) => {
  return (
    <AutocompleteMui
      freeSolo
      options={options}
      PopperComponent={PopperAutocomplete}
      getOptionLabel={(optionLabel) => {
        return optionLabel ? optionLabel.toString() : '';
      }}
      value={value}
      onInputChange={(_, value) => {
        onChange(value)
      }}
      getOptionSelected={(option, value) => {
        return option.indexOf(value) !== -1;
      }}
      {...rest}
      renderInput={(params) => (
        <InputText
          type='text'
          label={label}
          error={error}
          errorMessage={errorMessage}
          {...params}
        />
      )}
    />
  )
}
