import mockWellData from 'reducers/well_data_optional.json';
import mockMetadataOptionals from 'reducers/well_metadata_mock_optionals.json';
import {
  deleteWell,
  normalizeWellBoreToStore,
  getFileToParse,
  updateBucketWellFile
} from 'api/wells';
import store from 'store';
import GlobalCutOff from 'utils/GlobalCutOff';
import { removeAllZones } from 'utils/Zones';

import * as actions from '../constants/wells';
import {
  findOnIndexDB,
  setOnIndexDB
} from '../utils';
import { getWellSiteScales } from 'utils/getWellSiteScales';
import { getFormattedWell } from 'utils/getFormattedWell';
import { receiveCutOffs } from './cutoff';
import { getWellData, getWellMetadata, listWellsMetadata } from '../api/import-data';
import { useSelector } from 'react-redux';
import { statisticsCurvesNames, defaultCriticalTypes } from 'utils/constants';

export const receiveLogsetGqc = (gqc) => ({
  type: actions.RECEIVE_LOGSET_GQC,
  gqc
});

export const updateModulesParametersGQC = gqc => ({
  type: actions.UPDATE_MODULES_PARAMETERS_GQC,
  gqc
})

export const receiveQualityData = (data) => ({
  type: actions.RECEIVE_QUALITY_DATA,
  data
});


export const updateMetadata = (metadata) => ({
  type: actions.UPDATE_METADATA,
  metadata
});

export const receiveFilteredData = (filteredData, storeCutoff) => ({
  type: actions.FILTERED_DATA,
  filteredData,
  storeCutoff
});

export const updateWellInfo = well => ({
  type: actions.UPDATE_WELL,
  well,
});

export const receiveWells = (wells) => ({
  type: actions.RECEIVE_WELLS,
  wells,
});

export const removeCurrentWell = wellId => ({
  type: actions.DELETE_WELL,
  wellId,
});

export const registerDepthData = data => ({
  type: actions.REGISTER_DEPTH_DATA,
  data,
});

export const receiveSelectedDepthIndex = index => ({
  type: actions.RECEIVE_SELECTED_INDEX,
  index,
});

export const filterWell = wellId => ({
  type: actions.FILTER_WELL,
  wellId,
});

export const receiveWell = well => ({
  type: actions.RECEIVE_WELL,
  well
});

export const receiveMetadata = metadata => ({
  type: actions.RECEIVE_METADATA,
  metadata
});

export const updateCurrentWellData = well => ({
  type: actions.UPDATE_CURRENT_WELL_DATA,
  well
});

export const receiveSelectedMinMax = data => ({
  type: actions.RECEIVE_SELECTED_MIN_MAX,
  data
});

export const listWells = () => {
  const token = store.get('token');
  return async (dispatch) => {
    try {
      const { data: { content: wells } } = await listWellsMetadata(token);
      dispatch(receiveWells(wells))
      return wells;
    } catch (e) {
      console.log('Error listWells', e);
    }
  };
};

export const wellMetadata = (wellId) => {
  const token = store.get('token');
  return async () => {
    try {
      const { data: { content: well } } = await getWellMetadata(wellId, token);
      return well;
    } catch (e) {
      console.log('Error well metadata', e);
    }
  };
};

export const checkOldWellsAndStoreToCloud = (
  openModalToSyncWells,
  setOldWellNames,
  afterSyncWells
) => {
  return async (dispatch) => {
    const wells = await findOnIndexDB('wells');
    // const oldWells = Object.keys(wells || {})
    //   .filter(wellKey => wells[wellKey] && !wells[wellKey].pathFile);
    if (wells && wells.length) {
      openModalToSyncWells(true);
      setOldWellNames(wells.map(w => w.wellName));
      try {
        await setOnIndexDB('wells', {});
        afterSyncWells();
        dispatch(receiveWells([]));
        dispatch(listWells());
      } catch(e) {
        console.log('error when try to sync wells', e);
      }
    }
  };
};

export const listWell = (wellId, token, afterSuccess) => {
  return (async (dispatch, getState) => {
    const state = getState().wells;
    try {
      const { data: { content: wellData } } = await getWellData(wellId);

      // TODO change this when list well from API
      const wellWithZone = {
        ...wellData,
      };
      const formattedWell = getFormattedWell(wellWithZone);
      const scales = getWellSiteScales(formattedWell);
      let wellSiteWithScales = {
        ...formattedWell,
        scales
      };

      const wellWithMetadata = state.currentWell;
      if (wellData?.log_sets?.STATISTICS && wellWithMetadata?.modules_parameters?.STATISTICS) {
        // go through each statistics data until get data related with each mnemonic
        // should return a object with
        // { criticalAreasC1: [], criticalAreasC2: [] etc... }
        const curvesNames = statisticsCurvesNames;
        // see in currentWell?.modules_parameters?.STATISTICS?.settings
        // should return ['C1  MAIN', 'C2  MAIN']
        const mnemonics = wellWithMetadata?.modules_parameters?.STATISTICS?.settings ? Object.keys(
          wellWithMetadata?.modules_parameters?.STATISTICS?.settings
        ) : [];
        const mountingCriticalAreas = wellWithMetadata?.log_sets?.STATISTICS?.data?.reduce((acc, item) => {
          // c1_diff
          const currentType = defaultCriticalTypes.find(type => type === item?.type);
          if (currentType && item?.type) {
            // transform c1_diff to C1
            const curveName = item?.type?.split('_')?.[0].toUpperCase();

            // accessing all curves settings
            const curvesSettings = wellWithMetadata?.modules_parameters?.STATISTICS?.settings;

            //  find current mnemonic to access via settings
            const currentMnemonic = mnemonics.find(c => c.includes(curveName));

            // accessing settings to see critical_value
            const settingMnemonicCurve = wellWithMetadata?.modules_parameters?.STATISTICS?.settings?.[currentMnemonic];
            // create the lines to mark selectedDepth
            const criticalAreaValues = item?.data?.reduce((acc, criticalArea, index) => {
              acc.push({
                depthIndex: index,
                criticalAreaValue: criticalArea,
                shouldShow: criticalArea >= settingMnemonicCurve.critical_value,
                color: '#FF2727',
                curveName
              });
              return acc;
            }, []);
            acc[`criticalAreas${curveName}`] = criticalAreaValues;
          }
          return acc;
        }, {});
        wellSiteWithScales = {...wellSiteWithScales, ...mountingCriticalAreas };
      }
      dispatch(receiveWell(wellSiteWithScales));

      if (afterSuccess && typeof afterSuccess === 'function') {
        afterSuccess();
      }
      return wellSiteWithScales;
    } catch(e) {
      console.log(e)
    }
    // try {
    //   // wells files
    //   const { data: { files } } = await fetchWellSite(wellId, token);

    //   // --------------- fetch calculated data ----------------
    //   const calculatedFileName = files && files[0] ? files[0].file_name : '';
    //   if (!files.length) {
    //     alert('There are no data for this well');
    //     return;
    //   }

    //   const responseCalculatedData = await fetchBucketFile(calculatedFileName, token);
    //   const calculatedData = await getFileToParse(
    //     responseCalculatedData.data.signed_url,
    //     token
    //   );

    //   // --------------- fetch rawData ----------------
    //   const rawDataFileName = files && files[1] ? files[1].file_name : '';
    //   const responseRawData = await fetchBucketFile(rawDataFileName, token);
    //   const rawData = await getFileToParse(
    //     responseRawData.data.signed_url
    //   );

    //   const well = {
    //     ...rawData,
    //     ...calculatedData,
    //     wellId,
    //     rawFileName: rawDataFileName,
    //     calculatedFileName,
    //     rawData,
    //     calculatedData
    //   };
    //   const selectedWell = getState().wells.currentWell;
    //   GlobalCutOff.registerDefaultData({ ...calculatedData, ...selectedWell, ...rawData, ...well });
    //   dispatch(receiveWell(well));
    //   // afterSuccess && afterSuccess();
    //   if (afterSuccess) {
    //     afterSuccess();
    //   }
    //   // map(response.zones, (zone) => {
    //   //   removeAllZones(zone.id);
    //   // });
    // } catch (e) {
    //   console.log('Error list well', e);
    // }
  })
};

export const removeWell = (token, wellId) => {
  return async (dispatch) => {
    try {
      const response = await deleteWell(token, wellId);
      dispatch(removeCurrentWell({ wellId }));
      dispatch(listWells());
      return response;
    } catch (e) {
      console.log('error remove well', e);
    }
  };
};

export const receiveStatistics = (statistics) => ({
  type: actions.RECEIVE_STATISTICS,
  statistics
});

export const receiveCriticalAreas = (criticalAreas) => ({
  type: actions.RECEIVE_CRITICAL_AREAS,
  criticalAreas
});
