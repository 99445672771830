import React, { useEffect, useRef, useState } from 'react';

import { InputNumber, Slider, Typography } from 'antd';
import { findMinMaxDepth } from 'lib/Well';
import uniq from 'lodash.uniq';
import { connect } from 'react-redux';

import MultiSelect from '../MultiSelect';
import { useStatisticWizard } from './useStatisticsWizard';
import AlertWarning from '../AlertWarning';
import { set } from 'store';

const { Paragraph } = Typography;

const FirstStep = ({
  values,
  errorMessage,
  errorMultipleSelect,
  onSelectCurves,
  wells,
  listOfCurvesCalculated,
  settingsCurve,
  listOfDefaultCurves
}) => {
  const { rangeMin, rangeMax, setRangeInterval, setCurves, currentWell } =
    useStatisticWizard();
  const sliderRef = useRef();

  // just to set defaultValue not value
  const [rangeValue, setRangeValue] = useState([0, 0]);
  const [topValue, setTopValue] = useState(0);
  const [bottomValue, setBottomValue] = useState(0);
  const [minDepth, setRangeMinDepth] = useState(0);
  const [maxDepth, setRangeMaxDepth] = useState(0);
  const [dirty, setDirty] = useState(false);
  
  const onChangeSlider = value => {
    if (value[0] !== value[1]) {
      setDirty(true);
      setRangeValue(value);
      setRangeInterval(value);
      setTopValue(value[0]);
      setBottomValue(value[1]);
    }
  };

  const onChangeTopValue = value => {
    setTopValue(value);
    setRangeValue([value, bottomValue]);
    setDirty(true);
  };

  const onChangeBottomValue = value => {
    setBottomValue(value);
    setRangeValue([topValue, value]);
    setDirty(true);
  };

  useEffect(() => {
    if (currentWell) {
      const { start_depth: startDepth, stop_depth: stopDepth } = currentWell;

      setRangeMinDepth(startDepth);
      setRangeMaxDepth(stopDepth);
      setDirty(true);

      if (!rangeValue[0] && !rangeValue[1]) {
        setRangeValue([startDepth, stopDepth]);
        setTopValue(startDepth);
        setBottomValue(stopDepth);
      }

      currentWell.statistics &&
        currentWell.statistics.curves &&
        setCurves(
          uniq(currentWell.statistics.curves.map(c => c.toUpperCase()))
        );
      // setRangeInterval([minDepth, maxDepth]);
      // setRangeTop(minDepth);
      // setRangeBottom(maxDepth);
    }
  }, [currentWell]);

  useEffect(() => {
    const [top, bottom] = rangeValue;
    if (top && bottom && !dirty) {
      setRangeValue([topValue, bottomValue]);
      setTopValue(top);
      setBottomValue(bottom);
      sliderRef.current.forceUpdate();
    }
  }, [rangeValue, dirty]);

  useEffect(() => {
    if (wells && wells.currentWell && wells.currentWell.statistics) {
      if (
        wells.currentWell.statistics.rangeMin &&
        wells.currentWell.statistics.rangeMax
      ) {
        setRangeValue([
          wells.currentWell.statistics.rangeMin,
          wells.currentWell.statistics.rangeMax
        ]);
      }
    }
  }, [wells]);

  return (
    <>
      <div className="statistic-modal-curves-first__content">
        {listOfCurvesCalculated && (
          <div className="container-alert-curves">
            <div className="alert-curves">
              <AlertWarning curves={listOfCurvesCalculated} />
            </div>
          </div>
        )}
        <div className="container-conflict-error">
          {errorMultipleSelect ? errorMessage : ''}
        </div>
        <div className="container-multiselect">
          <div>
            <Paragraph className="statistic-modal__label">
              Selected Gas Curves
            </Paragraph>
            <MultiSelect
              onSelectCurves={onSelectCurves}
              listOfCurvesCalculated={listOfCurvesCalculated}
              listOfDefaultCurves={listOfDefaultCurves}
              values={values}
            />
          </div>
          <div className="statistic-modal-curve__top-bottom-container">
            <Paragraph className="statistic-modal__label">
              Selected Interval
            </Paragraph>
            <div>
              <Slider
                ref={sliderRef}
                tooltipVisible
                range
                tipFormatter={value => value?.toFixed(2)}
                step={1}
                value={rangeValue}
                min={minDepth}
                max={maxDepth}
                onChange={onChangeSlider}
                className='statistic-modal__slider'
              />
              <div className='statistic-modal__slider-inputs-wrapper'>
                <div>
                  <p>Top</p>
                  <InputNumber 
                    placeholder='Ex:. 100' 
                    value={topValue} 
                    onChange={onChangeTopValue}
                    className='statistic-modal__slider-inputs'
                  />
                </div>
                <div>
                  <p>Base</p>
                  <InputNumber 
                    placeholder='Ex:. 100' 
                    value={bottomValue}
                    onChange={onChangeBottomValue}
                    className='statistic-modal__slider-inputs'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ wells }) => ({
  wells
});

export default connect(mapStateToProps)(FirstStep);
