import React, { ReactNode } from 'react';
import ReactModal from 'react-modal';

import './styles.scss';

interface ModalProps {
  children: ReactNode,
  isOpen: boolean
}

export const Modal = ({ children, isOpen }: ModalProps) => {
  return (
    <ReactModal
      isOpen={isOpen}
      className="content_modal"
      overlayClassName="overlay_modal"
    >
      {children}
    </ReactModal>
  )
}
