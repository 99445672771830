/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
import './style.scss';

import React, {
  useEffect,
  useState
} from 'react';

import * as wellActions from 'actions/wells';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import Ui from '@geowellex/shared-ui'
import {
  connect,
  useDispatch
} from 'react-redux';
import { bindActionCreators } from 'redux';
import store from 'store';
import { get } from 'lodash';

import { receiveSelectedDepthIndex } from '../../../actions/wells';
import {
  defaultCrossHair,
  disableDefaultZoom,
  displayZoomBtn,
  findCriticalAreas,
  getToolTipValues,
  getUnit,
  propagateZoom,
  syncronizeTooltip,
  getForm
} from '../../../utils';
import { DEFAULT_SCALES } from '../../../utils/constants';


Exporting(Highcharts);

const GRAY = 'rgba(0, 0, 0, 0)';

const updatedHeader = {
  scales: store.get('configScales') || DEFAULT_SCALES,
  selectedDepth: 0,
  unit: getUnit()
};

const initialHeader = {
  selectedDepth: 0,
  unit: getUnit()
};


const HeaderChart = React.memo(({ unit = '', criticalSelected, selectedDepthIndex, labels }) => {
  const dispatch = useDispatch();

  const configScales = store.get('configScales');
  // --- Intital State
  const [headerState, setHeaderState] = useState({
    ...initialHeader,
    scales: configScales || DEFAULT_SCALES
  });

  useEffect(() => {
    setHeaderState({
      scales: configScales
    });

    // TODO leo needs replace it using observer pattern
    const selectedDepthListener = ({ detail }) => {
      const { depth } = detail;
      if (depth) {
        updatedHeader.selectedDepth = depth;
        setHeaderState({
          ...headerState,
          ...updatedHeader
        });
      }
    };
    document.addEventListener('selected-depth', selectedDepthListener);

    const tooltipListener = ({ detail }) => {
      const { tooltipValues } = detail;
      if (tooltipValues && tooltipValues.selectedDepth) {
        const { x, y } = tooltipValues.selectedDepth;
        const currentDepthValue = y;
        const currentDepthIndex = x;
        dispatch(receiveSelectedDepthIndex(currentDepthIndex));
        updatedHeader.selectedDepth = currentDepthValue;

        setHeaderState({
          ...headerState,
          ...updatedHeader
        });
      }
    };
    document.addEventListener('tooltip-values', tooltipListener);

    return () => {
      document.removeEventListener('tooltip-values', tooltipListener);
      document.removeEventListener('selected-depth', selectedDepthListener);
    };
  }, []);

  useEffect(() => {
    setHeaderState({
      unit
    });
  }, [unit]);

  const { selectedDepth } = headerState;
  let criticalCurvesHeader = null;

  const isQualityControl = window?.location?.href?.includes('quality-control');


  if (criticalSelected && selectedDepthIndex >= 0 && criticalSelected[selectedDepthIndex] &&
    !isQualityControl && !labels
  ) {
    const criticalValues = criticalSelected[selectedDepthIndex];
    if (criticalValues.length > 0) {
      criticalCurvesHeader = criticalValues.reduce((acc, criticalValue) => {
        acc += `${criticalValue.curveName.includes('Composition')
          ? criticalValue.curveName.replace('Composition', '%') : criticalValue.curveName}, `;
        return acc;
      }, '');
      criticalCurvesHeader = `Critical points to: ${criticalCurvesHeader.slice(0, criticalCurvesHeader.length - 2)}`;
    }
  } else if (labels && labels?.data?.length && labels?.data?.[selectedDepthIndex]) {
    const label = labels?.data?.[selectedDepthIndex];
    criticalCurvesHeader = `Critical points to: ${label}`?.replaceAll('  MAIN', '');
  }

  return (
    <div className="charts-header__selected-depth">
      <div style={{ marginBottom: 20 }}>
        {criticalCurvesHeader && !isQualityControl
          ? <div style={{ width: 80, height: 55, border: 'solid 1px red', textAlign: 'center', color: 'red', marginTop: 15, borderRadius: 4 }}>
            <span style={{ textAlign: 'center'}}>
              <div style={{ fontSize: 10 }}>
                <div>{criticalCurvesHeader}</div>
              </div>
            </span>
          </div>
          : ''}
      </div>
      <Ui.Header
        textCenter={selectedDepth ? `${selectedDepth} (${unit})` : `Selected Depth (${unit})`}
        background='#515775'
      />
    </div>
  );
});

const getDefaultSerie = (data) => ({
  name: '',
  inverted: true,
  lineWidth: 0,
  opacity: 0,
  strokeWidth: 0,
  color: 'rgba(0, 0, 0, 0)',
  yAxis: 0,
  data,
  startOnTick: true,
  endOnTick: true,
  allowDecimals: true,
  tickPosition: 'inside',
  tickColor: '#000',
  marker: {
    enabled: false
  },
  dashStyle: ' '
});

class SelectedDepth extends React.Component {
  constructor(props) {
    super(props);
    this.chart = null;

    this.state = {
      data: [],
      selectedId: 0,
      plotBands: [],
      scatters: []
    };
  }

  componentDidMount() {
    const { id } = this.props;
    this.createSeriesChart();

    syncronizeTooltip(`#${id}`);
  }

  shouldComponentUpdate(nextProps) {
    const { currentWell, wells, updateChart, finishUpdate, selectedWell } = this.props;
    if (updateChart || selectedWell === -1) {
      this.chart.update({
        series: [getDefaultSerie(nextProps?.data ?? [])]
      });

      const { min, max } = nextProps;

      this.chart.xAxis[0].removePlotBandOrLine();
      this.zoomId = setTimeout(() => {
        this.chart.xAxis[0].setExtremes(min, max);
      }, 1000);
      if (finishUpdate && typeof finishUpdate === 'function') {
        finishUpdate();
      }
      return true;
    }

    if (currentWell !== nextProps.selectedWell && this.chart && this.chart.xAxis && this.chart.xAxis[0]) {
      this.chart.xAxis[0].removePlotBandOrLine();
      this.chart.xAxis[1].removePlotBandOrLine();
      this.chart.redraw();
    }

    const isQualityControl = window?.location?.href?.includes('quality-control');


    // gqr
    const nextCriticalAreasGQR = wells && wells.currentWell
      && wells.currentWell.statistics !== null
      && getForm(wells.currentWell.statistics, 'formCollapseGQR');

    if (
      nextCriticalAreasGQR && nextCriticalAreasGQR.criticalAreasGQR &&
      nextCriticalAreasGQR.criticalAreasGQR.length && !isQualityControl
    ) {
      (nextCriticalAreasGQR.criticalAreasGQR || []).forEach((criticalAreaItem) => {
        if (criticalAreaItem.shouldShow) {
          const indexLine = parseInt(criticalAreaItem.depthIndex);
          const line = {
            color: criticalAreaItem.color,
            width: 0.5,
            height: 50,
            value: indexLine,
            dashStyle: 'line',
            zIndex: 1,
            opacity: 0.5
          };
          this.chart.xAxis[0].addPlotLine(line);
        }
      });
      return true;
    }

    // C1
    const nextCriticalAreasC1 = wells && wells.currentWell
      && wells.currentWell.statistics !== null
      && getForm(wells.currentWell.statistics, 'formCollapseC1') ||
        (wells?.currentWell?.criticalAreasC1 ? { criticalAreasC1: wells?.currentWell?.criticalAreasC1 } : undefined);

    if (
      nextCriticalAreasC1 && nextCriticalAreasC1.criticalAreasC1 &&
      nextCriticalAreasC1.criticalAreasC1.length && !isQualityControl
    ) {
      (nextCriticalAreasC1.criticalAreasC1 || []).forEach((criticalAreaItem) => {
        if (criticalAreaItem.shouldShow) {
          const indexLine = parseInt(criticalAreaItem.depthIndex);
          const line = {
            color: criticalAreaItem.color,
            width: 0.5,
            height: 50,
            value: indexLine,
            dashStyle: 'line',
            zIndex: 1,
            opacity: 0.5
          };
          this.chart.xAxis[0].addPlotLine(line);
        }
      });
      return true;
    }

    // C2
    const nextCriticalAreasC2 = wells && wells.currentWell
      && wells.currentWell.statistics !== null
      && getForm(wells.currentWell.statistics, 'formCollapseC2') ||
      (wells?.currentWell?.criticalAreasC2 ? { criticalAreasC2: wells?.currentWell?.criticalAreasC2 } : undefined);

    if (
      nextCriticalAreasC2 && nextCriticalAreasC2.criticalAreasC2
      && nextCriticalAreasC2.criticalAreasC2.length !==
      nextProps && nextProps.criticalAreasC2 && nextProps.criticalAreasC2.length
      && !isQualityControl
    ) {
      (nextCriticalAreasC2.criticalAreasC2 || []).forEach((criticalAreaItem) => {
        if (criticalAreaItem.shouldShow) {
          const indexLine = parseInt(criticalAreaItem.depthIndex);

          const line = {
            color: criticalAreaItem.color,
            width: 0.5,
            height: 50,
            value: indexLine,
            dashStyle: 'line',
            zIndex: 1,
            opacity: 0.5
          };

          this.chart.xAxis[0].addPlotLine(line);
        }
      });
      return true;
    }

    // C3
    const nextCriticalAreasC3 = wells && wells.currentWell && wells.currentWell.statistics
    && wells.currentWell.statistics !== null
    && getForm(wells.currentWell.statistics, 'formCollapseC3') ||
      (wells?.currentWell?.criticalAreasC3 ? { criticalAreasC3: wells?.currentWell?.criticalAreasC3 } : undefined);

    if (
      nextCriticalAreasC3 && nextCriticalAreasC3.criticalAreasC3 && nextCriticalAreasC3.criticalAreasC3.length !==
    nextProps && nextProps.criticalAreasC3 && nextProps.criticalAreasC3.length
    && !isQualityControl
    ) {
      (nextCriticalAreasC3.criticalAreasC3 || []).forEach((criticalAreaItem) => {
        if (criticalAreaItem.shouldShow) {
          const indexLine = parseInt(criticalAreaItem.depthIndex);

          const line = {
            color: criticalAreaItem.color,
            width: 0.5,
            height: 50,
            value: indexLine,
            dashStyle: 'line',
            zIndex: 1,
            opacity: 0.5
          };

          this.chart.xAxis[0].addPlotLine(line);
        }
      });
      return true;
    }

    // C4
    const nextCriticalAreasC4 = wells && wells.currentWell
    && wells.currentWell.statistics !== null
    && getForm(wells.currentWell.statistics, 'formCollapseC4') ||
    (wells?.currentWell?.criticalAreasC4 ? { criticalAreasC4: wells?.currentWell?.criticalAreasC4 } : undefined);

    if (
      nextCriticalAreasC4 && nextCriticalAreasC4.criticalAreasC4
      && nextCriticalAreasC4.criticalAreasC4.length !==
      nextProps && nextProps.criticalAreasC4 && nextProps.criticalAreasC4.length
      && !isQualityControl
    ) {
      (nextCriticalAreasC4.criticalAreasC4 || []).forEach((criticalAreaItem) => {
        if (criticalAreaItem.shouldShow) {
          const indexLine = parseInt(criticalAreaItem.depthIndex);

          const line = {
            color: criticalAreaItem.color,
            width: 0.5,
            height: 50,
            value: indexLine,
            dashStyle: 'line',
            zIndex: 1,
            opacity: 0.5
          };

          this.chart.xAxis[0].addPlotLine(line);
        }
      });
      return true;
    }

    // C5
    const nextCriticalAreasC5 = wells && wells.currentWell && wells.currentWell.statistics
      && wells.currentWell.statistics !== null
      && getForm(wells.currentWell.statistics, 'formCollapseC5') ||
      (wells?.currentWell?.criticalAreasC5 ? { criticalAreasC5: wells?.currentWell?.criticalAreasC5 } : undefined);

    if (
      nextCriticalAreasC5 && nextCriticalAreasC5.criticalAreasC5 && nextCriticalAreasC5.criticalAreasC5.length !==
      nextProps && nextProps.criticalAreasC5 && nextProps.criticalAreasC5.length
      && !isQualityControl
    ) {
      (nextCriticalAreasC5.criticalAreasC5 || []).forEach((criticalAreaItem) => {
        if (criticalAreaItem.shouldShow) {
          const indexLine = parseInt(criticalAreaItem.depthIndex);

          const line = {
            color: criticalAreaItem.color,
            width: 0.5,
            height: 50,
            value: indexLine,
            dashStyle: 'line',
            zIndex: 1,
            opacity: 0.5
          };

          this.chart.xAxis[0].addPlotLine(line);
        }
      });
      return true;
    }

    // IC4
    const nextCriticalAreasIC4 = wells && wells.currentWell && wells.currentWell.statistics
      && wells.currentWell.statistics !== null
      && getForm(wells.currentWell.statistics, 'formCollapseIC4') ||
      (wells?.currentWell?.criticalAreasIC4 ? { criticalAreasIC4: wells?.currentWell?.criticalAreasIC4 } : undefined);

    if (
      nextCriticalAreasIC4 && nextCriticalAreasIC4.criticalAreasIC4 && nextCriticalAreasIC4.criticalAreasIC4.length !==
      nextProps && nextProps.criticalAreasIC4 && nextProps.criticalAreasIC4.length
      && !isQualityControl
    ) {
      (nextCriticalAreasIC4.criticalAreasIC4 || []).forEach((criticalAreaItem) => {
        if (criticalAreaItem.shouldShow) {
          const indexLine = parseInt(criticalAreaItem.depthIndex);

          const line = {
            color: criticalAreaItem.color,
            width: 0.5,
            height: 50,
            value: indexLine,
            dashStyle: 'line',
            zIndex: 1,
            opacity: 0.5
          };

          this.chart.xAxis[0].addPlotLine(line);
        }
      });
      return true;
    }

    // IC5
    const nextCriticalAreasIC5 = wells && wells.currentWell && wells.currentWell.statistics
      && wells.currentWell.statistics !== null
      && getForm(wells.currentWell.statistics, 'formCollapseIC5') ||
      (wells?.currentWell?.criticalAreasIC5 ? { criticalAreasIC5: wells?.currentWell?.criticalAreasIC5 } : undefined);

    if (
      nextCriticalAreasIC5 && nextCriticalAreasIC5.criticalAreasIC5 && nextCriticalAreasIC5.criticalAreasIC5.length !==
      nextProps && nextProps.criticalAreasIC5 && nextProps.criticalAreasIC5.length
      && !isQualityControl
    ) {
      (nextCriticalAreasIC5.criticalAreasIC5 || []).forEach((criticalAreaItem) => {
        if (criticalAreaItem.shouldShow) {
          const indexLine = parseInt(criticalAreaItem.depthIndex);

          const line = {
            color: criticalAreaItem.color,
            width: 0.5,
            height: 50,
            value: indexLine,
            dashStyle: 'line',
            zIndex: 1,
            opacity: 0.5
          };

          this.chart.xAxis[0].addPlotLine(line);
        }
      });
      return true;
    }

    // C1Compoosition
    const nextCriticalAreasC1Composition = wells && wells.currentWell && wells.currentWell.statistics
      && wells.currentWell.statistics !== null
      && getForm(wells.currentWell.statistics, 'formCollapseC1Composition');

    if (
      nextCriticalAreasC1Composition &&
      nextCriticalAreasC1Composition.criticalAreasC1Composition &&
      nextCriticalAreasC1Composition.criticalAreasC1Composition.length !==
      (nextProps &&
      nextProps.criticalAreasC1Composition &&
      nextProps.criticalAreasC1Composition.length) || 0
      && !isQualityControl
    ) {
      (nextCriticalAreasC1Composition.criticalAreasC1Composition || []).forEach((criticalAreaItem) => {
        if (criticalAreaItem.shouldShow) {
          const indexLine = parseInt(criticalAreaItem.depthIndex);

          const line = {
            color: criticalAreaItem.color,
            width: 0.5,
            height: 50,
            value: indexLine,
            dashStyle: 'line',
            zIndex: 1,
            opacity: 0.5
          };

          this.chart.xAxis[0].addPlotLine(line);
        }
      });
      return true;
    }

    // C2 Compoosition
    const criticalAreasC2Composition = wells && wells.currentWell
      && wells.currentWell.statistics !== null
      && getForm(wells.currentWell.statistics, 'formCollapseC2Composition');

    const nextCriticalAreasC2Composition = nextProps.wells && nextProps.wells.currentWell
      && nextProps.wells.currentWell.statistics !== null
      && getForm(nextProps.wells.currentWell.statistics, 'formCollapseC2Composition');
    if (
      criticalAreasC2Composition !== nextCriticalAreasC2Composition
      && !isQualityControl
    ) {
      (nextCriticalAreasC2Composition?.criticalAreasC2Composition || []).forEach((criticalAreaItem) => {
        if (criticalAreaItem.shouldShow) {
          const indexLine = parseInt(criticalAreaItem.depthIndex);
          const line = {
            color: criticalAreaItem.color,
            width: 0.5,
            height: 50,
            value: indexLine,
            dashStyle: 'line',
            zIndex: 1,
            opacity: 0.5
          };
          this.chart.xAxis[0].addPlotLine(line);
          this.chart.redraw();
        }
      });
      return true;
    }

    // C3 Compoosition
    const nextCriticalAreasC3Composition = wells && wells.currentWell && wells.currentWell.statistics
    && wells.currentWell.statistics !== null
    && getForm(wells.currentWell.statistics, 'formCollapseC3Composition');

    if (
      nextCriticalAreasC3Composition &&
      nextCriticalAreasC3Composition.criticalAreasC3Composition &&
      nextCriticalAreasC3Composition.criticalAreasC3Composition.length !==
      (nextProps &&
      nextProps.criticalAreasC3Composition &&
      nextProps.criticalAreasC3Composition.length) || 0
      && !isQualityControl
    ) {
      (nextCriticalAreasC3Composition.criticalAreasC3Composition || []).forEach((criticalAreaItem) => {
        if (criticalAreaItem.shouldShow) {
          const indexLine = parseInt(criticalAreaItem.depthIndex);

          const line = {
            color: criticalAreaItem.color,
            width: 0.5,
            height: 50,
            value: indexLine,
            dashStyle: 'line',
            zIndex: 1,
            opacity: 0.5
          };

          this.chart.xAxis[0].addPlotLine(line);
        }
      });
      return true;
    }

    // C4 Compoosition
    const nextCriticalAreasC4Composition = wells && wells.currentWell && wells.currentWell.statistics
    && wells.currentWell.statistics !== null
    && getForm(wells.currentWell.statistics, 'formCollapseC4Composition');

    if (
      nextCriticalAreasC4Composition &&
      nextCriticalAreasC4Composition.criticalAreasC4Composition &&
      nextCriticalAreasC4Composition.criticalAreasC4Composition.length !==
      (nextProps &&
      nextProps.criticalAreasC4Composition &&
      nextProps.criticalAreasC4Composition.length) || 0
      && !isQualityControl
    ) {
      (nextCriticalAreasC4Composition.criticalAreasC4Composition || []).forEach((criticalAreaItem) => {
        if (criticalAreaItem.shouldShow) {
          const indexLine = parseInt(criticalAreaItem.depthIndex);

          const line = {
            color: criticalAreaItem.color,
            width: 0.5,
            height: 50,
            value: indexLine,
            dashStyle: 'line',
            zIndex: 1,
            opacity: 0.5
          };

          this.chart.xAxis[0].addPlotLine(line);
        }
      });
      return true;
    }

    // C5 Compoosition
    const nextCriticalAreasC5Composition = wells && wells.currentWell && wells.currentWell.statistics
    && wells.currentWell.statistics !== null
    && getForm(wells.currentWell.statistics, 'formCollapseC5Composition');

    if (
      nextCriticalAreasC5Composition &&
      nextCriticalAreasC5Composition.criticalAreasC5Composition &&
      nextCriticalAreasC5Composition.criticalAreasC5Composition.length !==
      (nextProps &&
      nextProps.criticalAreasC5Composition &&
      nextProps.criticalAreasC5Composition.length) || 0
      && !isQualityControl
    ) {
      (nextCriticalAreasC5Composition.criticalAreasC5Composition || []).forEach((criticalAreaItem) => {
        if (criticalAreaItem.shouldShow) {
          const indexLine = parseInt(criticalAreaItem.depthIndex);

          const line = {
            color: criticalAreaItem.color,
            width: 0.5,
            height: 50,
            value: indexLine,
            dashStyle: 'line',
            zIndex: 1,
            opacity: 0.5
          };

          this.chart.xAxis[0].addPlotLine(line);
        }
      });
      return true;
    }

    return false;
  }

  componentWillUnmount() {
    // eslint-disable-next-line no-unused-expressions
    this.zoomId && clearTimeout(this.zoomId);
  }

  createSeriesChart = () => {
    const { id } = this.props;
    this.chart = Highcharts.chart(id, {
      chart: {
        zoomType: 'x',
        type: 'spline',
        inverted: true,
        width: 120,
        alignTicks: false,
        marginLeft: 0,
        margin: [4, 0, 2, 0],
        plotBackgroundColor: '#151724',
        backgroundColor: '#151724',
        events: {
          selection: (event) => {
            displayZoomBtn();
            propagateZoom(event);
          }
        },
        ...disableDefaultZoom
      },
      plotOptions: {
        series: {
          showInLegend: false,
          color: '#eeeeee',
          enableMouseTracking: false,
          marker: {
            states: {
              hover: {
                enabled: false
              }
            }
          }
        }
      },
      title: ' ',
      subtitle: {
        text: null
      },
      credits: {
        enabled: false
      },
      exporting: { enabled: false },
      xAxis: [
        {
          minorGridLineWidth: 0,
          minorTickLength: 0,
          minorTickWidth: 0,
          minPadding: 0,
          maxPadding: 0,
          offset: 8,
          opposite: true,
          tickPosition: 'inside',
          tickColor: GRAY,
          tickWidth: 0,
          lineColor: GRAY,
          lineWidth: 0,
          labels: {
            x: -120,
            y: 40,
            align: 'left',
            style: {
              color: '#fff',
            },
            formatter: currentItem => {
              const value = this.props?.data && this.props?.data[currentItem.value];
              return value;
              // return value ? Math.trunc(value) : '';
            }
          },
          allowDecimals: true,
          gridLineColor: '#333',
        },
        {
          tickInterval: 20,
          tickPosition: 'outinside',
          tickColor: GRAY,
          tickWidth: 0,
          offset: 5,
          lineColor: GRAY,
          left: 92,
          lineWidth: 0,
          gridLineWidth: 0,
          labels: {
            x: -10,
            y: 4,
            align: 'right',
            style: {
              fontSize: 10,
              color: '#FDFDFD',
            },
            formatter: currentItem => {
              const value = this.props?.data && this.props?.data[currentItem.value];
              return value ? value : '';
            }
          },
          linkedTo: 0,
          allowDecimals: true
        },
      ],
      yAxis: [
        {
          min: 0,
          gridLineColor: GRAY,
          lineWidth: 0,
          gridLineWidth: 0,
          opacity: 0,
          style: {
            lineWidth: 0,
            gridLineWidth: 0,
            opacity: 0,
          },
          labels: {
            format: ' ',
            style: {
              color: Highcharts.getOptions().colors[2]
            }
          },
          gridLineColor: 'rgba(0, 0, 0, 0)',
          gridLineWidth: '0',
          gridLineDashStyle: 'Dash',
          title: {
            text: '',
            y: -10,
            style: {
              marginBottom: 10,
              color: GRAY
            }
          },
        },
      ],
      tooltip: {
        ...defaultCrossHair,
        shared: true,
        formatter: getToolTipValues('selected-depth')
      },
      legend: {
        layout: 'vertical',
        align: 'left',
        x: 60,
        verticalAlign: 'top',
        y: -5,
        floating: true,
      },
      series: [getDefaultSerie(this.props?.data)]
    });
  }

  render() {
    const { id, unit, wells } = this.props;

    const criticalAreasGQR = get(wells, 'currentWell.GQR', []);

    const criticalAreasC1 = get(wells, 'currentWell.criticalAreasC1', []);
    const criticalAreasC2 = get(wells, 'currentWell.criticalAreasC2', []);
    const criticalAreasC3 = get(wells, 'currentWell.criticalAreasC3', []);
    const criticalAreasNC4 = get(wells, 'currentWell.criticalAreasC4', []);
    const criticalAreasNC5 = get(wells, 'currentWell.criticalAreasC5', []);
    const criticalAreasIC4 = get(wells, 'currentWell.criticalAreasIC4', []);
    const criticalAreasIC5 = get(wells, 'currentWell.criticalAreasIC5', []);

    const criticalAreasC1Composition = get(wells.currentWell, 'criticalAreasC1Composition', []);
    const criticalAreasC2Composition = get(wells.currentWell, 'criticalAreasC2Composition', []);
    const criticalAreasC3Composition = get(wells.currentWell, 'criticalAreasC3Composition', []);
    const criticalAreasC4Composition = get(wells.currentWell, 'criticalAreasC4Composition', []);
    const criticalAreasC5Composition = get(wells.currentWell, 'criticalAreasC5Composition', []);

    const allDepth = wells && wells?.RAW?.depth?.data;

    const allCriticalValuesWithCurves = {};
    (allDepth || []).forEach((depthIndex, index) => {

      const currentGQRCriticalValue = criticalAreasGQR
        .find(criticalValueObject =>
          criticalValueObject.depthIndex === index && !!criticalValueObject.shouldShow
        );
      if (currentGQRCriticalValue) {
        if (allCriticalValuesWithCurves[index]) {
          allCriticalValuesWithCurves[index].push(currentGQRCriticalValue);
        } else {
          allCriticalValuesWithCurves[index] = [currentGQRCriticalValue];
        }
      }

      const currentC1CriticalValue = criticalAreasC1
        .find(criticalValueObject => {
          return criticalValueObject.depthIndex === index && !!criticalValueObject.shouldShow
        }

        );

      if (currentC1CriticalValue) {
        if (allCriticalValuesWithCurves[index]) {
          allCriticalValuesWithCurves[index].push(currentC1CriticalValue);
        } else {
          allCriticalValuesWithCurves[index] = [currentC1CriticalValue];
        }
      }

      const currentC2CriticalValue = criticalAreasC2
        .find(criticalValueObject => criticalValueObject.depthIndex === index && criticalValueObject.shouldShow);
      if (currentC2CriticalValue) {
        if (allCriticalValuesWithCurves[index]) {
          allCriticalValuesWithCurves[index]= [
            ...allCriticalValuesWithCurves[index],
            currentC2CriticalValue
          ];
        } else {
          allCriticalValuesWithCurves[index] = [currentC2CriticalValue];
        }
      }

      const currentC3CriticalValue = criticalAreasC3
        .find(criticalValueObject => criticalValueObject.depthIndex === index && criticalValueObject.shouldShow);
      if (currentC3CriticalValue) {
        if (allCriticalValuesWithCurves[index]) {
          allCriticalValuesWithCurves[index]= [
            ...allCriticalValuesWithCurves[index],
            currentC3CriticalValue
          ];
        } else {
          allCriticalValuesWithCurves[index] = [currentC3CriticalValue];
        }
      }

      const currentNC4CriticalValue = criticalAreasNC4
        .find(criticalValueObject => criticalValueObject.depthIndex === index && criticalValueObject.shouldShow);
      if (currentNC4CriticalValue) {
        if (allCriticalValuesWithCurves[index]) {
          allCriticalValuesWithCurves[index]= [
            ...allCriticalValuesWithCurves[index],
            currentNC4CriticalValue
          ];
        } else {
          allCriticalValuesWithCurves[index] = [currentNC4CriticalValue];
        }
      }

      const currentIC4CriticalValue = criticalAreasIC4
        .find(criticalValueObject => criticalValueObject.depthIndex === index && criticalValueObject.shouldShow);
      if (currentIC4CriticalValue) {
        if (allCriticalValuesWithCurves[index]) {
          allCriticalValuesWithCurves[index]= [
            ...allCriticalValuesWithCurves[index],
            currentIC4CriticalValue
          ];
        } else {
          allCriticalValuesWithCurves[index] = [currentIC4CriticalValue];
        }
      }

      const currentNC5CriticalValue = criticalAreasNC5
        .find(criticalValueObject => criticalValueObject.depthIndex === index && criticalValueObject.shouldShow);
      if (currentNC5CriticalValue) {
        if (allCriticalValuesWithCurves[index]) {
          allCriticalValuesWithCurves[index]= [
            ...allCriticalValuesWithCurves[index],
            currentNC5CriticalValue
          ];
        } else {
          allCriticalValuesWithCurves[index] = [currentNC5CriticalValue];
        }
      }

      const currentIC5CriticalValue = criticalAreasIC5
        .find(criticalValueObject => criticalValueObject.depthIndex === index && criticalValueObject.shouldShow);
      if (currentIC5CriticalValue) {
        if (allCriticalValuesWithCurves[index]) {
          allCriticalValuesWithCurves[index]= [
            ...allCriticalValuesWithCurves[index],
            currentIC5CriticalValue
          ];
        } else {
          allCriticalValuesWithCurves[index] = [currentIC5CriticalValue];
        }
      }

      const currentC1CompositionCriticalValue = criticalAreasC1Composition
        .find(criticalValueObject => criticalValueObject.depthIndex === index && criticalValueObject.shouldShow);
      if (currentC1CompositionCriticalValue) {
        if (allCriticalValuesWithCurves[index]) {
          allCriticalValuesWithCurves[index]= [
            ...allCriticalValuesWithCurves[index],
            currentC1CompositionCriticalValue
          ];
        } else {
          allCriticalValuesWithCurves[index] = [currentC1CompositionCriticalValue];
        }
      }

      const currentC2CompositionCriticalValue = criticalAreasC2Composition
        .find(criticalValueObject => criticalValueObject.depthIndex === index && criticalValueObject.shouldShow);
      if (currentC2CompositionCriticalValue) {
        if (allCriticalValuesWithCurves[index]) {
          allCriticalValuesWithCurves[index]= [
            ...allCriticalValuesWithCurves[index],
            currentC2CompositionCriticalValue
          ];
        } else {
          allCriticalValuesWithCurves[index] = [currentC2CompositionCriticalValue];
        }
      }

      const currentC3CompositionCriticalValue = criticalAreasC3Composition
        .find(criticalValueObject => criticalValueObject.depthIndex === index && criticalValueObject.shouldShow);
      if (currentC3CompositionCriticalValue) {
        if (allCriticalValuesWithCurves[index]) {
          allCriticalValuesWithCurves[index]= [
            ...allCriticalValuesWithCurves[index],
            currentC3CompositionCriticalValue
          ];
        } else {
          allCriticalValuesWithCurves[index] = [currentC3CompositionCriticalValue];
        }
      }

      const currentC4CompositionCriticalValue = criticalAreasC4Composition
        .find(criticalValueObject => criticalValueObject.depthIndex === index && criticalValueObject.shouldShow);
      if (currentC4CompositionCriticalValue) {
        if (allCriticalValuesWithCurves[index]) {
          allCriticalValuesWithCurves[index]= [
            ...allCriticalValuesWithCurves[index],
            currentC4CompositionCriticalValue
          ];
        } else {
          allCriticalValuesWithCurves[index] = [currentC4CompositionCriticalValue];
        }
      }

      const currentC5CompositionCriticalValue = criticalAreasC5Composition
        .find(criticalValueObject => criticalValueObject.depthIndex === index && criticalValueObject.shouldShow);
      if (currentC5CompositionCriticalValue) {
        if (allCriticalValuesWithCurves[index]) {
          allCriticalValuesWithCurves[index]= [
            ...allCriticalValuesWithCurves[index],
            currentC5CompositionCriticalValue
          ];
        } else {
          allCriticalValuesWithCurves[index] = [currentC5CompositionCriticalValue];
        }
      }

      // fill array to add depth that there is no criticalValue
      if (!currentC1CriticalValue && !currentC2CriticalValue
        && !currentC2CriticalValue && !currentNC4CriticalValue
        && !currentIC4CriticalValue && !currentNC5CriticalValue
        && !currentIC5CriticalValue && !currentC1CompositionCriticalValue
        && !currentC2CompositionCriticalValue && !currentC3CompositionCriticalValue
        && !currentC4CompositionCriticalValue && !currentC5CompositionCriticalValue
        && !currentGQRCriticalValue
      ) {
        allCriticalValuesWithCurves[index] = [];
      }
    });

    const labels = this?.props?.currentWell?.log_sets?.STATISTICS?.data?.find(item => item?.type === 'crit_labels');

    return (
      <div className='chart__box-container' style={{ marginLeft: 5 }}>
        <HeaderChart
          unit={unit}
          labels={labels}
          selectedDepthIndex={wells.selectedDepthIndex}
          criticalSelected={allCriticalValuesWithCurves}
        />
        <div
          id={id}
          style={{
            width: '88px',
            height: 'calc(100% - 180px)'
          }}
        />
      </div>
    );
  }
}

SelectedDepth.defaultProps = {
  zoomType: 'x',
  parentContainer: '.charts-container',
  id: 'selected-depth'
};

// export default React.memo(SelectedDepth);

const mapStateToProps = ({ zone, wells, ...props }) => ({
  zone,
  wells,
  ...props
});

const mapDispatchToProps = dispatch => ({
  wellAction: bindActionCreators(wellActions, dispatch),
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectedDepth);

