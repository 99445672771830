import styled from 'styled-components';

export const LoadWellContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background: transparent;
  border-radius: 8px;
  color: #fff;
  height: 100%;

`

export const LoadWellHeader = styled.div`
    display: flex;
    gap: 32px;
`;