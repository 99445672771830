import styled from "styled-components";
import { Menu } from "@mui/material";
import { customTheme } from "../../theme/default";
import CustomScrollBar from "../CustomScrollBar";

interface MenuStyleProps {
  width?: string;
}

export const MenuStyle = styled(Menu)<MenuStyleProps>`
  .MuiPaper-root {
    background-color: ${customTheme.blue3};
    color: ${customTheme.white};
    width: ${(props) => props.width ?? 'fit-content'};
    border-radius: 8px;

    > ul {
      display: flex;
      flex-direction: column;
      align-items: start;
    }
  }
`;

export const ScrollBar = styled(CustomScrollBar)`
  > div:first-child {
    position: static !important;
    max-height: 300px;
  }
`