export const getChartConfig = (customSeries, minY, maxY, chartType) => ({
  chart: {
    minWidth: 800,
    height: 500,
    backgroundColor: '#202438',
  },
  title: {
    text: ''
  },
  navigation: {
    buttonOptions: {
      enabled: false
    }
  },
  xAxis: {
    // crosshair: true,
    tickInterval: 1,
    accessibility: {
      rangeDescription: 'Range: 1 to 10'
    },
    gridLineColor: '#323752',
    labels: {
      style: {
        color: '#FDFDFD',
      },
    },
    categories: [
      '<b>C1</b>',
      '<b>C2</b>',
      '<b>C3</b>',
      '<b>C4</b>',
      '<b>C5</b>'
    ],
    lineColor: '#D5D5D6',
    lineWidth: 1
  },
  legend: {
    enabled: false
  },
  yAxis: [
    // {
    //   // allowDecimals: true,
    //   // type: 'linear',
    //   // type: 'logarithmic',
    //   min: 0.1,
    //   max: 100,
    //   title: {
    //     text: '<b style="font-size: 18px;">mol %</b>'
    //   },
    //   keys: ['name', 'custom.x', 'y', 'custom.stdv']
    // },
    {
      labels: {
        style: {
          color: '#FDFDFD',
        },
      },
      // labels: {
      //   enabled: false
      // },
      // allowDecimals: true,
      style: {
        color: '#FDFDFD',
      },
      type: chartType || 'logarithmic',
      title: {
        text: '<b style="font-size: 18px;">mol %</b>',
        style: {
          color: '#FDFDFD',
        }
      },
      keys: ['name', 'custom.x', 'y', 'custom.stdv'],
      min: minY || 0.1,
      max: maxY || 100,
      gridLineColor: '#323752',
      startOnTick: true,
		  endOnTick: true,
      tooltip: {
        enabled: false
      }
    }
  ],
  tooltip: {
    pointFormat: (
      '<b>{point.series.name}</b>: <br/><b>%mol:</b> {point.options.y} - <b>stdv:</b> {point.options.custom.stdv}'
    )
  },
  series: customSeries,
  credits: {
    enabled: false
  }
});
