import {
  RECEIVE_WELL_PHASES,
  RECEIVE_WELL_PHASE
} from '../constants/wellphases';

export const receiveWellPhases = (wellphases) => ({
  type: RECEIVE_WELL_PHASES,
  wellphases
});

export const receiveWellPhase = (wellphases) => ({
  type: RECEIVE_WELL_PHASE,
  wellphases
});

