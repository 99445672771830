import { useContext, useRef } from 'react';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import ImportWellButton from './ImportWellButton';
import ConfigContext from '@geowellex/shared-ui/src/context/ConfigContext';


export const ImportFromFile = () => {
    const { onChangeUpload, uploadFile } = useContext(ConfigContext);
    const uploadRef = useRef<HTMLInputElement>(null);

    return (
        <>
            <input
                id="lasFile"
                type="file"
                data-cy="upload-las"
                accept=".las, .txt, .csv"
                onChange={onChangeUpload}
                style={{ display: 'none' }}
                ref={uploadRef}
            />
            <ImportWellButton
                text="Import from File"
                onClick={() => {
                    uploadFile(uploadRef);
                }}
                icon={{ icon: <InsertDriveFileOutlinedIcon />, color: '#1D3195' }}
            />
        </>
    );
};

export default ImportFromFile;