import { styled } from '@mui/material/styles';
import { customTheme } from '../../../../theme/default'; 
import ListItemText from '@mui/material/ListItemText';

export const CustomLogLine = styled(ListItemText)<{ color: string }>(() => (({ color }) => `
  display: flex;
  color: ${customTheme.gray2}
  padding-left: 20px;
  padding-right: 10px;
  & svg > path {
    stroke: ${color};
  }
`));
