import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
import { customTheme } from '../../../../theme/default';
import Switch from '@mui/material/Switch';
import { MenuItemType } from '../../types';
import { LogSetType, LogCurve } from '../../../SelectLogSets/types';
import { Logo } from '../../components/Logo';
// import { ReactComponent as Flare } from '../../../../icons/flare.svg';
import { SelectWell }  from '../../../SelectWell';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { LogSetsTitle } from '../../components/LogSetsTitle';
import { SelectLogSets }  from '../../../SelectLogSets';
import { LogList } from '../../../SelectLogSets/components/LogList';
import CustomScrollBar from '../../../CustomScrollBar';
import Drawer from '@mui/material/Drawer';
import RealtimeSwitch from '@geowellex/shared-hooks/src/RealtimeSwitch';
import store from 'store';

import { ListItemButtonStyle, DrawerWrapper, MenuItem, ListStyle } from './styles';
import { ClosedDrawer } from './ClosedDrawer';

const DrawerHeader = styled(Box)(() => `
  display: flex;
  width: 246px;
  align-items: center;
  justify-content: space-between;
 `,
);

// const ThemeButton = styled(Button)(() => `
//   display: flex;
//   width: 32px;
//   height: 32px;
//   border: 1px solid ${customTheme.purple3};
//   border-radius: 6px;
//  `,
// );

const LogoWrapper = styled(Box)(() => ({}));

const LogoDescription = styled(Box)(() => ({
  fontSize: 16,
  color: customTheme.white,
  fontWeight: 700
}));

const ActionsBox = styled(Box)(() => `
  color: ${customTheme.gray1};
  margin-top: 30px;
  & .MuiFormControlLabel-root {
    color: ${customTheme.gray1};
  }
 `,
);

const CustomListItemIcon = styled(ListItemIcon)(() => `
  min-width: 31px;
`);

const SwitchButtonWrapper = styled(Box)(() => (() => `
  display: flex;
  align-items: center;
  margin-top: 2px;
  // overwrite to reset margin in labels
  & .MuiFormControlLabel-labelPlacementStart {
    margin: 0;
    // border: 2px solid red;
    width: 258px;
    justify-content: space-between;
  }
`
));

const MenuItemsWrapper = styled(Box)(() => (() => `
  margin-top: 10px;
`
));

const PurpleSwitch = styled(Switch)(() => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: customTheme.purple1,
    '&:hover': {
      opacity: 1,
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: customTheme.purple2,
  },
}));

const SwitchOpenCrossPlots = () => {
  const [isOpenCrossPlots, setOpenCrossPlots] = React.useState(store.get('crossPlots') || false);

  const generateCrossPlot = () => {
    const currentValue = !isOpenCrossPlots;
    store.set('crossPlots', currentValue);
    setOpenCrossPlots(currentValue);
  };

  return (
    <FormControlLabel
      value="start"
      control={
        <PurpleSwitch 
          color="primary"
          defaultChecked={isOpenCrossPlots}
          value={isOpenCrossPlots}
          onChange={generateCrossPlot}
        />
      }
      label="Generate Crossplots"
      labelPlacement="start"
    />
  );
};

type Props = {
  menuConfig: MenuItemType[];
  menuSelectedIndex: number;
  logSets?: LogSetType[];
  afterSelectLogSet: (logSet: string) => void;
  logSetCurves: LogCurve[];
  logo: string;
  description: string;
  enableCrossPlotsButton?: boolean;
  enableRealtimeButton?: boolean;
  isCloseDrawer: boolean,
  warningNotification?: (message: string) => void;
};

export const GenerateDrawer = ({
  menuConfig,
  menuSelectedIndex,
  logSets,
  afterSelectLogSet,
  logSetCurves,
  logo,
  description,
  isCloseDrawer,
  enableCrossPlotsButton = true,
  enableRealtimeButton = true,
  warningNotification,
}: Props) => {
  const [pathName, setPathName] = React.useState<string>('');

  React.useEffect(() => {
    if (!pathName || window?.location?.pathname !== pathName) {
      setPathName(window?.location?.pathname);
    }
  }, [window?.location?.pathname, pathName, setPathName]);

  return (
    <CustomScrollBar>
      <DrawerWrapper isCloseDrawer={isCloseDrawer}>
        {isCloseDrawer ? (
          <ClosedDrawer
            menuConfig={menuConfig}
            menuSelectedIndex={menuSelectedIndex}
            logSets={logSets}
            afterSelectLogSet={afterSelectLogSet}
            logSetCurves={logSetCurves}
            logo={logo}
          />
        ) : (
          <>
            <DrawerHeader>
              <LogoWrapper>
                <Logo>
                  {logo}
                </Logo>
                <LogoDescription>
                {description}
                </LogoDescription>
              </LogoWrapper>
              {/* 
              <ThemeButton>
                <Flare /> 
              </ThemeButton>
              */}
            </DrawerHeader>
            <ActionsBox>
              <SelectWell />
              <SwitchButtonWrapper>
                <FormGroup aria-label="position" row>
                  {enableCrossPlotsButton ? (
                    <SwitchOpenCrossPlots />
                  ): null}
                  {enableRealtimeButton ? (
                    <RealtimeSwitch notificationFunction={warningNotification}
                      render={(handleChange: any, switchValue: any) => {
                          return (
                            <FormControlLabel
                              value="start"
                              control={<PurpleSwitch
                                color="primary"
                                defaultChecked={!!switchValue}
                                value={!!switchValue}
                                checked={!!switchValue}
                                onChange={handleChange}
                              />}
                              label="Realtime Update"
                              labelPlacement="start"
                            />
                          );
                      }}
                    />
                  ): null}
                </FormGroup>
              </SwitchButtonWrapper>
            </ActionsBox>
            <MenuItemsWrapper>
              <ListStyle>
                {menuConfig.map((menuItem: any, index: number) => (
                  <ListItem
                    key={menuItem.text}
                    disablePadding
                    style={{ 
                      backgroundColor:
                      // check routes that are not the first
                      (menuItem?.route && pathName && pathName?.includes(menuItem?.route) && index !== 0)
                      // check first route prevent beeing checked when first is root with children home/statistics
                      || (index === 0 && !pathName)
                      || (index === 0 && pathName && menuConfig?.[0]?.route && !pathName.split('/')?.[2]) 
                        ? customTheme.purple5 : 'transparent',
                      padding: 0
                    }}
                  >
                    <ListItemButtonStyle
                      onClick={() => menuItem?.onClick(index)}
                      disabled={menuItem?.isDisabled}
                      selected={menuSelectedIndex === index}
                    >
                      <CustomListItemIcon>
                        {menuItem.icon}
                      </CustomListItemIcon>
                      <MenuItem
                        primary={menuItem.text}
                        selected={menuSelectedIndex === index}
                      />
                    </ListItemButtonStyle>
                  </ListItem>
                ))}
              </ListStyle>
            </MenuItemsWrapper>
            {logSets?.length !== 0 && (
              <>
                <LogSetsTitle>
                  Log sets
                </LogSetsTitle>
                <SelectLogSets
                  afterSelectLogSet={afterSelectLogSet}
                  logSets={logSets}
                  disabled={logSets?.length === 0}
                />
                <LogList logSets={logSetCurves}/>
              </>
            )}
          </>
        )}
      </DrawerWrapper>
    </CustomScrollBar>
  );
};

interface CustomDrawerProps {
  children: React.ReactNode;
  drawerWidth: number;
  isOpen?: boolean;
}

export const CustomDrawer = ({ children, drawerWidth, isOpen }: CustomDrawerProps) => {
  return (
    <Drawer
      variant='permanent'
      sx={{
        display: { xs: 'none', sm: 'block' },
        '& .MuiDrawer-paper': { 
          boxSizing: 'border-box',
          width: drawerWidth,
          transition: ' width 0.3s ease-out',
          background: customTheme.bg,
          overflow: !isOpen ? 'hidden' : 'visible',
          borderRight: `1px solid ${customTheme.purple3}`
        },
      }}
      open={isOpen}
    > 
      {children}
    </Drawer>
  );
};
