import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { receiveWell } from '../../../../actions/wells';
import { Input, Select } from 'antd';
import { Button } from '../Button';
import { CloseOutlined, CheckOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { IconButton } from '@material-ui/core';
import { defaultNotification } from '../../../../utils';
import { PostGqcPayload } from '../../../../api/statistics';
import { useSelector } from 'react-redux';
import {
  ContainerTitle, CloseButton, TitleText,
  ContainerBody, ContainerFooter, FlexContainerBody
} from '../styles';
import { Close as CloseIcon, DeleteOutline as DeleteOutlinedIcon } from '@material-ui/icons';
import Ui from '@geowellex/shared-ui'

const { Option } = Select;

const RemoveDataModal = ({ isModalOpen, currentWell, dispatch, close, setIntervals, setPayload, payload }) => {
  const wells = useSelector(state => state.wells);
  const depth = wells?.currentWell?.RAW?.depth?.data;
  const [rows, setRows] = useState([
    { id: 1, top: '', bottom: '' },
  ]);

  const closeModal = () => {
    close();
  };

  const addRow = () => {
    const newRowId = rows.length + 1;
    const newRow = { id: newRowId, top: '', bottom: '' };
    setRows([...rows, newRow]);
  };

  const updateRowField = (id, field, value) => {
    const updatedRows = rows.map(row =>
      row.id === id ? { ...row, [field]: value } : row
    );
    setRows(updatedRows);
  };

  const deleteRow = (id) => {
    const updatedRows = rows.filter(row => row.id !== id);
    setRows(updatedRows);
  };

  function updateData(data, depth, min, max, percentage) {
    let updatedData = depth.map((value, index) => {
      if (value > min && value < max) {
        return percentage;
      }
      return data[index];
    });
    return updatedData;
  }

  const sendRemove = async () => {

    const intervals = rows.map(row => [parseInt(row.top), parseInt(row.bottom)]);
    const wellId = wells.currentWell.well_uid;
    setIntervals(intervals);
    const transformedIntervals = intervals.map(interval => ({
      top: interval[0],
      bottom: interval[1]
    }));

    console.log('intervals=>', intervals);
    // const payload = {
    //   "well_uid": wellId,
    //   "normalization_settings": null,
    //   "remove_data_settings": {
    //     "intervals": transformedIntervals
    //   },
    //   "cutoff_settings": null,
    //   "reduce_noise_settings": null,
    // }

    setPayload(prevState => ({
      ...prevState,
      remove_data_settings: {
        "intervals": transformedIntervals
      }

    }));

    if (currentWell && currentWell?.calculated?.qualityData) {
      let removedData = [];
      let keepData = [];
      intervals.forEach((interval) => {
        removedData = updateData(currentWell.calculated?.qualityData?.badData, depth, interval[0], interval[1], 100)
        keepData = updateData(currentWell.calculated?.qualityData?.goodData, depth, interval[0], interval[1], 0)
      });

      const newQualityData = { goodData: keepData, badData: removedData }
      console.log('New Quality Data =>', newQualityData)

      dispatch(receiveWell({
        ...currentWell,
        calculated: {
          ...currentWell.calculated,
          qualityData: newQualityData
        }
      }))
    }
    closeModal();
    defaultNotification('Remove data applied !');
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1000,
        },
        content: {
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '600px',
          width: '100%',
          height: '80vh',
          padding: 0,
          backgroundColor: '#151724',
          border: 'none',
          borderRadius: '8px',
        },
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <ContainerTitle>
          <TitleText>Remove Data</TitleText>
          <CloseButton onClick={closeModal} data-cy="close-extraction-button">
            <CloseIcon />
          </CloseButton>
      </ContainerTitle>
      <Ui.CustomScrollBar>
        <FlexContainerBody>
          <div style={{ display: 'flex', justifyContent: 'space-between', color: 'white' }}>
            <span style={{ flex: 1, textAlign: 'start' }}>Top</span>
            <span style={{ flex: 1, textAlign: 'start' }}>Bottom</span>
          </div>
          {rows.map((row, index) => (
            <div
              key={row.id}
              style={{ display: 'flex', alignItems: 'center' }}
              data-cy='row-remove-data'
            >
              <Input
                value={row.top}
                onChange={(e) => updateRowField(row.id, 'top', e.target.value)}
                style={{ flex: 1, marginRight: '8px', background: '#151724', color: 'white', border: '1px solid #525775' }}
                placeholder="Ex.: 3300"
                data-cy={`remove-top-data-${index}`}
              />
              <Input
                value={row.bottom}
                onChange={(e) => updateRowField(row.id, 'bottom', e.target.value)}
                style={{ flex: 1, background: '#151724', color: 'white', border: '1px solid #525775' }}
                placeholder="Ex.: 3300"
                data-cy={`remove-bottom-data-${index}`}
              />
              <Button
                theme='secondary'
                style={{ marginLeft: '8px' }}
              >
                Select Interval
              </Button>
              <IconButton
                onClick={() => deleteRow(row.id)}
                style={{ marginLeft: '8px', backgroundColor: 'transparent', color: '#ff4d4f' }}
                data-cy={`button-remove-interval-${index}`}
              >
                <DeleteOutlinedIcon />
              </IconButton>
            </div>
          ))}
          <Button
            onClick={addRow}
            theme='secondary'
            style={{ display: 'flex', alignItems: 'center', marginBottom: '8px', marginTop: '18px', alignSelf: 'start', background: '#151724', color: 'white', padding: '8px 16px' }}
            data-cy='button-add-interval'
          >
            <PlusOutlined />
            Interval
          </Button>
        </FlexContainerBody>
      </Ui.CustomScrollBar>
        <div style={{ flex: 1 }} />
        <ContainerFooter style={{ marginTop: 0 }}>
          <Button
            onClick={closeModal}
            theme='secondary'
          >
            Cancel
          </Button>
          <Button
            onClick={sendRemove}
            theme='primary'
            data-cy="confirm-remove-data-button"
          >
            <DeleteOutlined />
            Remove
          </Button>
        </ContainerFooter>
      </div>
    </Modal>
  );
};

export default RemoveDataModal;
