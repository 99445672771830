import React, { useState, useCallback } from 'react';
import ToggleButton from 'react-toggle-button';
import { Button, Input } from 'antd';
import { set } from 'store';

const EnableSelectCriticalAreas = ({ afterChange }) => {
  const [isActivated, setActive] = useState(false);
  const toggleActive = () => {
    afterChange(!isActivated);
    setActive(!isActivated);
  };
  return (
    <div className="cross-plot-toggle" style={{ marginBottom: 10 }}>
      <ToggleButton
        colors={{
          activeThumb: {
            base: '#4F62C5'
          },
          inactiveThumb: {
            base: '#D1D5DB'
          },
          active: {
            base: '#162674',
            hover: '#162674'
          },
          inactive: {
            base: '#4B5563',
            hover: '#4B5563'
          }
        }}
        value={isActivated}
        onToggle={toggleActive}
      />
    </div>
  );
};

const CriticalAreas = ({
  showCutLine,
  onSubmit,
  depthIndexToCut,
  setDepthIndexToCut,
  enableCutLine,
  chartInstance,
  percenteOfOccurrencesSignificanceLevel
}) => {
  const extremes = chartInstance && chartInstance.xAxis[0].getExtremes();
  const getXAxisMiddleValue = extremes
    ? (Math.round(extremes.max) - extremes.min) / 2
    : 0;

  const handleChangeSelectCritialAreas = isActive => {
    enableCutLine(isActive);
    if (isActive === false) {
      setDepthIndexToCut(undefined);
    } else {
      setDepthIndexToCut(getXAxisMiddleValue);
    }
  };

  const handleChangeInput = e => {
    const value = parseFloat(e.target.value); 
    if (isNaN(value)) { 
      return;
    }
    setDepthIndexToCut(value);
  }

  const onSubmitSignificanceLevel = useCallback(() => {
    console.log('percenteOfOccurrencesSignificanceLevel', percenteOfOccurrencesSignificanceLevel);
    onSubmit(0, 'cut', depthIndexToCut, showCutLine, percenteOfOccurrencesSignificanceLevel);
  }, [depthIndexToCut, showCutLine, percenteOfOccurrencesSignificanceLevel]);

  return (
    <div>
      <div className='container-significance-form__toggle'>
        <h3>Critical Areas</h3>
        <EnableSelectCriticalAreas afterChange={handleChangeSelectCritialAreas} />
      </div>
      <div className='container-significance-form__label'>
        <p>Calculate until:</p>
        <Input
          className="container-significance-form__input"
          placeholder='Ex.: 1.5'
          style={{ width: '120px' }}
          value={depthIndexToCut}
          onChange={handleChangeInput}
          disabled={!showCutLine}
        />
      </div>
      <Button
        type="button"
        className="container-significance-form__button"
        onClick={onSubmitSignificanceLevel}
        disabled={!showCutLine}
      >
        Apply and Cut
      </Button>
    
    </div>
  );
};

export default CriticalAreas;
