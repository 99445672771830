import React, { useState, useEffect } from 'react';
import { SelectChangeEvent, Alert, Box } from '@mui/material';
import { Subtitle } from '../Subtitle';
import { Select } from '../Select';
import { Title } from '../Title';

import { InputText } from '../TextField';
import { FieldRange } from '../TextField/FieldRange';
// import { Button } from '../Button'
import { RangeSlider } from '../RangeSlider';
import { useFormContext } from 'react-hook-form';
import InputContainer from '../MatchingCurves/components/InputContainer';
import { Label } from '../Label';

import {
  FormStyled, WrapperInputs, OverwriteMessage, //TuneIconStyled
  WrapperLoadedInterval, WrapperLoadedIntervalInputs, WrapperLogSetSelect
} from './styles'

const optionsImportData = [
  {text: 'Create New Well', value: 'Create'},
  {text: 'Update Well Data', value: 'Update'},
];

const optionsLogSet = [
  { text: 'Raw', value: 'Raw' },
  { text: 'Filtered', value: 'Filtered' },
  { text: 'Calculated', value: 'Calculated' },
];

const optionsUnit = [
  { text: 'Meters', value: 'm' },
  { text: 'Ft', value: 'ft' },
];

export const BasicInformationForm = ({
  setValue, defaultStartDepth, defaultStopDepth, defaultLogSet,
  defaultWellName, wellOptions, clearCustomError, customErrors,
  getDataType, wellData
}) => {
  const { control, getValues } = useFormContext();
  const [selectedImportData, setSelectedImportData] = useState<string | null>(null);
  const [selectedLogSet, setSelectedLogSet] = useState<{ text: string; value: string } | undefined>();
  const normalizedOptionWellNames = wellOptions.map(item => ({ text: item.label, value: item.value }));
  const formValues = getValues();
  const [depthReference, setDepthReference] = useState<string | null>(null);
  const [maxDepth, setMax] = useState<string | undefined | number>();
  const [minDepth, setMix] = useState<string | undefined | number>();
  const curves = wellData?.log_sets?.[0]?.curves?.filter(c => c.type === 'depth') || [];

  const handleDepthReference = (event: SelectChangeEvent<unknown>) => {
    setDepthReference(event.target.value as string);
    setValue('depth_reference', event.target.value);
    const depthReferenceCurve = formValues?.curves?.find(c => c.mnemonic === event.target.value);
    const { max_value, min_value } = depthReferenceCurve;
    setMax(max_value);
    setMix(min_value);
    setValue('start_depth', min_value);
    setValue('stop_depth', max_value);
    clearCustomError();
  };
  
  useEffect(() => {
    if (selectedImportData) {
      getDataType(selectedImportData);
    }
  }, [selectedImportData]);

  const handleChangeLogSet = (event: SelectChangeEvent<unknown>) => {
    setSelectedLogSet(event.target.value as string);
  };

  const getTopAndBottom = (value) => {
    setValue('range', { start_depth: value[0], stop_depth: value[1] });
    // will update TextFields
    setValue('start_depth', value[0]);
    setValue('stop_depth', value[1]);
  };

  const handleFieldRange = (name, rangeValue) => {
    const stopDepth = parseFloat(formValues?.stop_depth)?.toFixed();
    const startDepth = parseFloat(formValues?.start_depth)?.toFixed();
    const startDepthRangeValue = parseFloat(rangeValue)?.toFixed();
    if (name === 'start_depth') {
      setValue('range', { start_depth: startDepthRangeValue, stop_depth: stopDepth });
    } else {
      setValue('range', { start_depth: startDepth, stop_depth: startDepth });
    }
  };
  
  const startDepthRange = parseFloat(formValues?.range?.start_depth)?.toFixed();
  const stopDepthRange = parseFloat(formValues?.range?.stop_depth)?.toFixed();

  return (
    <FormStyled>
      <div>
        <Subtitle text='How do you want to import new data?' />
        <Select
          name="typedata"
          control={control}
          label=""
          defaultValue='Create'
          options={optionsImportData}
          dataCy="create-update-select"
        />
        {
          formValues?.typedata === "Update" ? (
            <OverwriteMessage data-cy="data-overwrite-message">ALL DATA will be overwritten</OverwriteMessage>
          ) : null
        }
      </div>
      <WrapperInputs>
        <Title text='Well Name' />
        <Subtitle text='Select the well from which the data will be imported' />
        {
          formValues.typedata === 'Update' ? (
            <Select
              name="name"
              control={control}
              label=""
              defaultValue='Create'
              options={normalizedOptionWellNames}
              errorMessage={customErrors?.name?.message}
            />
          ) : <InputText control={control} name="name" label="" clearError={clearCustomError} />
        }
      </WrapperInputs>
      <WrapperInputs>
        <Title text='Log Set' />
        <Subtitle text='Select the standard log for import' />
        <WrapperLogSetSelect>
          <Select
            name="log_sets"
            control={control}
            label=""
            defaultValue={defaultLogSet}
            options={optionsLogSet}
            handleChange={handleChangeLogSet}
            isDisable={true}
          />
          {/* <Button theme='gray' width='fit-content' startIcon={<TuneIconStyled />}>Edit Logs</Button> */}
        </WrapperLogSetSelect>
      </WrapperInputs>
      <WrapperInputs>
        {customErrors?.depth_reference ? (
          <Alert severity="error" style={{ marginBottom: 10, opacity: 0.8 }}>{
            customErrors?.depth_reference}
          </Alert>
        ) : ''}
        <InputContainer>
          <Box sx={{ mb: 1 }}>
            <Label text='Depth Reference' />
          </Box>
          <Select
            name="depth_reference"
            control={control}
            label=""
            options={
              curves.map(curve => ({
                  text: `${curve.mnemonic}`,
                  value: curve.mnemonic
                })
              )
            }
            defaultValue={
              wellData?.log_sets?.[0]?.index_curve?.mnemonic
            }
            value={depthReference}
            handleChange={handleDepthReference}
            dataCy='depth-reference-select'
          />
        </InputContainer>
      </WrapperInputs>
      <WrapperInputs>
        <Title text='Loaded interval'  />
        <Subtitle text='Select the interval to receive the data' />
        <WrapperLoadedInterval>
          <RangeSlider
            control={control}
            setValue={setValue}
            getTopAndBottom={getTopAndBottom}
            minRange={minDepth || defaultStopDepth}
            maxRange={maxDepth || defaultStartDepth}
          />
          <WrapperLoadedIntervalInputs>
            <div>
              <FieldRange
                control={control}
                name="start_depth"
                label="Top"
                selectedValue={startDepthRange}
                clearError={clearCustomError}
                handleChange={handleFieldRange}
                dataCy="startDepth"
              />
            </div>
            <div>
              <FieldRange
                control={control}
                name="stop_depth"
                label="Base"
                selectedValue={stopDepthRange}
                clearError={clearCustomError}
                handleChange={handleFieldRange}
                dataCy="stopDepth"
              />
            </div>
            <div>
              <Select
                name="unit"
                control={control}
                label="Unit"
                defaultValue={wellData?.index_unit}
                options={optionsUnit}
                dataCy="selectUnit"
              />
            </div>
          </WrapperLoadedIntervalInputs>
        </WrapperLoadedInterval>
      </WrapperInputs>
    </FormStyled>
  )
}
