import { Tabs, Tab, Badge, BadgeProps } from '@material-ui/core';
import { styled } from '@mui/system';

type StyledBadgeProps = {
    badgeColor: string;
    badgeTextColor: string;
} & BadgeProps;

export const CustomTabs = styled(Tabs)({
    color: '#fff',
    borderBottom: '1px solid #323752',

    '& .PrivateTabIndicator-root-1': {
        backgroundColor: '#4F62C5'
    },
    '.MuiButtonBase-root': {
        "&.Mui-selected": {
            borderColor: '#4F62C5',

        },
    },
});

export const CustomTab = styled(Tab)(() => ({
    fontWeight: 400,
    textTransform: 'none',
    fontSize: '16px',
    textAlign: 'start',
    '&:hover': {
        color: '#40a9ff',
        opacity: 1,
    },
}));

export const StyledBadge = styled(Badge)<StyledBadgeProps>(({ badgeColor, badgeTextColor }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: badgeColor,
        color: badgeTextColor,
        borderRadius: '4px',
        right: '-25px',
        top: '-10px'
    },
}));