import { styled } from '@mui/material/styles';
import { customTheme } from '../../../../theme/default'; 
import Box from '@mui/material/Box';

export const LogSetsTitle = styled(Box)(() => (() => `
  color: ${customTheme.gray3};
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;
  text-transform: uppercase;
`
)); 
