import React, { useEffect } from 'react';
import { Button } from 'antd';
import { useFormik } from 'formik';
import InputAutoComplete from '../InputAutoComplete/InputAutoComplete';
import { formatterAutocompleteData } from '../../utils/formatterAutocompleteData';
import { validateDepthValue } from '../../utils/validateForm';
import { customTheme } from '@geowellex/shared-ui/src/theme/default';
import { CardBase } from '@geowellex/shared-ui/src/components/IndicatorCard/CardBase';

interface submitRangeProps {
  top: string;
  bottom: string;
}

interface FormRangeTopBottomProps {
  top: number;
  bottom: number;
  currentWell: any;
  submitRange: ({top, bottom}:submitRangeProps) => void;
}

export const FormRangeTopBottom = ({ top, bottom, currentWell, submitRange }: FormRangeTopBottomProps) => {
  const dataSource = formatterAutocompleteData(currentWell);
  const formik = useFormik({
    initialValues: { top: top, bottom: bottom },
    onSubmit: (values) => {
      const validateTopValue = dataSource.find((depth: any) => (values.top || String(top)) === depth);
      const validateBottomValue = dataSource.find((depth: any) => (values.bottom || String(bottom)) === depth);
      const validateDepth = validateDepthValue(validateTopValue || top, validateBottomValue || bottom);
      if (validateDepth.length !== 0) {
        return;
      }
      // @ts-ignore
      submitRange(values);
    },
  });

  const { values, setFieldValue, handleSubmit, initialValues } = formik;

  useEffect(() => {
    if (values.top === undefined && values.bottom === undefined) {
      setFieldValue('top', initialValues.top);
      setFieldValue('bottom', initialValues.bottom);
    }
  }, []);

  return (
    <CardBase customStyles="width: 260px; maxWidth: 260px; height: 216px; padding-left: 0;">
      <form
        onSubmit={handleSubmit}
        className='form-top-bottom'
        style={{
          fontSize: 16,
          color: customTheme.white,
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <div>
          <span>
            Min
          </span>
          <div className="min-max-crossplot" style={{ color: customTheme.white }}>
            <InputAutoComplete
              style={{ 
                width: 240, height: 35, background: customTheme.bg, fontSize: 16,
                border: '1px solid #2F334D', marginTop: 4, marginBottom: 4
              }}
              formatterAutocompleteData={formatterAutocompleteData}
              onChange={(value: number) => setFieldValue('top', value)}
              type="text"
              name="top"
              placeholder="top"
              value={values.top}
              dataSource={dataSource}
              defaultValue={initialValues ? initialValues.top : ''}
            />
          </div>
        </div>
        <div>
          <span>Max</span>
          <div className="min-max-crossplot">
            <InputAutoComplete
              style={{ 
                width: 240, height: 35, background: customTheme.bg, fontSize: 16,
                border: '1px solid #2F334D',
              }}
              formatterAutocompleteData={formatterAutocompleteData}
              onChange={(value: number) => setFieldValue('bottom', value)}
              type="text"
              name="bottom"
              placeholder="bottom"
              value={values.bottom}
              dataSource={dataSource}
              defaultValue={initialValues ? initialValues.bottom : ''}
            />
          </div>
        </div>
        <Button
          type='primary'
          htmlType='submit'
          style={{
            width: 235,
            marginTop: 20,
            background: customTheme.blue4,
            border: 0,
            borderRadius: 6,
            fontWeight: 'bold',
            fontSize: 14,
            color: customTheme.gray6,
          }}
        >
          Apply
        </Button>
      </form>
    </CardBase>
  );
}
