import React from 'react';
import store from 'store';

import { DEFAULT_SCALES } from 'utils/constants';
import { Login as LoginModule } from '@geowellex/shared-login';
import { useService } from '@geowellex/shared-login/src/hooks/useService';

import LogoGeowellex from 'images/logo-geowellex-white.svg';
import LogoWellexGold from 'images/logo-gold-white.svg';

import image1 from 'images/carousel-start/carousel-01.svg';
import image2 from 'images/carousel-start/carousel-02.svg';
import image3 from 'images/carousel-start/carousel-03.svg';

import bgLogin from 'images/bg-login-blue.svg';

import screen from 'images/gold-preview.svg';

const Login = () => {
  const { setService } = useService();

  setService('GOLD');

  const carouselData = [
    { title: "Thae Gas-Oil Logging While Drilling (WellexGOLD®) platform is an advanced chromatographic data analysis system engineered to provide real-time insights into oil and gas zones", image: image1 },
    { title: "This cutting-edge tool delivers precise estimates of API Gravity, Gas-Oil Ratio (GOR), Fluid Typing, Fluid Composition and Gas Quality Control, offering a reliable alternative to traditional PVT analysis.", image: image2 },
    { title: "WellexGOLD empowers operators with fast and accurate reservoir fluid analyses, enhancing savings, safety, and efficiency throughout drilling operations.", image: image3 }
  ];

  const colors = {
    loginBg: '#151724',
    loginButton: '#233BB6'
  }

  const configScales = () => {
    const configScales = store.get('configScales');
    if (!configScales) {
      store.set('configScales', DEFAULT_SCALES);
    }
  };

  return (
    <LoginModule
      title="Welcome"
      subtitle="Log in to your account"
      logoOnCopyright={LogoGeowellex}
      logoOnForm={LogoWellexGold}
      carousel={carouselData}
      configScales={configScales}
      bg={bgLogin}
      screen={screen}
      colors={colors}
    />
  );
};

export default Login;
