import { ReactNode } from "react"
import BaseButton from "./BaseButton"
import PrimaryButton from "./PrimaryButton"
import SecondaryButton from "./SecondaryButton"

interface ButtonProps {
  type: 'submit' | 'button',
  theme: 'primary' | 'secondary',
  disabled: boolean,
  children: ReactNode,
  startIcon?: ReactNode,
  endIcon?: ReactNode,
  dataCy?: string,
  onClick?: () => void
}

const ThemeButton = {
  primary: PrimaryButton,
  secondary: SecondaryButton,
}

export const Button = ({
  children, type, startIcon, endIcon, theme, disabled,
  dataCy, onClick, ...rest
}: ButtonProps) => {
  return (
    <BaseButton
      type={type}
      startIcon={startIcon}
      endIcon={endIcon}
      as={ThemeButton[theme]}
      disabled={disabled}
      onClick={onClick}
      data-cy={dataCy}
      {...rest}
    >
      {children}
    </BaseButton>
  )
}
