import React from 'react';
import PopoverConcepts from '../PopoverConcepts';
import ModalSignificance from '../ModalSignificance';

const MessageSignificanceLevel = ({curve, differenceType, movingAvarage }) => {
  return (
    <div className="container-significance-level">
      <div className="container-significance-level__header">
        <span className="container-significance-level__header__title">
          {curve} - {movingAvarage} - {differenceType}
        </span>
        <ModalSignificance
          render={({ openModal }) => (
            <PopoverConcepts
              openModal={openModal}
            />
          )}
        />
      </div>
    </div>
  );
}

export default MessageSignificanceLevel;