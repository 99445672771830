import { Box, ExtendButtonBase, List, ListItem, ListItemButton, ListItemButtonTypeMap, ListItemIcon, ListItemText, Menu } from "@mui/material";
import styled from "styled-components";
import { customTheme } from "../../../../../theme/default";
import CustomScrollBar from "../../../../CustomScrollBar";

interface BaseDrawerClose {
  isCloseDrawer: boolean
}

export const LogoWrapper = styled(Box)<BaseDrawerClose>`
  display: flex;
  justify-content: ${props => props.isCloseDrawer ? 'center' : 'flex-start'};
`;

export const DrawerHeader = styled(Box)`
  display: flex;
  justify-content: center;
`;

export const ListStyle = styled(List)`
  && {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`

export const WrapperListBtn = styled(ListItem)`
  padding-top: 0;
  padding-bottom: 0;
`

export const ListItemButtonStyle = styled(ListItemButton)<ExtendButtonBase<ListItemButtonTypeMap<{}, "div">>>`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    padding: 12px 4px;

    &:hover, &::selection {
      background-color: ${customTheme.blue2}
    }
  }
`

export const CustomListItemIcon = styled(ListItemIcon)`
  && {
    min-width: 0px;
  }
`;

export const MenuItemsWrapper = styled(Box)`
  margin-top: 10px;
`;

export const MenuItem = styled(ListItemText)<{ selected: boolean }>`
  color: ${(props) => props.selected ? customTheme.gray2 : customTheme.gray1};

  .MuiListItemText-primary {
    font-size: 12px;
    font-weight: 400;
    text-align: center;
  }
`;

export const OptionLogSet = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  color: ${customTheme.gray1};
  font-size: 12px;
  cursor: pointer;
  padding: 12px 4px;

  > div {
    position: relative;
    height: 18px;
  }
`;

export const WrapperArrowIcon = styled(Box)`
  position: absolute;
  right: -32px;
  top: -4px;
  height: 18px;
`;

export const MenuStyle = styled(Menu)`
  .MuiPaper-root {
    background-color: ${customTheme.blue3};
    color: ${customTheme.white};
    width: 200px;
    border-radius: 8px;
  }
`;

export const ScrollBar = styled(CustomScrollBar)`
  > div:first-child {
    position: static !important;
    max-height: 300px;
  }
`

export const ListItemStyle = styled(ListItem)`
  padding-left: 12px;

  .MuiListItemSecondaryAction-root {
    padding-right: 12px
  }
`