import styled from "styled-components";
import { Box } from "@material-ui/core";
import { customTheme } from '@geowellex/shared-ui/src/theme/default';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const Title = styled.form`
  color: ${customTheme.white};
  font-size: 21px;
  font-weight: 700;
`

export const SelectIntervalContainer = styled(Box)`
  display: flex;
  padding: 16px;
  border: 1px solid ${customTheme.purple7};
  border-radius: 4px;
  gap: 10px;

  > div {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
  }
`

export const IntervalText = styled.span`
  color: ${customTheme.white};
  font-size: 16px;
`

export const WrapperBtnsZone = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`