import React, { useState, useEffect } from 'react';
import store from 'store';
import { useFormik } from 'formik';
import classes from 'classnames';
import Modal from 'react-modal';
import { CompactPicker } from 'react-color';
import { message } from 'antd';
import { DEFAULT_SCALES } from '../../utils/constants';
import Wrapper from '../Wrapper';
import { modalStyleConfirmSettings } from '../../utils';
import warningIcon from '../../assets/atention.svg';
import { initial } from 'lodash';
import { customTheme } from '@geowellex/shared-ui/src/theme/default';

const LOG_CURVES = [
  'totalGas',
  'total_gas',
  'totalCarbon',
  'c1Normalized',
  'chromatographyC1',
  'chromatographyC2',
  'chromatographyC3',
  'chromatographyIc4',
  'chromatographyIc5',
  'chromatographyNc4',
  'chromatographyNc5'
];

const validLogValues = [
  0.0001,
  0.001,
  0.01,
  0.1,
  1,
  10,
  100,
  1000,
  10000,
  100000,
  1000000,
  10000000
];

export const updateChart = (event, data) => {
  const updateScales = new CustomEvent(event, {
    detail: data
  });
  document.dispatchEvent(updateScales);
};

export const updateStorage = (curveName, newValues) => {
  const currentConfig = store.get('configScales');
  const oldConfig = currentConfig[curveName];
  store.set('configScales', {
    ...currentConfig,
    [curveName]: { ...oldConfig, ...newValues }
  });
};

export const checkGasComposition = (values) => {
  const errors = {};

  if (values.min < 0 || values.min > 100 ){
    errors.min = true;
    message.error('Invalid value');
    return errors;
  }
  if (values.max > 100 || values.max < 0 ){
    errors.max = true;
    message.error('Invalid value');
    return errors;
  }
  return errors;
};

export const logValidate = (typeOfCurve, values, curvesTocheck) => {
  const errors = {};
  const messageError = 'Empty value';

  const isLogCurve = curvesTocheck.includes(typeOfCurve);
  if (values.min === undefined) {
    errors.min = true;
    message.error(messageError);
    return errors;
  }
  if (isLogCurve && !validLogValues.includes(values.min)) {
    errors.min = true;
    message.error('Invalid min value out of the logarithmic scale.');
  }
  if (values.max === undefined) {
    errors.max = true;
    message.error(messageError);
    return errors;
  }
  if (isLogCurve && !validLogValues.includes(values.max)) {
    errors.max = true;
    message.error('Invalid max value out of the logarithmic scale.');
  }
  return errors;
};

export const validate = (typeOfCurve, values, curvesTocheck) => {
  if (typeOfCurve.includes('gasComposition')) {
    return checkGasComposition(values);
  }
  return logValidate(typeOfCurve, values, curvesTocheck);
};

export const updateOnLocalStorage = (
  typeOfCurve, curvesTocheck, onClose,
  validateInputs, updateLocalStorage, updateScalesChart
) => (values, { setErrors }) => {
  const errors = validateInputs(typeOfCurve, values, curvesTocheck);
  if (!Object.keys(errors).length) {
    updateLocalStorage(typeOfCurve, values);
    updateScalesChart(typeOfCurve, values);
    onClose();
    message.success('New scale settings successfully applied!')
  } else {
    setErrors(errors);
  }
};

export const restoreData = (
  curveName, defaultConfig, setFieldValue, updateLocalStorage, updateScalesChart
) => () => {
  setFieldValue('min', defaultConfig.min);
  setFieldValue('max', defaultConfig.max);
  setFieldValue('color', defaultConfig.color);

  updateScalesChart(curveName, defaultConfig);
  updateLocalStorage(curveName, defaultConfig);

  message.success('Scale settings successfully restored!');
};

const Field = ({ children }) => (
  <div className="form-scale__wrapper-input">
    <div className="form-scale__box-input">
      {children}
    </div>
  </div>
);

const Label = ({ id, text, className }) => (
  <label
    htmlFor={id}
    className={classes(
      'form-scale__label',
      { [className]: !!className }
    )}
    style={{
      color: customTheme.white,
      marginTop: 15,
    }}
  >
    {text}
  </label>
);

const InputConfig = ({
  name, onChange, value, className
}) => (
  <input
    className={classes('form-scale__input', {
      [className]: !!className
    })}
    style={{
      border: `1px solid ${customTheme.purple6} !important`,
    }}
    type="number"
    name={name}
    id={name}
    onChange={onChange}
    value={value}
    data-cy={`modal-input-${name}`}
  />
);

const ColorSelector = ({
  handleClickColor,
  handleBlurColor,
  handleChangeColor,
  color,
  activeColor
}) => {
  return (
    <>
      <div
        onClick={handleClickColor}
        className="form-scale__wrapper-color"
        style={{ backgroundColor: color }}
        data-cy="scale-color"
      />
      {activeColor ?
        (
          <div className="form-scale__tooltip-color">
            <div
              className="form-scale__close-tooltip"
              onClick={handleBlurColor}
              data-cy="close-color"
            />
            <div className="form-scale__picker">
              <CompactPicker
                color={color}
                onChange={handleChangeColor}
                onBlur={handleChangeColor}
              />
            </div>
          </div>
        ) : null
      }
    </>
  );
}

const WrapperActions = Wrapper('form-scale__group-buttons');
const WrapperSaveButtons = Wrapper('form-scale__group-save-buttons');

const ConfigActions = ({ onReset, onClose, openModal }) => (
  <>
    <WrapperActions>
    <button
      onClick={onReset}
      type="button"
      className="form-scale__restore-button"
      data-cy="reset-config"
    >
      Restore Defaults
    </button>
    <WrapperSaveButtons>
      <button
        type="submit"
        className="form-scale__ok-button"
      >
        ok
      </button>
      <button
        type="submit"
        className="form-scale__save-button"
        data-cy="apply-config"
      >
        Apply
      </button>
    </WrapperSaveButtons>
    </WrapperActions>
  </>
);

const WrapperInfoModal = Wrapper('form-scale__info-modal');
const WrapperImage = Wrapper('form-scale__warning-image');
const WrapperButtonsConfirmModal = Wrapper('form-scale__wrapper-btn-confirm');

const ConfirmActions = ({ modalState, confirmSettings, closeModal }) => (
  <Modal
    isOpen={modalState}
    style={confirmSettings}
    onRequestClose={closeModal}
  >
    <form
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <WrapperInfoModal>
        <WrapperImage>
          <img src={warningIcon} alt="warning"/>
        </WrapperImage>
        <div>
          <h4 className="form-scale__title-confirm">
            Do you want to make these changes?
          </h4>
          <span className="form-scale__text-confirm">
            <label className="form-scale__weight">All scales </label>
            on the chromatography charts will be changed.
          </span>
        </div>
      </WrapperInfoModal>
      <WrapperButtonsConfirmModal>
        <button
          onClick={closeModal}
          type="button"
          className="form-scale__cancel-button"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="form-scale__ok-button"
          data-cy="modal-button-ok"
        >
          ok
        </button>
      </WrapperButtonsConfirmModal>
    </form>
  </Modal>
);

const Title = ({ title, className }) => (
  <h3 className={classes(
    'title-row',
    { [className]: !!className }
  )}
  >
    {title}
  </h3>
);

const SubTitle = ({ title }) => (
  <Title title={title} className="form-scale__subtitle"/>
);

const ScalesConfig = ({ typeOfTitle, onClose }) => {
  let [scales, setScales] = useState();
  const [currentCurve, setCurrentCurve] = useState();

  if (scales && !scales.gqr) {
    scales = {
      ...scales,
      gqr: DEFAULT_SCALES.gqr,
    };
  }

  const [modalState, setModal] = useState(false);
  const [activeColor, setActiveColor] = useState(false);

  const closeModal = () => setModal(false);
  const openModal = () => setModal(true);

  const onSubmit = updateOnLocalStorage(typeOfTitle, LOG_CURVES, onClose, validate, updateStorage, updateChart);

  useEffect(() => {
    if (scales && !currentCurve) {
      setCurrentCurve(scales);
    }
  }, [scales, typeOfTitle, currentCurve]);

  useEffect(() => {
    if (typeOfTitle && store.get('configScales')?.[typeOfTitle] && !scales) {
      setScales(store.get('configScales')[typeOfTitle]);
    }
  }, [typeOfTitle]);

  const formik = useFormik({
    initialValues: {
      min: currentCurve?.min || DEFAULT_SCALES?.[typeOfTitle]?.min,
      max: currentCurve?.max || DEFAULT_SCALES?.[typeOfTitle]?.max,
      color: currentCurve?.color || DEFAULT_SCALES?.[typeOfTitle]?.color
    },
    enableReinitialize: true,
    onSubmit
  });

  const onReset = restoreData(typeOfTitle, DEFAULT_SCALES[typeOfTitle], formik.setFieldValue, updateStorage, updateChart);

  const { handleSubmit, handleChange, values } = formik;

  const handleClickColor = () => setActiveColor(true);
  const handleBlurColor = () => setActiveColor(false);

  const handleChangeColor = ({ hex }) => {
    handleChange({
      target: {
        value: hex,
        name: 'color'
      }
    });
  };

  return (
    <div className="form-scale">
      <form onSubmit={handleSubmit}>
        {/* <SubTitle title={DEFAULT_SCALES?.[typeOfTitle]?.titleModal || ''} /> */}
        <div
          className="form-scale__wrapper-inputs"
          style={{
            height: 'calc(100vh - 147px)'
          }}
        >
          <Field>
            <Label id="min" text="Min" />
            <InputConfig
              name="min"
              id="min"
              onChange={handleChange}
              value={values.min}
            />
          </Field>
          <Field>
            <Label id="max" text="Max" />
            <InputConfig
              name="max"
              id="max"
              onChange={handleChange}
              value={values.max}
            />
          </Field>
          <Field>
            <div style={{ position: 'relative', marginTop: 15 }}>
              <Label id="color" text="Color" />
              <div style={{
                position: 'absolute',
                top: 39,
                left: 34,
                width: 66,
                height: 30,
                fontSize: 16,
                fontWeight: 400,
                color: customTheme.white,
              }}>
                {values.color}
              </div>
              <div style={{
                width: 350,
                marginTop: 7,
                border: `1px solid ${customTheme.purple6}`,
                borderRadius: 6,
                padding: '8px 10px',
              }}>
                <ColorSelector
                  handleClickColor={handleClickColor}
                  handleBlurColor={handleBlurColor}
                  handleChangeColor={handleChangeColor}
                  color={values.color}
                  activeColor={activeColor}
                />
              </div>
            </div>
          </Field>
        </div>
        <ConfigActions
          onReset={onReset}
          onClose={onClose}
          openModal={openModal}
        />
        {/* <ConfirmActions
          modalState={modalState}
          confirmSettings={modalStyleConfirmSettings}
          closeModal={closeModal}
        /> */}
      </form>
    </div>
  );
};

export default ScalesConfig;
