/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable no-dupe-keys */
/* eslint-disable react/no-unused-state */
import './style.scss';

import React, {
  Component,
  useEffect,
  useState
} from 'react';

import classNames from 'classnames';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import { useSelector } from 'react-redux';
import store from 'store';
import Ui from '@geowellex/shared-ui';

import { applyDefaultZoomToChart } from '../../../lib/charts';
import {
  defaultCrossHair,
  defaultValuesToCrosshair,
  disableDots,
  disableLabels,
  displayZoomBtn,
  getToolTipValues,
  getUnit,
  handleSelectArea,
  propagateZoom,
  syncronizeTooltip,
  toggleSFChart,
  toggleTrack as toggleTrackUtils
} from '../../../utils';
import { DEFAULT_SCALES } from '../../../utils/constants';
import GlobalCutOff from '../../../utils/GlobalCutOff';
import { getCalculatedCurveData } from '../../../utils/getCalculatedCurveData';
import { useScales } from '../../../hooks/useScales';
import { shouldUpdateScale } from '../../../hooks/useScales';

Exporting(Highcharts);

const curveTypes = [
  'ic4nc4',
  'ic5nc5',
  'c1c2',
  'slope_factor'
];

const HeaderChart = () => {
  const wells = useSelector(state => state.wells);

  const initialHeader = {
    currentSlopeFactor: 0,
    c1C2: 0,
    ic4nc4: 0,
    ic5nc5: 0,
  };

  const {
    currentScale, setHeaderState, headerState
  } = useScales(
    curveTypes,
    wells.currentWell,
    initialHeader
  );

  // --- Toggle Tracks
  const [tracks, setToggle] = useState({
    isOpen: false,
    sfToggle: true,
    c1C2Toggle: true,
    ic5nC5Toggle: true,
    ic4nC4Toggle: true
  });

  const tooggleCallbacks = {
    sfToggle: toggleSFChart,
    c1C2Toggle: toggleSFChart,
    ic5nC5Toggle: toggleSFChart,
    ic4nC4Toggle: toggleSFChart
  };

  const toggleTrackCurrentTrack = (event, name, index) => {
    const trackName = tracks[name];
    const callback = tooggleCallbacks[name];
    if (callback) {
      callback(parseInt(index))(event);
      setToggle({
        ...tracks,
        isOpen: !tracks.isOpen,
        [name]: !trackName
      });
    }
  };

  // --- Open modal with config
  const dispatchModal = (typeOfTitle) => (event) => {
    event.persist();
    event.stopPropagation();
    if (event.target && event.target.dataset.cmd === 'toggle') {
      toggleTrackCurrentTrack(event);
      return;
    } else if (event.target && event.target.dataset.cmd === 'openConfig') {
      const openModal = new CustomEvent('openConfigModal', {
        detail: {
          type: typeOfTitle
        }
      });
      document.dispatchEvent(openModal);
      return;
    }
  };

  useEffect(() => {
    // setHeaderState({
    //   scales: store.get('configScales')
    // });

    const sfChartListener = (currentTrack) => ({ detail }) => {
      // updatedHeader.scales[currentTrack] = detail;
      // headerState.scales[currentTrack] = detail;
      setHeaderState({
        ...headerState,
        ...updatedHeader
      });
    };

    const sfCharts = [
      'slopeFactor',
      'c1c2',
      'ic4Nc4',
      'ic5Nc5'
    ];

    const slopeFactor = sfChartListener('slopeFactor');
    const c1c2 = sfChartListener('c1c2');
    const ic4Nc4 = sfChartListener('ic4Nc4');
    const ic5Nc5 = sfChartListener('ic5Nc5');

    const callbackListeners = {
      slopeFactor,
      c1c2,
      ic4Nc4,
      ic5Nc5
    };

    // Add event listeners
    for (let i = 0; i < sfCharts.length; i++) {
      const currentTrack = sfCharts[i];
      document.addEventListener(currentTrack, callbackListeners[currentTrack]);
    }

    return () => {
      // Remove all event listeners
      for (let i = 0; i < sfCharts.length; i++) {
        const currentTrack = sfCharts[i];
        document.removeEventListener(currentTrack, callbackListeners[currentTrack]);
      }
    };
  }, []);

  useEffect(() => {
    if (wells.currentWell && wells.selectedDepthIndex >= 0) {

      const ic4BynC4 = getCalculatedCurveData('ic4nc4', wells.currentWell);
      const ic5BynC5 = getCalculatedCurveData('ic5nc5', wells.currentWell);
      const c1Byc2 = getCalculatedCurveData('c1c2', wells.currentWell);
      const slopeFactor = getCalculatedCurveData('slope_factor', wells.currentWell);

      const index = wells.selectedDepthIndex;

      const slopeFactorValue = slopeFactor && slopeFactor.data && slopeFactor.data[index] ? slopeFactor.data[index] : 0;
      const c1Byc2Value = c1Byc2 && c1Byc2.data[index] && c1Byc2.data[index] ? c1Byc2.data[index] : 0;
      const ic4ByNc4Value = ic4BynC4 && ic4BynC4.data[index] && ic4BynC4.data[index] ? ic4BynC4.data[index] : 0;
      const c5ByNc5Value = ic5BynC5 && ic5BynC5.data[index] && ic5BynC5.data[index] ? ic5BynC5.data[index] : 0;

      setHeaderState({
        ...headerState,
        currentSlopeFactor: slopeFactorValue?.toFixed(2),
        c1C2: c1Byc2Value?.toFixed(2),
        ic4nc4: ic4ByNc4Value?.toFixed(2),
        ic5nc5: c5ByNc5Value?.toFixed(2)
      });
    }

    if (!wells.currentWell) {
      setHeaderState({
        ...headerState,
        currentSlopeFactor: 0,
        c1C2: 0,
        ic4nc4: 0,
        ic5nc5: 0
      });
    }
  }, [wells]);

  const {
    currentSlopeFactor, c1C2, ic5nc5, ic4nc4
  } = headerState;

  const c1ByC2TrackConfig = {
    title: 'C1/C2', className: 'sfC1ByC2'
  };
  const sfTrackConfig = {
    title: 'SF', className: 'sfTrackConfig'
  };
  const ic5ByNc5Config = {
    title: 'iC5/nC5', className: 'ic5Track'
  };
  const ic4ByNc4Config = {
    title: 'iC4/nC4', className: 'ic4Track'
  };

  return (
    <div className="sf-header-box">
      <Ui.Header
        min={currentScale?.ic4nc4?.min}
        max={currentScale?.ic4nc4?.max}
        textCenter={ic4nc4 > 0 ? `${ic4ByNc4Config.title} (${ic4nc4})` : `${ic4ByNc4Config.title}`}
        background={currentScale?.ic4nc4?.color}
        checkboxIsVisible
        checked={tracks.ic4nC4Toggle}
        onChangeCheckbox={(event) => toggleTrackCurrentTrack(event, 'ic4nC4Toggle', 4)}
        onClick={dispatchModal('ic4nc4')}
        activeCurveConfig="ic4nc4"
        dataCy="open-config-ic4Nc4"
      />
      <Ui.Header
        min={currentScale?.ic5nc5?.min}
        max={currentScale?.ic5nc5?.max}
        textCenter={ic5nc5 > 0 ? `${ic5ByNc5Config.title} (${ic5nc5})` : `${ic5ByNc5Config.title}`}
        background={currentScale?.ic5nc5?.color}
        checkboxIsVisible
        checked={tracks.ic5nC5Toggle}
        onChangeCheckbox={(event) => toggleTrackCurrentTrack(event, 'ic5nC5Toggle', 3)}
        onClick={dispatchModal('ic5nc5')}
        activeCurveConfig="ic5nc5"
        dataCy="open-config-ic5Nc5"
      />
      <Ui.Header
        min={currentScale?.c1c2?.min}
        max={currentScale?.c1c2?.max}
        textCenter={c1C2 > 0 ? `${c1ByC2TrackConfig.title} (${c1C2})` : `${c1ByC2TrackConfig.title}`}
        background={currentScale?.c1c2?.color}
        checkboxIsVisible
        checked={tracks.c1C2Toggle}
        onChangeCheckbox={(event) => toggleTrackCurrentTrack(event, 'c1C2Toggle', 2)}
        onClick={dispatchModal('c1c2')}
        activeCurveConfig="c1c2"
        dataCy="open-config-c1c2"
      />
      <Ui.Header
        min={currentScale?.slope_factor?.min}
        max={currentScale?.slope_factor?.max}
        textCenter={currentSlopeFactor > 0 ? `${sfTrackConfig.title} (${currentSlopeFactor})` : `${sfTrackConfig.title}`}
        background={currentScale?.slope_factor?.color}
        checkboxIsVisible
        checked={tracks.sfToggle}
        onChangeCheckbox={(event) => toggleTrackCurrentTrack(event, 'sfToggle', 1)}
        onClick={dispatchModal('slope_factor')}
        activeCurveConfig="slope_factor"
        dataCy="open-config-sf"
      />
    </div>
  );
};

const getDefaultSeries = (
  scales,
  SFData = [],
  C1C2Data = [],
  ic5Data = [],
  ic4Data = []
) => {
  const placeholderData = defaultValuesToCrosshair(C1C2Data, 0);
  const slope_factor = scales?.slope_factor ? scales?.slope_factor : DEFAULT_SCALES.slope_factor;
  const c1c2 = scales?.c1c2 ? scales?.c1c2 : DEFAULT_SCALES.c1c2;
  const ic4nc4 = scales?.ic4nc4 ? scales?.ic4nc4 : DEFAULT_SCALES.ic4nc4;
  const ic5nc5 = scales?.ic5nc5 ? scales?.ic5nc5 : DEFAULT_SCALES.ic5nc5;

  return [
    {
      fillOpacity: 0.1,
      name: ' ',
      type: 'spline',
      tooltip: {
        valueSuffix: ' ',
        enabled: false
      },
      // eslint-disable-next-line react/destructuring-assignment
      data: SFData,
      labels: {
        formatter: () => '<span class="deactivate-labels">-</span>'
      },
      lineColor: 'rgba(0, 0, 0, 0)',
      yAxis: 0,
      zIndex: -1,
      lineWidth: 1,
      index: 0
    },
    {
      fillOpacity: 0.1,
      name: ' ',
      type: 'spline',
      tooltip: {
        valueSuffix: ' ',
        enabled: false
      },
      // eslint-disable-next-line react/destructuring-assignment
      data: SFData,
      labels: {
        formatter: () => '<span class="deactivate-labels">-</span>'
      },
      lineColor: slope_factor?.color,
      color: slope_factor?.color,
      yAxis: 1,
      zIndex: 2,
      lineWidth: 1,
      index: 1
    },
    {
      fillOpacity: 0.5,
      name: ' ',
      type: 'line',
      tooltip: {
        valueSuffix: ' ',
        enabled: false
      },
      data: C1C2Data,
      lineColor: c1c2.color,
      color: c1c2.color,
      yAxis: 2,
      zIndex: 2,
      lineWidth: 1,
      index: 2
    },
    {
      fillOpacity: 0.5,
      name: ' ',
      type: 'spline',
      tooltip: {
        valueSuffix: ' ',
        enabled: false
      },
      data: ic5Data,
      lineColor: ic5nc5.color,
      color: ic5nc5.color,
      yAxis: 3,
      zIndex: 2,
      lineWidth: 1,
      index: 3
    },
    {
      fillOpacity: 0.5,
      name: ' ',
      type: 'spline',
      tooltip: {
        valueSuffix: ' ',
        enabled: false
      },
      data: ic4Data,
      lineColor: ic4nc4.color,
      color: ic4nc4.color,
      yAxis: 4,
      zIndex: 2,
      lineWidth: 1,
      index: 4
    }
  ];
};

class ReasonChart extends Component {
  constructor(props) {
    super(props);
    this.chart = null;

    this.state = {
      chartsHeight: 0,
      selectedId: 0,
      plotBands: [],
      C1C2: [],
      SF: [],
      nc4: [],
      nc5: [],
      series: {
        0: true,
        1: true,
        2: true,
        3: true,
        4: true
      },
      scales: store.get('configScales') || DEFAULT_SCALES,
      selectedDepth: 0,
      balanceRatio: 0,
      wetnessRatio: 0,
      chromatography: 0,
      characterRatio: 0,
      gasTotal: 0,
      isotope: 0,
      unit: getUnit(),
      rop: 0,
      gasRatio: 0,
      c1: 0,
      c2: 0,
      c3: 0,
      ic4: 0,
      nc4: 0,
      ic5: 0,
      nc5: 0,
      c1Comp: 0,
      c2Comp: 0,
      c3Comp: 0,
      c4Comp: 0,
      c5Comp: 0,
      slopeFactor: 0,
      c1Byc2: 0,
      ic4nc4: 0,
      ic5nc5: 0,
      tracks: {
        isOpen: false,
        rop: true,
        gasRatio: true,
        chromaC1: true,
        chromaC2: true,
        chromaC3: true,
        chromaIc4: true,
        chromaNc4: true,
        chromaIc5: true,
        chromaNc5: true,
        gasCompC1: true,
        gasCompC2: true,
        gasCompC3: true,
        gasCompC4: true,
        gasCompC5: true,
        character: true,
        balance: true,
        wetness: true,
        sfToggle: true,
        c1C2Toggle: true,
        ic5nC5Toggle: true,
        ic4nC4Toggle: true
      }
    };
    this.vpHeight = 0;
  }

  componentDidMount() {
    const { id, customEventType } = this.props;
    const { selectedId } = this.state;
    // This dispatch some event after to select the area and display the scatters plot
    // this.handleSelect = handleSelectArea(this, this.afterSelect);

    const { parentContainer } = this.props;
    this.vpHeight = document.querySelector(parentContainer).clientHeight - 10 || 0;

    this.createChart();

    if(this.chart) {
      this.zoomId = applyDefaultZoomToChart(this.chart);
    }

    GlobalCutOff.registerInstances(
      'slopeFactor',
      this.chart
    );

    // TODO apply it when mark to generate scatters
    document.addEventListener(customEventType, () => {
      this.chart.xAxis[0].removePlotBand(`selection${selectedId}`);
    });

    document.addEventListener('removePlotBands', () => {
      const count = store.get('openedCrossPlots')
        ? parseInt(store.get('openedCrossPlots'))
        : 0;

      if (count) {
        this.chart.xAxis[0].removePlotBand(`selection${selectedId}`);
      }
    });

    // TODO refactor this syncronization
    document.addEventListener('sincronize-selected', event => {
      const { detail: { min, max } } = event;
      this.chart.xAxis[0].setExtremes(min, max, true, true);
      this.chart.redraw();
    });
    syncronizeTooltip(`#${id}`);

    document.addEventListener('toggle-sf-chart', ({ detail: { index } }) => {
      toggleTrackUtils(this)(index);
    });

    // TODO refactor this workaround because with two windows we need to comunicate
    // theses tabs using some reactive way
    // this was made one day before alfa presentation
    this.timerId = window.setInterval(() => {
      const shoudlClose = !!store.get('opened-cross-plot-C1C2SF');
      if (shoudlClose === true) {
        this.chart.xAxis[0].removePlotBand(`selection${  selectedId}`);
      }
    }, 200);

    const sfCharts = [
      '',
      'slowFactor',
      'c1c2',
      'ic5Nc5',
      'ic4Nc4'
    ];
    for (let i = 1; i < sfCharts.length; i++) {
      const currentTrack = sfCharts[i];
      document.addEventListener(currentTrack, ({ detail }) => {
        this.chart.yAxis[i].update({
          min: detail.min,
          max: detail.max
        });
        const serie = this.chart.series[i];
        serie.update({
          color: detail.color,
          lineColor: detail.color
        });
        serie.redraw();
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    const { C1C2, SF, nc4, nc5, selectedWell, currentWell, min, max, updateChart } = nextProps;
    const { chart, props } = this;

    if (
      selectedWell !== props.selectedWell
      || nextProps.selectedWell !== props.selectedWell
      || props.beforeWell?.depth?.length !== currentWell?.depth?.length
      || props.C1C2 !== C1C2
      || updateChart
    ) {
      chart.update({
        series: getDefaultSeries(currentWell?.scales , SF, C1C2, nc5, nc4)
      });
      this.zoomId = setTimeout(() => {
        chart.xAxis[0].setExtremes(min, max);
      }, 300);
      return true;
    }

    const defaultScales = store.get('configScales');
    curveTypes.forEach((curveName) => {
      const checkToUpdateScales = shouldUpdateScale(
        curveName,
        currentWell?.scales,
        defaultScales
      );

      if (currentWell && checkToUpdateScales && this?.chart?.update) {
        this.chart.update({
          series: getDefaultSeries(
            currentWell?.scales,
            SF,
            C1C2,
            nc5,
            nc4
          )
        });
        return true;
      }
    });

    return false;
  }

  componentWillUnmount() {
    window.clearInterval(this.timerId);
    // eslint-disable-next-line no-unused-expressions
    this.zoomId && clearTimeout(this.zoomId);
  }

  // TODO refactor it this is repeated in Balance ratio
  selectAreas = event => {
    event.preventDefault();
    const axis = event.xAxis[0];
    const { customEventType, currentWell } = this.props;
    const { selectedId } = this.state;

    const afterSelect = () => {
      const minValue = Math.floor(axis?.min?.toFixed());
      const maxValue = Math.floor(axis?.max?.toFixed());
      let minLabel = minValue;
      let maxLabel = maxValue;
      if (currentWell) {
        const depthData = getCalculatedCurveData('depth', currentWell);
        minLabel = depthData?.data[minValue];
        maxLabel = depthData?.data[maxValue];
      }
      store.set('opencrossplotFluidTyping', '');
      const togglePlotBox = new CustomEvent('showPlotBox', {
        detail: {
          type: customEventType,
          min: minValue,
          max: maxValue,
          minLabel,
          maxLabel
        }
      });
      document.dispatchEvent(togglePlotBox);
    };

    // TODO Apply it when select plot band
    if (store.get('crossPlots')) {
      const count = store.get('openedCrossPlots')
        ? parseInt(store.get('openedCrossPlots'))
        : 0;
      store.set('openedCrossPlots', count + 1);
      handleSelectArea(this.chart, afterSelect, selectedId)(event);
    } else {
      displayZoomBtn();
      propagateZoom(event);
    }
  };

  createChart = () => {
    const { id, currentWell } = this.props;
    const { C1C2, nc4, nc5, SF } = this.state;
    const scales = currentWell?.scales || DEFAULT_SCALES;

    this.chart = Highcharts.chart(id, {
      chart: {
        zoomType: 'x',
        type: 'area',
        inverted: true,
        width: 160,
        padding: 0,
        margin: 0,
        // backgroundColor: window.color || '#fff',
        backgroundColor: '#151724',
        events: {
          selection: this.selectAreas
        },
        animation: false,
        shadow: false
      },
      boost: {
        useGPUTranslations: true,
        usePreAllocated: true
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false
          },
          enableMouseTracking: false,
          showInLegend: false
        }
      },
      exporting: { enabled: false },
      title: ' ',
      subtitle: {
        text: null
      },
      credits: {
        enabled: false
      },
      xAxis: [
        {
          minorGridLineWidth: 1,
          minorGridLineColor: '#666666',
          showFirstLabel: true,
          showLastLabel: true,
          minPadding: 0.0,
          maxPadding: 0.0,
          opposite: true,
          labels: {
            enabled: false
          },
          lineColor: '#666666',
          tickLength: 0,
          gridLineColor: '#666666',
          minorGridLineColor: '#666666',
          gridLineWidth: 0.5,
        }
      ],
      yAxis: [
        {
          boostThreshold: 1,
          minorGridLineWidth: 0.4,
          minorGridLineColor: '#333',
          gridLineColor: '#333',
          min: 0,
          max: 4,
          ...disableLabels,
          type: 'spline',
          allowDecimals: true,
          index: 0,
          labels: {
            enabled: false
          }
        },
        {
          boostThreshold: 1,
          min: scales?.slope_factor?.min,
          max: scales?.slope_factor?.max,
          ...disableLabels,
          minorGridLineWidth: 0.4,
          minorGridLineColor: '#333',
          gridLineColor: '#333',
          type: 'spline',
          allowDecimals: true,
          index: 1,
          labels: {
            enabled: false
          }
        },
        {
          minorGridLineWidth: 0.4,
          minorGridLineColor: '#333',
          gridLineColor: '#333',
          boostThreshold: 1,
          min: scales?.c1c2?.min,
          max: scales?.c1c2?.max,
          ...disableLabels,
          type: 'spline',
          allowDecimals: true,
          index: 2,
          labels: {
            enabled: false
          }
        },
        {
          minorGridLineWidth: 0.4,
          minorGridLineColor: '#333',
          gridLineColor: '#333',
          boostThreshold: 1,
          min: scales?.ic5nc5?.min,
          max: scales?.ic5nc5?.max,
          ...disableLabels,
          type: 'linear',
          allowDecimals: true,
          index: 3,
          labels: {
            enabled: false
          }
        },
        {
          minorGridLineWidth: 0.4,
          minorGridLineColor: '#333',
          gridLineColor: '#333',
          boostThreshold: 1,
          min: scales?.ic4nc4?.min,
          max: scales?.ic4nc4?.max,
          ...disableLabels,
          type: 'linear',
          allowDecimals: true,
          index: 4,
          labels: {
            enabled: false
          }
        }
      ],
      tooltip: {
        ...defaultCrossHair,
        formatter: getToolTipValues('sf-chart')
      },
      series: getDefaultSeries(
        currentWell?.scales || DEFAULT_SCALES,
        SF,
        C1C2,
        nc5,
        nc4
      )
    });
  }

  render() {
    const { id } = this.props;
    return (
      <div className="chart__box-container" style={{ marginLeft: 10 }}>
        <HeaderChart />
        <div
          id={id}
          style={{
            height: 'calc(100% - 180px)'
          }}
          data-cy="chart-reason"
        />
      </div>
    );
  }
}

ReasonChart.defaultProps = {
  id: 'reason-chart',
  parentContainer: '.charts-container',
  C1C2: [],
  SF: [],
  nc4: [],
  nc5: [],
  customEventType: 'C1C2SF'
};

export default ReasonChart;
