/* eslint-disable consistent-return */
import './style.scss';

import React, {
  memo,
  useEffect,
  useRef,
  useState
} from 'react';

import ChartContainer from 'components/Shared/ChartContainer';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import {
  applyDefaultZoomToChart,
  toggleCurves
} from 'lib/charts';
import store from 'store';
import {
  defaultCrossHair,
  displayZoomBtn,
  formattingMin,
  getToolTipValues,
  propagateZoom,
  syncronizeTooltip
} from 'utils';
import { DEFAULT_SCALES } from 'utils/constants';
import GlobalCutOff from '../../../utils/GlobalCutOff';
import ChartHeader from './ChartHeader';
import {
  createDefaultConfig,
  getDefaultSeries
} from './config';
import { useScales } from '../../../hooks/useScales';
import { totalGasCurveTypes } from './constants';

Exporting(Highcharts);

const TOGGLE_TOTAL_GAS = 'toggle-total-gas-chart';
const TOGGLE_TOTAL_CARBON = 'toggle-total-carbon-chart';
const TOGGLE_C1_NORMALIZED = 'toggle-c1-normalized-chart';
const TOGGLE_GQR = 'toggle-gqr-chart';


const TOTAL_GAS_EVENT = 'totalGas';
const TOTAL_CARBON_EVENT = 'totalCarbon';
const C1_NORMALIZED_EVENT = 'c1Normalized';
const GQR_EVENT = 'gqr';

const SYNCHRONIZED_EVENT = 'sincronize-selected';

const TotalGas = ({
  id, depth, totalGasData, totalCarbon, c1Normalized, gqr, selectedWell, currentWell, min, max,
  updateChart
}) => {
  const zoomRefId = useRef(null);
  const { currentScale: scales } = useScales(totalGasCurveTypes, currentWell);
  const [chart, setChart] = useState(null);
  const [rangeValues, setRangeValues] = useState([]);
  const [positionsLeft, setPositionsLeft] = useState([]);
  const [activeWell, setCurrentWell] = useState([]);
  const series = useState({
    0: true,
    1: true,
    2: true,
    3: true,
    4: true
  });

  const positioningTheLabels = (chartInstance) => {
    const {childNodes} = chartInstance.axes[1].labelGroup.element;

    const labels = [...childNodes].map(element => {
      if (element.childNodes.length === 2) {
        const currentScales = DEFAULT_SCALES;
        const content = element.childNodes[0].innerHTML;
        if (currentScales && currentScales.total_gas && currentScales.total_gas.max === 100000 && content === '1…') {
          return '100k';
        }
        return element.childNodes[0].innerHTML;
      }
      return element.innerHTML;
    }).filter(x => x);
    const newPositionsLeft = [...childNodes].map(element => `calc(${element.getAttribute('x')}px - 18px)`);

    setRangeValues(labels);
    setPositionsLeft(newPositionsLeft);
  };

  useEffect(() => {
    if (activeWell !== selectedWell) {
      setCurrentWell(selectedWell);
    }
  }, [selectedWell]);

  useEffect(() => {

    const totalGasConfig = createDefaultConfig(
      scales,
      displayZoomBtn,
      propagateZoom,
      getToolTipValues,
      getDefaultSeries,
      defaultCrossHair,
      totalGasData,
      totalCarbon,
      c1Normalized,
      gqr
    );

    const chartInstance = Highcharts.chart(id, totalGasConfig);
    if (chartInstance) {
      setChart(chartInstance);
      positioningTheLabels(chartInstance);
      zoomRefId.current = applyDefaultZoomToChart(chartInstance);

      if (currentWell && depth && depth.length && chart && chart.xAxis && chart.xAxis[0]) {
        chart.xAxis[0].setExtremes(min, max);
      }

      GlobalCutOff.registerInstances(
        'totalGas',
        chartInstance
      );

      syncronizeTooltip(`#${id}`);
    }
  }, []);

  useEffect(() => {
    if (
      activeWell && (chart
      || updateChart
      || totalCarbon && totalCarbon.length && (currentWell?.calculated?.total_carbon.data !== totalCarbon)
      || c1Normalized && c1Normalized.length
      || totalGasData && totalGasData.length
      || gqr && gqr.length)
    ) {
      if (chart && chart.update) {
        const newTotalGasData = totalGasData || [];
        const newTotalCarbon = totalCarbon || [];
        const newC1Normalized = c1Normalized || [];
        const newGqr = gqr || [];
        chart.update({
          series: getDefaultSeries(
            newTotalGasData,
            newTotalCarbon,
            newC1Normalized,
            newGqr
          )
        });
        chart.redraw();
        positioningTheLabels(chart);
      }

      if (currentWell && depth && depth.length && chart && chart.xAxis && chart.xAxis[0]) {
        chart.xAxis[0].setExtremes(min, max);
      }
    }
  }, [totalCarbon, depth, chart, c1Normalized, totalGasData, gqr, updateChart]);

  useEffect(() => {
    const setSeries = series[1];
    const genericToggle = ({ detail: { index } }) =>
      toggleCurves(chart, setSeries, index);
    const updateSetings = (chartInstance, indexSerie) => ({ detail }) => {
      if (chartInstance.yAxis && chartInstance.yAxis[indexSerie]) {
        chartInstance.yAxis[indexSerie].update(
          {
            min: formattingMin(detail.min),
            max: detail.max
          }
        );
        chartInstance.redraw();
        positioningTheLabels(chartInstance);
      }
      if (chartInstance.series && chartInstance.series[indexSerie]) {
        const serie = chartInstance.series[indexSerie];
        serie.update({
          color: detail.color,
          lineColor: detail.color
        });
        serie.redraw();
      }
    };

    const synchronizeSelectedDepth = event => {
      const {
        detail: { min: selectedMin, max: selectedMax }
      } = event;
      chart.xAxis[0].setExtremes(selectedMin, selectedMax, false, false);
      chart.redraw();
    };

    if (chart) {
      document.addEventListener(TOGGLE_TOTAL_GAS, genericToggle);
      document.addEventListener(TOGGLE_TOTAL_CARBON, genericToggle);
      document.addEventListener(TOGGLE_C1_NORMALIZED, genericToggle);
      document.addEventListener(TOGGLE_GQR, genericToggle);

      document.addEventListener(SYNCHRONIZED_EVENT, synchronizeSelectedDepth);

      // TODO add change scales of total carbon and c1Normalized
      // document.addEventListener(
      //   TOTAL_GAS_EVENT,
      //   updateSetings(chart, 0, positioningTheLabels)
      // );
      document.addEventListener(
        TOTAL_GAS_EVENT,
        updateSetings(chart, 1, positioningTheLabels)
      );
      document.addEventListener(
        TOTAL_CARBON_EVENT,
        updateSetings(chart, 2, positioningTheLabels)
      );
      document.addEventListener(
        C1_NORMALIZED_EVENT,
        updateSetings(chart, 3, positioningTheLabels)
      );
      document.addEventListener(
        GQR_EVENT,
        updateSetings(chart, 4, positioningTheLabels)
      );
      return () => {
        document.removeEventListener(TOGGLE_TOTAL_GAS, genericToggle);
        document.removeEventListener(TOGGLE_TOTAL_CARBON, genericToggle);
        document.removeEventListener(TOGGLE_C1_NORMALIZED, genericToggle);
        document.removeEventListener(TOGGLE_GQR, genericToggle);

        document.removeEventListener(SYNCHRONIZED_EVENT, synchronizeSelectedDepth);

        document.removeEventListener(TOTAL_GAS_EVENT, updateSetings);
        document.removeEventListener(TOTAL_CARBON_EVENT, updateSetings);
        document.removeEventListener(C1_NORMALIZED_EVENT, updateSetings);
        document.removeEventListener(GQR_EVENT, updateSetings);

        clearTimeout(zoomRefId);
      }
    }
  }, [chart]);
  return (
    <ChartContainer
      className="total-gas-container"
      style={{ marginRight: 3, marginLeft: 10 }}
    >
      <ChartHeader
        rangeValues={rangeValues}
        positionsLeft={positionsLeft}
      />
      <div
        id={id}
        style={{
          height: 'calc(100% - 180px)'
        }}
        data-cy="chart-total-gas"
      />
    </ChartContainer>
  );
};

TotalGas.defaultProps = {
  id: 'total-gas',
  parentContainer: '.charts-container'
};

export default memo(TotalGas);
