import { Button } from "@mui/material";
import styled from "styled-components";

interface BaseProps {
  contentAlign?: 'start' | 'center' | 'end'
}

const Base = styled(Button)<BaseProps>`
  && {
    color: #FDFDFD;
    font-size: 16px;
    font-weight: 500;
    padding: 8px 16px;
    gap: 8px;
    border-radius: 6px;
    text-transform: none;
    height: 36px;
    display: flex;
    justify-content: ${(props) => props.contentAlign ?? 'center'};
  }
  .MuiButton-startIcon, .MuiButton-endIcon {
    margin: 0;
  }
  &:disabled {
    color: #FDFDFD !important;
    opacity: 0.5;
  }
`

export default Base;