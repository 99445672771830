import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import * as pallete from '@geowellex/shared-ui/src/theme/default'

export const StyledButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    color: pallete.customTheme.gray1,
    border: `1px solid ${pallete.customTheme.purple3}`,
    lineHeight: 1.5,
    backgroundColor: 'transparent',
    borderColor: pallete.customTheme.purple3,
    fontFamily: [
    'Roboto',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        backgroundColor: pallete.customTheme.purple3,
        borderColor: pallete.customTheme.purple3,
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: pallete.customTheme.purple3,
        borderColor: pallete.customTheme.purple3,
    },
    '&:focus': {
        boxShadow: `0 0 0 0.2rem ${pallete.customTheme.purple3}`,
    },
});