import { customTheme } from '../../../../theme/default';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const Container = styled(Box)(() => (() => `
  display: flex;
`));

export const CardsWrapper = styled(Box)(() => (() => `
  width: 100%;
  height: 82px;
  display: flex;
`));

export const ChartsWrapper = styled(Box)(() => (() => `
  margin-top: 20px;
  width: 100%;
  height: calc(96vh - 127px); // 82px + 20px + 20px = 127px
  -webkit-box-shadow: -2px 3px 24px -2px rgba(13,13,17,1);
  -moz-box-shadow: -2px 3px 24px -2px rgba(13,13,17,1);
  box-shadow: -2px 3px 24px -2px rgba(13,13,17,1);
  border-radius: 8px;
  border: 1px solid #323752;
  padding: 4px;
  background-color: #202438;
`));

export const MainWrapper = styled(Box)(() => (() => `
  display: flex;
  flex-direction: column;
  background: ${customTheme.bg};
  width: 100%;
  height: 100vh;
  padding: 30px 30px 0px 20px;
`));
