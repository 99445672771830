import { fill } from "lodash";

export const formatLabels = data =>
  (currentItem) => parseFloat(data[currentItem.value])?.toFixed();

const COLORS = {
  serieColor: '#FDFDFD',
  black: '#FDFDFD',
  transparentLine: 'rgba(255,255,255,0)',
  white: '#fff',
  gray: '#FDFDFD',
};

export const getDefaultSerie = data => [{
  name: '',
  inverted: true,
  type: 'area',
  lineWidth: 0,
  strokeWidth: 0,
  yAxis: 0,
  data,
  gridLineWidth: 0,
  startOnTick: true,
  endOnTick: true,
  allowDecimals: true,
  showFirstLabel: false,
  showLastLabel: false,
  tickPosition: 'inside',
  fillColor: 'rgba(0, 0, 0, 0)',
  lineColor: 'rgba(0, 0, 0, 0)',
  tickColor: COLORS.gray,
  tickLength: 7,
  tickPixelInterval: 100,
  tickInterval: 100,
  minorTickInterval: 30000,
  dashStyle: ' ',
  showinlegend: false,
  color: COLORS.serieColor,
  enablemousetracking: false,
  marker: {
    states: {
      hover: {
        enabled: false
      }
    }
  }
}];

export const getConfig = (data, zoomType) => ({
  series: getDefaultSerie(data),
  chart: {
    backgroundColor: '#151724',
    plotBackgroundColor: '#151724',
    marginLeft: 5,
    zoomType,
    inverted: true,
    width: 100,
    alignTicks: false,
    resetZoomButton: {
      position: { x: 0, y: -50 }
    },
    labels: {
      style: {
        colors: COLORS.gray,
      },
    },
    margin: [4, 0, 4, 0], 
  },
  title: ' ',
  subtitle: {
    text: '  ',
    align: 'left'
  },
  credits: {
    enabled: false
  },
  exporting: { enabled: false },
  xAxis: [
    {
      tickInterval: 400,
      tickPixelInterval: 10,
      tickPosition: 'inside',
      tickColor: '#FDFDFD',
      tickWidth: 0,
      lineColor: 'rgba(0, 0, 0, 0)',
      lineWidth: 0,
      labels: {
        x: 6,
        y: 4,
        align: 'left',
        style: {
          color: COLORS.gray,
        },
        formatter: formatLabels(data)
      },
      gridLineWidth: 0,
      linkedTo: 0,
      startOnTick: true,
      endOnTick: true,
      allowDecimals: true,
      showFirstLabel: true,
      showLastLabel: true,
    },
    {
      // config of the RIGHT ticks and label
      tickInterval: 300,
      tickPosition: 'outinside',
      tickWidth: 0,
      lineColor: 'rgba(0, 0, 0, 0)',
      left: 97,
      lineWidth: 0,
      gridLineWidth: 0,
      labels: {
        x: -4,
        y: 4,
        align: 'right',
        style: {
          color: COLORS.gray,
        },
        formatter: formatLabels(data)
      },
      linkedTo: 0,
      allowDecimals: true,
      showFirstLabel: true,
      showLastLabel: true
    }
  ],
  yAxis: [
    {
      minorTickInterval: 50000,
      tickInterval: 100000,
      min: 0,
      max: 2,
      gridLineWidth: 0,
      labels: {
        format: ' ',
        color: COLORS.gray,
        style: {
          color: COLORS.gray
        }
      },
      title: {
        text: '',
        y: -28,
        style: {
          marginBottom: 10,
          color: '#FDFDFD'
        }
      },
      offset: -40,
      opposite: true,
      allowDecimals: true
    },
  ],
  legend: {
    layout: 'vertical',
    align: 'left',
    x: 90,
    verticalAlign: 'top',
    y: -10,
    floating: true
  }
});
