import {
  calculateWetness, calculateBalanceRatio, calculateCharacterRatio
} from './GasesCalculations';
import get from 'lodash.get';
/* eslint no-new-func: 0 */
const fnWithExpressions = (filterFnKey, expressions) => new Function(
  filterFnKey,
  `return ${expressions || filterFnKey}`
);

const seriesMap = {
  gqr: {
    instanceName: 'totalGas',
    indexSerie: 4,
    processedDataName: 'gqr'
  },
  c1: {
    instanceName: 'chromatography',
    indexSerie: 1
  },
  c2: {
    instanceName: 'chromatography',
    indexSerie: 2
  },
  c3: {
    instanceName: 'chromatography',
    indexSerie: 3
  },
  ic4: {
    instanceName: 'chromatography',
    indexSerie: 4
  },
  nc4: {
    instanceName: 'chromatography',
    indexSerie: 5
  },
  ic5: {
    instanceName: 'chromatography',
    indexSerie: 6
  },
  nc5: {
    instanceName: 'chromatography',
    indexSerie: 7
  },
  c1Composition: {
    instanceName: 'gasComposition',
    indexSerie: 1
  },
  c2Composition: {
    instanceName: 'gasComposition',
    indexSerie: 2
  },
  c3Composition: {
    instanceName: 'gasComposition',
    indexSerie: 2
  },
  nc4Composition: {
    instanceName: 'gasComposition',
    indexSerie: 4
  },
  nc5Composition: {
    instanceName: 'gasComposition',
    indexSerie: 5
  },
  character: {
    instanceName: 'characterRatio',
    indexSerie: 1
  },
  balance: {
    instanceName: 'characterRatio',
    indexSerie: 2
  },
  wetness: {
    instanceName: 'characterRatio',
    indexSerie: 3
  }
};

const characterMap = [
  {
    instanceName: 'characterRatio',
    indexSerie: 1,
    processedDataName: 'character'
  },
  {
    instanceName: 'characterRatio',
    indexSerie: 2,
    processedDataName: 'balance'
  },
  {
    instanceName: 'characterRatio',
    indexSerie: 3,
    processedDataName: 'wetness'
  }
];

const ic4ByNc4Map = {
  instanceName: 'slopeFactor',
  indexSerie: 4,
  processedDataName: 'ic4BynC4'
};

const ic5ByNc5Map = {
  instanceName: 'slopeFactor',
  indexSerie: 3,
  processedDataName: 'ic5BynC5'
};

const slopeAndCharacter = [
  {
    instanceName: 'slopeFactor',
    indexSerie: 2,
    processedDataName: 'c1ByC2'
  },
  {
    instanceName: 'slopeFactor',
    indexSerie: 1,
    processedDataName: 'slopeFactor'
  },
  ...characterMap
];

const allRelatedCharts = [
  {
    instanceName: 'chromatography',
    indexSerie: 1,
    processedDataName: 'c1'
  },
  {
    instanceName: 'chromatography',
    indexSerie: 2,
    processedDataName: 'c2'
  },
  {
    instanceName: 'chromatography',
    indexSerie: 3,
    processedDataName: 'c3'
  },
  {
    instanceName: 'chromatography',
    indexSerie: 4,
    processedDataName: 'ic4'
  },
  {
    instanceName: 'chromatography',
    indexSerie: 5,
    processedDataName: 'nc4'
  },
  {
    instanceName: 'chromatography',
    indexSerie: 6,
    processedDataName: 'ic5'
  },
  {
    instanceName: 'chromatography',
    indexSerie: 7,
    processedDataName: 'nc5'
  },
  {
    instanceName: 'gasComposition',
    indexSerie: 1,
    processedDataName: 'c1Composition'
  },
  {
    instanceName: 'gasComposition',
    indexSerie: 1,
    processedDataName: 'c1Composition'
  },
  {
    instanceName: 'gasComposition',
    indexSerie: 2,
    processedDataName: 'c2Composition'
  },
  {
    instanceName: 'gasComposition',
    indexSerie: 3,
    processedDataName: 'c3Composition'
  },
  {
    instanceName: 'gasComposition',
    indexSerie: 4,
    processedDataName: 'c4Composition'
  },
  {
    instanceName: 'gasComposition',
    indexSerie: 5,
    processedDataName: 'c5Composition'
  },
  ic4ByNc4Map,
  ic5ByNc5Map,
  ...slopeAndCharacter
];

const generateC15ToProcess = gasKey => (currentWell, filter) => {
  const data = currentWell;
  const { filterFnKey, expressions } = filter;

  let key = gasKey;
  const fnToValidatePredicate = fnWithExpressions(filterFnKey, expressions);

  let datasetName = '';
  let dataToMap = [];

  if (currentWell?.RAW?.[key]) {
    datasetName = 'RAW';
    dataToMap = currentWell?.RAW?.[key];
  }
  if (currentWell?.calculated?.[key]) {
    datasetName = 'calculated';
    dataToMap = currentWell?.calculated?.[key];
  }

  if (currentWell?.gqc && Object.keys(currentWell?.gqc)?.length > 1 || currentWell?.gqcData && Object.keys(currentWell?.gqcData)?.length > 1) {
    datasetName = 'gqcData';
    dataToMap = currentWell?.gqcData?.[key]?.data;
  }

  const calculatedOrGqcKey = datasetName === 'gqc' || datasetName === 'gqcData' ? 'gqcData': 'calculated';
  const gqcOrRAWKey = datasetName === 'gqc' ? 'qgcData': 'RAW';

  const c1 = data?.[gqcOrRAWKey]?.c1?.data;
  const c1Composition = data?.[calculatedOrGqcKey]?.c1_perc?.data;
  const c2Composition = data?.[calculatedOrGqcKey]?.c2_perc?.data;
  const c3Composition = data?.[calculatedOrGqcKey]?.c3_perc?.data;
  const nc4Composition = data?.[calculatedOrGqcKey]?.c4_perc?.data;
  const nc5Composition = data?.[calculatedOrGqcKey]?.c5_perc?.data;
  const slopeFactor = data?.[calculatedOrGqcKey]?.slope_factor?.data;
  const ic4BynC4 = data?.[calculatedOrGqcKey]?.ic4nc4?.data;
  const ic5BynC5 = data?.[calculatedOrGqcKey]?.ic5nc5?.data;
  const gqr = currentWell?.[calculatedOrGqcKey] || [];

  const datasetData = datasetName === 'gqc' || datasetName === 'gqcData' ? dataToMap : dataToMap?.data;
  return (datasetData || []).reduce((acc, currentGas, index) => {
    const fnToValidatePredicate = fnWithExpressions(filterFnKey, expressions);
    const dataset = currentWell?.[datasetName];
    const currentGasValue = dataset?.[key]?.data?.[index];

    const isValid = fnToValidatePredicate(currentGasValue);
    if (isValid || isValid === currentGasValue) {
      // const currentDataValue = data[key][index];
      // C1 composition
      const currentC1 = currentGas;
      const calculatedData = datasetName === 'gqc' || datasetName === 'gqcData'
        ? currentWell?.[calculatedOrGqcKey] : currentWell?.[calculatedOrGqcKey];
      const c1Comp = calculatedData?.c1_perc?.data[index];
      const c2Comp = calculatedData?.c2_perc?.data[index];
      const c3Comp = calculatedData?.c3_perc?.data[index];
      const c4Comp = calculatedData?.c4_perc?.data[index];
      const c5Comp = calculatedData?.c5_perc?.data[index];

      acc.c1.push(currentC1);
      acc.c1Composition.push(c1Comp);

      const rawData = datasetName === 'gqc' || datasetName === 'gqcData'
        ? currentWell?.[gqcOrRAWKey] : currentWell?.[gqcOrRAWKey];
      const currentC2 = rawData?.c2?.data[index];
      acc.c2.push(currentC2);
      acc.c2Composition.push(c2Comp);

      const currentC3 = rawData?.c3?.data[index];
      acc.c3.push(currentC3);
      acc.c3Composition.push(c3Comp);

      const currentNC4 = rawData?.nc4?.data[index];
      const currentIc4 = rawData?.ic4?.data[index];
      acc.nc4.push(currentNC4);
      acc.ic4.push(currentIc4);
      acc.c4Composition.push(c4Comp);

      const currentNC5 = rawData?.nc5?.data[index];
      acc.nc5.push(currentNC5);

      const currentIc5 = rawData?.ic5?.data[index];
      acc.ic5.push(currentIc5);
      acc.c5Composition.push(c5Comp);

      const currentSlope = calculatedData?.slope_factor?.data[index];
      acc.slopeFactor.push(currentSlope);

      const curentC1byC2 = calculatedData?.c1c2?.data[index];
      acc.c1ByC2.push(curentC1byC2)

      // Character, balance, wetness
      const character = calculatedData?.ch_ratio?.data[index];
      const balance = calculatedData?.bh_ratio?.data[index];
      const wetness = calculatedData?.wh_ratio?.data[index];
      acc.wetness.push(wetness);
      acc.balance.push(balance);
      acc.character.push(character);

      const currentIc4BynC4 = calculatedData?.ic4nc4?.data[index];
      const currentIc5BynC5 = calculatedData?.ic5nc5?.data[index];
      acc.ic4BynC4.push(currentIc4BynC4);
      acc.ic5BynC5.push(currentIc5BynC5);

      acc.gqr.push(dataToMap[index] || null);
    } else {
      acc.c1ByC2.push(null);
      acc.wetness.push(null);
      acc.balance.push(null);
      acc.character.push(null);
      acc.ic4BynC4.push(null);
      acc.ic5BynC5.push(null);
      acc.c1.push(null);
      acc.c2.push(null);
      acc.c3.push(null);
      acc.nc4.push(null);
      acc.ic4.push(null);
      acc.ic5.push(null);
      acc.nc5.push(null);
      acc.c1Composition.push(null);
      acc.c2Composition.push(null);
      acc.c3Composition.push(null);
      acc.c4Composition.push(null);
      acc.c5Composition.push(null);
      acc.slopeFactor.push(null);
      acc.gqr.push(null);
    }
    return acc;
  }, {
    c1: [],
    c2: [],
    c3: [],
    nc4: [],
    ic4: [],
    nc5: [],
    ic5: [],
    c1Composition: [],
    c2Composition: [],
    c3Composition: [],
    c4Composition: [],
    c5Composition: [],
    c1ByC2: [],
    wetness: [],
    balance: [],
    character: [],
    ic4BynC4: [],
    ic5BynC5: [],
    slopeFactor: [],
    gqr: []
  });
};

/*
 * CurveRelation
 * this is a simple map to access curves that rely on c1 value wich
 * needs to be processed again after apply the filter
 *
 * IMPORTANT: each curve that relies of c1, c2, c3, c4, c5
 * for example gas composition, character, wetness, balance
 * we gonna walking through each item and store these indexes
 * to access and apply these values to others charts, because
 * all values are already calculated.
 *
 * When we are apply cutoffs to curves in PPM, c1, c2, c3, c4, c5
 * this curve should recalculate everything that relies
 */
const curveRelations = {
  /*
   * This array has all information of each related chart that will be affected
   * by the c1Composition filter what means that they will display the data
   * */
  c1Composition: {
    relatedChartInData: allRelatedCharts,
    fnToProcess: generateC15ToProcess('c1Composition')
  },
  c2Composition: {
    relatedChartInData: allRelatedCharts,
    fnToProcess: generateC15ToProcess('c2Composition')
  },
  c3Composition: {
    relatedChartInData: allRelatedCharts,
    fnToProcess: generateC15ToProcess('c3Composition')
  },
  nc4Composition: {
    relatedChartInData: allRelatedCharts,
    fnToProcess: generateC15ToProcess('nc4Composition')
  },
  nc5Composition: {
    relatedChartInData: allRelatedCharts,
    fnToProcess: generateC15ToProcess('nc5Composition')
  },
  gqr: {
    relatedChartInData: allRelatedCharts,
    fnToProcess: generateC15ToProcess('gqr')
  },
  c1: {
    relatedChartInData: allRelatedCharts,
    fnToProcess: generateC15ToProcess('c1')
  },
  c2: {
    relatedChartInData: allRelatedCharts,
    fnToProcess: generateC15ToProcess('c2')
  },
  c3: {
    relatedChartInData: allRelatedCharts,
    fnToProcess: generateC15ToProcess('c3')
  },
  nc4: {
    relatedChartInData: allRelatedCharts,
    fnToProcess: generateC15ToProcess('nc4')
  },
  ic4: {
    relatedChartInData: allRelatedCharts,
    fnToProcess: generateC15ToProcess('ic4')
  },
  nc5: {
    relatedChartInData: allRelatedCharts,
    fnToProcess: generateC15ToProcess('nc5')
  },
  ic5: {
    relatedChartInData: allRelatedCharts,
    fnToProcess: generateC15ToProcess('ic5')
  },
  balance: {
    relatedChartInData: allRelatedCharts,
    fnToProcess: generateC15ToProcess('balance')
  },
  character: {
    relatedChartInData: allRelatedCharts,
    fnToProcess: generateC15ToProcess('character')
  },
  wetness: {
    relatedChartInData: allRelatedCharts,
    fnToProcess: generateC15ToProcess('wetness')
  }
};

/*
 * generateResetFilter
 */
const generateResetFilter = (curveName, curveRelationsMap) => {
  const filter = { curveName, filterFnKey: curveName };
  const relatedChartsFn = curveRelationsMap[filter.curveName];
  return { ...filter, ...relatedChartsFn };
};

const checkCurvesToReset = (oldFilters, newFilters) => {
  const curvesFromFilters = newFilters && newFilters.map(filter => filter.curveName);
  // Whether there is filter but this is not on the new filters reset it
  return oldFilters.reduce((acc, curveName, index) => {
    if (curveName && curvesFromFilters && !curvesFromFilters.includes(curveName)) {
      acc.toReset.push(curveName);
      acc.newFilters.splice(index, 1);
    }
    return acc;
  }, { newFilters: [...oldFilters], toReset:[] });
};

/**
 * FilterProcessor - Has the purpose to manipulate and propagate each filter applyed to the curve
 * C1 relations
 * C1 PPM is used on
 * C1 composition
 *  Character
 *  Wetness
 *
 *  When this some kind of this curve is related between them receive cutoff, this should process
 *  the data again and also needs to update the charts.
 *
 * Create a util class to recalculate and return the array of values that are associated
 * this class should propagate the change to the redux
 * update the global state
 * store this cutt offs on the indexDB
 *
 * Be able to process and unprocess
 *
 */
class CutOffProcessor {
  // store the necessary things to apply and propagate all filters to each curve
  constructor() {
    this.instances = {};
    this.currentWell = null;
    this.receiveWell = null;
    this.dispatch = null;
    this.action = null;
    this.filter = {};
    this.data = null;
    this.origData = null;
    this.filtersCurves = [];
    this.filters = null;
    this.redux = null;
    this.shouldCutoffCurves = true;
    this.qualityControlChart = null;
  }

  registerInstances = (chartName, instance) => {
    this.instances[chartName] = instance;
  }

  registerDispatch = dispatch => {
    this.dipatch = dispatch;
  }

  registerDefaultData(data) {
    this.data = data;
    this.origData = data;
  }

  undoFilter = curveName => {
    this.filter = generateResetFilter(curveName, curveRelations);
    this.getFnsFromAllRelatedChart(curveName, true);
  }

  resetCurves(filters, currentWell, afterReset) {
    this.qualityControlChart = this.instances['quality-control-chart'];
    const qualityData = this.data?.RAW?.depth?.data.reduce((acc, curveValue, index) => {
      acc.badData[index] = 0;
      acc.goodData[index] = 100;
      return acc;
    }, { goodData: [], badData: [] });

    // update quality chart
    this.qualityControlChart.series[0].update(
      {
        data: qualityData,
      },
      true
    );
    this.qualityControlChart.series[1].update(
      {
        data: qualityData,
      },
      true
    );

    this.storeOnRedux({ qualityData, data: currentWell });

    // Reset Curves
    const curvesToReset = checkCurvesToReset(this.filtersCurves, filters);
    curvesToReset.toReset.reduce((acc, curve) => {
      this.undoFilter(curve);
      return acc;
    }, []);

    if(afterReset) {
      afterReset(qualityData)
    }

    // Removing old reseted curves
    this.filtersCurves = curvesToReset.newFilters;
  }

  // create filter by curveName
  registerFilters(wellId, filters, shouldCutoffCurves, currentWell) {
    this.filters = filters;

    // control when will cut each curve
    this.shouldCutoffCurves = shouldCutoffCurves;

    // Reset curves
    const curvesToReset = checkCurvesToReset(this.filtersCurves, filters);
    curvesToReset.toReset.reduce((acc, curve) => {
      this.undoFilter(curve);
      return acc;
    }, []);

    // Removing old reseted curves
    this.filtersCurves = curvesToReset.newFilters;

    // concact filters by curves
    const formattedFilters = filters.reduce((acc, currentFilter) => {
      const indexOfCurveName = acc.curveNames.findIndex(curveName => curveName === currentFilter.curveName);
      if (indexOfCurveName >= 0) {
        const previousFilterOfTheSameCurve = filters[indexOfCurveName];
        acc.filters[indexOfCurveName] = {
          ...currentFilter,
          expression: '&&',
          expressions: `${previousFilterOfTheSameCurve.expressions} && ${currentFilter.expressions}`
        };
      } else {
        acc.curveNames.push(currentFilter.curveName);
        acc.filters.push(currentFilter);
      }
      return acc;
    }, { curveNames: [], filters: [] });

    this.data = currentWell;

    // Apply the filters
    /* eslint-disable */
    for (const filterKey in formattedFilters.filters) {
      const filter = filters[filterKey];
      const { curveName } = filter;
      const relatedChartsFn = curveRelations[curveName];

      this.filter = { ...filter, ...relatedChartsFn };
      this.addCurves(curveName);
      this.getFnsFromAllRelatedChart(curveName);
    }
  }

  addCurves = curveName => {
    if (!this.filtersCurves.includes(curveName)) {
      this.filtersCurves.push(curveName);
    }
  }

  // should access all curve relation and process this data
  getFnsFromAllRelatedChart = (curveName, shouldReset = false) => {
    const currentRelationCurve = curveRelations[curveName];
    const toProcess = currentRelationCurve?.fnToProcess;
    if (toProcess) {
      this.processAllData(curveName, toProcess, shouldReset);
    }
  }

  // Access the last data processed walk through each filter array of each related chart and curve and process data
  // should get all data related with the current curve and should execute the calculation
  // Get the base value C1 in PPM, apply cutoffs and reuse it on other places

  processAllData(curveName, fnToProcessAllData, shouldReset) {
    const dataToProcess = this.origData;
    const processedData = fnToProcessAllData(dataToProcess, this.filter);
    this.updateSeries(curveName, processedData);
  }

  // should receive the data and apply
  updateSeries = (curveName, data) => {
    this.qualityControlChart = this.instances['quality-control-chart'];
    const { instances } = this;
    const currentCurveData = data[curveName];

    const { instanceName, indexSerie } = seriesMap[curveName];
    const chart = instances[instanceName];
    // Update all charts that relies from the the current curve
    // -access all instance
    // -get the config series and index
    // -walking through a loop to update it
    if (this.shouldCutoffCurves) {
      chart?.series[indexSerie].update({
        data: currentCurveData
      });

      const relatedConfigCharts = curveRelations[curveName].relatedChartInData;
      relatedConfigCharts.forEach(currentConfig => {
        /* eslint no-shadow: 0 */
        const { instanceName, indexSerie, processedDataName } = currentConfig;
        const instanceChart = this.instances[instanceName];

        const currentConfigData = data[processedDataName];

        // usign the current cutoff to generate good and bad Data
        // genearate GQR good/bad data
        instanceChart?.series[indexSerie].update({
          data: currentConfigData
        });
        instanceChart?.redraw();
      });
    }
    if (this.qualityControlChart?.series[0]) {
      const qualityData = currentCurveData.reduce((acc, curveValue, index) => {
        if (!curveValue) {
          acc.badData[index] = 100;
          acc.goodData[index] = 0;
        } else {
          acc.badData[index] = 0;
          acc.goodData[index] = 100;
        }
        return acc;
      }, { goodData: [], badData: [] });
      // update quality chart
      this.qualityControlChart?.series[0]?.update(
        {
          data: qualityData,
        },
        true
      );
      this.qualityControlChart?.series[1]?.update(
        {
          data: qualityData,
        },
        true
      );
      this.storeOnRedux({ qualityData, data });
    }
  }

  setAction = (filterAction) => {
    this.filterAction = filterAction;
  }

  connectToRedux = (receiveWell, currentWell, state, dispatch) => {
    this.receiveWell = receiveWell;
    this.currentWell = currentWell;
    this.redux = state;
    this.dispatch = dispatch;
  }

  registerData = (currentWell, state) => {
    this.currentWell = currentWell;
    this.redux = state;
  }

  storeOnRedux = (newData) => {
    // refactor the keys here
    if (this.filterAction && this.filters) {
      this?.dispatch(
        this.filterAction(this.filters)
      );
    }

    this?.dispatch(
      this?.receiveWell({
        ...this?.currentWell,
        RAW: {
          ...this?.currentWell?.RAW,
          c1: {
            ...this?.currentWell?.RAW?.c1,
            data: newData?.data?.c1,
          },
          c2: {
            ...this?.currentWell?.RAW?.c2,
            data: newData?.data?.c2,
          },
          c3: {
            ...this?.currentWell?.RAW?.c3,
            data: newData?.data?.c3,
          },
          ic4: {
            ...this?.currentWell?.RAW?.ic4,
            data: newData?.data?.ic4,
          },
          nc4: {
            ...this?.currentWell?.RAW?.nc4,
            data: newData?.data?.nc4,
          },
        },
        calculated: {
          ...this?.currentWell?.calculated,
          qualityData: newData?.qualityData,
          c1_perc: {
            ...this?.currentWell?.calculated?.c1_perc,
            data: newData?.data?.c1Composition,
          },
          c2_perc: {
            ...this?.currentWell?.calculated?.c2_perc,
            data: newData?.data?.c2Composition,
          },
          c3_perc: {
            ...this?.currentWell?.calculated?.c3_perc,
            data: newData?.data?.c3Composition,
          },
          c4_perc: {
            ...this?.currentWell?.calculated?.c4_perc,
            data: newData?.data?.c4Composition,
          },
          c5_perc: {
            ...this?.currentWell?.calculated?.c5_perc,
            data: newData?.data?.c5Composition,
          },
          c1c2: {
            ...this?.currentWell?.calculated?.c1c2,
            data: newData?.data?.c1ByC2,
          },
          ic4nc4: {
            ...this?.currentWell?.calculated?.ic4nc4,
            data: newData?.data?.ic4BynC4,
          },
          ic5nc5: {
            ...this?.currentWell?.calculated?.ic5nc5,
            data: newData?.data?.ic5BynC5,
          },
          wh_ratio: {
            ...this?.currentWell?.calculated?.wh_ratio,
            data: newData?.data?.wetness,
          },
          bh_ratio: {
            ...this?.currentWell?.calculated?.bh_ratio,
            data: newData?.data?.balance,
          },
          ch_ratio: {
            ...this?.currentWell?.calculated?.ch_ratio,
            data: newData?.data?.character,
          },
          slope_factor: {
            ...this?.currentWell?.calculated?.slope_factor,
            data: newData?.data?.slopeFactor,
          },
          gqr: {
            ...this?.currentWell?.calculated?.gqr,
            data: newData?.data?.gqr,
          },
        }
    }));
  }
}

export default new CutOffProcessor();
