import {
  DELETE_ALL,
  RECEIVE_ZONE,
  RECEIVE_ZONES,
  UPDATE_ZONE
} from '../constants/zone';

export const deleteAll = () => ({
  type: DELETE_ALL
});

export const receiveZones = zones => ({
  type: RECEIVE_ZONES,
  zones
});

export const receiveZone = zone => ({
  type: RECEIVE_ZONE,
  zone
});

export const updateSingleZone = zone => ({
  type: UPDATE_ZONE,
  zone
})
