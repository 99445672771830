import { CompactPicker, RGBColor, AlphaPicker } from 'react-color';
import { BoxColor, ColorPickerCompact, ColorPickerContainer, ContainerInputColor, WrapperBoxColor } from './styles';
import { CSSProperties, useState } from 'react';
import { Popover } from '@mui/material';

export const InputColor = ({ colorInput, onChange }: any) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  function rgbToHex(colorRGB: any) {
    const { r, g, b } = colorRGB;
    return "#" + ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1);
  }

  const handleBoxColor = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeColor = (color: { rgb: RGBColor }) => {
    onChange({ ...color?.rgb, a: colorInput?.a });
  };

  const handleChangeOpacity = (color: { rgb: RGBColor }) => {
    onChange({ ...colorInput, a: color?.rgb?.a });
  };

  const selectedColour: CSSProperties = {
    backgroundColor: `rgba(${colorInput?.r},${colorInput?.g}, ${colorInput?.b}, ${colorInput?.a})`,
  };

  const open = Boolean(anchorEl);

  return (
    <ContainerInputColor>
      <span>Color</span>
      <WrapperBoxColor>
        <BoxColor style={selectedColour} onClick={handleBoxColor} />
        <span>{rgbToHex(colorInput).toUpperCase()}</span>
      </WrapperBoxColor>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ColorPickerContainer>
          <ColorPickerCompact>
            <CompactPicker
              color={colorInput}
              onChangeComplete={handleChangeColor}
            />
          </ColorPickerCompact>
          <AlphaPicker
            color={colorInput}
            onChange={handleChangeOpacity}
          />
        </ColorPickerContainer>
      </Popover>
    </ContainerInputColor>
  )
}