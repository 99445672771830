import '../../style.scss';
import './style.scss';
import { customTheme } from '@geowellex/shared-ui/src/theme/default/index.tsx';

import React from 'react';

import {
  Button,
  Divider,
  Drawer,
  Input
} from 'antd';
import { useFormik } from 'formik';

import { errorNotification } from '../../../../utils';
import { Box } from '@mui/material';
import styled from 'styled-components';
import { ReactComponent as CloseDrawer } from 'assets/close-drawer.svg';
import { Button as ButtonMui } from '../Button/index';

const ContainerBaseline = styled(Box)`
	& .ant-drawer-content {
		background-color: ${customTheme.bg} !important;
	}
`;

const WrapperDrawerContent = styled(Box)`
  display: flex;
  height: 90%;
  flex-direction: column;
`;

const validateNumbers = (objectValue) => {
  let isValid = true;
  Object.keys(objectValue).forEach(key => {
    const value = objectValue[key];
    if (isNaN(value)) {
      isValid = false;
    }
  });
  return isValid;
};

const convertToNumbersAndFillEmptyValues = (values) => {
  const formattedValues = {};
  Object.keys(values).forEach(key => {
    const value = values[key];
    formattedValues[key] = parseFloat(value || 0);
  });
  return formattedValues;
};

const BaseLinePanel = ({
  onClose, visible, defaultBaselineValue, onSubmit
}) => {
  const validateBaseline = (values) => {
    if (!validateNumbers(values)) {
      errorNotification(`Invalid values, please type valid numbers.`);
      return;
    }
    const formattedValues = convertToNumbersAndFillEmptyValues(values);
    onSubmit(formattedValues);
    onClose();
  };

  const formik = useFormik({
    initialValues: defaultBaselineValue || {
      c1MolPercent: 0,
      c1TStdMolPercent: 0,
      c2MolPercent: 0,
      c2TStdMolPercent: 0,
      c3MolPercent: 0,
      c3TStdMolPercent: 0,
      c4MolPercent: 0,
      c4TStdMolPercent: 0,
      c5MolPercent: 0,
      c5TStdMolPercent: 0
    },
    enableReinitialize: true,
    onSubmit: validateBaseline
  });

  const { handleSubmit, handleChange, values } = formik;

  return(
    <ContainerBaseline>
    <Drawer
      placement="right"
      closable={false}
      onClose={onClose}
      visible={visible}
      width={385}
      className="selected-zones"
      getContainer={false}
    >
      <div style={{
        display: 'flex',
        flexDirection: 'column',
      }}>
      <WrapperDrawerContent>
        <div style={{
          withd: '100%',
          height: 70,
          borderBottom: `1px solid ${customTheme.purple6}`,
          display: 'flex',
          justifyContent: 'space-between',
        }}>
          <div
            className="selected-zones-title"
          >
            Baseline
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ButtonMui
              sx={{
                border: 0,
                background: 'transparent',
                padding: 0,
              }}
              onClick={onClose}
            >
              <CloseDrawer />
            </ButtonMui>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
        <h2 className="section-title">C1</h2>
        <div className="baseline-form">
          <div className="box-inputs wrapper-settings-input-molar wrapper-baseline-input">
            <label className="label-settings-molar-chart">%mol</label>
            <div className="text-min box-input-molar">
              <Input
                onChange={handleChange}
                name="c1MolPercent"
                type="text"
                placeholder="10"
                value={values.c1MolPercent}
                className="input-settings-molar-chart"
                style={{
                  border: `1px solid ${customTheme.purple6}`,
                  background: 'transparent'
                }}
              />
            </div>
          </div>
          <div className="box-inputs wrapper-settings-input-molar wrapper-baseline-input">
            <label className="label-settings-molar-chart">STD-%mol</label>
            <div className="text-min box-input-molar">
              <Input
                onChange={handleChange}
                name="c1TStdMolPercent"
                type="text"
                placeholder="10"
                value={values.c1TStdMolPercent}
                className="input-settings-molar-chart"
                style={{
                  border: `1px solid ${customTheme.purple6}`,
                  background: 'transparent'
                }}
              />
            </div>
          </div>
        </div>

        <h2 className="section-title">C2</h2>
        <div className="baseline-form">
          <div className="box-inputs wrapper-settings-input-molar wrapper-baseline-input">
            <label className="label-settings-molar-chart">%mol</label>
            <div className="text-min box-input-molar">
              <Input
                onChange={handleChange}
                name="c2MolPercent"
                type="text"
                placeholder="10"
                value={values.c2MolPercent}
                className="input-settings-molar-chart"
                style={{
                  border: `1px solid ${customTheme.purple6}`,
                  background: 'transparent'
                }}
              />
            </div>
          </div>
          <div className="box-inputs wrapper-settings-input-molar wrapper-baseline-input">
            <label className="label-settings-molar-chart">STD-%mol</label>
            <div className="text-min box-input-molar">
              <Input
                onChange={handleChange}
                name="c2TStdMolPercent"
                type="text"
                placeholder="10"
                value={values.c2TStdMolPercent}
                className="input-settings-molar-chart"
                style={{
                  border: `1px solid ${customTheme.purple6}`,
                  background: 'transparent'
                }}
              />
            </div>
          </div>
        </div>

        <h2 className="section-title">C3</h2>
        <div className="baseline-form">
          <div className="box-inputs wrapper-settings-input-molar wrapper-baseline-input">
            <label className="label-settings-molar-chart">%mol</label>
            <div className="text-min box-input-molar">
              <Input
                onChange={handleChange}
                name="c3MolPercent"
                type="text"
                placeholder="10"
                value={values.c3MolPercent}
                className="input-settings-molar-chart"
                style={{
                  border: `1px solid ${customTheme.purple6}`,
                  background: 'transparent'
                }}
              />
            </div>
          </div>
          <div className="box-inputs wrapper-settings-input-molar wrapper-baseline-input">
            <label className="label-settings-molar-chart">STD-%mol</label>
            <div className="text-min box-input-molar">
              <Input
                onChange={handleChange}
                name="c3TStdMolPercent"
                type="text"
                placeholder="10"
                value={values.c3TStdMolPercent}
                className="input-settings-molar-chart"
                style={{
                  border: `1px solid ${customTheme.purple6}`,
                  background: 'transparent'
                }}
              />
            </div>
          </div>
        </div>

        <h2 className="section-title">C4</h2>
        <div className="baseline-form">
          <div className="box-inputs wrapper-settings-input-molar wrapper-baseline-input">
            <label className="label-settings-molar-chart">%mol</label>
            <div className="text-min box-input-molar">
              <Input
                onChange={handleChange}
                name="c4MolPercent"
                type="text"
                placeholder="10"
                value={values.c4MolPercent}
                className="input-settings-molar-chart"
                style={{
                  border: `1px solid ${customTheme.purple6}`,
                  background: 'transparent'
                }}
              />
            </div>
          </div>
          <div className="box-inputs wrapper-settings-input-molar wrapper-baseline-input">
            <label className="label-settings-molar-chart">STD-%mol</label>
            <div className="text-min box-input-molar">
              <Input
                onChange={handleChange}
                name="c4TStdMolPercent"
                type="text"
                placeholder="10"
                value={values.c4TStdMolPercent}
                className="input-settings-molar-chart"
                style={{
                  border: `1px solid ${customTheme.purple6}`,
                  background: 'transparent'
                }}
              />
            </div>
          </div>
        </div>

        <h2 className="section-title">C5</h2>
        <div className="baseline-form">
          <div className="box-inputs wrapper-settings-input-molar wrapper-baseline-input">
            <label className="label-settings-molar-chart">%mol</label>
            <div className="text-min box-input-molar">
              <Input
                onChange={handleChange}
                name="c5MolPercent"
                type="text"
                placeholder="10"
                value={values.c5MolPercent}
                className="input-settings-molar-chart"
                style={{
                  border: `1px solid ${customTheme.purple6}`,
                  background: 'transparent'
                }}
              />
            </div>
          </div>
          <div className="box-inputs wrapper-settings-input-molar wrapper-baseline-input">
            <label className="label-settings-molar-chart">STD-%mol</label>
            <div className="text-min box-input-molar">
              <Input
                onChange={handleChange}
                name="c5TStdMolPercent"
                type="text"
                placeholder="10"
                value={values.c5TStdMolPercent}
                className="input-settings-molar-chart"
                style={{
                  border: `1px solid ${customTheme.purple6}`,
                  background: 'transparent'
                }}
              />
            </div>
          </div>
        </div>
        </form>
      </WrapperDrawerContent>
        <div
          className="container-actions"
          style={{
            borderTop: `1px solid ${customTheme.purple7}`,
            height: '120px',
            justifyItems: 'center',
            alignItems: 'center'
          }}
        >
          <Button
            className="btn-cancel"
            onClick={onClose}
            style={{
              width: 85,
              height: 35,
            }}
          >
            CANCEL
          </Button>
          <Button
            type="primary"
            className="btn-apply"
            htmlType="submit"
            style={{
              width: 75,
              height: 35,
              background: customTheme.blue4,
              border: 'none',
            }}
          >
            APPLY
          </Button>
        </div>
      </div>
    </Drawer>
    </ContainerBaseline>
  );
}

export default BaseLinePanel;
