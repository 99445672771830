// import buttons from './buttons';
// import colors from './colors';
import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    main: string;
    white: string;
    yellow1: string;
    gray1: string;
    purple1: string;
    purple2: string;
    purple3: string;
    purple4: string;
    bg: string;
  }

  interface PaletteOptions {
    white: string;
    main: string;
    yellow1: string;
    gray1: string;
    purple1: string;
    purple2: string;
    purple3: string;
    purple4: string;
    bg: string;
  }
}


export type ThemeProps = { theme: any };

export const customTheme = {
  white: '#fff',
  yellow1: '#F1C622',
  gray1: '#A3A8C7',
  gray2: '#FAFBFF',
  gray3: '#4B5563',
  gray4: '#7B89D3',
  gray5: '#979798',
  gray6: '#FDFDFD',
  gray7: '#D5D5D6',
  purple1: '#4f62c5',
  purple2: '#1535d7', 
  purple3: '#2F334D',
  purple4: '#202438',
  purple5: '#1A2040',
  purple6: '#525775',
  purple7: '#323752',
  bg: '#151724',
  blue1: '#212438',
  blue2: '#1A2040',
  blue3: '#232439',
  blue4: '#233BB6',
  blue5: '#A7B1E2',
  blue6: '#101C54',
  green1: '#84B53D',
  green2: '#16A34A',
  orange1: '#E19238',
  orange2: '#EA580C',
  red1: '#E58E8E',
  red2: "#ff1744",
  red3: '#F5C9C9',
  red4:  '#DC2626',
  red5:  '#B22021',
};

let theme = createTheme({
  // @ts-ignore
	palette: {
    main: customTheme.bg,
    white: '#fff',
    // yellow1: '#F1C622',
    gray1: '#A3A8C7',
    purple1: '#4f62c5',
    purple2: '#1535d7',
    purple3: '#2F334D',
    purple4: '#202438',
    bg: '#151724',
  },
  components: {
    MuiButton: { 
      styleOverrides: { 
        root: { minWidth: 0 } 
      }
    },
  }
	// colors,
	// space: ...space,
	// buttons: ...buttons,
}) as any;

const defaultTheme = createTheme(theme, {
  // Custom colors created with augmentColor go here
  palette: {
    yellow1: theme.palette.augmentColor({
      color: {
        main: '#F1C622',
      },
      name: 'yellow1',
    }),
  },
  components: {
    MuiButton: { 
      styleOverrides: { 
        root: { minWidth: 0 } 
      }
    },
  }
});
  
export default defaultTheme;