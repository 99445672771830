import styled from 'styled-components';
import * as pallete from '@geowellex/shared-ui/src/theme/default'

export const ImportFileButton = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
  height: 92px;
  padding: 24px;
  padding-right: 30px;
  border: 1px solid ${pallete.customTheme.purple3};
  border-radius: 5px;
  justify-content: flex-start;
  background-color: ${pallete.customTheme.purple4};
  margin-right: 10px;
  &:hover {
    background-color:${pallete.customTheme.purple3};
    border: 1px solid #1890ff;
    transition: all ease-in-out 0.3s;
  }

`;

export interface ImportFileButtonIconProps {
  color: string | undefined;
  icon?: any;
}

export const ImportFileButtonIcon = styled.div<ImportFileButtonIconProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 24px;
  border-radius: 50%;
  background-color: ${props => props.color};
  color: ${pallete.customTheme.white};
`;
