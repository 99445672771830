import React, { useState, useEffect, useCallback } from 'react';

import {
  Button,
  Input
} from 'antd';
import { useFormik } from 'formik';
import find from 'lodash.find';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { useLocation } from 'react-router-dom';
import { successNotification, errorNotification } from 'utils';

import MiniSpinner from '../MiniSpinner';
import { useStatisticWizard } from '../ModalStatistics/useStatisticsWizard';

// Example of Props
// curveName="C1"
// typeForm="significanceLevelC1"
// dataCurve="c1"

const FormSignificanceLevel = ({ onSubmitForm, displayCutLine, errorEmptyForm, errorPercentForm, curveName, typeForm, depthIndexToCut, showCutLine, percenteOfOccurrencesSignificanceLevel }) => {
  const formik = useFormik({
    initialValues: {
      [`significanceLevel${curveName}`]: ''
    },
    enableReinitialize: true,
    onSubmit: onSubmitForm
  });

  const { handleSubmit, setFieldValue, initialValues } = formik;

  const onChangeInput = (e) => {
    setFieldValue(typeForm, e.target.value);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="container-significance-form">
          <h3>Significance Level</h3>
          <p>Significance Level (%)*:</p>
          <Input
            type="text"
            placeholder="Ex.: 35"
            name={typeForm}
            className="container-significance-form__input"
            onChange={onChangeInput}
            defaultValue={initialValues[`significanceLevel${curveName}`]}
            disabled={displayCutLine}
            data-cy="significance-level-input"
          />
          <Button
            type="submit"
            className="container-significance-form__button"
            htmlType="submit"
            data-cy="apply-significance-level-save"
            disabled={displayCutLine}
          >
            Apply
          </Button>
        </div>
        {errorEmptyForm || errorPercentForm ?
          <div className="error-significance-level">
            <div className="container-significance-form__input--error">{errorEmptyForm || errorPercentForm}</div>
          </div> : ''
        }
      </form>
    </div>
  )}

export default FormSignificanceLevel;
