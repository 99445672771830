import React, { useMemo, useState, useCallback, useEffect, useContext } from 'react';
import UI from '@geowellex/shared-ui';
import ConfigContext from '@geowellex/shared-ui/src/context/ConfigContext';
import { customTheme } from '@geowellex/shared-ui/src/theme/default';
import { AlertTitle, Alert } from '@mui/material';
import { ZoneProps, ZoneItemType } from './types/zone';
import { useZones } from './hooks/useApi';
import { config } from './config/index';
import List from './components/List/index';
import EmptyZoneState from './components/EmptyZoneState';
import FormZone from './components/FormZone/FormZone';
import { useToggle, ViewModes } from './hooks/useToggle';
import { sortZones } from './utils/sort';
import { ReactComponent as AddIcon} from './assets/add_icon.svg';

import { SkeletonLoading, SnackbarMessage, ZoneContainer, ZoneHeader } from './styles/zone';

const Zone = ({
  currentWell,
  submitZone,
  afterDelete,
  customCallbackActions,
  isThereNoSensorType,
  closeEtheneAlert,
  isLoadingZones,
  // afterRevalidate -> callback that will provide new lists of zones when revalidat receiving new items we need to dispach to redux or do another thing after having a new one
  afterRevalidate,
  zoneData,
  getCurrentZone
}: ZoneProps) => {
  const urlParams = new URLSearchParams(window.location.search);
  const wellId = urlParams.get('wellId');
  
  // const isCloseFluidType = store.get('close-fluid-type');
  const { nameDrawerOpen } = useContext(ConfigContext);
  const { isLoading, isValidating } = useZones(wellId, config.auth, currentWell);
  const [currentZoneToEdit, setCurrentZoneToEdit] = useState<ZoneItemType | undefined>();
  const [zonesList, setZonesList] = useState<ZoneItemType[] | undefined>();
  const [indexBottomZoneCreated, setIndexBottomZoneCreated] = useState();
  const { viewMode, setListViewMode, setFormViewMode } = useToggle();

  useEffect(() => {
    const newZoneItems =  zoneData ?? [];
    if ((newZoneItems?.length && !zonesList) || (zonesList?.length !== newZoneItems.length)) {
      setZonesList(newZoneItems);
      afterRevalidate?.(newZoneItems);
    }
  }, [zoneData, setZonesList, afterRevalidate]);

  const isListViewMode = ViewModes.LIST === viewMode;
  const isFormViewMode = ViewModes.FORM === viewMode;

  const title = currentZoneToEdit?.top && currentZoneToEdit?.bottom ? 'Update' : 'Create';

  const isLoadingAllZone = isLoadingZones || isLoading || isValidating;
  
  const afterUpdate = useCallback((zoneId: string) => {
    const normalizeZones = zoneData ?? [];
    const zone = normalizeZones.find(
      (z: ZoneItemType) => z?.uuid === zoneId
    ) as ZoneItemType | undefined;
    setCurrentZoneToEdit(zone);
    setFormViewMode();
  }, [zoneData, setFormViewMode]);

  const handleDeleteZone = (zoneId: string) => {
    setCurrentZoneToEdit(undefined);
    afterDelete(zoneId);
  };

  // useEffect(() => {
  //   // const shouldUpdateZoneList = store.get('close-fluid-type');
  //   // if (shouldUpdateZoneList === true) {
  //     // mutate();
  //     console.log('close-fluid-type', store.get('close-fluid-type'));
  //     // store.set('close-fluid-type', false);
  //   // }
  // }, [store.get('close-fluid-type')]);

  const afterOpenForm = () => {
    setListViewMode();
  };

  const formViewModeInsert = () => {
    if (zoneData.length > 0) {
      const lastZone = zoneData?.slice(-1)?.pop();
      setIndexBottomZoneCreated(lastZone?.bottom_depth_index);
    }
    setCurrentZoneToEdit(undefined);
    setFormViewMode();
  };

  const defaultLastBottom = useMemo(() => {
    const normalizeZones = zoneData ?? [];
    const sortedZones = sortZones(normalizeZones);
    const lastValue = sortedZones.slice(-1).pop();
    return lastValue?.bottom;
  }, [zoneData]);

  return (
    <UI.Drawer drawerDirection='right' width={672} isOpenDrawer={nameDrawerOpen === 'zone analysis'} title='Zones'>
      <ZoneContainer>
        {isLoadingAllZone && (
          <>
            <SkeletonLoading variant="rectangular" width={132} height={36} />
            <SkeletonLoading variant="rectangular" width="100%" height={219} />
            <SkeletonLoading variant="rectangular" width="100%" height={219} />
          </>
        )}

        <SnackbarMessage
          open={isThereNoSensorType}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          autoHideDuration={6000}
          onClose={closeEtheneAlert}
        >
          <Alert severity="warning" style={{ color: customTheme.orange1 }} onClose={closeEtheneAlert}>
            <AlertTitle color={customTheme.orange1}>There is no data in Relative Ethene!</AlertTitle>
            Please, select a type of sensor <strong>FID</strong> or <strong>MS</strong> when to create a well.
          </Alert>
        </SnackbarMessage>

        {!isLoadingAllZone && (
          <>
            {isListViewMode && (
              <ZoneHeader>
                <UI.Button
                  theme='primary'
                  type='button'
                  startIcon={<AddIcon />}
                  onClick={formViewModeInsert}
                >
                  Add Zone
                </UI.Button>
              </ZoneHeader>
            )}

            {isListViewMode && zoneData.length === 0 && (
              <EmptyZoneState />
            )}
        
            {isFormViewMode && !isLoading ? (
              <FormZone
                toggleCancel={afterOpenForm}
                submitText={`${title} Zone`}
                submitZone={submitZone}
                afterSubmit={afterOpenForm}
                zoneBottom={defaultLastBottom ?? 0}
                zoneCount={zoneData?.length ? zoneData?.length + 1 : 1}
                currentWell={currentWell}
                currentZoneToEdit={currentZoneToEdit}
                indexBottomZoneCreated={indexBottomZoneCreated}
              />
            ) : null }
            {
              !isFormViewMode ? (
                <List
                  zones={zoneData ?? []}
                  afterDelete={handleDeleteZone}
                  afterUpdate={afterUpdate}
                  customCallbackActions={customCallbackActions}
                  getCurrentZone={getCurrentZone}
                />
              ) : null
            }
          </>
        )}
      </ZoneContainer>
    </UI.Drawer>
  );
}

export default Zone;