import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { StepIcon } from '@mui/material';
import { Remove } from '@mui/icons-material';

import { CarouselProps } from '../../types/login';
import {
  CarouselItem, CarouselDescription, Step,
  StepContainer, CarouselContainer, ScreenImage,
  ScreenContainer, TextContainer
} from '../../styles/styles'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export const Carousel = ({ data, screen }: CarouselProps) => {
  const [activeStep, setActiveStep] = useState(0);

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <div data-testid="carousel">
      <CarouselContainer>
        <TextContainer>
          <AutoPlaySwipeableViews
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
            interval={5000}
          >
            {data.map((carouselData, index) => (
              <CarouselItem key={index}>
                <CarouselDescription data-testid="title-carousel">
                  {carouselData.title}
                </CarouselDescription>
              </CarouselItem>
            ))}
          </AutoPlaySwipeableViews>
          <CarouselItem>
            <StepContainer>
              {
                data.map((carouselData, index) => (
                  <Step key={index} $active={activeStep === index} onClick={() => handleStepChange(index)}>
                    <StepIcon key={carouselData.title} icon={<Remove />} />
                  </Step>
                ))
              }
            </StepContainer>
          </CarouselItem>
        </TextContainer>
        <ScreenContainer>
          <ScreenImage src={screen} data-cy='login-screen-image' />
        </ScreenContainer>
      </CarouselContainer>
    </div>
  )
};
