import '../../styles/app.scss';
/* eslint-disable */
import React, {
  Fragment,
  useEffect,
  useState,
  useCallback,
  useRef
} from 'react';
import Highcharts from 'highcharts';
import { postCalculationApiAndGor } from 'api/statistics';
import { useZones } from '@geowellex/shared-zone/src/hooks/useApi';
import { applyZoneToCharts } from '@geowellex/shared-zone/src/utils/applyZoneToCharts';
import { findDepthIndexValueToApplyInPlotBand, normalizedBottomAndTop } from '@geowellex/shared-zone/src/utils/submitZone';
import { setupToken, allCharts } from '@geowellex/shared-zone/src/config/index';
import { defaultNotification } from '../../utils';
import _get from 'lodash.get';
// import { mean } from 'mathjs';
import { useDispatch, useSelector } from 'react-redux';
import store from 'store';

import * as wellActions from 'actions/wells';
import * as zoneActions from 'actions/zone';
import * as crossPlotsActions from '../../actions/crossplots';
import ScatterPlotC2ByC3 from '../../components/C2ByC3';
import C2iC4 from '../../components/C2iC4';
import IcByNc from '../../components/IcByNc';
import Legend from '../../components/Legend';
import ScatterPlotC1ByC2 from '../../components/ScatterPlot/ScatterPlotC1ByC2';
import ScatterPlotC1C2ByC3
  from '../../components/ScatterPlot/ScatterPlotC1C2ByC3';
import ScatterPlotC1 from '../../components/ScatterPlot/ScatterPlotC1C3';
import ScatterPlotC1C45 from '../../components/ScatterPlot/ScatterPlotC1C45';
import ScatterPlotWetness
  from '../../components/ScatterPlot/ScatterPlotWetness';
import VerticalScatterPlotC1C2
  from '../../components/ScatterPlot/VerticalScatterPlotC1C2';
import VerticalScatterplotC25
  from '../../components/ScatterPlot/VerticalScatterPlotc25';
import { getUnit } from '../../utils';
import Drawer from './Drawer';
import { useParams } from 'react-router-dom';
import { FormRangeTopBottom } from '@geowellex/shared-zone/src/components/FormRangeTopBottom/FormRangeTopBottom';
import { FluidTypingZone } from '@geowellex/shared-zone';
import { listWell } from '../../actions/wells';
import { generateBalanceCrossplots } from '../PlotBox';
import { customTheme } from '@geowellex/shared-ui/src/theme/default';
import { addUuidOnlyInOneZone, formatZones } from '../../utils/Zones';
import { backdropClasses } from '@mui/material';
import IndicatorCard from "@geowellex/shared-ui/src/components/IndicatorCard";
import MinusPlus from '../../assets/minusplus.svg';
import UI from '@geowellex/shared-ui';

// import PostProcessingButton from './PostProcessingButton';

// TODO LEO should move the whole logic to redux
// we need to turn this component a stateless and separate
// this logic to store it temporarily on the indexDB, otherwhise
// when the user to click to save it permanently
// this will be associated with the config of each chart, and also
// to remind of use useMemo hook in each cahrt to avoid unexpected re-render

const removeNegativeValues = (data) => {
  return data?.map(arr => {
    if (arr[0] < 0) {
      arr[0] = 0;
    }
    if (arr[1] < 0) {
      arr[1] = 0;
    }
    return arr;
  });
};

const Crossplots = () => {
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const token = store.get('token');
  const params = useParams();
  const [id] = params.id.split('--');
  const queries = store.get('querys');
  const queryData = store.get('querys')[id];
  const currentZoneList = store.get('currentZoneList');
  const {
    maxLabel: bottomLabel, minLabel: topLabel,
    min: indexMin, max: indexMax
  } = queryData;
  const { wellId } = [id];
  const { zone } = useSelector(state => state);
  const zoneData = zone.items;
  const depthData =  queryData?.depth;
  const selectedMin = depthData ? (depthData.length - 1) - ((depthData.length-1)/3) : 0;
  const selectedMax = depthData ? depthData.length - 1 : 0;

  const { currentWell: currentWellInfo, items: wells, ...wellsState } = useSelector(state => state.wells);
  const zoneSets = currentWellInfo?.zone_sets?.gold_zones;
  const {
    insertNewZone,
    updateAndMutateZone,
    isLoading,
    data,
    mutate
  } = useZones(wellId, store.get('token'));
  const currentUnitValue = store.get('currentUnit');
  const [allZones, setAllZones] = useState();
  const [apiGorValue, setApiGorValue] = useState();
  const [apiGorLoading, setApiGorLoading] = useState();
  const [apiGorIsError, setApiGorIsError] = useState(false);
  const [postProcessingIsEnabled, setActive] = useState(false);
  const [currentChart, setChart] = useState();
  const [visible, setVisible] = useState(false);
  const [scattersConfig, setConfig] = useState();
  const [chartType, setCurrentType] = useState();
  const [ranges, setRanges] = useState(null);
  const [pointsInsideZones, setZones] = useState([]);
  const [currentUnit, setUnit] = useState(getUnit());
  const [configMin, setConfigMin] = useState();
  const [configMax, setConfigMax] = useState();
  const [configIndexMin, setConfigIndexMin] = useState(indexMin);
  const [configIndexMax, setConfigIndexMax] = useState(indexMax);
  const [initialValues, setInitialValues] = useState({
    yMin: '',
    yMax: '',
    yTitle: '',
    yAxisType: '',
    xMin: '',
    xMax: '',
    xTitle: '',
    xAxisType: '',
    isVisible: true,
  });

  const [isLoadingZones, setLoadingZones] = useState(false);
  const [isLoadingFluidTypeZone, setLoadingFluidTypeZone] = useState(false);
  const [isCreateZone, setIsCreateZone] = useState(false);
  const refCloseWindow = useRef(null);
  const [createZoneMessage, setCreatedZoneMessage] = useState(false);
  const [isLoadingCreateZone, setIsLoadCreateZone] = useState(false);
  const [formFluidType, setFormFluidType] = useState('');
  const [updatedNameZone, setUpdatedNameZone] = useState('');

  useEffect(() => {
    console.log('form fluid', store.get('opencrossplotFluidTyping'));
    if(store.get('opencrossplotFluidTyping') === 'zone') {
      setFormFluidType('zone');
    } else {
      setFormFluidType('balanceRatio');
    }
  }, [store.get('opencrossplotFluidTyping')]);

  useEffect(() => {
    dispatch(wellActions.listWells());
  }, []);

  // useEffect(() => {
  //   if (refCloseWindow.current) {
  //     store.set('close-fluid-type', true);
  //   }
  // }, [refCloseWindow.current]);

  useEffect(() => {
    if (queryData && queryData.data && (queryData.data.c2 || queryData.data.c3 || queryData.data.ic4 || queryData.data.nc4) && !apiGorValue) {
      const { data, min, max } = queryData;
      calculatingApiAndGor(queryData.data, min, max)
    }
  }, [queries && Object.keys(queries).includes(id)]);

  useEffect(() => {
    const [id, type] = params.id.split('--');
    const queryData = store.get('querys')[id];
    if (queryData) {
      setConfig({ ...queryData, id });

      const { wellId, token } = queryData;
      try {
        dispatch(listWell(wellId, token))
      } catch (error) {
        console.log('Error fetch well', error)
      }
    }

    setChart(type);
    setUnit(getUnit());
  }, [id]);

  const toggleActive = () => {
    const currentValue = !postProcessingIsEnabled;
    setActive(currentValue);
  };

  const titles = {
    balanceRatioToggle: 'Fluid Typing Checks',
    C1C2SF: 'Geochemistry',
  };

  const calculatingApiAndGor = (crossplotsData, min, max) => {
    const c2 = (crossplotsData.c2 || []).slice(Number(min), Number(max) + 1);
    const c3 = (crossplotsData.c3 || []).slice(Number(min), Number(max) + 1);
    const ic4 = (crossplotsData.ic4 || []).slice(Number(min), Number(max) + 1);
    const nc4 = (crossplotsData.nc4 || []).slice(Number(min), Number(max) + 1);
    const curves = {
      c2: c2 || [],
      c3: c3 || [],
      ic4: (ic4 || []).map(item => item < 0 ? 0 : item),
      nc4: (nc4 || []).map(item => item < 0 ? 0 : item)
    };

    setApiGorLoading(true);
    postCalculationApiAndGor(curves).then(response => {
      setApiGorValue(response.data);
      setApiGorIsError(false);
      setApiGorLoading(false);
    }).catch(error => {
      console.log('postCalculationApiAndGor ===>', error?.data || error?.message);
      setApiGorIsError(true);
      setApiGorLoading(false);
    });
  }

  const toggleDrawer = (type) => () => {
    setCurrentType(type);
    if (scattersConfig && type) {
      const windowConfig = store.get('querys')[scattersConfig.id];
      const chartConfig = windowConfig.scales[type];
      setInitialValues({
        yMin: chartConfig.y.min,
        yMax: chartConfig.y.max,
        yTitle: chartConfig.y.title,
        yAxisType: chartConfig.y.yAxisType,
        xMin: chartConfig.x.min,
        xMax: chartConfig.x.max,
        xTitle: chartConfig.x.title,
        xAxisType: chartConfig.x.xAxisType,
        isVisible: chartConfig.isVisible,
      });
    }
    setVisible(!visible);
  };

  const closeDrawer = () => {
    setCurrentType(null);
    setVisible(false);
  };

  /*
   * This function receive the submit from the form
   * and will update on localStorage in a specific query
   * the config of a specific cross plot contained on this query
   * This function has the purporse as well to dispatch the new values
   * with the new config to apply in each crossplot
   * We will get the values from the title
   */
  const changeScatterConfig = (values, actions) => {
    // Update the localStorage
    const { id, scales } = scattersConfig;
    const updatedQuery = { ...scattersConfig, };
    const { yMin, yMax, yTitle, yAxisType, xMin, xMax, xTitle, xAxisType } = values;
    updatedQuery.scales[chartType] = {
      y: { min: Number(yMin), max: Number(yMax), title: yTitle, yAxisType, },
      x: { min: Number(xMin), max: Number(xMax), title: xTitle, xAxisType, },
      isVisible: values.isVisible,
    };

    const oldQueys = store.get('querys');
    store.set('querys', {
      ...oldQueys,
      [id]: updatedQuery,
    });

    // Dispatch It
    const changeConfig = new CustomEvent(
      `${id}-${chartType}`, {
        detail: {
          chartType,
          config: updatedQuery.scales[chartType],
          id,
        },
      },
    );
    document.dispatchEvent(changeConfig);
    // Hide the Drawer
    setVisible(!visible);
  };

  const receivePoints = (pWithDepth) => {
    dispatch(
      crossPlotsActions.receiveFluidTypeValues(pWithDepth)
    );
  };

  const hangleSubmitTopBottom = ({ top, bottom }) => {
    setConfigMin(top);
    setConfigMax(bottom);
    const { type, minLabel, maxLabel } = queryData;
    const { id } = scattersConfig;

    const topIndex = (currentWellInfo?.RAW?.depth?.data || []).findIndex((depth) => depth === parseFloat(top));
    const bottomIndex = (currentWellInfo?.RAW?.depth?.data || []).findIndex((depth) => depth === parseFloat(bottom));
    setConfigIndexMin(topIndex);
    setConfigIndexMax(bottomIndex);

    const newCrossplotsData = generateBalanceCrossplots(
      type, topIndex, bottomIndex, currentWellInfo, currentWellInfo.curvesKeys, top, bottom, id
    );
    calculatingApiAndGor(newCrossplotsData, topIndex, bottomIndex);

    setConfig({ ...scattersConfig, data: newCrossplotsData });
  };

  // balanceRatioToggle
  const wetnessDataWithDepth = _get(scattersConfig, 'data.chart3.itemsWithDepth', []);
  const wetnessData = _get(scattersConfig, 'data.chart3.items', []);
  const C1C2C3DataWithDepth = _get(scattersConfig, 'data.chart2.itemsWithDepth', []);
  const C1C2C3Data = _get(scattersConfig, 'data.chart2.items', []);

  // C1C2SF
  const ScatterPlotC1C2Data = _get(scattersConfig, 'data.chart1.items', []);
  const scatterplotC25Data = _get(scattersConfig, 'data.chart2.items', []);

  const chart1BiodegradationWithDepth = _get(scattersConfig, 'data.chart1.itemsWithDepth', []);
  const chart2BiodegradationWithDepth = _get(scattersConfig, 'data.chart2.itemsWithDepth', []);
  const slopeFactorWithDepth = _get(scattersConfig, 'data.chart3.itemsWithDepth', []);
  const ic4WithDepth = _get(scattersConfig, 'data.chart10.itemsWithDepth', []);
  const ic5WithDepth = _get(scattersConfig, 'data.chart9.itemsWithDepth', []);
  const c2iC4WithDepth = _get(scattersConfig, 'data.chart8.itemsWithDepth', []);
  const c2C3WithDepth = _get(scattersConfig, 'data.chart7.itemsWithDepth', []);

  // get min Label
  let minLabel = '';
  if (scattersConfig && scattersConfig.minLabel && typeof scattersConfig.minLabel === 'number') {
    minLabel = scattersConfig.minLabel
  } else if (scattersConfig && scattersConfig.minLabel && typeof scattersConfig.minLabel === 'string') {
    minLabel = scattersConfig.minLabel.split('-')[0].trim()
  }

  let maxLabel = '';
  if (scattersConfig && scattersConfig.maxLabel && typeof scattersConfig.maxLabel === 'number') {
    maxLabel = scattersConfig.maxLabel
  } else if (scattersConfig && scattersConfig.maxLabel && typeof scattersConfig.maxLabel === 'string') {
    maxLabel = Number(scattersConfig.maxLabel.split('-')[0].trim())
  }

  let C1C2C3DataPostProcessing = C1C2C3Data;

  if (postProcessingIsEnabled) {
    const C1C2C3DataXY = C1C2C3Data.reduce((acc, dataList) => {
      const [x, y] = dataList;
      acc.x.push(x);
      acc.y.push(y);
      return acc;
    }, { x: [], y: []});
    // const C1C2C3PostProcessingX = mean(C1C2C3DataXY.x);
    // const C1C2C3PostProcessingY = mean(C1C2C3DataXY.y);
    const C1C2C3PostProcessingX = 0;
    const C1C2C3PostProcessingY = 0;
    C1C2C3DataPostProcessing = [C1C2C3PostProcessingX, C1C2C3PostProcessingY];
  }

  const submitFluidTypeZone = useCallback((currentZone) => async (values) => {
    setUpdatedNameZone(values?.name);
    if (!values) {
      setLoadingZones(false)
      alert('Top and bottom are required fields.');
      return;
    }

    if (currentZone?.length > 0) {
      const checkIfExistZone = currentZone.find(zone => Number(zone.bottom) === Number(configMax) && Number(zone.top) === Number(configMin));

      if (checkIfExistZone) {
        defaultNotification('Error saving zone', 'Already exists a zone with the same top and bottom! Try different values.');
        setLoadingZones(false);
        setIsLoadCreateZone(false);
        return;
      }
    }

    setLoadingFluidTypeZone(true);

    const afterCreate = (newWell) => {
      setIsLoadCreateZone(false);
      const zoneSets = newWell?.content.well_metadata?.zone_sets;
      if (zoneSets) {
        const lastZoneFormatted = formatZones(zoneSets);
        const lastZone = lastZoneFormatted[0];
        dispatch(zoneActions.receiveZones(zoneSets));
        applyZoneToCharts('total-gas')(lastZone, Highcharts.charts);
      }
    };

    const wellIdQueryData = String(queryData?.wellId);
    if (wellIdQueryData) {
      const uid = store.get('email');
      const username = store.get('preferred_username');
      try {
        const wellsWithMetadata = wellsState?.wellsWithPathFile;
        const previousZones = wellsWithMetadata.reduce((acc, w) => {
          const zoneSetIndex = w?.zone_sets?.findIndex(z => z.name === 'gold-zones');
          if (w?.uid === currentWellInfo?.well_uid && zoneSetIndex >= 0) {
            acc = w?.zone_sets?.[zoneSetIndex]?.zones;
          }
          return acc;
        }, []);

        const newZoneItem = {
          top: configMin !== undefined ? parseFloat(configMin) : topLabel,
          top_depth_index: configIndexMin,
          bottom: configMax !== undefined ? parseFloat(configMax) : bottomLabel,
          bottom_depth_index: configIndexMax,
          label: values.name,
          zone_type: values.fluidType,
          zone_plot_settings: {
            border_color: 'blue',
            border_width: '1',
            color: {
              r: values.color.r.toString(),
              g: values.color.g.toString(),
              b: values.color.b.toString(),
              a: values.color.a.toString()
            }
          }
        };
        const newZoneWithUuid = addUuidOnlyInOneZone(newZoneItem);
        setLoadingFluidTypeZone(true);
        const updateZoneData = {
          well_uid: wellIdQueryData,
          zone_set_name: 'gold-zones',
          zones: [
            ...(allZones ?? (previousZones || [])),
            newZoneWithUuid,
          ],
          user: {
            uid,
            username,
            role: 'none'
          }
        };
        setIsLoadCreateZone(true);

        let result = undefined;
        if (previousZones?.length === 0) {
          result = await insertNewZone(
            updateZoneData,
            afterCreate,
            store.get('token')
          );
        } else {
          result = await updateAndMutateZone(
            currentWellInfo?.well_uid,
            updateZoneData,
            store.get('token')
          );
        }
        if (result?.response?.status === 500) {
          defaultNotification('Error saving zone', 'Already exists a zone with the same top and bottom! Try different values.');
          setLoadingZones(false);
          setIsLoadCreateZone(false);
          return;
        }
        if (result?.status === 200 || result?.status === 201) {
          dispatch(zoneActions.receiveZone(newZoneWithUuid));
          setAllZones([
            ...(previousZones || []),
            newZoneWithUuid,
          ])
          defaultNotification('Zone created successfully', 'Try to see the curves');
          setLoadingZones(false);
          setIsLoadCreateZone(false);
          store.set('close-fluid-type', true);
        }
      } catch (e) {
        console.log('error when create zone', e);
        setLoadingZones(false)
        setIsLoadCreateZone(false);
      }
    }
    setLoadingFluidTypeZone(false);
  }, [currentWellInfo?.well_uid, configMin, configMax, configIndexMin, configIndexMax]);


  console.log('wetnessDataWithDepth ==>', {
    currentChart,
    scattersConfig,
    C1C2C3DataPostProcessing,
    wetnessDataWithDepth
  });
  return (
    <div
      className="scatters-plot"
      style={{
        background: customTheme.bg
      }}
    >
      <UI.CustomScrollBar>
      <Drawer
        toggleDrawer={toggleDrawer}
        visible={visible}
        initialValues={initialValues}
        changeScatterConfig={changeScatterConfig}
        closeDrawer={closeDrawer}
      />
      <br />
        {formFluidType === 'zone' ?
        <div style={{ width: '100%', display: 'flex' }}>
          <div>
              <div
                  style={{
                  minWidth: 130,
                  color: '#fff',
                  fontWeight: 'bold',
                  backgroundColor: 'transperent',
                  borderRadius: '5px',
                  color: '#fff',
                  fontSize: '22px',
                  paddingRight: 10,
                  paddingLeft: 10,
                  marginLeft: 40,
                }}
              >
              <h3 style={{color: '#fff', fontWeight: 'bold'}}>{updatedNameZone !== '' ? updatedNameZone : currentZoneList.label}</h3>
              </div>
            </div>
          </div>
        : ''
      }
      {currentChart && (
        <>
          {scattersConfig && scattersConfig.name ?
            <div className="scatter-title-zone">
              <h3 className="title-zone">{scattersConfig && scattersConfig.name}</h3>
            </div>
          : null}
          <div className="scatter-title-wrapper scatter-title-margin">
            <div className="scatter-title-box">
              <h3 className="scatters-title">
                {titles[currentChart]}
              </h3>
              {scattersConfig && (
                <h3 
                  className="scatters-range"
                  style={{
                    fontSize: 14,
                    fontWeight: 400,
                    color: customTheme.gray5,
                  }}
                >
                  {`Min: ${minLabel} - Max: ${maxLabel}`}
                </h3>
              )}
              <div className="scatters-legends">
                <Legend
                  styleBox={{
                    background: customTheme.green1,
                  }}
                  name="oil"
                  label="Oil"
                />
                <Legend
                  styleBox={{
                    background: customTheme.orange1,
                  }}
                  name="transition"
                  label="Transition"
                />
                <Legend
                  styleBox={{
                    background: customTheme.red1,
                  }}
                  label="Gas"
                />
              </div>
            </div>
          </div>
          {/* <PostProcessingButton toggleActive={toggleActive} isActivated={postProcessingIsEnabled} /> */}
        </>
      )}
      {currentChart && currentChart === 'balanceRatioToggle' && (
        <Fragment>
          <div class='all-plot'>
              <div className="wrapper-api-gor-calculation"
                style={{
                  overflow: 'auto',
                  width: 289,
                  maxHeight: 700,
                  marginTop: 35
                }}
              >
                <UI.CustomScrollBar>
                  <div className="api-gor-calculation-container">
                    <IndicatorCard
                        styles={{ maxWidth: 260 }}
                        color={'#D8DBF0'}
                        title="Estimated API"
                        value={
                          apiGorIsError ? 'N/A' : !apiGorLoading ? 
                            <div style={{
                              display: 'flex',
                              height: 41,
                              alignItems: 'center',
                            }}>
                              <span>{apiGorValue?.api && apiGorValue?.api.api_median}&nbsp;</span>
                                <span>
                                <div className="plus-minimus">
                                  <div>&#177;</div>
                                </div>
                              </span>
                              <span>&nbsp;{apiGorValue?.api?.api_std}</span>
                            </div>
                          : <span style={{ fontSize: 16 }}>Loading Api...</span>
                        }
                        unit=""
                    />
                    <IndicatorCard
                        styles={{ maxWidth: 260 }}
                        color={'#D8DBF0'}
                        title="Estimated GOR"
                        value={
                          apiGorIsError ? 'N/A' : !apiGorLoading ? 
                            <div style={{
                              display: 'flex',
                              height: 41,
                              alignItems: 'center',
                              fontSize: 18,
                            }}>
                              <span>{apiGorValue?.gor?.gor_median} m³/m³&nbsp;</span>
                                <span>
                                <div className="plus-minimus">
                                  <div>&#177;</div>
                                </div>
                              </span>
                              <span>&nbsp;{apiGorValue?.gor?.gor_std}</span>
                            </div>
                          : <span style={{ fontSize: 16 }}>Loading Gor...</span>
                        }
                        unit=""
                      />
                    {/* <div className="estimated-title">Estimated GOR</div>
                      <div className="estimated-data">
                        {apiGorIsError ? (
                            'N/A'
                          ) : !apiGorLoading ? (
                            <>
                              <span>{apiGorValue && apiGorValue.gor && apiGorValue.gor.gor_median} m³/m³&nbsp;</span>
                              <span>
                                <div className="plus-minimus">
                                  <div>&#177;</div>
                                </div>
                              </span>
                              <span>&nbsp;{apiGorValue && apiGorValue.gor && apiGorValue.gor.gor_std}</span>
                            </>
                            ) : 'Loading Gor...'}
                      </div>
                    </div> */}
                    {scattersConfig && (
                      <h3 className="scatters-range">
                        <FormRangeTopBottom
                          top={topLabel}
                          bottom={bottomLabel}
                          currentWell={currentWellInfo}
                          submitRange={hangleSubmitTopBottom}
                        />
                      </h3>
                    )}
                    <div>
                      <FluidTypingZone
                        zoneData={currentZoneList}
                        submitZoneFluidType={submitFluidTypeZone(zoneData)}
                        zoneCount={zoneData?.zones?.length ? zoneData?.zones?.length + 1 : 1}
                        isLoading={createZoneMessage}
                        isLoadingCreate={isLoadingCreateZone}
                        formFluidType={formFluidType}
                      />
                    </div>
                  </div>
                </UI.CustomScrollBar>
              </div>
            <div className="scatter-group-container">
              <div className="scatter-group-fluid-typing" style={{ position: 'relative', marginTop: 30 }}>
                <ScatterPlotC1C2ByC3
                  id={'scatter-c1-c3'}
                  key={'scatter-c1-c3'}
                  yScale={
                    scattersConfig &&
                    scattersConfig.scales &&
                      scattersConfig.scales.chart2 &&
                      scattersConfig.scales.chart2.y
                      ? scattersConfig.scales.chart2.y
                      : { min: 0.0001, max: 100, title: '(C4+C5) / (C1+C2)', yAxisType: 'logarithmic', }
                  }
                  xScale={
                    scattersConfig &&
                    scattersConfig.scales
                      && scattersConfig.scales.chart2
                      && scattersConfig.scales.chart2.x
                      ? scattersConfig.scales.chart2.x
                      : { min: 0.1, max: 1000, title: '(C1+C2) / C3', xAxisType: 'logarithmic' }
                  }
                  openConfigureScale={toggleDrawer('chart2')}
                  idListener={scattersConfig && scattersConfig.id}
                  receivePointsIsideZone={receivePoints}
                  data={removeNegativeValues(C1C2C3DataPostProcessing)}
                  dataWithDepth={C1C2C3DataWithDepth}
                />
                <ScatterPlotWetness
                  id= "crossplot-wetness"
                  yScale={
                    scattersConfig &&
                    scattersConfig.scales &&
                    scattersConfig.scales.chart3 &&
                    scattersConfig.scales.chart3.y
                  }
                  xScale={
                    scattersConfig &&
                    scattersConfig.scales &&
                    scattersConfig.scales.chart3 &&
                    scattersConfig.scales.chart3.x
                  }
                  openConfigureScale={toggleDrawer('chart3')}
                  idListener={scattersConfig && scattersConfig.id}
                  receivePointsIsideZone={receivePoints}
                  dataWithDepth={wetnessDataWithDepth}
                  data={removeNegativeValues(wetnessData)}
                />
                <ScatterPlotC1
                  id={'scatter-c1'}
                  key={'scatter-c1'}
                  yScale={
                    scattersConfig &&
                    scattersConfig.scales &&
                    scattersConfig.scales.chart4 &&
                    scattersConfig.scales.chart4.y
                  }
                  xScale={
                    scattersConfig &&
                    scattersConfig.scales &&
                    scattersConfig.scales.chart4 &&
                    scattersConfig.scales.chart4.x
                  }
                  openConfigureScale={toggleDrawer('chart4')}
                  idListener={scattersConfig && scattersConfig.id}
                  receivePointsIsideZone={receivePoints}
                  dataWithDepth={
                    scattersConfig &&
                    scattersConfig.data &&
                    scattersConfig.data.chart4 &&
                    scattersConfig.data.chart4.itemsWithDepth
                      ? scattersConfig.data.chart4.itemsWithDepth : []
                  }
                  data={
                    scattersConfig &&
                    scattersConfig.data &&
                    scattersConfig.data.chart4.items ?
                    scattersConfig.data.chart4.items : []
                  }
                />
                <ScatterPlotC1C45
                  id={'c1-c45'}
                  key={'c1-c45'}
                  yScale={
                    scattersConfig &&
                    scattersConfig.data &&
                    scattersConfig.scales.chart5 &&
                    scattersConfig.scales.chart5.y
                  }
                  xScale={
                    scattersConfig &&
                    scattersConfig.data &&
                    scattersConfig.scales.chart5 &&
                    scattersConfig.scales.chart5.x
                  }
                  openConfigureScale={toggleDrawer('chart5')}
                  idListener={scattersConfig && scattersConfig.id}
                  receivePointsIsideZone={receivePoints}
                  dataWithDepth={
                    scattersConfig &&
                    scattersConfig.data &&
                    scattersConfig.data.chart5 &&
                    scattersConfig.data.chart5.itemsWithDepth ? scattersConfig.data.chart5.itemsWithDepth : []
                  }
                  data={
                    scattersConfig &&
                    scattersConfig.data &&
                    scattersConfig.data.chart5 &&
                    scattersConfig.data.chart5.items ? scattersConfig.data.chart5.items : []
                  }
                />
              </div>
            </div>
          </div>
        </Fragment>
      )}
      {/* Geochemistry */}
      {currentChart && currentChart === 'C1C2SF' && (
        <>
          <div className="wrapper-vertical-plot">
            <div className="wrapper-c1-c2">
              <div className="vertical-plot">
                <div className="wrapper-scatter-plot-c1c2">
                  <span className="title-vertical-c1byc2-oil">OIL CHARGE</span>
                  <span className="title-vertical-c1byc2-gas">GAS CHARGE</span>
                  {/* <span className="title-vertical-c1byc2">C1/C2 (MS)</span> */}
                  <VerticalScatterPlotC1C2
                    dataWithDepth={chart1BiodegradationWithDepth}
                    id={'c1-c2'}
                    key={'c1-c2'}
                    yScale={
                      scattersConfig &&
                      scattersConfig.scales &&
                        scattersConfig.scales.chart1 &&
                        scattersConfig.scales.chart1.y
                        ? scattersConfig.scales.chart1.y
                        : { min: 1000, max: 7000, title: 'MD Depth (m)', yAxisType: 'linear', }
                    }
                    xScale={
                      scattersConfig &&
                      scattersConfig.scales
                        && scattersConfig.scales.chart1
                        && scattersConfig.scales.chart1.x
                        ? scattersConfig.scales.chart1.x
                        : { min: 0, max: 20, title: 'C1/C2', xAxisType: 'linear' }
                    }
                    idListener={scattersConfig && scattersConfig.id}
                    data={ScatterPlotC1C2Data}
                    openConfigureScale={toggleDrawer('chart1')}
                    currentUnit={currentUnitValue}
                  />
                </div>
                <div className="wrapper-scatterplot-c25">
                {/* <span className="title-vertical-c-25">SF C2S</span> */}
                <span className="title-vertical-c-5-oil">OIL CHARGE</span>
                <span className="title-vertical-c-5-gas">GAS CHARGE</span>
                 <VerticalScatterplotC25
                    dataWithDepth={chart2BiodegradationWithDepth}
                    id={'c-25'}
                    key={'c-25'}
                    yScale={
                      scattersConfig &&
                      scattersConfig.scales &&
                        scattersConfig.scales.chart2 &&
                        scattersConfig.scales.chart2.y
                        ? scattersConfig.scales.chart2.y
                        : { min: 1000, max: 7000, title: 'MD Depth (m)', yAxisType: 'linear', }
                    }
                    xScale={
                      scattersConfig &&
                      scattersConfig.scales
                        && scattersConfig.scales.chart2
                        && scattersConfig.scales.chart2.x
                        ? scattersConfig.scales.chart2.x
                        : { min: 0, max: 4, title: 'SF C2-5 (ppm)', xAxisType: 'linear' }
                    }
                    idListener={scattersConfig && scattersConfig.id}
                    data={scatterplotC25Data}
                    openConfigureScale={toggleDrawer('chart2')}
                    currentUnit={currentUnitValue}
                  />
                </div>
              </div>
              <div className="scatter-group plot-c1-c2-sf">
                <ScatterPlotC1ByC2
                  dataWithDepth={slopeFactorWithDepth}
                  id={'scatter-c1-by-c2'}
                  key={'scatter-c1-by-c2'}
                  yScale={
                    scattersConfig &&
                    scattersConfig.scales &&
                    scattersConfig.scales.chart3 &&
                    scattersConfig.scales.chart3.y
                    ? scattersConfig.scales.chart3.y
                    : { min: 1000, max: 7000, title: 'SF C2-5 (ppm)', yAxisType: 'linear', }
                  }
                  xScale={
                    scattersConfig &&
                    scattersConfig.scales
                      && scattersConfig.scales.chart3
                      && scattersConfig.scales.chart3.x
                      ? scattersConfig.scales.chart3.x
                      : { min: 0, max: 40, title: 'C1/C2', xAxisType: 'linear' }
                  }
                  idListener={scattersConfig && scattersConfig.id}
                  data={scattersConfig && scattersConfig.data && scattersConfig.data.chart3.items ? scattersConfig.data.chart3.items : []}
                  openConfigureScale={toggleDrawer('chart3')}
                />
              </div>
            </div>
            <div className="scatter-title-wrapper" style={{ flexDirection : 'column' }}>
              <div className="scatter-title-box">
                <h3 className="scatters-title">
                  Biodegradation
                </h3>
                {'  '}
                {scattersConfig && (
                  <h3 
                    className="scatters-range"
                    style={{
                      fontSize: 14,
                      fontWeight: 400,
                      color: customTheme.gray5,
                    }}
                  >
                    {`Min: ${minLabel} - Max: ${maxLabel}`}
                  </h3>
                )}
              </div>
              <div className="scatters-legends">
                <Legend
                  styleBox={{
                    background: customTheme.green1,
                  }}
                  name="oil"
                  label="Oil"
                />
                <Legend
                  styleBox={{
                    background: customTheme.orange1,
                  }}
                  name="transition"
                  label="Transition"
                />
                <Legend
                  styleBox={{
                    background: customTheme.red1,
                  }}
                  label="Gas"
                />
              </div>
            </div>
            
            <div className="wrapper-ic4-ic5-c2">
              <div className="vertical-plot">
                <div className="wraper-ic4-nc4">
                  <IcByNc
                    dataWithDepth={ic4WithDepth}
                    id={'ic4-nc4'}
                    key={'ic4-nc4'}
                    title="iC4/nC4"
                    yScale={
                      scattersConfig &&
                      scattersConfig.scales &&
                        scattersConfig.scales.chart10 &&
                        scattersConfig.scales.chart10.y
                        ? scattersConfig.scales.chart10.y
                        : { min: 1000, max: 7000, title: 'MD Depth (m)', yAxisType: 'linear' }
                    }
                    xScale={
                      scattersConfig &&
                      scattersConfig.scales
                        && scattersConfig.scales.chart10
                        && scattersConfig.scales.chart10.x
                        ? scattersConfig.scales.chart10.x
                        : { min: 0, max: 5, title: 'iC4/nC4', xAxisType: 'linear' }
                    }
                    idListener={scattersConfig && scattersConfig.id}
                    nameChart={'chart10'}
                    data={scattersConfig &&
                      scattersConfig.data &&
                      scattersConfig.data.chart10 ? scattersConfig.data.chart10 : []}
                    openConfigureScale={toggleDrawer('chart10')}
                  />
                </div>
                <div className="wrapper-ic5-nc5">
                  <IcByNc
                    dataWithDepth={ic5WithDepth}
                    id={'ic5-nc5'}
                    key={'ic5-nc5'}
                    title="iC5/nC5"
                    yScale={
                      scattersConfig &&
                      scattersConfig.scales &&
                        scattersConfig.scales.chart9 &&
                        scattersConfig.scales.chart9.y
                        ? scattersConfig.scales.chart9.y
                        : { min: 1000, max: 7000, title: 'MD Depth (m)', yAxisType: 'linear' }
                    }
                    xScale={
                      scattersConfig &&
                      scattersConfig.scales
                        && scattersConfig.scales.chart9
                        && scattersConfig.scales.chart9.x
                        ? scattersConfig.scales.chart9.x
                        : { min: 0, max: 5, title: 'iC5/nC5', xAxisType: 'linear' }
                    }
                    idListener={scattersConfig && scattersConfig.id}
                    nameChart={'chart9'}
                    data={
                      scattersConfig &&
                      scattersConfig.data &&
                      scattersConfig.data.chart9 ? scattersConfig.data.chart9 : []}
                    openConfigureScale={toggleDrawer('chart9')}
                  />
                </div>
              </div>
              <div className="scatter-group plot-c1-c2-sf d-column wrapper-c2-ic4-c2-by-c3 ">
                <div className="wrapper-c2-ic4">
                  <C2iC4
                    dataWithDepth={c2iC4WithDepth}
                    data={
                      scattersConfig &&
                      scattersConfig.data &&
                      scattersConfig.data.chart8 ? scattersConfig.data.chart8.items : []}
                    id={'c2-ic4'}
                    key={'c2-ic4'}
                    yScale={
                      scattersConfig &&
                      scattersConfig.scales &&
                        scattersConfig.scales.chart8 &&
                        scattersConfig.scales.chart8.y
                        ? scattersConfig.scales.chart8.y
                        : { min: 1, max: 10, title: 'C2/C3', yAxisType: 'linear' }
                    }
                    xScale={
                      scattersConfig &&
                      scattersConfig.scales
                        && scattersConfig.scales.chart8
                        && scattersConfig.scales.chart8.x
                        ? scattersConfig.scales.chart8.x
                        : { min: 0, max: 80, title: 'C2/iC4', xAxisType: 'linear' }
                    }
                    idListener={scattersConfig && scattersConfig.id}
                    openConfigureScale={toggleDrawer('chart8')}
                  />
                </div>
                <div className="wrapper-c2-c3">
                  <ScatterPlotC2ByC3
                    dataWithDepth={c2C3WithDepth}
                    data={scattersConfig?.data?.chart7?.items || []}
                    id={'c2-by-c3'}
                    key={'c2-by-c3'}
                    yScale={
                      scattersConfig &&
                      scattersConfig.scales &&
                        scattersConfig.scales.chart7 &&
                        scattersConfig.scales.chart7.y
                        ? scattersConfig.scales.chart7.y
                        : { min: 1, max: 100, title: 'C1/C2', yAxisType: 'linear' }
                    }
                    xScale={
                      scattersConfig &&
                      scattersConfig.scales
                        && scattersConfig.scales.chart7
                        && scattersConfig.scales.chart7.x
                        ? scattersConfig.scales.chart7.x
                        : { min: 0, max: 9, title: 'C2/C3', xAxisType: 'linear' }
                    }
                    idListener={scattersConfig && scattersConfig.id}
                    openConfigureScale={toggleDrawer('chart7')}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      </UI.CustomScrollBar>
    </div>
  );
};

export default Crossplots;
