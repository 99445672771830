import React from 'react';
import { ImportFileButton, ImportFileButtonIcon, ImportFileButtonIconProps } from './styles';


interface ImportWellButtonProps {
    onClick: () => void;
    text: string;
    icon?: ImportFileButtonIconProps;
}

const ImportWellButton: React.FC<ImportWellButtonProps> = ({ onClick, text, icon, ...rest }) => {
    return (
        <ImportFileButton onClick={onClick }  {...rest}>
            <ImportFileButtonIcon color={icon?.color} >
                {icon?.icon}
            </ImportFileButtonIcon>
            <span>{text}</span>
        </ImportFileButton>
    );
};

export default ImportWellButton;