import React, { useEffect } from 'react';
import AOS from 'aos';

import Copyright from './components/Copyright';
import Carousel from './components/Carousel';
import Form from './components/Form';
import Header from './components/Header';
import Title from './components/Title';
import { useProvideAuth } from './hooks/useAuth';
import { LoginProps } from './types/login';

import {
  CarouselImageBg,
  LeftSideContainer,
  LoginContainer, WrapperInsideLeftSide, Content
} from './styles/styles';

export const Login = ({
  title,
  subtitle,
  logoOnCopyright,
  logoOnForm,
  carousel,
  bg,
  screen,
  colors,
  configScales
}: LoginProps) => {

  const {signIn, error, loading, signOut} = useProvideAuth();

  useEffect(() => {
    if (configScales) {
      configScales();
    }

    AOS.init({
      disable: () => {
        const minHeight = 750;
        return window.innerHeight < minHeight;
      }
    });

    document.addEventListener('logout', signOut);

    return () => {
      document.removeEventListener('logout', signOut);
    };
  }, []);

  return (
    <LoginContainer bgColor={colors?.loginBg}>
      <LeftSideContainer>
        <WrapperInsideLeftSide>
          <Header
            errorLogin={error}
            logo={logoOnForm}
          />
          <Content>
            <Title title={title} subtitle={subtitle} />
            <Form
              onSubmitLogin={signIn}
              errorLogin={error}
              loading={loading}
              buttonColor={colors?.loginButton}
            />
          </Content>
          <Copyright
            logo={logoOnCopyright}
          />
        </WrapperInsideLeftSide>
      </LeftSideContainer>
      <CarouselImageBg src={bg}>
        <Carousel
          data={carousel}
          screen={screen}
        />
      </CarouselImageBg>
    </LoginContainer>
  );
}
