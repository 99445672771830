import styled from 'styled-components';
import ButtonBase from './ButtonBase';
import { ButtonStyleProps } from './types';
import { customTheme } from '@geowellex/shared-ui/src/theme/default/index.tsx';

const ButtonPrimary = styled(ButtonBase)<ButtonStyleProps>`
  && {
    width: ${(props) => props.width};
    background: ${customTheme.blue1};
    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
      background: ${customTheme.purple3};
    }
  }
`;

export default ButtonPrimary