import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { Input, Select, } from 'antd';
import { Button } from 'antd';
import { ColorPicker } from '../ColorPicker/ColorPicker.tsx';
// import { ZoneScheme } from '../../utils/validateForm.ts';
import { defaultZoneFluidType } from '../../utils/defaultZoneValues.ts';
import FormLabel from '../FormLabel/index.ts';
import { CardBase } from '@geowellex/shared-ui/src/components/IndicatorCard/CardBase';
import { customTheme } from '@geowellex/shared-ui/src/theme/default';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const fluidTypes = [
  { value: 'Oil', label: <span>Oil Zone</span> },
  { value: 'Gas', label: <span>Gas Zone </span> },
  { value: 'Transition', label: <span>Transition</span> },
  { value: 'Undefined', label: <span>Undefined</span> },
];

const WrapperFluidType = styled(Box)(() => (({ customStyles }: { customStyles?: string }) => `
  & :where(.css-dev-only-do-not-override-1rqnfsa).ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector  { {
      width: 240px;
      height: 35px;
      background: ${customTheme.bg};
      font-size: 16px;
      color: ${customTheme.white};
      border: 1px solid #2F334D;
      marginTop: 4;
      marginBottom: 4;
  }
  ${customStyles}
`));

const FormFluidTypeZone = ({
  submitZoneFluidType,
  zoneBottom,
  zoneCount,
  currentZoneToEdit,
  isLoading,
  isLoadingCreate,
  zoneData,
  formFluidType,
}: any) => {
  const { label, zone_type, zone_plot_settings } = zoneData;
  const [fluidTypeValue, setSelectFluidType] = useState(zone_type || 'Undefined');
  const [editingFluidTyping, setEditingFluidTyping] = useState(false);
  const [isDirtyValue, setIsDirty] = useState(false);
  const [_, setNewZoneName] = useState(label);

  const formik = useFormik({
    initialValues: currentZoneToEdit || { ...defaultZoneFluidType, name: formFluidType === 'zone' ? label : `Zone ${zoneCount}`, top: zoneBottom, fluidType: zone_type },
    onSubmit: (values) => {
      submitZoneFluidType(values);
      // formik.resetForm({});
    },
    // validationSchema: ZoneScheme,
    enableReinitialize: true,
  });

  const { values, setFieldValue, handleSubmit, handleChange, handleBlur } = formik;

  useEffect(() => {
    if (formFluidType === 'zone') {
      setNewZoneName(label);
      setFieldValue('name', label);
      setEditingFluidTyping(true);
    } else {
      if (zoneCount > 0 && values?.name === 'Zone 1') {
        setNewZoneName(`Zone ${zoneCount}`);
        setFieldValue('name', `Zone ${zoneCount}`);
      }
    }
  }, [formFluidType]);

  const handleSelectChange = (value:string) => {
    setSelectFluidType(value);
    setFieldValue('fluidType', value);
    setIsDirty(true);
  };

  return (
    <CardBase customStyles="width: 260px; maxWidth: 260px; height: 312px; padding-left: 0;">
      <form
        onSubmit={handleSubmit}
        className="zones__form"
        style={{ padding: '8px 6px'}}
      >
        <FormLabel
          label="Zone Name"
          required
          data-cy="label-zone-name"
        >
          <Input
            style={{ 
              width: 240, height: 35, background: customTheme.bg, fontSize: 16,
              border: '1px solid #2F334D', marginTop: 4, marginBottom: 4
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            type="text"
            name="name"
            value={values.name || label}
            defaultValue={currentZoneToEdit ? label : ''}
            className="input--zone"
            data-cy="input-zone-name"
          />
        </FormLabel>
        <br />
        <div style={{ width: 200 }}>
        <FormLabel
          label="Fluid Type"
          required
          data-cy="label-fluid-type"
        >
          <WrapperFluidType>
            <Select
              placeholder="Select X Axis"
              // style={{ width: '100%', minWidth: '200px' }}
              style={{ 
                width: 240, height: 35, background: customTheme.bg, fontSize: 16,
                border: '1px solid #2F334D', marginTop: 4, marginBottom: 4
              }}
              value={fluidTypeValue}
              onChange={handleSelectChange}
            >
              {fluidTypes.map(option => (
                // @ts-ignore
                <Option key={option.label} value={option.value}>{option.value}</Option>
              ))}
            </Select>
          </WrapperFluidType>
        </FormLabel>
          <div style={{
             marginTop: 8,
             marginLeft: 5,
          }}>
            <ColorPicker
              fluidTypeValue={fluidTypeValue}
              currentZoneToEdit={currentZoneToEdit}
              afterChange={setFieldValue}
              editingFluidTyping={editingFluidTyping}
              color={zone_plot_settings?.color}
              isDirtyValue={isDirtyValue}
            />
          </div>
        </div>
        <div style={{ marginTop: 5 }}>
            <Button
              type="primary"
              htmlType="submit"
              data-cy="submit-zone"
              loading={isLoadingCreate}
              style={{
                width: 230,
                marginLeft: 8,
                marginTop: 4,
                border: 0,
                borderRadius: 6,
                fontWeight: 'bold',
                fontSize: 14,
                background: customTheme.blue4,
                color: customTheme.gray6,
              }}
            >
              Create zone 
            </Button>
            {isLoading ?
              <div style={{ 
                width: 250,
                padding: 10,
                marginTop: 30,
                borderRadius: 5,
                color: '#155724',
                border: 'solid 1px #fff',
                background: '#d4edda',
                textAlign: 'center'
                }}>
                Created zone sucessfully!
              </div>
              : ''
            }
        </div>
      </form>
    </CardBase>
  );
};
  
export default FormFluidTypeZone;
  