import React from 'react'
import { Button } from '../Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import { Container, WrapperNextPreviousButton } from './styles';
import Progress from '../Progress';

const Footer = ({ activeStep, steps, onCancel, onPrevious, onNext, isLoading }) => {
  return (
    <Container>
      <Button
          theme='secondary'
          startIcon={<CloseIcon />}
          width='fit-content'
          onClick={onCancel}
          disabled={isLoading}
          dataCy='cancel-button'
        >
        Cancel
      </Button>
      <WrapperNextPreviousButton>
        {activeStep !== 0 && (
          <Button
              theme='secondary'
              startIcon={<ArrowBackIcon />}
              width='fit-content'
              onClick={onPrevious}
              disabled={isLoading}
              dataCy='previous-button'
            >
            Previous
          </Button>
        )}
        {activeStep !== (steps.length - 1) ? (
          <Button
            type='button'
            theme='primary'
            width='fit-content'
            onClick={onNext}
            endIcon={activeStep !== (steps.length - 1) ? <ArrowForwardIcon /> : ''}
            loading={true}
            dataCy='next-button'
          >
            {activeStep === (steps.length - 1) ? 'Save' : 'Next'}
          </Button>
        ) : (
          <Button
            type='button'
            theme='primary'
            width='fit-content'
            onClick={onNext}
            loading={true}
            dataCy='save-button'
          >
          {isLoading ? (
            <>
              <Progress size={20} /> <span style={{ marginLeft: 8 }}>Saving...</span>
            </>
          ) : 'Save'}
          </Button>
        )}
          
      </WrapperNextPreviousButton>
    </Container>
  )
}

export default Footer;