import styled from "styled-components";
import BaseButton from "./Base";

const Tertiary = styled(BaseButton)`
  && {
    background-color: transparent;
    border: none;
    color: #7B89D3;
    font-weight: 400;
    transition: background-color 0.2s;
  }

  &:hover {
    background-color: #2F334D;
  }
`

export default Tertiary;