import React, { useState, useEffect } from 'react';
import { RGBColor } from 'react-color';
import MyPicker from './MyPicker.tsx';
import { defaultZone } from '../../utils/defaultZoneValues.ts';
import { ZoneItemType } from '../../types/zone';

type Props = {
  currentZoneToEdit: ZoneItemType;
  afterChange: (color: string, rgb: RGBColor) => void;
  fluidTypeValue: string;
  editingFluidTyping: boolean;
  color: RGBColor;
  isDirtyValue: boolean;
};

const mapFluidType = [
  {
    'Oil': {
      a: "0.5",
      b: 188,
      g: 218,
      r: 209
    },
    'Gas': {
      a: "0.5",
      b: 167,
      g: 167,
      r: 241
    },
    'Transition': {
      a: "0.5",
      b: 148,
      g: 192,
      r: 244
    },
    'Undefined': {
      a: "0.5",
      b: 179,
      g: 179,
      r: 179
    }
  }
];

export const ColorPicker = ({ currentZoneToEdit, afterChange, fluidTypeValue, editingFluidTyping, color, isDirtyValue }: Props) => {
  const defaultZoneColor = currentZoneToEdit ? currentZoneToEdit?.zone_plot_settings?.color: defaultZone?.color;
  const [displayColor, setDisplayColor] = useState<boolean>(false);
  const [currentColor, setChangeColor] = useState<any>(defaultZoneColor);

  const onHandleColorPicker = () => {
    // if (fluidTypeValue) {
    //   return setDisplayColor(false);
    // } else {
      return setDisplayColor(true);
    // }
  };

  useEffect(() => {
    if(fluidTypeValue && isDirtyValue) {
      mapFluidType.map(fluidType => {
        // @ts-ignore
        afterChange('color', fluidType[fluidTypeValue]);
        // @ts-ignore
        setChangeColor(fluidType[fluidTypeValue]);
      });
    }
    if (editingFluidTyping && !isDirtyValue) {
      // @ts-ignore
      afterChange('color', color);
      // @ts-ignore
      setChangeColor(color);
    }
  }, [fluidTypeValue, editingFluidTyping]);

  const handleChangeColor = (color: { rgb: RGBColor }) => {
    afterChange('color', { ...color?.rgb, a: currentColor?.a });
    setChangeColor({ ...color?.rgb, a: currentColor?.a });
  };

  const handleChangeAlpha = (color: { rgb: RGBColor }) => {
    afterChange('color', { ...currentColor, a: color?.rgb?.a });
    setChangeColor({ ...currentColor, a: color?.rgb?.a });
  };

  const selectedColour: React.CSSProperties = {
    backgroundColor: `rgba(${currentColor?.r},${currentColor?.g}, ${currentColor?.b}, ${currentColor?.a})`,
  };

  return (
    <div className="zones-form__color">
      <label>
        <span className="text-danger">*&nbsp;</span>
        <span>Color</span>
      </label>
      <div>
        <div className="zones-div-input-color">
          <input
            type="button"
            name="color"
            style={selectedColour}
            onClick={onHandleColorPicker}
          />
        </div>
        <div className="zones-displayColor">
          {displayColor ? (
            <MyPicker
              color={currentColor}
              onChangeOpacity={handleChangeAlpha}
              onChangeColor={handleChangeColor}
            />
          ) : null}
        </div>
      </div>
    </div>
  )
};
