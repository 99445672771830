import { Box } from "@mui/material";
import styled from "styled-components";
import { customTheme } from "../../../src/theme/default";

export const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
  border-bottom: 1px solid ${customTheme.purple7};
  font-size: 16px;
  font-weight: 700;
  color: ${customTheme.white};

  > div {
    height: fit-content;
  }

  > button {
    padding: 0;
  }
`