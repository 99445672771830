import React, { useState, useEffect } from 'react';
import { Tabs, Drawer } from 'antd';
import { useParams } from 'react-router-dom';
import store from 'store';
import AxisLeftForm from './AxisLeftForm';
import AxisRightForm from './AxisRightForm';
import XAxisForm from './XAxisForm';
import close from '../../../assets/close-white.svg';

import './style/settings.scss';

const { TabPane } = Tabs;

const Settings = ({
  visible, onClose, currentCurve,
  onSubmitLeft, onSubmitRight, onSubmitXAxis,
  currentScaleConfig, onChangeTab
}) => {
  const [curves, setCurves] = useState({});
  const params = useParams();

  useEffect(() => {
    const [pathFile] = params.id.split('--');
    // (async () => {
    //   const { data: response } = await fetchWellSite(
    //     pathFile.replaceAll('+', '/'),
    //     store.get('token')
    //   );
    //   if (response.data) {
    //     setCurves(response.data);
    //   }
    // })();
    // (async () => {
    //   try {
    //     await dispatch(listWell(wellId, store.get('token')));
    //   } catch(e) {
    //     console.log('CutOfss/Chart/index error fetchWellSite', e);
    //   }
    // })();
  }, []);

  const submitXAxis = values => {
    onSubmitXAxis(values);
  };

  const submitLeft = values => {
    onSubmitLeft(values);
  };

  const submitRight = values => {
    onSubmitRight(values);
  };

  return (
    <Drawer
      placement="right"
      closable={false}
      onClose={onClose}
      visible={visible}
      maskClosable={false}
      width={530}
      getContainer={false}
      className="wrapper-drawer cutoff-view-config drawer-settings-mask"
    >
      <div className="wrapper-settings">
        <div className="wrapper-settings-header">
          <h4 className="title-settings">Settings</h4>
          <img
            className="settings-close"
            src={close}
            alt="close"
            onClick={onClose}
          />
        </div>
        <div className="wrapper-menu">
          <Tabs defaultActiveKey="1" onChange={onChangeTab}>
            <TabPane tab="xAxis" key="1">
              <div className="content-tab">
                <XAxisForm
                  currentCurve={currentCurve}
                  currentScaleConfig={currentScaleConfig}
                  onSubmitXAxis={submitXAxis}
                  onClose={onClose}
                  curves={curves}
                />
              </div>
            </TabPane>
            <TabPane tab="yAxis Left" key="2">
              <AxisLeftForm
                currentCurve={currentCurve}
                currentScaleConfig={currentScaleConfig}
                onSubmitLeft={submitLeft}
                onClose={onClose}
                curves={curves}
              />
            </TabPane>
            <TabPane tab="yAxis Right" key="3">
              <AxisRightForm
                currentCurve={currentCurve}
                currentScaleConfig={currentScaleConfig}
                onSubmitRight={submitRight}
                onClose={onClose}
              />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </Drawer>
  );
};

export default Settings;
