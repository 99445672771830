import * as React from 'react';
import { useFormContext } from "react-hook-form";
import { InputText } from '../../../TextField';
import {
  WrapperInputRow, WrapperInputs,
  WrapperLoadedIntervalInputs
} from './styles';
import { Grid, Box} from '@mui/material';
import { CheckBoxDataGrid } from '../CheckBoxDataGrid';
import { Autocomplete } from '../../../Autocomplete';
import { typeOptions } from '../../../../utils/matchCurves';

export const CurvesTable = ({ clearErrors }) => {
	const { control, getValues } = useFormContext();
	const curves = getValues().curves;

  return (
    <WrapperInputs>
			<WrapperLoadedIntervalInputs>
				<WrapperInputRow>
				{curves.length > 0 
				? <Box sx={{ width: 652, flexGrow: 1, mb: 2, }}>
							<Grid container spacing={2} justifyContent="space-between">
								<Grid container item>
									{
										curves.map((item, index) => {
											return (
												<Grid container spacing={1} style={{ marginTop: 10 }} key={index}>
													<Grid item xs={4} style={{ display: 'flex' }}>
														<CheckBoxDataGrid
															name={`curves.${index}.checked`}
															control={control}
															afterChange={clearErrors}
															defaultChecked={item?.type && item?.name || !!item?.checked}
															dataCy={`curves-${index}-checked`}
														/>
														<InputText
															readyOnly
															control={control}
															inputProps={{
																readOnly: true,
																disabled: true,
															}}
															name={`curves.${index}.mnemonic`}
															label={index === 0 ? "Mnemonic" : ''}
															type="text"
															defaultValue={item.mnemonic}
															dataCy={`curves-${index}-mnemonic`}
														/>
													</Grid>	
													<Grid item xs={3}>
														<InputText
															control={control}
															name={`curves.${index}.name`}
															label={index === 0 ? "Name" : ''}
															type="text"
															defaultValue={item.name}
															dataCy={`curves-${index}-name`}
														/>
													</Grid>
													<Grid item xs={3}>
														<Autocomplete
															control={control}
															name={`curves.${index}.type`}
															options={typeOptions}
															label={index === 0 ? "Type" : ''}
															dataCy={`curves-${index}-type`}
														/>
													</Grid>
													<Grid item xs={2}>
														{index === 0 ? (
															<label
																style={{
																	fontWeight: 700,
																	fontSize: 16,
																	color: '#FFFFFF'
																}}
															>
																Unit
															</label>
														) : null}
														<InputText
															control={control}
															name={`curves.${index}.unit`}
															label=""
															type="text"
															defaultValue={item.unit}
															dataCy={`curves-${index}-unit`}
														/>
													</Grid>
												</Grid>
											);
										})
									}
								</Grid>
							</Grid>
						</Box>
				: ''}
				</WrapperInputRow>
			</WrapperLoadedIntervalInputs>
		</WrapperInputs>
  );
};
