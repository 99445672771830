import styled from "styled-components";
import BaseButton from "./Base";

const Primary = styled(BaseButton)`
  && {
    background-color: #233BB6;
    transition: border 0.1s;

    &:hover {
      background-color: #1D3195;
      border: 3px solid #7B809E;
    }

    &:disabled {
      background-color: #2F334D;
    }
  }
`

export default Primary;