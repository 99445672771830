import './style.scss';

import React from 'react';

import { Button, Tabs } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ModalStatistics from './components/ModalStatistics';
import {
  useStatisticWizard,
  WizardProvider
} from './components/ModalStatistics/useStatisticsWizard';
import TabsSignificanceLevel from './components/TabsSignificanceLevel';

const { TabPane } = Tabs;

const StatisticsTabs = () => {
  const { validFormsCurves } = useStatisticWizard();
  const wells = useSelector(state => state.wells);

  return (
    <>
      <Tabs defaultActiveKey="1" className="custom-tabs">
        <TabPane
          tab="Configuration"
          key="1"
          className="tab-panel-statistics"
        >
          <ModalStatistics
            render={openModal => (
              <Button onClick={openModal}>Open Modal</Button>
            )}
          />
        </TabPane>
        <TabPane
          tab="Significance Level"
          key="2"
          className="tab"
          disabled={!(validFormsCurves && validFormsCurves.length)}
        >
          <TabsSignificanceLevel currentWell={wells.currentWell} />
        </TabPane>
      </Tabs>
    </>
  );
};

const Statistics = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [_, wellId] =
    location && location.search ? location.search.split('=') : '-1';

  if (wellId.toString() === '-1') {
    navigate(`/home?wellId=${wellId}`);
  }

  return (
    <WizardProvider>
      <div className="container">
        <div className="content-statistic-page">
          <StatisticsTabs />
          {/* <TabsSignificanceLevel /> */}
        </div>
        {/* <Loading
          size="50"
        />
        <div style={{ width: '500px' }}>
          <CollapsePanel onChange={() => {}} />
        </div>
        <AlertError/>
        <div className="wrapper-significance">
          <TabsSignificanceLevel/>
        </div>
        <div className="wrapper-significance">
          <MessageSignificanceLevel
            curve="C1"
          />
        </div>
        <div className="alert-statistics-concepts">
          <ModalStatisticsConcepts
            render={(openModal) => (
              <AlertStatisticsConcepts
                openModal={openModal}
              />
            )}
          />
        </div>*/}
      </div>
    </WizardProvider>
  );
};

export default Statistics;
