import React, { useState, useEffect } from 'react';
import store from 'store';
import { useSelector } from 'react-redux';
import get from 'lodash.get';
import Ui from '@geowellex/shared-ui'

import {
  propagateZoom, syncronizeTooltip,
  getToolTipValues, displayZoomBtn, defaultCrossHair,
  disableDots, nFormatter, defaultValuesToCrosshair
} from '../../../../utils';
import { getRawCurveData } from 'utils/getRawCurveData';
import { useScales } from '../../../../hooks/useScales';
import { DEFAULT_SCALES } from '../../../../utils/constants';

export const curveTypes = ['isotope'];

// eslint-disable-next-line no-unused-vars
const updatedHeader = {
  scales: store.get('configScales') || { ...DEFAULT_SCALES },
  isotope: 0
};

const initialHeader = {
  isotope: 0
};

export const ChartHeader = () => {
  const wells = useSelector(state => state.wells);

  const {
    currentScale, setHeaderState, headerState
  } = useScales(
    curveTypes,
    wells.currentWell,
    initialHeader
  );

  // --- Open modal with config
  const dispatchModal = (typeOfTitle) => (event) => {
    event.persist();
    event.stopPropagation();
    const openModal = new CustomEvent('openConfigModal', {
      detail: {
        type: typeOfTitle
      }
    });
    document.dispatchEvent(openModal);
  };

  useEffect(() => {
    if (wells.currentWell && wells.selectedDepthIndex >= 0) {
      const isotope = get(getRawCurveData('isotope', wells.currentWell), 'data', []);
      const index = wells.selectedDepthIndex;
      const isotopeValue = isotope && isotope?.data?.[index] ? isotope?.data?.[index] : 0;
      setHeaderState({
        ...headerState,
        isotope: isotopeValue ? isotopeValue?.toFixed() : 0
      });
    }
    if (!wells.currentWell) {
      setHeaderState({
        ...headerState,
        isotope: 0
      });
    }
  }, [wells]);

  useEffect(() => {
    setHeaderState({
      scales: store.get('configScales')
    });

    const listenConfigScales = ({ detail }) => {
      setHeaderState({
        ...headerState,
        ...updatedHeader
      });
    };
    // // Update isotope track
    document.addEventListener('isotope', listenConfigScales);

    return () => {
      document.removeEventListener('isotope', listenConfigScales);
    };
  }, []);

  const { isotope } = headerState;
  return (
    <div className="isotope-header">
      <Ui.Header
        min={nFormatter(currentScale?.isotope?.min)}
        max={nFormatter(currentScale?.isotope?.max)}
        textCenter={`δ13C (${isotope || ''})`}
        background={currentScale?.isotope?.color}
        onClick={dispatchModal('isotope')}
        activeCurveConfig='isotope'
        dataCy="open-config-isotope"
      />
    </div>
  );
};
