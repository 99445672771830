import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-modal';
import classes from 'classnames';
import ScalesConfig from '../ScalesConfig';
import removeIcon from '../../assets/close.svg';
import { modalStyle } from '../../utils';
import { DEFAULT_SCALES } from '../../utils/constants';
import ConfigContext from '@geowellex/shared-ui/src/context/ConfigContext';
import { customTheme } from '@geowellex/shared-ui/src/theme/default';

const Title = ({ title, className, classTitle }) => (
  <h3
    className={classes(
      '',
      {
        [className]: !!className,
        [classTitle]: !!classTitle
      }
    )}
    style={{
      color: customTheme.white,
      height: 21,
      fontWeight: 700,
    }}
  >
    {title}
  </h3>
);

Title.defaultProps = {
  classTitle: 'modal-scale__title'
};

const ConfigModal = () => {
  const {
    activeCurveConfig: typeOfTitle,
    setOpenDrawer
  } = useContext(ConfigContext);

  const closeModal = () => setOpenDrawer(false);
  return (
    <div
      className="modal-scale__form"
    >
      {typeOfTitle && (
        <ScalesConfig
          typeOfTitle={typeOfTitle}
          onClose={closeModal}
        />
      )}
    </div>
  );
};

export default ConfigModal;
