import React from 'react';
import { ReactComponent as NoZonesIcon } from '../../assets/no_zones_icon.svg';
import { EmptyZoneContainer, MoreInfo, Title } from './styles';

export const EmptyZoneState = () => {
  return (
    <EmptyZoneContainer>
      <NoZonesIcon />
      <Title>No zones yet</Title>
      <MoreInfo>There aren’t any zone at the moment</MoreInfo>
    </EmptyZoneContainer>
  );
};
