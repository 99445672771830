import styled from "styled-components";
import BaseButton from "./BaseButton";

const SecondaryButton = styled(BaseButton)`
  && {
    background-color: #151724;
    border: 1px solid #323752;
  }
`

export default SecondaryButton;
