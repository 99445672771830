import React from 'react';
import cutoff from '../../../assets/cutoff-icon.png';
import PlotBox from './PlotBox';
import { Button } from '../../../screens/QualityControl/components/Button'

export const CutOffViewerBtn = () => {

  const openCrossPlots = () => {
    const detail = {
      type: 'cutOff'
    };
    const openWindow = new CustomEvent('plotBoxCuttOff', { detail });
    document.dispatchEvent(openWindow);
  };

  return (
    <div className="wrapper-cutoff-button" style={{margin: '10px 20px'}}>
      <Button
        type="button"
        theme="secondary"
        className="button-cutoff-viewer"
        onClick={openCrossPlots}
      >
        <img className="img-cutoff-viewer" src={cutoff} alt="Cutoff viewer"/>
        Cutoff Viewer
      </Button>
      <PlotBox />
    </div>
  );
};
