import { CircularProgress } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const OverlayWrapper = styled(Box)(({ show }: { show: boolean}) => `
  display: ${show ? 'block' : 'none'};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 10000;
 `,
);

const OverlayLoadingWrapper = styled(Box)(() => `
  text-align: center;
  box-sizing: border-box;
  padding: 20px;
  color: #fff;
  background: rgba(0, 0, 0, 0);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
 `,
);

const OverlayTitle = styled(Box)(() => `
  font-family: sans-serif;
  font-size: 16px;
  color: #fff;
 `,
);

const OverlayLoad = styled(Box)(() => `
  width: 100px;
  height: 50px;
 `,
);

const Loading = ({ show }: any) => {
  return (
    <OverlayWrapper
      data-cy="overlay-load"
      show={show}
    >
      <OverlayLoadingWrapper>
        <OverlayLoad>
          <CircularProgress />
        </OverlayLoad>
        <OverlayTitle>Loading...</OverlayTitle>
      </OverlayLoadingWrapper>
    </OverlayWrapper>
  )
};

export default Loading;
