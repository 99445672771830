// import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
// import { Select, Button, Input } from 'antd';
// import { CheckOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux'; // useDispatch
// import { promise } from 'zod';
// import { createCutOffFilter } from '../../../../api/cutoff';
// import store from 'store';
// import { fetchCutOffs, deleteCutOff } from '../../../../actions/cutoff';
// import { getAllCutOffs } from '../../../../api/cutoff';
import { Close as CloseIcon, Check as CheckIcon, Cached as CachedIcon } from '@material-ui/icons';
import CutoffProxy from 'components/CutOffs';
import { CutOffViewerBtn } from '../../../../components/CutOffs/Chart/CutOffViewerBtn';
import UI from '@geowellex/shared-ui';

import {
  CloseButton, ContainerBody, ContainerFooter,
  ContainerTitle, TitleExtraction, WrapperApplyReset,
  WrapperExtraction
} from '../ExtractionModal/styles'

const CutOffModal = ({
  isModalOpen, close, payload,
  setPayload, setCutoffSettings, wellActions,
  setReset, afterSave,
 }) => {
  const wells = useSelector(state => state.wells);

  const closeModal = () => {
    close();
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1000,
        },
        content: {
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '600px',
          width: '100%',
          height: '80vh',
          padding: '0',
          backgroundColor: '#151724',
          border: 'none',
          borderRadius: '8px',
        },
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <ContainerTitle>
          <TitleExtraction>
            Cutoffs
          </TitleExtraction>
          <CloseButton onClick={closeModal} data-cy="close-extraction-button">
            <CloseIcon />
          </CloseButton>
        </ContainerTitle>
        <UI.CustomScrollBar>
          <div style={{width: '100%'}}>
            <CutOffViewerBtn />
            <CutoffProxy
              disableCutOffViewer
              enableSaveButton
              currentWell={wells.currentWell}
              setCutoffSettings={setCutoffSettings}
              wellActions={wellActions}
              setReset={setReset}
              afterSave={afterSave}
            />
          </div>
        </UI.CustomScrollBar>
      </div>
    </Modal>
  );
};

export default CutOffModal;
