import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod'
import { Close as CloseIcon, Check as CheckIcon, Cached as CachedIcon } from '@material-ui/icons';
import { errorNotification } from '../../../../utils';
import { InputText } from '../InputText';
import { Modal } from '../Modal';
import { Button } from '../Button';

import {
  CloseButton, ContainerBody, ContainerFooter,
  ContainerTitle,TitleExtraction, WrapperApplyReset,
  WrapperExtraction
} from './styles'

const validation = z.string().regex(/^(100|[1-9]?\d(\.\d{1,2})?)$|^$/, 'Invalid number, must be between 0 and 100').transform((val) => val ? parseFloat(val) : val);

const extractionSchema = z.object({
  c1: validation,
  c2: validation,
  c3: validation,
  ic4: validation,
  nc4: validation,
  c4: validation,
  ic5: validation,
  nc5: validation,
  c5: validation
})

const ExtractionModal = ({ isModalOpen, closeModal, payload, saveData, fieldValues }) => {
  const numberToPercentageString = (number) => (number*100).toString();

  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: zodResolver(extractionSchema),
    defaultValues: {
      c1: fieldValues?.c1 ? numberToPercentageString(fieldValues.c1) : '',
      c2: fieldValues?.c2 ? numberToPercentageString(fieldValues.c2) : '',
      c3: fieldValues?.c3 ? numberToPercentageString(fieldValues.c3) : '',
      ic4: fieldValues?.ic4 ? numberToPercentageString(fieldValues.ic4) : '',
      nc4: fieldValues?.nc4 ? numberToPercentageString(fieldValues.nc4) : '',
      c4: fieldValues?.c4 ? numberToPercentageString(fieldValues.c4) : '',
      ic5: fieldValues?.ic5 ? numberToPercentageString(fieldValues.ic5) : '',
      nc5: fieldValues?.nc5 ? numberToPercentageString(fieldValues.nc5) : '',
      c5: fieldValues?.c5 ? numberToPercentageString(fieldValues.c5) : ''
    }
  });
  const [options, setOptions] = useState([]);

  const percentageToDecimal = (value) => Number((parseFloat(value) / 100).toFixed(3));

  const wells = useSelector(state => state.wells);

  useEffect(() => {
    if (wells.currentWell) {
      const predefinedOptions = [
        "c1", "c2", "c3", "ic4", "nc4", "c4", "ic5", "nc5", "c5"
      ];
      const filteredOptions = predefinedOptions.map(option => ({ label: option, value: option }));
      setOptions(filteredOptions);

    }
  }, [wells.currentWell]);

  const removeEmptyProperties = (obj) => {
    const result = {}

    Object.keys(obj).forEach((key) => {
      if (obj[key] !== '') {
        const decimalValue = percentageToDecimal(obj[key])
        result[key] = decimalValue;
      }
    })

    return result;
  }

  const sendNormalizationData = (data) => {
    const message = {
      success: 'Gas normalization applied!',
      error: 'Error when saving gas normalization'
    };

    saveData(data, message);
    closeModal()
  }

  const handleResetNormalizationData = () => {
    const newPayload = {
      ...payload,
      normalization_settings: null
    };

    sendNormalizationData(newPayload)
    reset({ c1: '', c2: '', c3: '', ic4: '', nc4: '', c4: '', ic5: '', nc5: '', c5: '' });
  }

  const handleCloseModal = () => {
    reset();
    closeModal()
  }

  const handleExtraction = (data) => {
    const formattedData = removeEmptyProperties(data);
    const isObjectEmpty = Object.keys(formattedData).length === 0;

    if (isObjectEmpty) {
      errorNotification('No fields filled');
      return;
    };

    const newPayload = {
      ...payload,
      normalization_settings: formattedData
    };

    sendNormalizationData(newPayload);
  }

  return (
    <Modal isOpen={isModalOpen}>
      <WrapperExtraction>
        <ContainerTitle>
          <TitleExtraction>
            Extraction Efficiency Coefficients (%)
          </TitleExtraction>
          <CloseButton onClick={handleCloseModal} data-cy="close-extraction-button">
            <CloseIcon />
          </CloseButton>
        </ContainerTitle>

        <ContainerBody>
          {options.map((option) => (
            <InputText
              label={option.label.toUpperCase()}
              type="number"
              placeholder="Ex:. 100"
              error={!!errors[option.value]}
              errorMessage={errors[option.value]?.message}
              register={{...register(option.value)}}
              dataCyInput={`input-${option.label}-extraction`}
              dataCyText={`${option.label}-text-extraction`}
            />
          ))}
        </ContainerBody>

        <ContainerFooter>
          <Button
            type='button'
            theme='secondary'
            onClick={handleCloseModal}
            dataCy="cancel-extraction-button"
          >
            Cancel
          </Button>
          <WrapperApplyReset>
            <Button
              type='button'
              theme='secondary'
              startIcon={<CachedIcon/>}
              onClick={handleResetNormalizationData}
              dataCy="reset-extraction-button"
            >
              Reset
            </Button>
            <Button
              type='button'
              theme='primary'
              startIcon={<CheckIcon/>}
              onClick={handleSubmit(handleExtraction)}
              dataCy="apply-extraction-button"
            >
              Apply
            </Button>
          </WrapperApplyReset>
        </ContainerFooter>
      </WrapperExtraction>
    </Modal>
  );
};

export default ExtractionModal;
