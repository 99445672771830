import { getRawCurveData } from './getRawCurveData';

export const statisticsCurvesNames = [
  'C1', 'C2', 'C3', 'NC4', 'IC4', 'NC5', 'IC5'
];

export const defaultCriticalTypes = [
  'c1_diff', 'c2_diff', 'c3_diff',
  'nc4_diff', 'ic4_diff', 'nc5_diff',
  'ic5_diff'
];

export const GAS_COMP_DEFAULT = 'gasComposition';
export const GAS_COMP_C1 = 'c1_perc';
export const GAS_COMP_C2 = 'c2_perc';
export const GAS_COMP_C3 = 'c3_perc';
export const GAS_COMP_C4 = 'c4_perc';
export const GAS_COMP_C5 = 'c5_perc';

export const CHROMA_C1 = 'c1';
export const CHROMA_C2 = 'c2';
export const CHROMA_C3 = 'c3';
export const CHROMA_IC4 = 'ic4';
export const CHROMA_NC4 = 'nc4';
export const CHROMA_NC5 = 'nc5';
export const CHROMA_IC5 = 'ic5';

export const ROP = 'rop';

export const UPDATE = 'update';
export const CREATE = 'create';

export const scales = {
  rop: {
    min: 0,
    max: 60,
    color: '#075E43',
    line: '#075E43',
    title: 'ROP',
    titleModal: 'ROP'
  },
  gasRatio: {
    min: 0,
    max: 60,
    color: '#9600ff',
    title: 'GR',
    titleModal: 'GAMA RAY'
  },
  total_gas: {
    min: 0.1,
    max: 1000,
    color: '#D5D5D6',
    line: '#D5D5D6',
    title: 'Total Gas (units)',
    titleModal: 'Total Gas'
  },
  total_carbon: {
    min: 0.1,
    max: 100000,
    color: '#5C2291',
    line: '#5C2291',
    title: 'Total Carbon',
    titleModal: 'Total Carbon'
  },
  c1_norm: {
    min: 0.1,
    max: 100000,
    color: '#881A1B',
    line: '#881A1B',
    title: 'C1',
    titleModal: 'C1'
  },
  c1Normalized: {
    min: 0.1,
    max: 100000,
    color: '#881A1B',
    line: '#881A1B',
    title: 'C1 Normalized',
    titleModal: 'C1 Normalized'
  },
  gqr: {
    min: 0.2,
    max: 2,
    color: '#116531',
    line: '#116531',
    title: 'GQR ()',
    titleModal: 'Gas Quality Ratio'
  },
  chromatography: {
    min: 0.1,
    max: 100000,
    color: '#8a221b'
  },
  [CHROMA_C1]: {
    min: 0.1,
    max: 100000,
    color: '#FF0000',
    title: 'C1',
    titleModal: 'C1'
  },
  [CHROMA_C2]: {
    min: 0.1,
    max: 100000,
    color: '#40650C',
    line: '#40650C',
    title: 'C2',
    titleModal: 'C2'
  },
  [CHROMA_C3]: {
    min: 0.1,
    max: 100000,
    color: '#1A3F91',
    line: '#1A3F91',
    title: 'C3',
    titleModal: 'C3'
  },
  [CHROMA_IC4]: {
    min: 0.1,
    max: 100000,
    color: '#771A83',
    line: '#771A83',
    title: 'IC4',
    titleModal: 'IC4'
  },
  [CHROMA_NC4]: {
    min: 0.1,
    max: 100000,
    color: '#4E2693',
    line: '#4E2693',
    title: 'NC4',
    titleModal: 'NC4'
  },
  [CHROMA_IC5]: {
    min: 0.1,
    max: 100000,
    color: '#413AB9',
    line:  '#413AB9',
    title: 'iC5',
    titleModal: 'IC5'
  },
  [CHROMA_NC5]: {
    min: 0.1,
    max: 100000,
    line: '#085B6F',
    color: '#085B6F',
    title: 'nC5',
    titleModal: 'NC5'
  },
  gasComposition: {
    min: 0.01,
    max: 100,
    title: 'C1',
    titleModal: 'C1'
  },
  [GAS_COMP_C1]: {
    min: 0,
    max: 100,
    color: '#881A1B',
    line: '#881A1B',
    title: 'C1',
    titleModal: 'C1'
  },
  [GAS_COMP_C2]: {
    min: 0,
    max: 100,
    color: '#116531',
    line: '#116531',
    title: 'C2',
    titleModal: 'C2'
  },
  [GAS_COMP_C3]: {
    min: 0,
    max: 100,
    line: '#1A3F91',
    color: '#1A3F91',
    title: 'C3',
    titleModal: 'C3'
  },
  [GAS_COMP_C4]: {
    min: 0,
    max: 100,
    line: '#4E2693',
    color: '#4E2693',
    title: 'C4',
    titleModal: 'C4'
  },
  [GAS_COMP_C5]: {
    min: 0,
    max: 100,
    line: '#D5D5D6',
    color: '#D5D5D6',
    text: '#0C0C14',
    title: 'C5',
    titleModal: 'C5'
  },
  ch_ratio: {
    min: 0.1,
    max: 10,
    color: '#085B6F',
    line: '#085B6F',
    title: 'Cha. Ratio',
    titleModal: 'Character Ratio'
  },
  bh_ratio: {
    min: 0.1,
    max: 100,
    color: '#5C2291',
    line: '#5C2291',
    title: 'Bal. Ratio',
    titleModal: 'Balance Ratio'
  },
  wh_ratio: {
    min: 0.1,
    max: 100,
    color: '#116531',
    line: '#116531',
    title: 'Wet. Ratio',
    titleModal: 'Wetness Ratio'
  },
  ic4nc4: {
    min: 0,
    max: 2,
    color: '#332E8E',
    line: '#332E8E',
    title: 'ic4/Nc4',
    titleModal: 'ic4/Nc4'
  },
  ic5nc5: {
    min: 0,
    max: 10,
    line: '#90380C',
    color:  '#90380C',
    title: 'iC5/nC5',
    titleModal: 'iC5/nC5'
  },
  c1c2: {
    min: 0,
    max: 0.1,
    color: '#871B4C',
    line: '#871B4C',
    title: 'C1/C2',
    titleModal: 'C1/C2'
  },
  c1c2GQR: {
    min: 0.1,
    max: 100000,
    color: '#EA008A',
    title: 'C1/C2',
    titleModal: 'C1/C2'
  },
  c1c3: {
    min: 0.1,
    max: 100000,
    color: '#C47206',
    title: 'C1/C3',
    titleModal: 'C1/C3'
  },
  c1c4: {
    min: 0.1,
    max: 100000,
    color: '#2870DC',
    title: 'C1/C4',
    titleModal: 'C1/C4'
  },
  c1c5: {
    min: 0.1,
    max: 100000,
    color: '#C22F5F',
    title: 'C1/C5',
    titleModal: 'C1/C5'
  },
  slope_factor: {
    min: 0,
    max: 50,
    color: '#8B1530',
    line: '#8B1530',
    className: 'sfC1ByC2',
    title: 'Slope Factor',
    titleModal: 'Slope Factor'
  },
  isotope: {
    min: -100,
    max: 50,
    color: '#40650C',
    line: '#40650C',
    title: 'δ13C ()',
    titleModal: 'Isotopes'
  },
  goodData: {
    min: 0,
    max: 1,
    color: '#33cc33',
    line: '#33cc33',
    title: 'Good Data',
    titleModal: 'Good Data',
  },
  badData: {
    min: 0,
    max: 1,
    color: '#D22B2B',
    line: '#D22B2B',
    title: 'Bad Data',
    titleModal: 'Bad Data',
  },
};

export const DEFAULT_SCALES = Object.freeze(scales);

export const configChart = (type, currentWell) => {
  const raw = getRawCurveData(type, currentWell);
  return {
    [type]: {
      min: raw?.visualization_config?.left_scale,
      max: raw?.visualization_config?.right_scale,
      color: raw?.visualization_config?.color,
      titleModal: raw?.name,
      name: type,
    },
  };
};

const white = '#fff';

export const COLORS = {
  color1: white
};

export const defaultCurveKeys = {
  depth: null,
  flowppms: null,
  rop: null,
  gamma_ray: null,
  gas_ratio: null,
  total_gas: null,
  c1: null,
  c2: null,
  c3: null,
  c4: null,
  nc4: null,
  ic4: null,
  c5: null,
  nc5: null,
  ic5: null,
  isotope: null,
  c1Byc2: null,
  ic4BynC4: null,
  ic5BynC5: null,
  wetnessRatio: null,
  balanceRatioData: null,
  characterRatioData: null,
  c1Composition: null,
  c2Composition: null,
  c3Composition: null,
  nc4Composition: null,
  nc5Composition: null,
  gor: null,
  api: null,
  c2h4: null,
  c2h6: null,
  c2s: null,
};

export const defaultCurveKeysSensorType = {
  c2h4: null,
  c3h6: null,
  c2s: null
}

// Add a pre-list with a lot of mnemonic or curve keys here
export const defaultMnemonics = {
  depth: ['tvdss','dept', 'depth_tvd', 'tvd', 'tvd_depth', 'md', 'total_depth', 'depth', 'depth_md', 'prof'],
  flow: ['flow', 'flowin', 'flowout', 'flowinout', 'flowin/ou', 'flwpmps', 'flow_in'],
  rop: ['rop', 'rop_min', 'rop/min', 'rop_time_avg', 'rop_avg'],
  gamma_ray: ['g_ray', 'gamma_ray', 'gammaRay', 'gamma_r'],
  total_gas: ['t_gas', 'total_gas', 'gast', 'gast_t', 'gas_total', 'gastotal', 'totalgas', 'gastchr', 'hydc'],
  c1: ['c1', 'meth'],
  c2: ['c2', 'eth'],
  c3: ['c3', 'prp'],
  nc4: ['nc4', 'n_c4', 'normalC4', 'NC4'],
  ic4: ['ic4', 'isotpec4'],
  c4: ['c4', 'C4'],
  nc5: ['nc5', 'n_c5', 'normalC5', 'NC5'],
  ic5: ['ic5', 'isotpec5'],
  c5: ['c5', 'C5'],
  isotope: ['isotope', 'isotopes', 'iso', 'delta', 'iso_delta']
};

export const defaultMnemonicsSensorType = {
  c2h4: ['c2h4'],
  c3h6: ['c3h6']
};

export const defaultMnemonicsSensorTypeC2s = {
  c2s: ['c2s', 'c2sf']
};
