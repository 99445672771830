import React from 'react';

const RenderChart = ({
  id, styles, className
}) => (
  <div
    id={id}
    style={styles}
    className={className}
  />
);

RenderChart.defaultProps = {
  styles: {
    overflow: 'auto',
    width: '92vw',
    background: 'transparent',
    alignSelf: 'center',
    borderRadius: '8px'
  },
  className: ''
};

export default RenderChart;
