import React, { useState, useEffect, useCallback } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { capitalize } from 'lodash';

import { BasicInformationForm } from '../BasicInformationForm';
import { WellPhases } from '../WellPhases';
import { MatchingCurves } from '../MatchingCurves';
import Button from '@mui/material/Button';
import { Alert, Snackbar } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { WellCoordinates } from '../WellCoordinates';
import Footer from '../Footer';
import { LasReview } from '../LasReview';
import Steps from '../Steps';
import { checkAndValidateBitSizes, wellCoodinatesSchema, WellCoordinatesAreEmpty } from './validations';
import { formatDiameter } from '../../utils/formatDiameter';
import { WrapperFooter } from './styles';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabsPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const requiredMessage =  { type: 'custom', message: 'Required'};

export default function BasicTabs({ getDataType, lasInfo, onSubmit, changedUnit, defaultData, wellOptions, onClose, isLoading }) {
  const methods = useForm();
  const formValues = methods.getValues();
  const currentFormValues = methods.watch();
  const [customErrors, setCustomErrors] = useState({});
  const [openAlert, setOpenAlert] = useState(false);

  // setup default values to form
  useEffect(() => {
    if (formValues.start_depth !== defaultData.start_depth) {
      methods.setValue('start_depth', defaultData.start_depth);
      methods.setValue('stop_depth', defaultData.stop_depth);
    }

    if (!methods?.formState?.isDirty) {
      if (!formValues.bs && defaultData?.well_phases) {
        const newWellPhases = formatDiameter(defaultData.well_phases);
        methods.setValue('bs', newWellPhases);
      }
    
      if (!formValues.typedata && !defaultData?.typedata) {
        methods.setValue('typedata', 'Create');
      }

      if (!formValues.unit && !defaultData?.unit) {
        methods.setValue('unit', 'm');
      } else if (!formValues.unit && defaultData?.unit) {
        methods.setValue('unit', defaultData?.unit);
      }

      if (!formValues.name && defaultData?.name) {
        methods.setValue('name', defaultData?.name);
      }

      if (!formValues.top && defaultData?.start_depth) {
        methods.setValue('top', defaultData?.start_depth);
        methods.setValue('start_depth', defaultData?.start_depth);
      }

      if (!formValues.base && defaultData?.stop_depth) {
        methods.setValue('base', defaultData?.stop_depth);
        methods.setValue('stop_depth', defaultData?.stop_depth);
        methods.setValue('range', { start_depth: defaultData?.start_depth, stop_depth: defaultData?.stop_depth });
      }

      if (!formValues.has_sensor && defaultData?.ethene_sensor) {
        methods.setValue('has_sensor', defaultData?.ethene_sensor?.has_sensor);
      }

      if (!formValues.logset && defaultData?.log_sets?.[0]) {
        const defaultLogSet = defaultData.log_sets?.[0]?.name 
          ? capitalize(defaultData.log_sets?.[0]?.name) : undefined;
        methods.setValue('logset', defaultLogSet);
      }

      if (!formValues.depth_reference && defaultData?.log_sets?.[0]?.index_curve?.mnemonic) {
        const depthReference = defaultData?.log_sets?.[0]?.index_curve?.mnemonic;
        methods.setValue('depth_reference', depthReference);
      }

      const { lat_long_coordinates, utm_coordinates } = defaultData;
      if (lat_long_coordinates) {
        const {
          degrees_lat, minutes_lat, seconds_lat, degrees_long,
          minutes_long, seconds_long, reference
        } = lat_long_coordinates;
        methods.setValue('degrees_lat', degrees_lat);
        methods.setValue('minutes_lat', minutes_lat);
        methods.setValue('seconds_lat', seconds_lat);
        methods.setValue('degrees_long', degrees_long);
        methods.setValue('minutes_long', minutes_long);
        methods.setValue('seconds_long', seconds_long);
        methods.setValue('reference', reference);
      }

      if (utm_coordinates) {
        const { x_value, y_value, zone, reference_zone } = utm_coordinates;
        methods.setValue('x_value', x_value);
        methods.setValue('y_value', y_value);
        methods.setValue('zone', zone);
        methods.setValue('reference_zone', reference_zone);
      }
    }
  }, [
    defaultData?.log_sets?.[0]?.index_curve,
    defaultData?.log_sets?.[0].curves,
    defaultData?.name,
    formValues?.name,
    defaultData?.stop_depth,
    defaultData?.start_depth,
    defaultData?.logset,
    defaultData?.well_phases
  ]);

  useEffect(() => {
    if (!formValues.curves && defaultData?.log_sets?.[0].curves && formValues.depth_reference) {
      const curves = defaultData?.log_sets?.[0].curves.map((c: any) => {
        return {
          ...c,
          checked: c?.type && c?.name || formValues.depth_reference === c?.mnemonic ? true : false,
        };
      });
      methods.setValue('curves', curves);
    }
  }, [formValues?.curves, defaultData?.log_sets?.[0]?.curves, formValues.depth_reference]);

  useEffect(() => {
    const { curves } = formValues;
    if (curves) {
      const depthIndex = curves.findIndex(curve => curve.type === 'depth');
      const newDepth = {
        ...curves[depthIndex],
        unit: formValues?.unit
      }
      curves[depthIndex] = newDepth;
      methods.setValue('curves', curves);
    }
    changedUnit(formValues?.unit);
  }, [formValues?.unit]);

  useEffect(() => {
    methods.setValue('start_depth', defaultData?.start_depth);
    methods.setValue('stop_depth', defaultData?.stop_depth);
    methods.setValue('range', { start_depth: defaultData?.start_depth, stop_depth: defaultData?.stop_depth });
  }, [defaultData?.index_unit]);

  const handleCloseAlert = () => {
    setOpenAlert(false);
  }
  
  const handleSubmit = () => {
    const currentFormValues = methods.getValues();

    const depthCurve = currentFormValues.curves.find(curve => curve.type === 'depth');

    if (currentFormValues.unit !== depthCurve?.unit) {
      setOpenAlert(true);
      return;
    }

    const formattedCurves = currentFormValues?.curves?.map((c) => ({
      ...c,
      checked: Object.keys(c?.checked).length ? c?.checked?.checked : c?.checked,
    }));

    const selectedCurves = formattedCurves.filter(c => c?.checked);

    const depthReference = currentFormValues.depth_reference;
    const selectedDepthCurve = currentFormValues.curves.find(c => c.mnemonic === depthReference);
    if (!selectedDepthCurve?.checked) {
      setCustomErrors((oldErrors) => ({
        ...oldErrors,
        depth_reference: `You should select a curve that matches depth reference ${selectedDepthCurve.mnemonic}`,
      }));
      return;
    }

    const { curves, ...formValues } = currentFormValues;
    const payload = {
      ...formValues,
      curves: selectedCurves,
      range: { start_depth: parseFloat(currentFormValues.start_depth), stop_depth: parseFloat(currentFormValues.stop_depth) },
    };
    onSubmit(payload);
  };

  const [value, setValue] = useState(0);

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());

  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const checkBitSizeValidations = useCallback(() => {
    const { bs, range } = methods.getValues();
  
    const setError = (field, errorMessage) => {
      setCustomErrors((oldErrors) => ({
        ...oldErrors,
        [field]: errorMessage
      }));
      methods.setError(field, errorMessage);
    };
    const isValid = checkAndValidateBitSizes(
      bs, 
      {
        start_depth: parseFloat(currentFormValues?.start_depth),
        stop_depth: parseFloat(currentFormValues?.stop_depth)
      },
      setError
    );
    return isValid;
  }, [formValues, currentFormValues, methods]);

  const handleNextStep = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const validateBasicInfo = () => {
    const values = methods.getValues();
    if (!values.name) {
      setCustomErrors((previous) => ({ ...previous, 'name': requiredMessage }));
      return false;
    }
    if (values.start_depth === null || values.start_depth === undefined || values.start_depth === '') {
      setCustomErrors((previous) => ({ ...previous, 'start_depth': requiredMessage }));
      methods.setError('start_depth', requiredMessage)
      return false;
    }
    if (values.stop_depth === null || values.stop_depth === undefined || values.stop_depth === '') {
      setCustomErrors((previous) => ({ ...previous, 'stop_depth': requiredMessage }));
      methods.setError('stop_depth', requiredMessage)
      return false;
    }
    setCustomErrors({})
    return true;
  };

  const validateWellCoordinates = () => {
    if (WellCoordinatesAreEmpty(currentFormValues)) {
      return true;
    }

    const isValid = wellCoodinatesSchema.safeParse(currentFormValues);
    if (!isValid.success) {
      methods.clearErrors();
      isValid.error.issues.map((fieldError) => (
        methods.setError(fieldError.path[0], { type: 'custom', message: fieldError.message })
      ))
      return false;
    }
    return true;
  }

  const handleNext = () => {
    if (activeStep === 0) {
      const isValid = validateBasicInfo();
      if (isValid) {
        handleNextStep();
        return;
      } else {
        return;
      }
    }

    if (activeStep === 1) {
      const isValid = validateWellCoordinates();
      if (isValid) {
        handleNextStep();
        return;
      } else {
        return;
      }
    }

    if (activeStep === 2) {
      const isValid = checkBitSizeValidations();
      if (isValid) {
        clearErrors();
        handleNextStep();
        return;
      } else {
        return;
      }
    }

    if (activeStep === 3) {
      handleSubmit();
      return;
    } else {
      return;
    }

    handleNextStep();
  };

  const handleBack = () => {
    methods.clearErrors();
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const clearErrors = () => {
    setCustomErrors({});
  };

  const clearCustomError = (fieldName) => {
    setCustomErrors({ [fieldName]: undefined });
  };

  const steps = ['Basic Information', 'Well Coordinates', 'Well Phases', 'Matching Curves'];

  const defaultWellName = defaultData?.name;
  const defaultStartDepth = defaultData?.stop_depth;
  const defaultStopDepth = defaultData?.start_depth;
  const defaultLogSet = defaultData?.log_sets?.[0]?.name 
    ? capitalize(defaultData?.log_sets?.[0]?.name) : undefined;
  

  return (
    <Box sx={{ width: '100%', backgroundColor: '#151724' }}>
      <Snackbar
        open={openAlert}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity="warning"
          sx={{ width: '100%' }}
        >
          Select the depth unit correctly in bit depth and basic information!
        </Alert>
      </Snackbar>
      <Box sx={{ borderBottom: 1, borderColor: '#323752' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab style={{ color: '#F5FAFF', textTransform: 'capitalize' }} label="Import Data" />
          <Tab style={{ color: '#F5FAFF', textTransform: 'capitalize' }} label="LAS Review" />
        </Tabs>
      </Box>
      <TabsPanel value={value} index={0}>
        <Box sx={{ width: '100%' }}>
          <Steps
            activeStep={activeStep}
            steps={steps}
            skipped={skipped}
          />
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
              <FormProvider {...methods}>
                {/* <form onSubmit={methods.handleSubmit(handleSubmit)}> */}
                <form>
                  <Box style={{
                    minHeight: 'calc(100vh - 158px)',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around'
                  }}>
                    <Box style={{ flexGrow: '1' }}>
                      {activeStep === 0 && <BasicInformationForm
                        getDataType={getDataType}
                        customErrors={customErrors}
                        clearCustomError={clearCustomError}
                        setValue={methods.setValue}
                        defaultStartDepth={defaultStartDepth}
                        defaultStopDepth={defaultStopDepth}
                        defaultLogSet={defaultLogSet}
                        defaultWellName={defaultWellName}
                        wellOptions={wellOptions}
                        wellData={defaultData}
                      />}
                      {activeStep === 1 && <WellCoordinates
                        defaultData={defaultData}
                      />}
                      {activeStep === 2 && <WellPhases
                        defaultData={defaultData}
                        customErrors={customErrors}
                        clearErrors={clearErrors}
                      />}
                      {activeStep === 3 && <MatchingCurves
                        defaultData={defaultData}
                        hasEtheneSensor={defaultData?.ethene_sensor?.has_sensor}
                        setValue={methods.setValue}
                        customErrors={customErrors}
                        clearErrors={clearErrors}
                      />}
                    </Box>
                    <WrapperFooter>
                      <Footer
                        activeStep={activeStep}
                        steps={steps}
                        onCancel={onClose}
                        onPrevious={handleBack}
                        onNext={handleNext}
                        isLoading={isLoading}
                      />
                    </WrapperFooter>
                  </Box>
                </form>
              </FormProvider>
              </Typography>
            </React.Fragment>
          )}
        </Box>
      </TabsPanel>
      <TabsPanel value={value} index={1}>
        <LasReview lasInfo={lasInfo} />
      </TabsPanel>
    </Box>
  );
}
