import React, { useEffect } from 'react';
import UI from '@geowellex/shared-ui';
import { useForm, Controller } from "react-hook-form";
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod'
import { validateDepthValue } from '../../utils/validateForm.ts';
import { defaultZone } from '../../utils/defaultZoneValues.ts';
import { formatterAutocompleteData } from '../../utils/formatterAutocompleteData.ts';
import { ReactComponent as TaskAltIcon } from '../../assets/task_alt_icon.svg';

import { Title, SelectIntervalContainer, IntervalText, Form, WrapperBtnsZone } from './styles.tsx'

const zoneSchema = z.object({
  label: z.string().min(1, { message: 'Name is required' }),
  top: z.string().min(1, { message: 'Top is required' }),
  bottom: z.string().min(1, { message: 'Bottom is required' }),
}).required()

const FormZone = ({
  submitText, submitZone, afterSubmit, toggleCancel, zoneBottom,
  zoneCount, currentZoneToEdit, currentWell,  indexBottomZoneCreated
}: any) => {
  const dataSource = formatterAutocompleteData(currentWell);
  const { control, handleSubmit, setValue, watch, reset, setError, formState: { errors } } = useForm({
    defaultValues: currentZoneToEdit ? {
      ...currentZoneToEdit,
      top: currentZoneToEdit.top.toString(),
      bottom: currentZoneToEdit.bottom.toString()} :
      {
        ...defaultZone,
        label: `Zone ${zoneCount}`,
        top: zoneBottom !== 0 ? dataSource[indexBottomZoneCreated] : ''
      },
    resolver: zodResolver(zoneSchema)
  });

  // useEffect(() => {
  //   if (!watch().bottom && zoneBottom) {
  //     setValue('bottom', zoneBottom);
  //   }
  // }, [watch().bottom, zoneBottom, setValue]);

  useEffect(() => {
    if (currentZoneToEdit) {
      const { top_depth_index, bottom_depth_index } = currentZoneToEdit;
      const topWithCriticalValues = dataSource[top_depth_index];
      const bottomWithCriticalValues = dataSource[bottom_depth_index];
      setValue('top', topWithCriticalValues);
      setValue('bottom', bottomWithCriticalValues);
    }
  }, [currentZoneToEdit]);

  const handleColor = (newColor: any) => {
    setValue('color', newColor);
  }

  const onSubmit = (data: any) => {
    const {
      top_depth_index, bottom_depth_index,
      zone_type, zone_plot_settings, color, uuid
    } = watch();
    const { top, bottom, label } = data;

    const valuesFormatted = {
      uuid,
      top: top.toString(),
      top_depth_index,
      bottom: bottom.toString(),
      bottom_depth_index,
      label,
      zone_type,
      zone_plot_settings: {
        ...zone_plot_settings,
        color: color ?? zone_plot_settings.color
      }
    }
  
    const validateTopValue = dataSource.find((depth: string) => valuesFormatted.top === depth);
    const validateBottomValue = dataSource.find((depth: string) => valuesFormatted.bottom === depth);

    const validateDepth = validateDepthValue(validateTopValue, validateBottomValue);
    
    if (validateDepth.length !== 0) {
      validateDepth.map((fieldError) => (
        setError(fieldError, { message: `Enter a valid ${fieldError} depth value` })
      ))
      return;
    }
    afterSubmit();
    submitZone(valuesFormatted, !!currentZoneToEdit);
    reset()
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Title>{submitText}</Title>

      <Controller
        name="label"
        control={control}
        render={({ field }) => (
          <UI.InputText
            type='text'
            label='Zone Name'
            error={!!errors?.label}
            errorMessage={errors?.label?.message as string}
            {...field}
          />
        )}
      />

      <div>
        <IntervalText>Selected Interval</IntervalText>
        <SelectIntervalContainer>        
          <div>
            <Controller
              name="top"
              control={control}
              render={({ field }) => (
                <UI.Autocomplete
                  options={dataSource}
                  label='Top'
                  error={!!errors?.top}
                  errorMessage={errors?.top?.message as string}
                  {...field}
                />
              )}
            />

            <Controller
              name="bottom"
              control={control}
              render={({ field }) => (
                <UI.Autocomplete
                  options={dataSource}
                  label='Bottom'
                  error={!!errors?.bottom}
                  errorMessage={errors?.bottom?.message as string}
                  {...field}
                />
              )}
            />
          </div>
        </SelectIntervalContainer>
      </div>

      <UI.InputColor
        colorInput={watch()?.color ?? currentZoneToEdit?.zone_plot_settings?.color}
        onChange={handleColor}
      />
      
      <WrapperBtnsZone>
        <UI.Button
          type='submit'
          theme='primary'
          startIcon={<TaskAltIcon />}
        >
          {submitText}
        </UI.Button>

        <UI.Button
          type='button'
          theme='secondary'
          onClick={toggleCancel}
        >
          Cancel
        </UI.Button>
      </WrapperBtnsZone>
    </Form>
  );
};

export default FormZone;
