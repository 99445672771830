import styled from "styled-components";
import BaseButton from "./BaseButton";

const PrimaryButton = styled(BaseButton)`
  && {
    background: #233BB6;
    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
      background: #233BB6;
    }
  }
`

export default PrimaryButton;
