import { TextField } from '@mui/material'
import styled from "styled-components";
import { customTheme } from "../../../src/theme/default";

export const TextFieldStyled = styled(TextField)`
  width: 100%;
  .MuiOutlinedInput-root {
    fieldset {
      border-color: ${(props) => props.error ? customTheme.red4 : customTheme.purple6};
    }
    &.Mui-focused fieldset {
      border-color: ${(props) => props.error ? customTheme.red4 : customTheme.purple6};
    }
    &:hover fieldset {
      border-color: ${(props) => props.error ? customTheme.red4 : customTheme.purple6};
    }
  }

  // Label error
  .MuiFormHelperText-contained {
    margin: 0;
    color: ${customTheme.red4};
    bottom: -28px;
    font-size: 16px;
  }

  div {
    color: ${customTheme.gray2};
    font-size: 16px;
    border-radius: 6px;
    padding: 0 !important;
    
    input {
      padding: 8px !important;
    }

    // Remove arrows
    // Chrome, Safari, Edge, Opera
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    // Firefox
    input[type=number] {
      -moz-appearance: textfield;
    }
  }

`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
`
