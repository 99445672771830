import React from 'react';
import classNames from 'classnames';

const Legend = ({
  name, label, styleBox,
}) => (
  <div className="legend__container">
    <div
      className='legend__box'
      style={styleBox || {}}
    />
    <div className="legend__label">
      {label}
    </div>
  </div>
);

export default Legend;
