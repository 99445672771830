import { useCallback, useEffect, useState } from 'react';
import * as wellActions from 'actions/wells';
import Highcharts from 'highcharts';
import UI from '@geowellex/shared-ui';
import { applyZoneToCharts } from '@geowellex/shared-zone/src/utils/applyZoneToCharts';
import ZoomButton from 'components/ZoomButton';
import Overlay from '../../../../components/Overlay';
import get from 'lodash.get';
import { useSelector, useDispatch } from 'react-redux';
import { getForm, syncronizeDepthZoom, divideArrays, extractImageFileExtensionFromBase64} from 'utils';
import { Dropdown, Menu } from 'antd';
import { DownloadOutlined, HomeOutlined, CheckOutlined, PropertySafetyFilled } from '@ant-design/icons';
import html2canvas from 'html2canvas';
import store from 'store';
import { updateWell } from 'actions/wells';
import { Loading } from '../Loading';
import { customTheme } from '@geowellex/shared-ui/src/theme/default';
import { DEFAULT_SCALES } from '../../../../utils/constants';

import { getRawCurveData } from '../../../../utils/getRawCurveData';
import { getCalculatedCurveData } from '../../../../utils/getCalculatedCurveData';

import { postGasQualityRatio, PostGqcPayload } from '../../../../api/statistics';
import { successNotification, defaultNotification, warningNotification, errorNotification } from '../../../../utils';
import { getCurveData } from '../../../../utils/getCurveDataBySet';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  TotalDepth,
  SelectedDepth,
  RopChart,
  Chromatography,
  GasComposition,
  BalanceRatio,
  Isotope,
  QualityControlChart,
  PixlerChart,
  GqrChart
} from '../../../../components/Charts';
import CutOffModal from '../CutOffModal';
import { metadataCutoffKeys } from '../../../../components/CutOffs/index';
import RemoveDataModal from '../removeDataModal';
import ExtractionModal from '../ExtractionModal';
import NoiseModal from '../ReduceNoiseModal';
import CropImageModal from '../CropImageModal';
import useQualityControlContext from '../../hooks/useQualityControl';
import GlobalCutOff from '../../../../utils/GlobalCutOff';
import { getGQCData } from '../../../../utils/getGQCData';
import jsPDF from 'jspdf';
import { receiveWell } from '../../../../actions/wells';
import { Button } from '../Button';
import ConfigModal from '../../../../components/ConfigModal';

const getLogSetNameByCurve = (curve) => {
  const validRawCurves = [
    'c1', 'c2', 'c3', 'nc4', 'ic4', 'nc5', 'ic5',
    'c1_perc', 'c2_perc', 'c3_perc', 'c4_perc',
    'c5_perc', 'wh_ratio', 'wh_ratio', 'ch_ratio',
    'bh_ratio', 'ic4nc4', 'ic5nc5', 'c1c2',
  ];
  if (validRawCurves.includes(curve)) {
    return 'RAW';
  }
  return 'calculated';
};

const getMnemonic = (newCutoffSettings, currentWell) => {
  const cutoffs = newCutoffSettings.cutoffs.map((c) => {
    const normalizedCurveName = metadataCutoffKeys[c.curve];
    const logSet = getLogSetNameByCurve(normalizedCurveName);
    return {
      ...c,
      curve: currentWell?.[logSet]?.[normalizedCurveName]?.mnemonic,
    };
  });
  return {
    ...newCutoffSettings,
    cutoffs
  };
};

export const DashboardQC = ({ navigate, wellId, ...props }) => {
  const wells = useSelector(state => state.wells);
  const zones = useSelector(state => state.zone);
  const [showOverlay, setShowOverlay] = useState(false);
  const [updateChart, setUpdateChart] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { selectedWell, currentWell } = wells;
  const [intervals, setIntervals] = useState([]);
  const [imgSrc, setImgSrc] = useState();
  const [inititalQualityData, setInitialQualityData] = useState();
  const [logSet, setLogSet]  = useState(['RAW', 'calculated'])
  const [croppedImage, setCroppedImage] = useState();
  const [previousCutoffs, setPrevious] = useState();
  const [resetCutoff, setReset] = useState(false);
  const [payload, setPayload] = useState({
    well_uid: currentWell?.well_uid,
    normalization_settings: currentWell?.modules_parameters?.gqc?.settings?.normalization_settings ?? null,
    remove_data_settings: null,
    cutoff_settings: currentWell?.modules_parameters?.gqc?.settings?.cutoff_settings ?? null,
    reduce_noise_settings: null
  });

  const setCutoffSettings = (newCutoffSettings) => {

    if (newCutoffSettings === null) {
      setPayload((previousState) => ({
        ...previousState,
        cutoff_settings: null,
      }));
      return;
    }
    const formattedWithMnemonic = getMnemonic(newCutoffSettings, currentWell);
    if (payload?.cutoff_settings) {
      setPrevious(payload?.cutoff_settings);
    }
    setPayload((previousState) => {
      return {
        ...(previousState || {}),
        cutoff_settings: formattedWithMnemonic,
      };
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (payload.well_uid && (
        payload?.normalization_settings ||
        payload?.remove_data_settings ||
        payload?.reduce_noise_settings ||
        payload?.cutoff_settings) ||
        previousCutoffs?.length && payload?.cutoff_settings === null ||
        resetCutoff
      ) {
        if (resetCutoff) {
          setReset(false);
        }
        try {
          setIsLoading(true);
          const response = await PostGqcPayload(payload, store.get('token'));
          setIsLoading(false);
          const gqc = response.data.content.well_data.log_sets.GQC;
          const modulesParametersGqc = response.data.content.well_metadata.modules_parameters.gqc
          dispatch(wellActions.receiveLogsetGqc(gqc));
          dispatch(wellActions.updateModulesParametersGQC(modulesParametersGqc));
        } catch(e) {
          setIsLoading(false);
          console.log('GQC error', e);
        }

      }
    };
    fetchData();

  }, [
    currentWell?.well_uid,
    payload?.remove_data_settings,
    payload?.reduce_noise_settings,
    payload?.cutoff_settings,
    resetCutoff
  ]);

  const handleSaveModulesParametersGQC = async (payloadData, message) => {
    if (payloadData.well_uid ) {
      if (resetCutoff) {
        setReset(false);
      }

      try {
        setIsLoading(true);
        const response = await PostGqcPayload(payloadData, store.get('token'));
        const gqc = response.data.content.well_data.log_sets.GQC;
        const modulesParametersGqc = response.data.content.well_metadata.modules_parameters.gqc
        dispatch(wellActions.receiveLogsetGqc(gqc));
        dispatch(wellActions.updateModulesParametersGQC(modulesParametersGqc));

        setPayload(payloadData);
        setIsLoading(false);
        successNotification(message.success);
      } catch(e) {
        setIsLoading(false);
        errorNotification(message.error);
        console.log('GQC error', e);
      }

    }
  };

  useEffect(()=>{
    if (wells.currentWell?.gqc) {
      setLogSet(['gqcData', 'gqcData']);
    } else{
      setLogSet(['RAW', 'calculated']);
    }
  }, [wells.currentWell]);

  const { isQualityControlOn } = useQualityControlContext();

  const defaultWell = { depth: [] };

  const depth = get(wells, 'currentWell.RAW.depth', []);
  const depthUnit = get(wells, 'currentWell.RAW.depth.unit', '');
  const wellName = get(wells, 'currentWell.nameWell', '');
  const criticalAreasC1 = get(wells, 'currentWell.statistics.criticalAreasC1', []);
  const criticalAreasC2 = get(wells, 'currentWell.statistics.criticalAreasC2', []);
  const criticalAreasC3 = get(wells, 'currentWell.statistics.criticalAreasC3', []);
  const criticalAreasC4 = get(wells, 'currentWell.statistics.criticalAreasC4', []);
  const criticalAreasC5 = get(wells, 'currentWell.statistics.criticalAreasC5', []);
  const criticalAreasIC4 = get(wells, 'currentWell.statistics.criticalAreasIC4', []);
  const criticalAreasIC5 = get(wells, 'currentWell.statistics.criticalAreasIC5', []);
  const criticalAreasC1Composition = get(wells, 'currentWell.statistics') ?
    getForm(wells.currentWell.statistics, 'formCollapseC1Composition') : [];
  const criticalAreasC2Composition = get(wells, 'currentWell.statistics') ?
    getForm(wells.currentWell.statistics, 'formCollapseC2Composition') : [];
  const criticalAreasC3Composition = get(wells, 'currentWell.statistics') ?
    getForm(wells.currentWell.statistics, 'formCollapseC3Composition') : [];
  const criticalAreasC4Composition = get(wells, 'currentWell.statistics') ?
    getForm(wells.currentWell.statistics, 'formCollapseC4Composition') : [];
  const criticalAreasC5Composition = get(wells, 'currentWell.statistics') ?
    getForm(wells.currentWell.statistics, 'formCollapseC5Composition') : [];

  const gammaRay = get(getCurveData('gr', currentWell, logSet[0]), 'data', []);
  const rop = get(getCurveData('rop', currentWell, logSet[0]), 'data', []);


  const c1 = getCurveData('c1', currentWell, logSet[0]);
  const c2 = getCurveData('c2', currentWell, logSet[0]);
  const c3 = getCurveData('c3', currentWell, logSet[0]);
  const ic4Normal = getCurveData('ic4', currentWell, logSet[0]);
  const ic5Normal = getCurveData('ic5', currentWell, logSet[0]);
  const nc4 = getCurveData('nc4', currentWell, logSet[0]);
  const nc5 = getCurveData('nc5', currentWell, logSet[0]);

  const index = wells.selectedDepthIndex || 0;
  const totalGas = getCurveData('total_gas', currentWell,logSet[0]);

  const totalCarbon = getCurveData('total_carbon', currentWell,logSet[1]);
  const c1Normalized = getCurveData('c1_norm', currentWell,logSet[1]);
  const gqr = getCurveData('gqr', currentWell,logSet[1]);

  const c1Composition = getCurveData('c1_perc', currentWell,logSet[1]);
  const c2Composition = getCurveData('c2_perc', currentWell,logSet[1]);
  const c3Composition = getCurveData('c3_perc', currentWell,logSet[1]);
  const nc4Composition = getCurveData('c4_perc', currentWell,logSet[1]);
  const nc5Composition = getCurveData('c5_perc', currentWell,logSet[1]);

  const wetnessRatio = getCurveData('wh_ratio', currentWell,logSet[1]);
  const balanceRatioData = getCurveData('bh_ratio', currentWell,logSet[1]);
  const characterRatioData = getCurveData('ch_ratio', currentWell,logSet[1]);

  const c1c2 = getCurveData('c1c2', currentWell,logSet[1]);
  const c1c3 = getCurveData('c1c3', currentWell,logSet[1]);
  const c1c4 = getCurveData('c1c4', currentWell,logSet[1]);
  const c1c5 = getCurveData('c1c5', currentWell,logSet[1]);

  const ic4BynC4 = getCurveData('ic4nc4', currentWell,logSet[1]);
  const ic5BynC5 = getCurveData('ic5nc5', currentWell,logSet[1]);
  const c1Byc2 = getCurveData('c1c2', currentWell,logSet[1]);
  const slopeFactor = getCurveData('slope_factor', currentWell,logSet[1]);
  const cutoffMask = getCurveData('coff_mask', currentWell, logSet[0]) ?? [];


  const cutOffs = selectedWell !== -1 ? wells.currentWell.cutOffs : {};
  const thereIsASelectedWell = selectedWell && selectedWell !== -1;
  const maxDepthHoleDepthCard = depth && depth.length > 0 ? depth.slice(-1) : 'N/A';
  const unit = currentWell ? currentWell.unit : '';
  const isotopeData = get(wells, 'currentWell.isotope', []);
  const selectedMin = depth?.data ? (depth.data.length - 1) - ((depth.data.length - 1) / 3) : 0;
  const selectedMax = depth?.data ? depth.data.length - 1 : 0;

  const handleUpdateChart = () => setUpdateChart(!updateChart);

  useEffect(() => {
    if (thereIsASelectedWell) {
      const minMaxData = { min: selectedMin, max: selectedMax }
      dispatch(wellActions.receiveSelectedMinMax(minMaxData))

      if (zones?.items?.length > 0) {
        zones.items.map((zone) => applyZoneToCharts('chromatography')(zone, Highcharts.charts))
      }
      handleUpdateChart();
      updatepixlerRatios();
    }
  }, [selectedWell])

  const propagateTotalDetphZoom = (min, max) => {
    const minMaxData = { min, max }
    dispatch(wellActions.receiveSelectedMinMax(minMaxData))
    syncronizeDepthZoom(Highcharts, min, max);
  }

  const memoizedPropagateTotalDetphZoom = useCallback(propagateTotalDetphZoom, [])
  const toggleModal = (modalName) => {
    handleMenuClick(modalName);
  };

  async function getGqrData(data){
    const isValidRawCurves = data && data?.c1 && data?.c1.length > 1 &&
      data && data?.c2 && data?.c2.length > 1 &&
      data && data?.c3 && data?.c3.length > 1 &&
      data && data?.ic4 && data?.ic4.length > 1 &&
      data && data?.nc4 && data?.nc4.length > 1 &&
      data && data?.ic5 && data?.ic5.length > 1 &&
      data && data?.nc5 && data?.nc5.length > 1 &&
      data && data?.totalGas && data?.totalGas.length > 1;
    const isAllZero = data?.totalGas && data?.totalGas.every(item => item === 0);
    if (isValidRawCurves && !isAllZero) {
      try {
        const responseGQR = await postGasQualityRatio(
          {
            c1: data.c1.map(item => item < 0 ? 0 : item),
            c2: data.c2.map(item => item < 0 ? 0 : item),
            c3: data.c3.map(item => item < 0 ? 0 : item),
            ic4: data.ic4.map(item => item < 0 ? 0 : item),
            nc4: data.nc4.map(item => item < 0 ? 0 : item),
            ic5: data.ic5.map(item => item < 0 ? 0 : item),
            nc5: data.nc5.map(item => item < 0 ? 0 : item),
            total_gas: ((data.totalGas || []).map(item => item <= 0 ? 0 : item).filter(item => item != null)).slice(0, data.c1.length)
          }
        );
        if (responseGQR && (responseGQR.data || responseGQR.data.gqr)) {
          const calculatatedWithGQR = {
            gqr: responseGQR.data.gqr,
          };
          wellActions.receiveWell({
            ...currentWell,
            gqr: calculatatedWithGQR,
          });

          GlobalCutOff.registerDefaultData({
            ...currentWell,
            gqr: responseGQR.data.gqr,
          });
          GlobalCutOff.registerData({
            ...currentWell,
            gqr: responseGQR.data.gqr,
          });
        }
      } catch (error) {
        warningNotification('Internal server error: GQR could not be calculated!')
        console.log('error', error);
      }
    }
  }

  function getPixlerRatio(depthLength){
    let pixlerRatios = {}
    const c4 = []
    const c5 = []

    for (let index = 0; index < c1?.data?.length; index++){
      if (ic4Normal?.data?.[index] && nc4?.data?.[index]) {
        c4.push(Number(ic4Normal?.data?.[index]) + Number(nc4?.data?.[index]))
      }else{
        c4.push(0)
      }
      if (ic5Normal?.data?.[index] && nc5?.data?.[index]) {
        c5.push(Number(ic5Normal?.data?.[index]) + Number(nc5?.data?.[index]))
      } else {
        c5.push(0)
      }
    }
    pixlerRatios = {
      ...pixlerRatios,
      c1ByC2: divideArrays(c1?.data || [], c2?.data || []),
      c1ByC3: divideArrays(c1?.data || [], c3?.data || []),
      c1ByC4: divideArrays(c1?.data || [], c4 || []),
      c1ByC5: divideArrays(c1?.data || [], c5 || [])
    }
    return pixlerRatios;
  }

  function updatepixlerRatios() {
    const depthLength = wells?.currentWell?.RAW?.depth?.length;
    const pixlerRatios = getPixlerRatio(depthLength);
    const currentCalculatedData = {
      ...wells?.currentWell?.calculated,
      c1c2: {
        ...wells?.currentWell?.calculated.c1c2,
        data: pixlerRatios.c1ByC2
      },
      c1c5: {
        ...wells?.currentWell?.calculated.c1c5,
        data: pixlerRatios.c1ByC5
      },
      pixlerRatios,
    };

    dispatch(
      wellActions.receiveWell({
        ...wells.currentWell,
        calculated: currentCalculatedData,
      })
    );

    GlobalCutOff.registerData({
      ...wells.currentWell,
      calculated: currentCalculatedData,
    });

    GlobalCutOff.registerDefaultData({
      ...wells?.currentWell,
      calculated: currentCalculatedData,
    });
  }

  useEffect(() => {
    if (
      wells?.currentWell?.RAW?.depth &&
      !wells?.currentWell?.calculated.pixlerRatios?.c1ByC2
    ) {
      updatepixlerRatios()
    }
  }, [wells?.currentWell, c1, c2, c3, ic4Normal, nc4, ic5Normal, nc5, wellId, selectedWell]);

  useEffect(() => {
    if (wells.currentWell?.RAW?.length && wells.currentWell?.calculated) {
      getGqrData(wells.currentWell.calculated);
    }
  }, [currentWell, totalGas, wellId]);

  // setup cutoffs to receive filtered data
  useEffect(() => {
    if (wells?.currentWell?.RAW?.c1) {
      GlobalCutOff.connectToRedux(wellActions.receiveWell, wells?.currentWell, wells, dispatch);
      GlobalCutOff.setAction(wellActions.receiveFilteredData);
    }
  }, [wells?.currentWell]);

  // setup updating default scales
  useEffect(() => {
    const configScales = store.get('configScales');
    if (!configScales?.c1c2 || !configScales?.c1c3 || !configScales.c1c4 || !configScales.c1c5) {
      store.set('configScales', DEFAULT_SCALES);
    }
  }, []);

  function onBackHome(){
    navigate(`/home?wellId=${wellId}`);
  }

  const ActionsOptions = ({ handleMenuClick }) => {
    return (
      <Menu onClick={(e) => handleMenuClick(e.key)}>
        <Menu.Item key="cutoff" data-cy="cutoff-module-button" style={{ color: '#fff' }}>
          Cutoff Module
        </Menu.Item>
        <Menu.Item key="remove" data-cy="remove-data-button" style={{ color: '#fff' }}>
          Remove Data
        </Menu.Item>
        <Menu.Item key="noise" data-cy="reduce-noise-button" style={{ color: '#fff' }}>
          Reduce Noise
        </Menu.Item>
        <Menu.Item key="eec" data-cy="eec-gas-normalization-button" style={{ color: '#fff' }}>
          EEC & Gas Normalization
        </Menu.Item>
      </Menu>
    );
  };

  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  const generatePdf = useCallback(() => {
    if (!wells?.currentWell) return;

    setIsGeneratingPDF(true);
    const delayInSeconds = 1;

    const wellNameObject = wells.wellOptions.find(
      (well) => well.value === wells.currentWellKey
    );

    if (!wellNameObject) {
      console.error('No well found with the specified wellId');
      setIsGeneratingPDF(false);
      return;
    }

    const wellName = wellNameObject.label;

    setTimeout(() => {
      const chartsDiv = document.querySelector('.home__charts');
      const scale = 2;
      html2canvas(chartsDiv, { scale: scale }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = canvas.width / scale;
        const imgHeight = canvas.height / scale;

        const pdf = new jsPDF({
          orientation: imgWidth > imgHeight ? 'landscape' : 'portrait',
          unit: 'pt',
          format: [imgWidth, imgHeight],
        });

        const fontSize = 36;
        const marginBottom = 20;
        pdf.setFontSize(fontSize);
        const textWidth = pdf.getTextWidth(wellName);
        const textX = (imgWidth - textWidth) / 2;
        const textY = 40;

        pdf.text(wellName, textX, textY);
        pdf.addImage(imgData, 'PNG', 0, textY + marginBottom, imgWidth, imgHeight);
        pdf.save('chart.pdf');

        setIsGeneratingPDF(false);
      }).catch((error) => {
        console.error('Error generating PDF:', error);
        setIsGeneratingPDF(false);
      });
    }, delayInSeconds * 1000);
  }, [wells?.currentWell, wells, setIsGeneratingPDF]);

  const [modal, setModal] = useState({
    cutoff: false,
    remove: false,
    noise: false,
    eec: false,
    crop: false
  });

  const handleMenuClick = (modalName) => {
    setModal((prevModal) => ({
      ...prevModal,
      [modalName]: !prevModal[modalName]
    }));
  };

  const handleApply = () => {
    defaultNotification('Quality control applied !');
    setShowOverlay(true);

    const afterUpdate = () => {
      setShowOverlay(false);
    };
    dispatch(receiveWell(currentWell));
    afterUpdate();
  };

  const afterSaveCutoff = () => {
    setModal(previous => ({
      ...previous,
      cutoff: false,
    }));
  };

  return (
    <div className="home__main-content quality__control">
      {isLoading && <Loading />}
      <Overlay show={showOverlay} />
      <CutOffModal
        isModalOpen={modal.cutoff}
        close={() => handleMenuClick('cutoff')}
        payload={payload}
        setPayload={setPayload}
        setCutoffSettings={setCutoffSettings}
        wellActions={wellActions}
        setReset={setReset}
        afterSave={afterSaveCutoff}
      />
      <RemoveDataModal
        isModalOpen={modal.remove}
        setIntervals={setIntervals}
        currentWell={currentWell}
        dispatch={dispatch}
        close={() => handleMenuClick('remove')}
        setPayload={setPayload}
        payload={payload}
      />
      <ExtractionModal
        isModalOpen={modal.eec}
        closeModal={() => handleMenuClick('eec')}
        payload={payload}
        saveData={handleSaveModulesParametersGQC}
        setPayload={setPayload}
        fieldValues={payload.normalization_settings}
      />
      <NoiseModal
        isModalOpen={modal.noise}
        close={() => handleMenuClick('noise')}
        setPayload={setPayload}
        payload={payload}
      />
      <CropImageModal
        isModalOpen={modal.crop}
        imgSrc={imgSrc}
        setNewImage={setCroppedImage}
        currentWell={currentWell}
        setIsGeneratingPDF={setIsGeneratingPDF}
        close={() => handleMenuClick('crop')}
      />
      <div className="home__cards quality__control_header_area">
        <div className='quality__control_buttons_area'>
          <Dropdown
            name="field1"
            overlay={<ActionsOptions
              handleMenuClick={handleMenuClick} />}
            className="btn-upload-csv btn-export-well"
          >
            <Button theme="secondary" data-cy="actions-gqc-button">
              <span style={{ marginRight: 5 }}>Actions</span>
              <ExpandMoreIcon />
            </Button>
          </Dropdown>
          <Button
            type="button"
            theme="secondary"
            className="btn-upload"
            title="Edit Data"
            onClick={generatePdf}
          >
            <DownloadOutlined />
            <span style={{ marginLeft: 5 }}>Save PDF</span>
          </Button>
          <Button
            type='submit'
            theme="primary"
            className="btn-apply-changes"
            title="Apply and save rules"
            onClick={handleApply}
          >
            <CheckOutlined />
            <span style={{ marginLeft: 5 }}>Apply and save rules</span>
          </Button>
        </div>
      </div>
      <div className="home__charts">
        {!isGeneratingPDF && (
          <ZoomButton
            min={currentWell?.selectedMin}
            max={currentWell?.selectedMax}
          />)}
          <div className="charts-wrapper"
              style={{
                borderRadius: '12px',
                border: `1px solid ${customTheme.purple3}`,
              }}>
            <div className="charts-container">
              <UI.Drawer
                triggerEvent="openConfigModal"
                getTitleByEventType={(eventType) => DEFAULT_SCALES?.[eventType]?.titleModal}
              >
                <ConfigModal />
              </UI.Drawer>
              <TotalDepth
                data={currentWell?.RAW?.depth.data}
                afterSelect={memoizedPropagateTotalDetphZoom}
                unit={unit}
                min={currentWell?.selectedMin}
                max={currentWell?.selectedMax}
                selectedWell={selectedWell}
                previousSelectedWell={wells.beforeWell}
                onAfter={wellActions.registerDepthData}
              />
              <SelectedDepth
                hideSelectedDepth={isQualityControlOn}
                criticalAreasC1={criticalAreasC1}
                criticalAreasC2={criticalAreasC2}
                criticalAreasC3={criticalAreasC3}
                criticalAreasC4={criticalAreasC4}
                criticalAreasC5={criticalAreasC5}
                criticalAreasIC4={criticalAreasIC4}
                criticalAreasIC5={criticalAreasIC5}
                criticalAreasC1Composition={criticalAreasC1Composition}
                criticalAreasC2Composition={criticalAreasC2Composition}
                criticalAreasC3Composition={criticalAreasC3Composition}
                criticalAreasC4Composition={criticalAreasC4Composition}
                criticalAreasC5Composition={criticalAreasC5Composition}
                min={currentWell?.selectedMin}
                max={currentWell?.selectedMax}
                data={depth?.data}
                unit={depthUnit}
                wells={wells}
                selectedWell={selectedWell}
                beforeWell={wells.beforeWell || defaultWell}
                currentWell={wells.currentWell || defaultWell}
                selectedDepthIndex={wells.selectedDepthIndex}
                updateChart={updateChart}
                finishUpdate={handleUpdateChart}
              />
              <Chromatography
                min={currentWell?.selectedMin}
                max={currentWell?.selectedMax}
                c1={c1.data}
                c2={c2.data}
                c3={c3.data}
                ic4={ic4Normal.data}
                nc4={nc4.data}
                ic5={ic5Normal.data}
                nc5={nc5.data}
                selectedWell={selectedWell}
                cutOffs={cutOffs}
                beforeWell={wells.beforeWell || defaultWell}
                currentWell={wells.currentWell || defaultWell}
                isGeneratingPDF={isGeneratingPDF}
                updateChart={updateChart}
              />
              <GasComposition
                min={currentWell?.selectedMin}
                max={currentWell?.selectedMax}
                gasCompositionC1={c1Composition.data}
                gasCompositionC2={c2Composition.data}
                gasCompositionC3={c3Composition.data}
                gasCompositionC4={nc4Composition.data}
                gasCompositionC5={nc5Composition.data}
                selectedWell={selectedWell}
                cutOffs={cutOffs}
                beforeWell={wells.beforeWell || defaultWell}
                currentWell={wells.currentWell || defaultWell}
                updateChart={updateChart}
              />

              <BalanceRatio
                enableZoomIn
                min={currentWell?.selectedMin}
                max={currentWell?.selectedMax}
                selectedWell={selectedWell}
                wetnessData={wetnessRatio.data}
                balanceRatioData={balanceRatioData.data}
                characterRatioData={characterRatioData.data}
                beforeWell={wells.beforeWell || defaultWell}
                currentWell={wells.currentWell || defaultWell}
                cutOffs={cutOffs}
                updateChart={updateChart}
              />
              <PixlerChart
                enableZoomIn
                min={currentWell?.selectedMin}
                max={currentWell?.selectedMax}
                c1c2={c1c2.data}
                c1c3={c1c3.data}
                c1c4={c1c4.data}
                c1c5={c1c5.data}
                selectedWell={selectedWell}
                beforeWell={wells.beforeWell || defaultWell}
                currentWell={wells.currentWell || defaultWell}
                cutOffs={cutOffs}
                updateChart={updateChart}
              />
              <GqrChart
                min={currentWell?.selectedMin}
                max={currentWell?.selectedMax}
                selectedWell={selectedWell}
                gqrData={currentWell?.calculated?.gqr?.data}
                beforeWell={wells.beforeWell || defaultWell}
                currentWell={wells.currentWell || defaultWell}
                cutOffs={cutOffs}
                updateChart={updateChart}
              />
              <QualityControlChart
                min={currentWell?.selectedMin}
                max={currentWell?.selectedMax}
                depth={currentWell?.RAW?.depth}
                dispatch={dispatch}
                selectedWell={selectedWell}
                beforeWell={wells.beforeWell || defaultWell}
                currentWell={wells.currentWell || defaultWell}
                intervals={payload?.remove_data_settings}
                cutOffs={cutOffs}
                qualityData={cutoffMask}
                goodBadData={currentWell?.calculated?.qualityData}
              />
          </div>
        </div>
      </div>
    </div>
  )
}
