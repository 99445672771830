import './style.scss';

import React, {
  useEffect,
  useState
} from 'react';

import {
  Button,
  Checkbox,
  Divider,
  Drawer,
  Icon
} from 'antd';

import {
  errorNotification,
  successNotification
} from '../../../../utils';
import { customTheme } from '@geowellex/shared-ui/src/theme/default';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import { ReactComponent as ConfigureBaseline } from 'images/configure-baseline.svg';

const WrapperDrawer = styled(Box)`
  & .ant-drawer-content {
    background-color: ${customTheme.bg};
  }
`;

const WrapperDrawerContent = styled(Box)`
  display: flex;
  height: calc(100vh - 71px);
  flex-direction: column;
`;

const WrapperCheckbox = styled(Box)`
  & .ant-checkbox-checked::after,
  & .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${customTheme.blue4};
    border: 0;
  }
  & .ant-checkbox-inner {
    border: 2px solid #A3A8C7;
    background: transparent;
  }
`;

const SelectedZones = ({
  onClose, visible, zones,
  defaultCheckedZones, openAddBaseline, onSubmit,
  setDirty, dirty, compare, setCompare
}) => {
  const [checkedValues, setChecked] = useState();
  const [compareDisabled, setCompareDisabled] = useState(true);

  // make sure to update the default state when zones are active on the first load
  useEffect(() => {
    if (!dirty && defaultCheckedZones) {
      setChecked(defaultCheckedZones);
    }
  }, [dirty, defaultCheckedZones]);

  // find and check or uncheck zone
  const onToggleZone = (currentZone) => {
    setDirty(true);
    const newZones = checkedValues.map((z) => {
      if (z.id === currentZone.target.value) {
        return {
          id: z.id,
          checked: currentZone.target.checked
        };
      }
      return z;
    });
    const zonesLength = (newZones ? newZones.filter(z => !!z.checked) : []).length;

    if (zonesLength < 2) {
      setCompareDisabled(true);
      setCompare(false)
    } else {
      setCompareDisabled(false);
    }

    setChecked(newZones);
  };

  const onCompare = (event) => {
    const shouldCompare = event.target.checked;
    setCompare(shouldCompare);
    setDirty(true);
  };

  const onSubmitZonesConfig = () => {
    if (checkedValues.filter(z => z.checked).length === 0) {
      errorNotification(`You should select at least one zone to save this settings.`);
      return;
    }

    onSubmit({
      checkedZones: checkedValues,
      compare
    });

    successNotification(`Settings applyed successfully`);
    onClose();
  };

  return(
    <WrapperDrawer>
      <Drawer
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
        width={385}
        className="selected-zones"
        getContainer={false}
      >
        <WrapperDrawerContent>
          <div className="selected-zones-title">Selected Zones</div>
          <div className="wrapper-zones-container">
            <div className="wrapper-zones-title">Zones</div>
            <div className="wrapper-zones-name-container">
              {(zones ?? []).map((zone, index) => {
                const { color } = zone.zone_plot_settings;
                return (
                  <div className="zone-list-item">
                    <WrapperCheckbox>
                      <Checkbox
                        value={zone.id}
                        onChange={onToggleZone}
                        name="checkbox-zone-1"
                        data-group="filter1"
                        checked={checkedValues && checkedValues[index] ? checkedValues[index].checked : false}
                      />
                    </WrapperCheckbox>
                    <div className="zone-circle"
                      style={{
                        background: `rgba(${color.r}, ${color.g}, ${color.b}, 1)`,
                        width: 16,
                        height: 16
                      }}
                    />
                    <div 
                      className="wrapper-zones-name"
                      style={{ color: customTheme.white }}
                    >{zone.label}</div>
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            <div className="wrapper-zones-title">Options</div>
            <div className="wrapper-zones-name-container flex compare-section">
              <WrapperCheckbox>
                <Checkbox
                  name="checkbox-compare"
                  data-group="compare"
                  checked={compare}
                  disabled={compareDisabled}
                  onChange={onCompare}
                />
              </WrapperCheckbox>
              <div
                className={`wrapper-zones-name compare-text ${compareDisabled ? 'disabled-label' : ''}`}
                style={{
                  color: customTheme.white
                }}
              >
                Compare
              </div>
            </div>
          </div>
          <div className="add-baseline-section">
            <Button
              className="btn-add-baseline"
              onClick={openAddBaseline}
              style={{
                background: '#212438',
                border: '1px solid #2F334D',
                color: customTheme.gray2,
                width: 328,
                position: 'relative',
                fontSize: 16,
                fontWeight: '500'
              }}
            > 
              <div style={{
                width: 15,
                height: 15,
                position: 'absolute',
                left: 65,
                top: 12,
              }}>
                <ConfigureBaseline />
              </div> Configure Baseline
            </Button>
          </div>
        </WrapperDrawerContent>
        <div className="container-actions"
          style={{
            borderTop: `1px solid ${customTheme.purple7}`
          }}
        >
          <Button
            className="btn-cancel"
            onClick={onClose}
            style={{
              width: 85,
              height: 35,
            }}
          >
            CANCEL
          </Button>
          <Button
            type="primary"
            className="btn-apply"
            onClick={onSubmitZonesConfig}
            style={{
              width: 75,
              height: 35,
              background: customTheme.blue4,
              border: 'none',
            }}
          >
            APPLY
          </Button>
        </div>
      </Drawer>
    </WrapperDrawer>
  );
}

export default SelectedZones;
