import { CheckBoxCurve, ContainerHeader, RangeValueContainer, TextCenterContainer, TextCenterHeader } from "./styles"
import * as React from 'react';
import ConfigContext from '@geowellex/shared-ui/src/context/ConfigContext';

interface HeaderProps {
  textCenter?: string;
  min?: string;
  max?: string;
  background: string;
  dataCy?: string;
  rangeValue?: number[];
  checkboxIsVisible?: boolean;
  checked?: boolean;
  onClick?: (event: any) => void;
  onChangeCheckbox?: () => React.ChangeEvent<HTMLInputElement>
  activeCurveConfig?: string;
}

export const Header = ({
  textCenter, min, max, background, dataCy, rangeValue,
  checkboxIsVisible, checked, onClick, onChangeCheckbox,
  activeCurveConfig,
}: HeaderProps) => {
  const { 
    setActiveCurve
  } = React.useContext(ConfigContext);

  const onClickEvent = (event: any) => {
    onClick?.(event);
    console.log(activeCurveConfig)
    setActiveCurve?.(activeCurveConfig || '');
  };

  return (
    <ContainerHeader
      background={background}
      align={(min && max) ? 'space-between' : 'center'}
      onClick={onClickEvent}
      data-cy={dataCy}
      data-cmd="openConfig"
    >
      {min && <span onClick={onClickEvent} data-cmd="openConfig">{min}</span>}
      {textCenter && (
        <TextCenterContainer onClick={onClickEvent} data-cmd="openConfig">
          <TextCenterHeader onClick={onClickEvent} data-cmd="openConfig">{textCenter}</TextCenterHeader>
          {checkboxIsVisible && (
            <CheckBoxCurve
              checked={checked}
              onChange={onChangeCheckbox}
              size="small"
            />
          )}
        </TextCenterContainer>
      )}
      {max && <span onClick={onClickEvent} data-cmd="openConfig">{max}</span>}
      {rangeValue && (
        <RangeValueContainer onClick={onClickEvent} data-cmd="openConfig">
          {rangeValue.map((label) => (
            <span>{label}</span>
          ))}
        </RangeValueContainer>
      )}
    </ContainerHeader>
  )
}