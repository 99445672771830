import React from 'react';
import { SliderStyled } from './styles';
import Stack from '@mui/material/Stack';

function valuetext(value: number) {
  return `${value}`;
}

interface FieldValue {
  startDepth: number;
  stopDepth: number;
}

interface RangeSliderProps {
  minRange: number,
  maxRange: number,
  fieldValue: FieldValue,
  onChange: (range: number[]) => void
}

export function RangeSlider({
  minRange, maxRange, fieldValue, onChange,
}: RangeSliderProps) {
  const { startDepth, stopDepth } = fieldValue;
  const formatMaxRange = parseInt(maxRange?.toFixed(2));
  const formatMinRange = parseInt(minRange?.toFixed(2));
  const marks = [
    {
      value: -formatMaxRange,
      label: formatMaxRange,
    },
    {
      value: -formatMinRange,
      label: formatMinRange
    }
  ];

  const handleChange = (event: Event, newValue: any) => {
    const positiveValue = [Math.abs(newValue[1]), Math.abs(newValue[0])]
    console.log('event: ', event, positiveValue)
    onChange(positiveValue);
  };

  return (
    <Stack sx={{ height: 300, marginLeft: '30px' }} spacing={1} direction="row">
      <SliderStyled
        orientation='vertical'
        getAriaValueText={valuetext}
        defaultValue={[-formatMinRange, -formatMaxRange]}
        valueLabelDisplay="auto"
        value={[-startDepth, -stopDepth]}
        onChange={handleChange}
        marks={marks}
        min={-formatMaxRange}
        max={-formatMinRange}
        scale={x => -x}
        data-cy="slider"
      />
    </Stack>
  )
}
