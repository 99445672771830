import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import * as pallete from '@geowellex/shared-ui/src/theme/default';
import Button from '@material-ui/core/Button';

export const StyledDataGrid = styled(DataGrid)(() => ({
    border: `1px solid ${pallete.customTheme.purple3}`,
    color: 'rgba(255,255,255,0.85)',
    marginBottom: '10px',
    fontFamily: [
        'Roboto',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    WebkitFontSmoothing: 'auto',
    letterSpacing: 'normal',
    "--DataGrid-containerBackground": `${pallete.customTheme.purple4}`,
    "--DataGrid-rowBorderColor": `${pallete.customTheme.purple4}`,
    backgroundColor: `${pallete.customTheme.purple4}`,
    '& .MuiDataGrid-cell:focus':{
        border: 'none',
        outline: 'none',
    },
    '& .MuiDataGrid-toolbarContainer': {
        padding: '10px',
    },
    '& .MuiDataGrid-toolbarQuickFilter': {
        width:'100%',
        paddingTop: '5px',
        paddingLeft: '10px',
        paddingBottom: '0',
        borderRadius: '6px',
        border: `1px solid ${pallete.customTheme.purple3}`,
        '& .MuiInputBase-root':{
            color: '#fff'
        },
        '& .MuiInput-underline:before': {
            display: 'none',
        },
        '& .MuiInput-underline:after': {
            display: 'none',
        },
    },
    '& .MuiDataGrid-topContainer': {
        background: `${pallete.customTheme.purple4}`,
    },
    '& .MuiDataGrid-container--top': {
        background: `${pallete.customTheme.purple4}`,
    },
    '& MuiDataGrid-columnHeaderRow': {
        background: `${pallete.customTheme.purple4}`,
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        fontSize: '16px',
        color: `${pallete.customTheme.gray1}`,
    },
    '& MuiDataGrid-columnSeparator': {
        color: `${pallete.customTheme.purple1}`,
    },
    '& .MuiDataGrid-withBorderColor': {
        borderColor: `${pallete.customTheme.purple4}`,
    },
    '&  .MuiDataGrid-footerContainer': {
        borderTop: `1px dashed ${pallete.customTheme.purple3}`,
    },
    '& p.MuiTablePagination-displayedRows': {
        color: `${pallete.customTheme.gray1}`,
    },
    '& .MuiIconButton-colorInherit': {
        color: `${pallete.customTheme.gray1}`,
    },
    '& .MuiTab-root':{
        padding: '0 10px',
        color: `${pallete.customTheme.gray1}`
    },
    '& .MuiDataGrid-overlay':{
        background: 'transparent',
    },
    '& .MuiDataGrid-menuIcon > button':{
        color: `${pallete.customTheme.gray1}`,
    },
    '& .MuiDataGrid-iconButtonContainer > button':{
        color: `${pallete.customTheme.gray1}`,
    },
    "& ::-webkit-scrollbar": {
        width: "6px"
    },
    "& ::-webkit-scrollbar-track": {
        backgroundColor: "transparent"
    },
    "& ::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
        boxShadow: "inset 0 0 6px rgba(255,255,255,0.3)",
        backgroundColor: "transparent"
    }
}));

export const LinkButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: 'none',
    lineHeight: 1.5,
    backgroundColor: 'transparent',
    color: `${pallete.customTheme.gray1}`,
    textDecoration: 'underline',
    fontFamily: [
        'Roboto',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        backgroundColor: `${pallete.customTheme.purple3}`,
        textDecoration: 'underline',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: `${pallete.customTheme.purple3}`,
        textDecoration: 'underline',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        textDecoration: 'underline',
    },
    "&:disabled": {
      backgroundColor: 'transparent',
      color: pallete.customTheme.gray1,
      textDecoration: 'none',
    },
});
