import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import useDataTable, { UseDataTableProps } from '../../hooks/useDataTable';
import { StyledButton } from './styles';

type ImportWellActionProps = {
    well: any;
    btnText: string;
    importType: ImportWellActionEnum;
} & UseDataTableProps;

export enum ImportWellActionEnum {
    AVAILABLE_WELLSITES = 0,
    CLOUD_CONNECTION = 1,
}

export const ImportWellAction = ({ setLoading, token, selectedKey, well, btnText, importType}: ImportWellActionProps) => { 
    const { handleSelectWell } = useDataTable({ setLoading, token, selectedKey });

    return (
        <div>
            <StyledButton 
                variant='outlined' 
                aria-label="import-well" 
                onClick={() =>{
                    handleSelectWell(well, importType);
                }}>
                {btnText}
                <ArrowForwardOutlinedIcon />
            </StyledButton>
        </div>
    );
};