import { styled } from '@mui/material/styles';
import { customTheme } from '../../../../theme/default'; 
import Box from '@mui/material/Box';

export const Logo = styled(Box)(() => `
  font-size: 22px;
  font-weight: 700;
  color: ${customTheme.yellow1};
  text-transform: uppercase;
 `,
);
