import { useState, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';

import { customTheme } from '../../theme/default';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { HomeIcon } from '../../icons/home_icon';
import { LoadWellIcon } from '../../icons/load_well';
import { GasQualityIcon } from '../../icons/gas_quality';
import { StatisticsIcon } from '../../icons/statistics';
import { ZoneAnalysisIcon } from '../../icons/zone_analysis';
// import { PreferencesIcon } from '../../icons/preferences';
import { ExportPDFIcon } from '../../icons/export_pdf';
import { MenuItemType } from './types';
import { BtnToggleDrawer } from './components/BtnToggleDrawer';
import { CustomDrawer, GenerateDrawer } from './components/Drawer';
import ConfigContext from '../../context/ConfigContext';

type Props = {
  menuItems?: MenuItemType[];
  location?: any;
};

export const Sidebar = ({ menuItems }: Props) => {
  const { state, logSets, warningNotification, setNameDrawerOpen, wellUid, setWellUid, afterSelect } = useContext(ConfigContext);
  const wells = useSelector((state: any) => state?.wells);
  const location = useLocation();
  const [menuSelectedIndex, setMenuSelectedIndex] = useState<number>(0);
  const currentRoute = location.pathname;

  const menuConfig: MenuItemType[] = menuItems ?? [
    {
      text: 'Load Well',
      icon: <LoadWellIcon color={menuSelectedIndex === 0 ? customTheme.gray2 : customTheme.gray1}  />,
      onClick: (menuIndex: number) => {
        setWellUid?.('');
        afterSelect(-1);
        setMenuSelectedIndex(menuIndex);
        state?.navigate?.('/home');
      },
      route: 'home'
    },
    {
      text: 'Dashboard',
      icon: <HomeIcon color={menuSelectedIndex === 1 ? customTheme.gray2 : customTheme.gray1} />,
      onClick: (menuIndex: number) => {
        setMenuSelectedIndex(menuIndex);
        if (wellUid === ''){
          state?.navigate?.('/home/well/-1');
        } else { 
          state?.navigate?.(`/home/well/${wellUid}`)
        };
      },
      route: 'home/well/'
    },
    {
      text: 'Gas Quality Control',
      icon: <GasQualityIcon color={menuSelectedIndex === 2 ? customTheme.gray2 : customTheme.gray1} />,
      onClick: (menuIndex: number) => {
        setMenuSelectedIndex(menuIndex);
        state?.navigate?.('quality-control');
      },
      route: 'quality-control',
      isDisabled: !wells.currentWell
    },
    {
      text: 'Statistics',
      icon: <StatisticsIcon color={menuSelectedIndex === 3 ? customTheme.gray2 : customTheme.gray1} />,
      onClick: (menuIndex: number) => {
        setMenuSelectedIndex(menuIndex);
        state?.navigate?.('statistics');
      },
      route: 'statistics',
      isDisabled: !wells.currentWell
    },
    {
      text: 'Zone Analysis',
      icon: <ZoneAnalysisIcon color={menuSelectedIndex === 4 ? customTheme.gray2 : customTheme.gray1} />,
      onClick: (menuIndex: number) => {
        setMenuSelectedIndex(menuIndex);
        setNameDrawerOpen?.('zone analysis');
      },
      route: 'zones',
      isDisabled: !wells.currentWell || !currentRoute.includes('/home/well/')
    },
    // {
    //   text: 'Preferences',
    //   icon: <PreferencesIcon color={menuSelectedIndex === 5 ? customTheme.gray2 : customTheme.gray1} />,
    //   onClick: (menuIndex: number) => {
    //     setMenuSelectedIndex(menuIndex);
    //   },
    //   route: 'preferences'
    // },
    {
      text: 'Export PDF',
      icon: <ExportPDFIcon color={menuSelectedIndex === 6 ? customTheme.gray2 : customTheme.gray1} />,
      onClick: (menuIndex: number) => {
        setMenuSelectedIndex(menuIndex);
      },
      route: 'export-pdf',
      isDisabled: !wells.currentWell
    }
  ];

  useEffect(() => {
    menuConfig?.forEach((item, index) => {
      if (currentRoute.includes(item?.route)) {
        setMenuSelectedIndex(index);
      }
    });
  }, [location.pathname]);

  const [isOpenDrawer, toggleDrawer] = useState(true);
  const [selectedLogSet, setSelectedLogSet] = useState<string | undefined>(
    logSets?.[0]?.logSetName
  );

  const handleToggleDrawer = () => {
    toggleDrawer((previousValue) => !previousValue);
  };

  const defaultDrawerWidth = isOpenDrawer ? 290 : 100;
  const afterSelectLogSet = (logSet: string | undefined) => {
    setSelectedLogSet(logSet);
  };

  const logSetCurves = logSets?.find(
    (logSet) => logSet.logSetName === selectedLogSet
  )?.curves || [];

  return (
    <Box
      component="nav"
      sx={{
        width: { sm: defaultDrawerWidth }, flexShrink: { sm: 0 },
        position: 'relative',
        background: customTheme.bg,
        transition: 'width 0.3s ease-out'
      }}
      aria-label="mailbox folders"
    >
      <BtnToggleDrawer onClick={handleToggleDrawer} isOpen={isOpenDrawer}>
        {isOpenDrawer ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
      </BtnToggleDrawer>
      <CustomDrawer
        drawerWidth={defaultDrawerWidth}
        isOpen={isOpenDrawer}
      >
        <GenerateDrawer
          warningNotification={warningNotification}
          menuConfig={menuConfig}
          menuSelectedIndex={menuSelectedIndex}
          logSets={logSets}
          afterSelectLogSet={afterSelectLogSet}
          logSetCurves={logSetCurves}
          isCloseDrawer={!isOpenDrawer}
          logo={'Gold'}
          description={'Advanced Gas Log'}
        />
      </CustomDrawer>
    </Box>
  );
};
