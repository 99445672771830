import { useContext, useEffect, useMemo } from 'react';
import Sidebar from '../Sidebar';
import { CardList } from '../IndicatorCard/CardList';
import { IndicatorCardType } from '../IndicatorCard/types';
import { Container, MainWrapper, ChartsWrapper, CardsWrapper } from './components/Layout';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import ConfigContext from '../../context/ConfigContext';

interface Props {
  children: React.ReactNode;
};

export const Dashboard = ({ children }: Props) => {
  const navigate = useNavigate();
  const {
    setState, state, api,
    gor, totalCarbon, holeDepth, 
    getUnitValue , ethene, propene,
  } = useContext(ConfigContext);
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    if (state?.navigate === undefined && typeof navigate === 'function') {
      setState?.({ navigate });
    }
  }, [navigate, state, setState]);

  const cardData: IndicatorCardType[] = useMemo(() => {
    return [
      {
        title: 'Hole Depth',
        value: holeDepth,
        unit:  holeDepth === 'N/A' ? '' : getUnitValue?.(),
        color: "#E27730"
      },
      {
        title: 'Total Carbon',
        value: totalCarbon,
        unit: '',
        color: "#29B16D"
      },
      {
        title: 'API',
        value: api,
        unit: '',
        color: "#298FEC"
      },
      {
        title: 'GOR',
        value: gor,
        unit: '',
        color: "#298FEC"
      },
      {
        title: 'Ethene',
        value: ethene,
        unit: '',
        color: "#A459FD"
      },
      {
        title: 'Propene',
        value: propene,
        unit: '',
        color: "#A459FD"
      },
    ];
  }, [
    api, gor, holeDepth, totalCarbon,
    getUnitValue, ethene, propene
  ]);


  return (
    <Container>
      <Sidebar />
      <MainWrapper>
        {params?.id && location?.pathname?.includes('/home/well') ? (
          <CardsWrapper>
            <CardList data={cardData} />
          </CardsWrapper>
        ) : null}
        {location?.pathname?.includes('/home/well/') ? (
          <ChartsWrapper>
            {children}
          </ChartsWrapper>
          ) : children}
      </MainWrapper>
    </Container>
  );
};
