import styled from "styled-components";
import BaseButton from "./Base";

const Secondary = styled(BaseButton)`
  && {
    background-color: #212438;
    border: 1px solid #2F334D;
    transition: border 0.1s;
  }

  &:hover {
    border: 3px solid #7B809E;
  }

  &:disabled {
    background-color: #0C0C14;
  }
`

export default Secondary;