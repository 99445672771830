import styled from "styled-components";
import { Popper } from "@mui/material";
import { customTheme } from '../../theme/default'

export const PopperStyled = styled(Popper)`
  .MuiAutocomplete-paper {
    background-color: ${customTheme.blue1};
    color: ${customTheme.gray4};
    border-radius: 8px;
  }
`