import { useContext, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchInWellWatcher, fetchInWellWatcherSites } from 'api/wellwatcher';
import { listWellsMetadata, parseWellDataIntegration } from 'api/import-data';
import { deleteWell } from 'api/wells';
import { removeWell, receiveWells, receiveMetadata } from 'actions/wells';
import { successNotification, errorNotification } from 'utils';
import { ImportWellActionEnum } from '../components/ImportWellAction';
import ConfigContext from '@geowellex/shared-ui/src/context/ConfigContext';

export interface UseDataTableProps {
  setLoading?: (loading: boolean) => void;
  setCanDelete?: (canDelete: boolean) => void;
  token: any;
  selectedKey?: any;
}

function mapWellData(input: any) {
  return {
    uid: input.uid,
    uidWell: input.uidWell,
    uidWellbore: input.uidWellbore,
    wellName: input.nameWell,
    logName: input.logName,
    start_depth: input.startIndex,
    stop_depth: input.endIndex,
    index_unit: input.indexUnit,
    is_active: !!(input.isActive == 'true'),
    old_data: { ...input }
  };
};

const useDataTable = ({ setLoading, setCanDelete, token, selectedKey }: UseDataTableProps) => {
  const { wellsItems, handleOpenImportData, setWellUid } = useContext(ConfigContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [wellsData, setWellsData] = useState([]);

  const checkIfExistsWell = (wells: any) => (wellName: string) => {
    return wells.find((well: any) => well.wellName === wellName);
  };

  async function updateWellMetadataList(token: any) {
    try {
      const response = await listWellsMetadata(token)
      const updatedWells = response.data.content;
      dispatch(receiveWells(updatedWells));
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteWell = async (wellUid: any) => {
    if (!wellUid) return;
    if (setLoading) setLoading(true);
    try {
      if (wellUid === selectedKey) {
        dispatch(removeWell(token, wellUid));
      } else {
        await deleteWell(token, wellUid);
      }
      await updateWellMetadataList(token);
      successNotification('Success', 'Well deleted successfully');
    } catch (err) {
      console.log(err);
      errorNotification('Error', 'An error occurred while deleting the well');
    } finally {
      if (setLoading) setLoading(false);
    }
  };

  const selectAvailableWell = (well: any) => {
    setWellUid?.(well.uid);
    navigate(`/home/well/${well.uid}`);
  };

  const importWellFromCloudConnection = async (token: any, well: any) => {
    if (setLoading) setLoading(true);
    try {
      const response = await fetchInWellWatcher(token, well.source, well.uid, well.uidWell);
      const isThereConflict = checkIfExistsWell(wellsItems);
      if (!isThereConflict) return;
      const payload = { well_metadata: well, well_data: response.data };
      const { data: { content: parsedMetadata } } = await parseWellDataIntegration(payload, token);
      dispatch(receiveMetadata(parsedMetadata));
      if (handleOpenImportData) {
        handleOpenImportData();
      }
    } catch (e) {
      console.error(e);
      errorNotification('Error', 'An error occurred while importing the well');
    } finally {
      if (setLoading) setLoading(false);
      if (setCanDelete) setCanDelete(true);
    }
  };

  const uploadCloudWell = useCallback(async () => {
    if (setLoading) setLoading(true);
    try {
      const response = await fetchInWellWatcherSites(token);
      const data = response.data.map((well: any) => mapWellData(well));
      setWellsData(data);
      if(setCanDelete) setCanDelete(false);
    } catch (e) {
      console.error(e)
    } finally {
      if (setLoading) setLoading(false);
    }
  }, []);

  const handleSelectWell = (well: any, importType: ImportWellActionEnum) => {
    if (importType === ImportWellActionEnum.AVAILABLE_WELLSITES) {
      selectAvailableWell(well);
    }
    else if (importType === ImportWellActionEnum.CLOUD_CONNECTION) {
      importWellFromCloudConnection(token, well?.old_data);
    }
  };

  return {
    updateWellMetadataList,
    handleDeleteWell,
    importWellFromCloudConnection,
    wellsData,
    setWellsData,
    uploadCloudWell,
    selectAvailableWell,
    handleSelectWell
  };
};

export default useDataTable;