import React, {
  memo,
  useEffect,
  useState
} from 'react';

import { useSelector } from 'react-redux';
import store from 'store';
import {
  nFormatter
} from 'utils';
import Ui from '@geowellex/shared-ui'
import { DEFAULT_SCALES } from 'utils/constants';
import { toggleQualityChart } from '../../../../utils';

const updatedHeader = {
  scales: store.get('configScales') || { ...DEFAULT_SCALES },
  goodData: 0,
  badData: 0
};

const initialHeader = {
  goodData: 0,
  badData: 0
};

const GOOD_DATA = 'goodData';
const BAD_DATA = 'badData';

const ChartHeader = ({qualityData}) => {
  const wells = useSelector(state => state.wells);

  // --- Intital State
  const [headerState, setHeaderState] = useState({
    ...initialHeader,
    scales: store.get('configScales') || { ...DEFAULT_SCALES }
  });

  // --- Toggle Tracks
  const [tracks, setToggle] = useState({
    isOpen: false,
    goodDataToggle: true,
    badDataToggle: true
  });

  const tooggleCallbacks = {
    goodDataToggle: toggleQualityChart,
    badDataToggle: toggleQualityChart
  };

  const toggleTrackCurrentTrack = event => {
    const {
      dataset: { name, index }
    } = event.target;
    const trackName = tracks[name];
    const callback = tooggleCallbacks[name];
    if (callback) {
      callback(parseInt(index))(event);
      setToggle({
        ...tracks,
        isOpen: !tracks.isOpen,
        [name]: !trackName
      });
    }
  };

  const dispatchModal = typeOfTitle => event => {
    event.persist();
    event.stopPropagation();
    if (event.target && event.target.dataset.cmd === 'toggle') {
      toggleTrackCurrentTrack(event);
      return;
    } else if (event.target && event.target.dataset.cmd === 'openConfig') {
      const openModal = new CustomEvent('openConfigModal', {
        detail: {
          type: typeOfTitle
        }
      });
      document.dispatchEvent(openModal);
      return;
    }
  };

  useEffect(() => {
    if (wells.currentWell && wells.selectedDepthIndex >= 0) {
      const index = wells.selectedDepthIndex ?? 0;

      const goodDataValue = qualityData?.goodData?.[index] ? qualityData?.goodData?.[index] : 0;

      const badDataValue = qualityData?.badData?.[index] ? qualityData?.badData?.[index] : 0;

      setHeaderState({
        ...headerState,
        goodData: goodDataValue > 0 ? goodDataValue?.toFixed(2) : goodDataValue,
        badData: badDataValue > 0 ? badDataValue?.toFixed(2) : badDataValue,
      });
    }
  }, [wells]);

  useEffect(() => {
    setHeaderState({
      scales: store.get('configScales')
    });

    const sfChartListener = (currentTrack) => ({ detail }) => {
      headerState.scales[currentTrack] = detail;
      setHeaderState({
        ...headerState,
      });
    };

    const qualityCharts = [
      'goodData',
      'badData',
    ];

    const goodData = sfChartListener('goodData');
    const badData = sfChartListener('badData');

    const callbackListeners = {
      goodData,
      badData,
    };

    // Add event listeners
    for (let i = 0; i < qualityCharts.length; i++) {
      const currentTrack = qualityCharts[i];
      document.addEventListener(currentTrack, callbackListeners[currentTrack]);
    }

    return () => {
      // Remove all event listeners
      for (let i = 0; i < qualityCharts.length; i++) {
        const currentTrack = qualityCharts[i];
        document.removeEventListener(currentTrack, callbackListeners[currentTrack]);
      }
    };
  }, []);

  useEffect(() => {
    // Scales listener
    const badDataConfigListener = ({ detail }) => {
      updatedHeader.scales.badData = detail;
      headerState.scales.badData = detail;

      setHeaderState({
        ...headerState,
        ...updatedHeader
      });
    };
    document.addEventListener(BAD_DATA, badDataConfigListener);

    const aiGrConfigListener = ({ detail }) => {
      updatedHeader.scales.goodData = detail;
      headerState.scales.goodData = detail;
      setHeaderState({
        ...headerState,
        ...updatedHeader
      });
    };
    document.addEventListener(GOOD_DATA, aiGrConfigListener);

    // ---- Remove listeners
    return () => {
      document.removeEventListener(BAD_DATA, badDataConfigListener);
      document.removeEventListener(GOOD_DATA, aiGrConfigListener);
    };
  }, []);

  const { goodData, badData, scales } = headerState;

  const badDataColor = scales && scales.badData ? scales.badData.color : DEFAULT_SCALES.badData.color;
  const goodDataColor = scales && scales.goodData ? scales.goodData.color : DEFAULT_SCALES.goodData.color;
  const goodDataMin = scales && scales.goodData ? scales && scales.goodData.min : DEFAULT_SCALES.goodData.min;
  const goodDataMax = scales && scales.goodData ? scales && scales.goodData.max : DEFAULT_SCALES.goodData.max;
  const badDataMin = scales && scales.badData ? scales && scales.badData.min : DEFAULT_SCALES.badData.min;
  const badDataMax = scales && scales.badData ? scales && scales.badData.max : DEFAULT_SCALES.badData.max;

  return (
    <div
      style={{
        display: 'flex',
        height: '132px',
        width: '140px',
        gap: '5px',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        position: 'relative'
      }}
    >
      <Ui.Header
        min={nFormatter(goodDataMin)}
        max={nFormatter(goodDataMax)}
        textCenter={`Good Data`}
        background={goodDataColor}
        checkboxIsVisible
        checked={tracks.goodDataToggle}
        onChangeCheckbox={toggleTrackCurrentTrack}
        onClick={dispatchModal(GOOD_DATA)}
        activeCurveConfig={GOOD_DATA}
        dataCy="open-config-good-data"
      />
      <Ui.Header
        min={nFormatter(badDataMin)}
        max={nFormatter(badDataMax)}
        textCenter={`Bad Data`}
        background={badDataColor}
        checkboxIsVisible
        checked={tracks.badDataToggle}
        onChangeCheckbox={toggleTrackCurrentTrack}
        onClick={dispatchModal(BAD_DATA)}
        activeCurveConfig={BAD_DATA}
        dataCy="open-config-bad-data"
      />
    </div>
  );
};

export default memo(ChartHeader);