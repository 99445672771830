import styled from "styled-components";
import { Box } from "@material-ui/core";
import { customTheme } from '@geowellex/shared-ui/src/theme/default';

export const EmptyZoneContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
  gap: 16px;
`

export const Title = styled.span`
  color: ${customTheme.white};
  font-weight: 700;
  font-size: 21px;
`

export const MoreInfo = styled.span`
  color: ${customTheme.gray1};
  font-size: 16px;
`